/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
    'Boas-vindas!': 'Welcome!',
    'Esqueceu sua senha?': 'Forgot your password?',
    Senha: 'Password',
    ENVIAR: 'SEND',
    Enviar: 'Send',

    // Invitee Metadata
    Nome: 'Name',
    'E-mail': 'E-mail',
    País: 'Country',
    UF: 'State',
    'Estou fora do Brasil': 'I am not in Brazil',
    Cidade: 'City',
    'Última titulação': 'Last degree',
    Sexo: 'Gender',
    'Vínculo Institucional': 'Institutional bond',
    Doutorado: 'Doctorate degree',
    Mestrado: 'Master degree',
    Graduação: 'Graduation',
    'Pós-Doutorado': 'post doctoral',
    'Pós-Graduação': 'postgraduate',
    Outros: 'Other',
    Masculino: 'Male',
    Feminino: 'Female',
    'Apagar meus dados': 'Clean all data',
    'Instituição de ensino e pesquisa pública': 'Government/state-owned teaching and research institution',
    'Instituição de ensino e pesquisa privada': 'Private teaching and research institution',
    'Administração pública': 'Public administration',
    'Empresa privada': 'Private company',
    'Empresa pública': 'Government/state-owned company',
    'Economia mista': 'Mixed economy organization',
    'Terceiro Setor': 'Third sector organization',

    // Questions
    'Digite sua resposta': 'Type here',
    'Digite um número inteiro': 'Enter an integer',
    'Digite um número': 'Enter a number',
    'Não desejo responder': 'Don`t send an answer',
    'Pergunta não obrigatória tooltext': 'Non-obligatory question. Check the box to not submit an answer to this question.',
    'Mediana das respostas dos outros participantes da pesquisa': 'Median value of the responses from other survey participants.',
    'Digite um número inteiro entre': 'Enter a number between',
    'Digite um número entre': 'Enter a number between',
    e: 'and',

    // Actions
    'Salvar rascunho': 'Save draft',
    Fechar: 'Close',
    Salvar: 'Save',
    'Após o rascunho ser salvo, você pode fechar a pesquisa e continuá-la em outro momento pelo link enviado a seu email': 'After the draft is saved, you can close the survey and continue it at any  time using the link sent to your email.',
    'Confirmar envio': 'Confirm submission',
    'Gostaria de confirmar o envio das respostas?': 'Would you like to confirm the submission of your answers?',
    Ok: 'Ok',

    // Yup validations
    'Pergunta obrigatória': 'Required',
    'O valor mínimo para esta questão é': 'The minimum value for this question is',
    'O valor máximo para esta questão é': 'The maximum value for this question is',
    'Email inválido': 'Invalid e-mail',
    'A UF é obrigatória': 'State is required',
    'A cidade é obrigatória': 'City is required',
    'A ocupação é obrigatória': 'The institutional bond is required',
    'A titulação é obrigatória': 'The last degree is required',
    'Especificação da titulação é obrigatório': 'The last degree is required',
    'O nome é obrigatório': 'The name is required',
    'O email é obrigatório': 'The e-mail is required',
    'O sexo é obrigatório': 'The gender is required',
    'Dia de nascimento é obrigatório': 'Birthday is required',
    'Senha é obrigatória': 'Password is required',
    'Senha deve conter 6 caracteres': 'Password must have at least 6 characters',
    'Senha deve ter no mínimo um caractere especial': 'Password must have at least one special character',
    'Senha deve ter no mínimo uma letra maiúscula': 'Password must have at least one uppercase letter',
    'Respostas incompletas': 'Uncomplete answers',
    'O valor deve ser inteiro': 'Value must be an integer',
    'Pergunta X obrigatória': 'Question %{x} is required',

    // Submission made
    'Sua resposta foi registrada com sucesso': 'Your answers were submitted successfully.',
    'Seu rascunho foi salvo e o link para você continuar a pesquisa foi mandado a seu email': 'Your draft has been saved and a link to continue your submission has been sent to your email',

    // Actor motricity
    'descrição pesquisa motricidade do ator': 'Fill the sections below with values that represent the capacity that the actor on the left has on changing the behavior/occurence of the variable on the right, using the following values:\n0 – No influence \n1 – Low influence \n2 – Average influence \n3 – Decisive influence to the event`s occurrence',
    'Indique um valor para a capacidade do ator da esquerda de interferir na variável da direita': 'Assign the value relative to the ability that the actor on the left has to influence the variable on the right',
    'Ator seguinte': 'Next actor',
    'Ator anterior': 'Previous actor',
    'Respondendo seção': 'Section',
    de: 'of',

    // Motricity and dependency
    'descrição pesquisa motricidade e dependência': 'Fill the sections below with values that represent the capacity that the variable on the left has on changing the behavior/occurence of the variable on the right, using the following values:\n0 – No influence \n1 – Low influence \n2 – Average influence \n3 – Decisive influence to the event`s occurrence',
    'Variável seguinte': 'Next variable',
    'Variável anterior': 'Previous variable',
    'Indique um valor para a capacidade da variável da esquerda de interferir na variável da direita': 'Assign the value relative to the ability that the variable on the left has to influence the variable on the right',

    // Critical Uncertainties
    Baixa: 'Low',
    Alta: 'High',
    Importância: 'Importance',
    'Probabilidade de Ocorrência': 'Probability of occurrence',

    // Eisenhower
    Urgência: 'Urgency',
};

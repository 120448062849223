import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom Components
import t from '@services/locale';

// Styles
import { GetParsedColor } from '@styles/colors';
import {
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import Box from '../Box';

import * as faIcons from './faIcons';

const FaIcon = ({
    clickable = false, color, handleClick,
    name, scale, size = '1x', style,
    tooltipText, translate = true,
}) => (
    <Tooltip
        disableHoverListener={!tooltipText}
        placement="top"
        title={translate ? t(tooltipText || '') : tooltipText || ''}
    >
        <Box style={style}>
            <FontAwesomeIcon
                icon={faIcons[name]}
                size={size}
                style={{
                    color: GetParsedColor(color),
                    cursor: clickable ? 'pointer' : '',
                    transform: `scale(${scale || 1})`,
                }}
                onClick={() => handleClick?.()}
            />
        </Box>
    </Tooltip>
);

FaIcon.propTypes = {
    clickable: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    scale: PropTypes.number.isRequired,
    tooltipText: PropTypes.string.isRequired,
    translate: PropTypes.bool.isRequired,
    ...sizePropsTypes,
    ...paddingPropsTypes,
    ...marginPropsTypes,
};

export default FaIcon;

import React from 'react';
import PropTypes from 'prop-types';

import { EisenhowerQuestion } from '@components/organisms';

const EisenhowerForm = ({ control, formState, getValues }) => {
    let fields = getValues().answers;
    fields = fields.filter((value, index) => !(index % 2));
    return (
        <>
            {fields.map((question, idx) => (
                <EisenhowerQuestion
                    control={control}
                    formState={formState}
                    idx={idx}
                    key={String(question.num_order)}
                    question={question}
                />
            ))}
        </>
    );
};

EisenhowerForm.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            answers: PropTypes.shape({
                importance_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
                urgency_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
            }),
        }),
    }).isRequired,
    getValues: PropTypes.func.isRequired,
};

export default EisenhowerForm;

import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@components/atoms';
import { CustomContainer, Slider } from '@components/molecules';
import { capitalizeFirstLetters } from '@services/utils';

const GenericMatrixQuestion = ({
    control, idx, question,
}) => {
    const axis_y = question.num_order - 1;
    const axis_x = question.num_order;
    const { max, min } = question.axes;
    const steps = max - 1;

    return (
        <CustomContainer>
            <Text bold color="black" translate={false}>
                {idx + 1}
                .&nbsp;
                {question.statement}
            </Text>
            <Text translate={false}>{question.context}</Text>

            <Text bold>{capitalizeFirstLetters(question.axes.y)}</Text>
            <Slider
                control={control}
                max={max}
                max_label={max}
                min={min}
                min_label={min}
                name={`answers.${axis_y}.answer`}
                steps={steps}
                type="discrete"
            />

            <Text bold>{capitalizeFirstLetters(question.axes.x)}</Text>
            <Slider
                control={control}
                max={max}
                max_label={max}
                min={min}
                min_label={min}
                name={`answers.${axis_x}.answer`}
                steps={steps}
                type="discrete"
            />
        </CustomContainer>
    );
};

GenericMatrixQuestion.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    idx: PropTypes.number.isRequired,
    question: PropTypes.shape({
        axes: PropTypes.shape({
            max: PropTypes.number,
            min: PropTypes.number,
            x: PropTypes.string,
            y: PropTypes.string,
        }).isRequired,
        context: PropTypes.string,
        num_order: PropTypes.number,
        statement: PropTypes.string,
    }).isRequired,
};

export default GenericMatrixQuestion;

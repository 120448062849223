import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { splitEvery } from 'ramda';

import device from '@styles';
import { Box } from '@components/atoms';
import {
    Button,
    CustomContainer,
    MotricityProgress,
} from '@components/molecules';
import {
    MotricityDependenceQuestion,
} from '@components/organisms';
import { GetParsedColor } from '@styles/colors';

import t from '@services/locale';

const MotricityDependenceForm = ({
    control, getValues, onSubmitEnabled,
}) => {
    const [hasPreviousValue, setHasPreviousValue] = useState(false);
    const [hasNextValue, setHasNextValue] = useState(true);
    const [section, setSection] = useState(1);
    const [isDone, setIsDone] = useState(false);

    const currentRow = section - 1;
    const fields = getValues('answers');
    const numColumns = Math.sqrt(fields.length);

    const chunks = useMemo(() => splitEvery(numColumns, fields), [fields]);
    const sectionTitleRef = useRef(null);

    const scrollToSectionTitle = () => {
        sectionTitleRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        setHasPreviousValue(section > 1);
        const hasNext = chunks.length > section;
        setHasNextValue(hasNext);
        setIsDone((currentIsDone) => currentIsDone || !hasNext);
        scrollToSectionTitle();
    }, [section]);

    useEffect(() => {
        onSubmitEnabled(isDone);
    }, [isDone]);

    const previous = () => {
        setSection((currentSection) => currentSection - 1);
    };

    const next = () => {
        setSection((currentSection) => currentSection + 1);
    };

    const descriptionText = t('descrição pesquisa motricidade e dependência');
    const lightBlue = GetParsedColor('lightBlue');

    return (
        <>
            <CustomContainer>
                {descriptionText}
            </CustomContainer>
            <MotricityProgress
                currentQuestion={section}
                ref={sectionTitleRef}
                totalQuestions={chunks.length}
            />
            {
                chunks[currentRow].map((variable, index) => currentRow !== index && (
                    <MotricityDependenceQuestion
                        answers={fields}
                        control={control}
                        key={variable.short_name}
                        selectedCell={{
                            column: index,
                            context: variable.context,
                            row: currentRow,
                            shortName: variable.short_name,
                            statement: variable.statement,
                        }}
                    />
                ))
            }
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Button.Step
                    direction="left"
                    isDisabled={!hasPreviousValue}
                    style={{
                        borderColor: lightBlue,
                        width: device === 'web' ? '15rem' : '40vw',
                    }}
                    title="Variável anterior"
                    variant="outline"
                    onClick={previous}
                />

                <Button.Step
                    direction="right"
                    isDisabled={!hasNextValue}
                    style={{
                        borderColor: lightBlue,
                        width: device === 'web' ? '15rem' : '40vw',
                    }}
                    title="Variável seguinte"
                    variant="solid"
                    onClick={next}
                />
            </Box>
        </>
    );
};

MotricityDependenceForm.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    getValues: PropTypes.func.isRequired,
    onSubmitEnabled: PropTypes.func.isRequired,
};

export default MotricityDependenceForm;

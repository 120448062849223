export const styles = {
    styledTableRowEven: {
        backgroundColor: '#EAEAEA',
        minWidth: 43,
    },
    styledTableRowOdd: {
        backgroundColor: 'white',
        minWidth: 43,
    },
    tableFirstRowEven: {
        backgroundColor: '#64B4A0',
        borderBottom: 'none',
        color: 'white',
        minWidth: 43,
    },
    tableFirstRowOdd: {
        backgroundColor: '#589986',
        borderBottom: 'none',
        color: 'white',
        minWidth: 43,
    },
    tableHeadEven: {
        backgroundColor: '#64B4A0',
        color: 'white',
        minWidth: 43,
    },
    tableHeadOdd: {
        backgroundColor: '#589986',
        color: 'white',
        minWidth: 43,
    },
};

export default styles;

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// Material UI
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom
import t from '@services/locale';
import { GetParsedColor } from '@styles/colors';
import { Box, Text } from '@components/atoms';
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';

const TextArea = (props) => {
    const {
        control,
        defaultValue,
        error,
        mt,
        name,
        placeholder,
        translate = false,
        value,
        width,
    } = props;

    const darkGray = GetParsedColor('grey.900');
    const light_grey = GetParsedColor('grey.500');
    const green = GetParsedColor('green');
    const blue = GetParsedColor('blue');
    const theme = createTheme({
        palette: {
            primary: { main: green },
            secondary: { main: blue },
            text: { primary: darkGray, secondary: darkGray },
        },
        typography: { allVariants: { color: light_grey } },
    });
    if (control) {
        return (
            <>
                <Box ml="1vw" mt={mt} width={width}>
                    <ThemeProvider theme={theme}>
                        <Controller
                            control={control}
                            defaultValue={defaultValue || ''}
                            name={name}
                            render={({ field }) => (
                                <FormControl style={{ width: width || '20vw' }}>
                                    <TextField
                                        multiline
                                        error={(!!error)}
                                        helperText={error}
                                        id={name}
                                        label={translate ? t(placeholder) : placeholder}
                                        name={field.name}
                                        ref={field.ref}
                                        value={field.value}
                                        onBlur={field.onBlur}
                                        onChange={field.onChange}
                                    />
                                </FormControl>
                            )}
                        />
                    </ThemeProvider>
                </Box>
            </>
        );
    }
    return (
        <>
            <Box ml="1vw" mt={mt} width={width}>
                <ThemeProvider theme={theme}>

                    <FormControl style={{ width: width || '20vw' }}>
                        <TextField
                            multiline
                            id={name}
                            label={t(placeholder)}
                            value={value}
                        />
                    </FormControl>
                </ThemeProvider>
            </Box>
            {error && typeof error === 'string' && (
                <Text color="red" ml={2} mt={0.5}>
                    {error}
                </Text>
            )}
        </>
    );
};

TextArea.defaultProps = {
    error: undefined,
};

TextArea.propTypes = {
    error: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

TextArea.defaultProps = {
    value: '',
};

export default TextArea;

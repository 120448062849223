export const UFS = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Tocantins', value: 'TO' },
];

export const CITIES = {
    AC: [
        { label: 'Acrelândia', value: 'Acrelândia' },
        { label: 'Assis Brasil', value: 'Assis Brasil' },
        { label: 'Brasiléia', value: 'Brasiléia' },
        { label: 'Bujari', value: 'Bujari' },
        { label: 'Capixaba', value: 'Capixaba' },
        { label: 'Cruzeiro do Sul', value: 'Cruzeiro do Sul' },
        { label: 'Epitaciolândia', value: 'Epitaciolândia' },
        { label: 'Feijó', value: 'Feijó' },
        { label: 'Jordão', value: 'Jordão' },
        { label: 'Mâncio Lima', value: 'Mâncio Lima' },
        { label: 'Manoel Urbano', value: 'Manoel Urbano' },
        { label: 'Marechal Thaumaturgo', value: 'Marechal Thaumaturgo' },
        { label: 'Plácido de Castro', value: 'Plácido de Castro' },
        { label: 'Porto Acre', value: 'Porto Acre' },
        { label: 'Porto Walter', value: 'Porto Walter' },
        { label: 'Rio Branco', value: 'Rio Branco' },
        { label: 'Rodrigues Alves', value: 'Rodrigues Alves' },
        { label: 'Santa Rosa do Purus', value: 'Santa Rosa do Purus' },
        { label: 'Sena Madureira', value: 'Sena Madureira' },
        { label: 'Senador Guiomard', value: 'Senador Guiomard' },
        { label: 'Tarauacá', value: 'Tarauacá' },
        { label: 'Xapuri', value: 'Xapuri' },
    ],
    AL: [
        { label: 'Água Branca', value: 'Água Branca' },
        { label: 'Anadia', value: 'Anadia' },
        { label: 'Arapiraca', value: 'Arapiraca' },
        { label: 'Atalaia', value: 'Atalaia' },
        { label: 'Barra de Santo Antônio', value: 'Barra de Santo Antônio' },
        { label: 'Barra de São Miguel', value: 'Barra de São Miguel' },
        { label: 'Batalha', value: 'Batalha' },
        { label: 'Belém', value: 'Belém' },
        { label: 'Belo Monte', value: 'Belo Monte' },
        { label: 'Boca da Mata', value: 'Boca da Mata' },
        { label: 'Branquinha', value: 'Branquinha' },
        { label: 'Cacimbinhas', value: 'Cacimbinhas' },
        { label: 'Cajueiro', value: 'Cajueiro' },
        { label: 'Campestre', value: 'Campestre' },
        { label: 'Campo Alegre', value: 'Campo Alegre' },
        { label: 'Campo Grande', value: 'Campo Grande' },
        { label: 'Canapi', value: 'Canapi' },
        { label: 'Capela', value: 'Capela' },
        { label: 'Carneiros', value: 'Carneiros' },
        { label: 'Chã Preta', value: 'Chã Preta' },
        { label: 'Coité do Nóia', value: 'Coité do Nóia' },
        { label: 'Colônia Leopoldina', value: 'Colônia Leopoldina' },
        { label: 'Coqueiro Seco', value: 'Coqueiro Seco' },
        { label: 'Coruripe', value: 'Coruripe' },
        { label: 'Craíbas', value: 'Craíbas' },
        { label: 'Delmiro Gouveia', value: 'Delmiro Gouveia' },
        { label: 'Dois Riachos', value: 'Dois Riachos' },
        { label: 'Estrela de Alagoas', value: 'Estrela de Alagoas' },
        { label: 'Feira Grande', value: 'Feira Grande' },
        { label: 'Feliz Deserto', value: 'Feliz Deserto' },
        { label: 'Flexeiras', value: 'Flexeiras' },
        { label: 'Girau do Ponciano', value: 'Girau do Ponciano' },
        { label: 'Ibateguara', value: 'Ibateguara' },
        { label: 'Igaci', value: 'Igaci' },
        { label: 'Igreja Nova', value: 'Igreja Nova' },
        { label: 'Inhapi', value: 'Inhapi' },
        { label: 'Jacaré dos Homens', value: 'Jacaré dos Homens' },
        { label: 'Jacuípe', value: 'Jacuípe' },
        { label: 'Japaratinga', value: 'Japaratinga' },
        { label: 'Jaramataia', value: 'Jaramataia' },
        { label: 'Jequiá da Praia', value: 'Jequiá da Praia' },
        { label: 'Joaquim Gomes', value: 'Joaquim Gomes' },
        { label: 'Jundiá', value: 'Jundiá' },
        { label: 'Junqueiro', value: 'Junqueiro' },
        { label: 'Lagoa da Canoa', value: 'Lagoa da Canoa' },
        { label: 'Limoeiro de Anadia', value: 'Limoeiro de Anadia' },
        { label: 'Maceió', value: 'Maceió' },
        { label: 'Major Isidoro', value: 'Major Isidoro' },
        { label: 'Mar Vermelho', value: 'Mar Vermelho' },
        { label: 'Maragogi', value: 'Maragogi' },
        { label: 'Maravilha', value: 'Maravilha' },
        { label: 'Marechal Deodoro', value: 'Marechal Deodoro' },
        { label: 'Maribondo', value: 'Maribondo' },
        { label: 'Mata Grande', value: 'Mata Grande' },
        { label: 'Matriz de Camaragibe', value: 'Matriz de Camaragibe' },
        { label: 'Messias', value: 'Messias' },
        { label: 'Minador do Negrão', value: 'Minador do Negrão' },
        { label: 'Monteirópolis', value: 'Monteirópolis' },
        { label: 'Murici', value: 'Murici' },
        { label: 'Novo Lino', value: 'Novo Lino' },
        "Olho d'Água das Flores",
        "Olho d'Água do Casado",
        "Olho d'Água Grande",
        { label: 'Olivença', value: 'Olivença' },
        { label: 'Ouro Branco', value: 'Ouro Branco' },
        { label: 'Palestina', value: 'Palestina' },
        { label: 'Palmeira dos Índios', value: 'Palmeira dos Índios' },
        { label: 'Pão de Açúcar', value: 'Pão de Açúcar' },
        { label: 'Pariconha', value: 'Pariconha' },
        { label: 'Paripueira', value: 'Paripueira' },
        { label: 'Passo de Camaragibe', value: 'Passo de Camaragibe' },
        { label: 'Paulo Jacinto', value: 'Paulo Jacinto' },
        { label: 'Penedo', value: 'Penedo' },
        { label: 'Piaçabuçu', value: 'Piaçabuçu' },
        { label: 'Pilar', value: 'Pilar' },
        { label: 'Pindoba', value: 'Pindoba' },
        { label: 'Piranhas', value: 'Piranhas' },
        { label: 'Poço das Trincheiras', value: 'Poço das Trincheiras' },
        { label: 'Porto Calvo', value: 'Porto Calvo' },
        { label: 'Porto de Pedras', value: 'Porto de Pedras' },
        { label: 'Porto Real do Colégio', value: 'Porto Real do Colégio' },
        { label: 'Quebrangulo', value: 'Quebrangulo' },
        { label: 'Rio Largo', value: 'Rio Largo' },
        { label: 'Roteiro', value: 'Roteiro' },
        { label: 'Santa Luzia do Norte', value: 'Santa Luzia do Norte' },
        { label: 'Santana do Ipanema', value: 'Santana do Ipanema' },
        { label: 'Santana do Mundaú', value: 'Santana do Mundaú' },
        { label: 'São Brás', value: 'São Brás' },
        { label: 'São José da Laje', value: 'São José da Laje' },
        { label: 'São José da Tapera', value: 'São José da Tapera' },
        { label: 'São Luís do Quitunde', value: 'São Luís do Quitunde' },
        { label: 'São Miguel dos Campos', value: 'São Miguel dos Campos' },
        { label: 'São Miguel dos Milagres', value: 'São Miguel dos Milagres' },
        { label: 'São Sebastião', value: 'São Sebastião' },
        { label: 'Satuba', value: 'Satuba' },
        { label: 'Senador Rui Palmeira', value: 'Senador Rui Palmeira' },
        "Tanque d'Arca",
        { label: 'Taquarana', value: 'Taquarana' },
        { label: 'Teotônio Vilela', value: 'Teotônio Vilela' },
        { label: 'Traipu', value: 'Traipu' },
        { label: 'União dos Palmares', value: 'União dos Palmares' },
        { label: 'Viçosa', value: 'Viçosa' },
    ],
    AM: [
        { label: 'Alvarães', value: 'Alvarães' },
        { label: 'Amaturá', value: 'Amaturá' },
        { label: 'Anamã', value: 'Anamã' },
        { label: 'Anori', value: 'Anori' },
        { label: 'Apuí', value: 'Apuí' },
        { label: 'Atalaia do Norte', value: 'Atalaia do Norte' },
        { label: 'Autazes', value: 'Autazes' },
        { label: 'Barcelos', value: 'Barcelos' },
        { label: 'Barreirinha', value: 'Barreirinha' },
        { label: 'Benjamin Constant', value: 'Benjamin Constant' },
        { label: 'Beruri', value: 'Beruri' },
        { label: 'Boa Vista do Ramos', value: 'Boa Vista do Ramos' },
        { label: 'Boca do Acre', value: 'Boca do Acre' },
        { label: 'Borba', value: 'Borba' },
        { label: 'Caapiranga', value: 'Caapiranga' },
        { label: 'Canutama', value: 'Canutama' },
        { label: 'Carauari', value: 'Carauari' },
        { label: 'Careiro', value: 'Careiro' },
        { label: 'Careiro da Várzea', value: 'Careiro da Várzea' },
        { label: 'Coari', value: 'Coari' },
        { label: 'Codajás', value: 'Codajás' },
        { label: 'Eirunepé', value: 'Eirunepé' },
        { label: 'Envira', value: 'Envira' },
        { label: 'Fonte Boa', value: 'Fonte Boa' },
        { label: 'Guajará', value: 'Guajará' },
        { label: 'Humaitá', value: 'Humaitá' },
        { label: 'Ipixuna', value: 'Ipixuna' },
        { label: 'Iranduba', value: 'Iranduba' },
        { label: 'Itacoatiara', value: 'Itacoatiara' },
        { label: 'Itamarati', value: 'Itamarati' },
        { label: 'Itapiranga', value: 'Itapiranga' },
        { label: 'Japurá', value: 'Japurá' },
        { label: 'Juruá', value: 'Juruá' },
        { label: 'Jutaí', value: 'Jutaí' },
        { label: 'Lábrea', value: 'Lábrea' },
        { label: 'Manacapuru', value: 'Manacapuru' },
        { label: 'Manaquiri', value: 'Manaquiri' },
        { label: 'Manaus', value: 'Manaus' },
        { label: 'Manicoré', value: 'Manicoré' },
        { label: 'Maraã', value: 'Maraã' },
        { label: 'Maués', value: 'Maués' },
        { label: 'Nhamundá', value: 'Nhamundá' },
        { label: 'Nova Olinda do Norte', value: 'Nova Olinda do Norte' },
        { label: 'Novo Airão', value: 'Novo Airão' },
        { label: 'Novo Aripuanã', value: 'Novo Aripuanã' },
        { label: 'Parintins', value: 'Parintins' },
        { label: 'Pauini', value: 'Pauini' },
        { label: 'Presidente Figueiredo', value: 'Presidente Figueiredo' },
        { label: 'Rio Preto da Eva', value: 'Rio Preto da Eva' },
        { label: 'Santa Isabel do Rio Negro', value: 'Santa Isabel do Rio Negro' },
        { label: 'Santo Antônio do Içá', value: 'Santo Antônio do Içá' },
        { label: 'São Gabriel da Cachoeira', value: 'São Gabriel da Cachoeira' },
        { label: 'São Paulo de Olivença', value: 'São Paulo de Olivença' },
        { label: 'São Sebastião do Uatumã', value: 'São Sebastião do Uatumã' },
        { label: 'Silves', value: 'Silves' },
        { label: 'Tabatinga', value: 'Tabatinga' },
        { label: 'Tapauá', value: 'Tapauá' },
        { label: 'Tefé', value: 'Tefé' },
        { label: 'Tonantins', value: 'Tonantins' },
        { label: 'Uarini', value: 'Uarini' },
        { label: 'Urucará', value: 'Urucará' },
        { label: 'Urucurituba', value: 'Urucurituba' },
    ],
    AP: [
        { label: 'Amapá', value: 'Amapá' },
        { label: 'Calçoene', value: 'Calçoene' },
        { label: 'Cutias', value: 'Cutias' },
        { label: 'Ferreira Gomes', value: 'Ferreira Gomes' },
        { label: 'Itaubal', value: 'Itaubal' },
        { label: 'Laranjal do Jari', value: 'Laranjal do Jari' },
        { label: 'Macapá', value: 'Macapá' },
        { label: 'Mazagão', value: 'Mazagão' },
        { label: 'Oiapoque', value: 'Oiapoque' },
        { label: 'Pedra Branca do Amapari', value: 'Pedra Branca do Amapari' },
        { label: 'Porto Grande', value: 'Porto Grande' },
        { label: 'Pracuúba', value: 'Pracuúba' },
        { label: 'Santana', value: 'Santana' },
        { label: 'Serra do Navio', value: 'Serra do Navio' },
        { label: 'Tartarugalzinho', value: 'Tartarugalzinho' },
        { label: 'Vitória do Jari', value: 'Vitória do Jari' },
    ],
    BA: [
        { label: 'Abaíra', value: 'Abaíra' },
        { label: 'Abaré', value: 'Abaré' },
        { label: 'Acajutiba', value: 'Acajutiba' },
        { label: 'Adustina', value: 'Adustina' },
        { label: 'Água Fria', value: 'Água Fria' },
        { label: 'Aiquara', value: 'Aiquara' },
        { label: 'Alagoinhas', value: 'Alagoinhas' },
        { label: 'Alcobaça', value: 'Alcobaça' },
        { label: 'Almadina', value: 'Almadina' },
        { label: 'Amargosa', value: 'Amargosa' },
        { label: 'Amélia Rodrigues', value: 'Amélia Rodrigues' },
        { label: 'América Dourada', value: 'América Dourada' },
        { label: 'Anagé', value: 'Anagé' },
        { label: 'Andaraí', value: 'Andaraí' },
        { label: 'Andorinha', value: 'Andorinha' },
        { label: 'Angical', value: 'Angical' },
        { label: 'Anguera', value: 'Anguera' },
        { label: 'Antas', value: 'Antas' },
        { label: 'Antônio Cardoso', value: 'Antônio Cardoso' },
        { label: 'Antônio Gonçalves', value: 'Antônio Gonçalves' },
        { label: 'Aporá', value: 'Aporá' },
        { label: 'Apuarema', value: 'Apuarema' },
        { label: 'Araças', value: 'Araças' },
        { label: 'Aracatu', value: 'Aracatu' },
        { label: 'Araci', value: 'Araci' },
        { label: 'Aramari', value: 'Aramari' },
        { label: 'Arataca', value: 'Arataca' },
        { label: 'Aratuípe', value: 'Aratuípe' },
        { label: 'Aurelino Leal', value: 'Aurelino Leal' },
        { label: 'Baianópolis', value: 'Baianópolis' },
        { label: 'Baixa Grande', value: 'Baixa Grande' },
        { label: 'Banzaê', value: 'Banzaê' },
        { label: 'Barra', value: 'Barra' },
        { label: 'Barra da Estiva', value: 'Barra da Estiva' },
        { label: 'Barra do Choça', value: 'Barra do Choça' },
        { label: 'Barra do Mendes', value: 'Barra do Mendes' },
        { label: 'Barra do Rocha', value: 'Barra do Rocha' },
        { label: 'Barreiras', value: 'Barreiras' },
        { label: 'Barro Alto', value: 'Barro Alto' },
        { label: 'Barro Preto', value: 'Barro Preto' },
        { label: 'Barrocas', value: 'Barrocas' },
        { label: 'Belmonte', value: 'Belmonte' },
        { label: 'Belo Campo', value: 'Belo Campo' },
        { label: 'Biritinga', value: 'Biritinga' },
        { label: 'Boa Nova', value: 'Boa Nova' },
        { label: 'Boa Vista do Tupim', value: 'Boa Vista do Tupim' },
        { label: 'Bom Jesus da Lapa', value: 'Bom Jesus da Lapa' },
        { label: 'Bom Jesus da Serra', value: 'Bom Jesus da Serra' },
        { label: 'Boninal', value: 'Boninal' },
        { label: 'Bonito', value: 'Bonito' },
        { label: 'Boquira', value: 'Boquira' },
        { label: 'Botuporã', value: 'Botuporã' },
        { label: 'Brejões', value: 'Brejões' },
        { label: 'Brejolândia', value: 'Brejolândia' },
        { label: 'Brotas de Macaúbas', value: 'Brotas de Macaúbas' },
        { label: 'Brumado', value: 'Brumado' },
        { label: 'Buerarema', value: 'Buerarema' },
        { label: 'Buritirama', value: 'Buritirama' },
        { label: 'Caatiba', value: 'Caatiba' },
        { label: 'Cabaceiras do Paraguaçu', value: 'Cabaceiras do Paraguaçu' },
        { label: 'Cachoeira', value: 'Cachoeira' },
        { label: 'Caculé', value: 'Caculé' },
        { label: 'Caém', value: 'Caém' },
        { label: 'Caetanos', value: 'Caetanos' },
        { label: 'Caetité', value: 'Caetité' },
        { label: 'Cafarnaum', value: 'Cafarnaum' },
        { label: 'Cairu', value: 'Cairu' },
        { label: 'Caldeirão Grande', value: 'Caldeirão Grande' },
        { label: 'Camacan', value: 'Camacan' },
        { label: 'Camaçari', value: 'Camaçari' },
        { label: 'Camamu', value: 'Camamu' },
        { label: 'Campo Alegre de Lourdes', value: 'Campo Alegre de Lourdes' },
        { label: 'Campo Formoso', value: 'Campo Formoso' },
        { label: 'Canápolis', value: 'Canápolis' },
        { label: 'Canarana', value: 'Canarana' },
        { label: 'Canavieiras', value: 'Canavieiras' },
        { label: 'Candeal', value: 'Candeal' },
        { label: 'Candeias', value: 'Candeias' },
        { label: 'Candiba', value: 'Candiba' },
        { label: 'Cândido Sales', value: 'Cândido Sales' },
        { label: 'Cansanção', value: 'Cansanção' },
        { label: 'Canudos', value: 'Canudos' },
        { label: 'Capela do Alto Alegre', value: 'Capela do Alto Alegre' },
        { label: 'Capim Grosso', value: 'Capim Grosso' },
        { label: 'Caraíbas', value: 'Caraíbas' },
        { label: 'Caravelas', value: 'Caravelas' },
        { label: 'Cardeal da Silva', value: 'Cardeal da Silva' },
        { label: 'Carinhanha', value: 'Carinhanha' },
        { label: 'Casa Nova', value: 'Casa Nova' },
        { label: 'Castro Alves', value: 'Castro Alves' },
        { label: 'Catolândia', value: 'Catolândia' },
        { label: 'Catu', value: 'Catu' },
        { label: 'Caturama', value: 'Caturama' },
        { label: 'Central', value: 'Central' },
        { label: 'Chorrochó', value: 'Chorrochó' },
        { label: 'Cícero Dantas', value: 'Cícero Dantas' },
        { label: 'Cipó', value: 'Cipó' },
        { label: 'Coaraci', value: 'Coaraci' },
        { label: 'Cocos', value: 'Cocos' },
        { label: 'Conceição da Feira', value: 'Conceição da Feira' },
        { label: 'Conceição do Almeida', value: 'Conceição do Almeida' },
        { label: 'Conceição do Coité', value: 'Conceição do Coité' },
        { label: 'Conceição do Jacuípe', value: 'Conceição do Jacuípe' },
        { label: 'Conde', value: 'Conde' },
        { label: 'Condeúba', value: 'Condeúba' },
        { label: 'Contendas do Sincorá', value: 'Contendas do Sincorá' },
        { label: 'Coração de Maria', value: 'Coração de Maria' },
        { label: 'Cordeiros', value: 'Cordeiros' },
        { label: 'Coribe', value: 'Coribe' },
        { label: 'Coronel João Sá', value: 'Coronel João Sá' },
        { label: 'Correntina', value: 'Correntina' },
        { label: 'Cotegipe', value: 'Cotegipe' },
        { label: 'Cravolândia', value: 'Cravolândia' },
        { label: 'Crisópolis', value: 'Crisópolis' },
        { label: 'Cristópolis', value: 'Cristópolis' },
        { label: 'Cruz das Almas', value: 'Cruz das Almas' },
        { label: 'Curaçá', value: 'Curaçá' },
        { label: 'Dário Meira', value: 'Dário Meira' },
        "Dias d'Ávila",
        { label: 'Dom Basílio', value: 'Dom Basílio' },
        { label: 'Dom Macedo Costa', value: 'Dom Macedo Costa' },
        { label: 'Elísio Medrado', value: 'Elísio Medrado' },
        { label: 'Encruzilhada', value: 'Encruzilhada' },
        { label: 'Entre Rios', value: 'Entre Rios' },
        { label: 'Érico Cardoso', value: 'Érico Cardoso' },
        { label: 'Esplanada', value: 'Esplanada' },
        { label: 'Euclides da Cunha', value: 'Euclides da Cunha' },
        { label: 'Eunápolis', value: 'Eunápolis' },
        { label: 'Fátima', value: 'Fátima' },
        { label: 'Feira da Mata', value: 'Feira da Mata' },
        { label: 'Feira de Santana', value: 'Feira de Santana' },
        { label: 'Filadélfia', value: 'Filadélfia' },
        { label: 'Firmino Alves', value: 'Firmino Alves' },
        { label: 'Floresta Azul', value: 'Floresta Azul' },
        { label: 'Formosa do Rio Preto', value: 'Formosa do Rio Preto' },
        { label: 'Gandu', value: 'Gandu' },
        { label: 'Gavião', value: 'Gavião' },
        { label: 'Gentio do Ouro', value: 'Gentio do Ouro' },
        { label: 'Glória', value: 'Glória' },
        { label: 'Gongogi', value: 'Gongogi' },
        { label: 'Governador Mangabeira', value: 'Governador Mangabeira' },
        { label: 'Guajeru', value: 'Guajeru' },
        { label: 'Guanambi', value: 'Guanambi' },
        { label: 'Guaratinga', value: 'Guaratinga' },
        { label: 'Heliópolis', value: 'Heliópolis' },
        { label: 'Iaçu', value: 'Iaçu' },
        { label: 'Ibiassucê', value: 'Ibiassucê' },
        { label: 'Ibicaraí', value: 'Ibicaraí' },
        { label: 'Ibicoara', value: 'Ibicoara' },
        { label: 'Ibicuí', value: 'Ibicuí' },
        { label: 'Ibipeba', value: 'Ibipeba' },
        { label: 'Ibipitanga', value: 'Ibipitanga' },
        { label: 'Ibiquera', value: 'Ibiquera' },
        { label: 'Ibirapitanga', value: 'Ibirapitanga' },
        { label: 'Ibirapuã', value: 'Ibirapuã' },
        { label: 'Ibirataia', value: 'Ibirataia' },
        { label: 'Ibitiara', value: 'Ibitiara' },
        { label: 'Ibititá', value: 'Ibititá' },
        { label: 'Ibotirama', value: 'Ibotirama' },
        { label: 'Ichu', value: 'Ichu' },
        { label: 'Igaporã', value: 'Igaporã' },
        { label: 'Igrapiúna', value: 'Igrapiúna' },
        { label: 'Iguaí', value: 'Iguaí' },
        { label: 'Ilhéus', value: 'Ilhéus' },
        { label: 'Inhambupe', value: 'Inhambupe' },
        { label: 'Ipecaetá', value: 'Ipecaetá' },
        { label: 'Ipiaú', value: 'Ipiaú' },
        { label: 'Ipirá', value: 'Ipirá' },
        { label: 'Ipupiara', value: 'Ipupiara' },
        { label: 'Irajuba', value: 'Irajuba' },
        { label: 'Iramaia', value: 'Iramaia' },
        { label: 'Iraquara', value: 'Iraquara' },
        { label: 'Irará', value: 'Irará' },
        { label: 'Irecê', value: 'Irecê' },
        { label: 'Itabela', value: 'Itabela' },
        { label: 'Itaberaba', value: 'Itaberaba' },
        { label: 'Itabuna', value: 'Itabuna' },
        { label: 'Itacaré', value: 'Itacaré' },
        { label: 'Itaeté', value: 'Itaeté' },
        { label: 'Itagi', value: 'Itagi' },
        { label: 'Itagibá', value: 'Itagibá' },
        { label: 'Itagimirim', value: 'Itagimirim' },
        { label: 'Itaguaçu da Bahia', value: 'Itaguaçu da Bahia' },
        { label: 'Itaju do Colônia', value: 'Itaju do Colônia' },
        { label: 'Itajuípe', value: 'Itajuípe' },
        { label: 'Itamaraju', value: 'Itamaraju' },
        { label: 'Itamari', value: 'Itamari' },
        { label: 'Itambé', value: 'Itambé' },
        { label: 'Itanagra', value: 'Itanagra' },
        { label: 'Itanhém', value: 'Itanhém' },
        { label: 'Itaparica', value: 'Itaparica' },
        { label: 'Itapé', value: 'Itapé' },
        { label: 'Itapebi', value: 'Itapebi' },
        { label: 'Itapetinga', value: 'Itapetinga' },
        { label: 'Itapicuru', value: 'Itapicuru' },
        { label: 'Itapitanga', value: 'Itapitanga' },
        { label: 'Itaquara', value: 'Itaquara' },
        { label: 'Itarantim', value: 'Itarantim' },
        { label: 'Itatim', value: 'Itatim' },
        { label: 'Itiruçu', value: 'Itiruçu' },
        { label: 'Itiúba', value: 'Itiúba' },
        { label: 'Itororó', value: 'Itororó' },
        { label: 'Ituaçu', value: 'Ituaçu' },
        { label: 'Ituberá', value: 'Ituberá' },
        { label: 'Iuiú', value: 'Iuiú' },
        { label: 'Jaborandi', value: 'Jaborandi' },
        { label: 'Jacaraci', value: 'Jacaraci' },
        { label: 'Jacobina', value: 'Jacobina' },
        { label: 'Jaguaquara', value: 'Jaguaquara' },
        { label: 'Jaguarari', value: 'Jaguarari' },
        { label: 'Jaguaripe', value: 'Jaguaripe' },
        { label: 'Jandaíra', value: 'Jandaíra' },
        { label: 'Jequié', value: 'Jequié' },
        { label: 'Jeremoabo', value: 'Jeremoabo' },
        { label: 'Jiquiriçá', value: 'Jiquiriçá' },
        { label: 'Jitaúna', value: 'Jitaúna' },
        { label: 'João Dourado', value: 'João Dourado' },
        { label: 'Juazeiro', value: 'Juazeiro' },
        { label: 'Jucuruçu', value: 'Jucuruçu' },
        { label: 'Jussara', value: 'Jussara' },
        { label: 'Jussari', value: 'Jussari' },
        { label: 'Jussiape', value: 'Jussiape' },
        { label: 'Lafaiete Coutinho', value: 'Lafaiete Coutinho' },
        { label: 'Lagoa Real', value: 'Lagoa Real' },
        { label: 'Laje', value: 'Laje' },
        { label: 'Lajedão', value: 'Lajedão' },
        { label: 'Lajedinho', value: 'Lajedinho' },
        { label: 'Lajedo do Tabocal', value: 'Lajedo do Tabocal' },
        { label: 'Lamarão', value: 'Lamarão' },
        { label: 'Lapão', value: 'Lapão' },
        { label: 'Lauro de Freitas', value: 'Lauro de Freitas' },
        { label: 'Lençóis', value: 'Lençóis' },
        { label: 'Licínio de Almeida', value: 'Licínio de Almeida' },
        { label: 'Livramento de Nossa Senhora', value: 'Livramento de Nossa Senhora' },
        { label: 'Luís Eduardo Magalhães', value: 'Luís Eduardo Magalhães' },
        { label: 'Macajuba', value: 'Macajuba' },
        { label: 'Macarani', value: 'Macarani' },
        { label: 'Macaúbas', value: 'Macaúbas' },
        { label: 'Macururé', value: 'Macururé' },
        { label: 'Madre de Deus', value: 'Madre de Deus' },
        { label: 'Maetinga', value: 'Maetinga' },
        { label: 'Maiquinique', value: 'Maiquinique' },
        { label: 'Mairi', value: 'Mairi' },
        { label: 'Malhada', value: 'Malhada' },
        { label: 'Malhada de Pedras', value: 'Malhada de Pedras' },
        { label: 'Manoel Vitorino', value: 'Manoel Vitorino' },
        { label: 'Mansidão', value: 'Mansidão' },
        { label: 'Maracás', value: 'Maracás' },
        { label: 'Maragogipe', value: 'Maragogipe' },
        { label: 'Maraú', value: 'Maraú' },
        { label: 'Marcionílio Souza', value: 'Marcionílio Souza' },
        { label: 'Mascote', value: 'Mascote' },
        { label: 'Mata de São João', value: 'Mata de São João' },
        { label: 'Matina', value: 'Matina' },
        { label: 'Medeiros Neto', value: 'Medeiros Neto' },
        { label: 'Miguel Calmon', value: 'Miguel Calmon' },
        { label: 'Milagres', value: 'Milagres' },
        { label: 'Mirangaba', value: 'Mirangaba' },
        { label: 'Mirante', value: 'Mirante' },
        { label: 'Monte Santo', value: 'Monte Santo' },
        { label: 'Morpará', value: 'Morpará' },
        { label: 'Morro do Chapéu', value: 'Morro do Chapéu' },
        { label: 'Mortugaba', value: 'Mortugaba' },
        { label: 'Mucugê', value: 'Mucugê' },
        { label: 'Mucuri', value: 'Mucuri' },
        { label: 'Mulungu do Morro', value: 'Mulungu do Morro' },
        { label: 'Mundo Novo', value: 'Mundo Novo' },
        { label: 'Muniz Ferreira', value: 'Muniz Ferreira' },
        { label: 'Muquém de São Francisco', value: 'Muquém de São Francisco' },
        { label: 'Muritiba', value: 'Muritiba' },
        { label: 'Mutuípe', value: 'Mutuípe' },
        { label: 'Nazaré', value: 'Nazaré' },
        { label: 'Nilo Peçanha', value: 'Nilo Peçanha' },
        { label: 'Nordestina', value: 'Nordestina' },
        { label: 'Nova Canaã', value: 'Nova Canaã' },
        { label: 'Nova Fátima', value: 'Nova Fátima' },
        { label: 'Nova Ibiá', value: 'Nova Ibiá' },
        { label: 'Nova Itarana', value: 'Nova Itarana' },
        { label: 'Nova Redenção', value: 'Nova Redenção' },
        { label: 'Nova Soure', value: 'Nova Soure' },
        { label: 'Nova Viçosa', value: 'Nova Viçosa' },
        { label: 'Novo Horizonte', value: 'Novo Horizonte' },
        { label: 'Novo Triunfo', value: 'Novo Triunfo' },
        { label: 'Olindina', value: 'Olindina' },
        { label: 'Oliveira dos Brejinhos', value: 'Oliveira dos Brejinhos' },
        { label: 'Ouriçangas', value: 'Ouriçangas' },
        { label: 'Ourolândia', value: 'Ourolândia' },
        { label: 'Palmas de Monte Alto', value: 'Palmas de Monte Alto' },
        { label: 'Palmeiras', value: 'Palmeiras' },
        { label: 'Paramirim', value: 'Paramirim' },
        { label: 'Paratinga', value: 'Paratinga' },
        { label: 'Paripiranga', value: 'Paripiranga' },
        { label: 'Pau Brasil', value: 'Pau Brasil' },
        { label: 'Paulo Afonso', value: 'Paulo Afonso' },
        { label: 'Pé de Serra', value: 'Pé de Serra' },
        { label: 'Pedrão', value: 'Pedrão' },
        { label: 'Pedro Alexandre', value: 'Pedro Alexandre' },
        { label: 'Piatã', value: 'Piatã' },
        { label: 'Pilão Arcado', value: 'Pilão Arcado' },
        { label: 'Pindaí', value: 'Pindaí' },
        { label: 'Pindobaçu', value: 'Pindobaçu' },
        { label: 'Pintadas', value: 'Pintadas' },
        { label: 'Piraí do Norte', value: 'Piraí do Norte' },
        { label: 'Piripá', value: 'Piripá' },
        { label: 'Piritiba', value: 'Piritiba' },
        { label: 'Planaltino', value: 'Planaltino' },
        { label: 'Planalto', value: 'Planalto' },
        { label: 'Poções', value: 'Poções' },
        { label: 'Pojuca', value: 'Pojuca' },
        { label: 'Ponto Novo', value: 'Ponto Novo' },
        { label: 'Porto Seguro', value: 'Porto Seguro' },
        { label: 'Potiraguá', value: 'Potiraguá' },
        { label: 'Prado', value: 'Prado' },
        { label: 'Presidente Dutra', value: 'Presidente Dutra' },
        { label: 'Presidente Jânio Quadros', value: 'Presidente Jânio Quadros' },
        { label: 'Presidente Tancredo Neves', value: 'Presidente Tancredo Neves' },
        { label: 'Queimadas', value: 'Queimadas' },
        { label: 'Quijingue', value: 'Quijingue' },
        { label: 'Quixabeira', value: 'Quixabeira' },
        { label: 'Rafael Jambeiro', value: 'Rafael Jambeiro' },
        { label: 'Remanso', value: 'Remanso' },
        { label: 'Retirolândia', value: 'Retirolândia' },
        { label: 'Riachão das Neves', value: 'Riachão das Neves' },
        { label: 'Riachão do Jacuípe', value: 'Riachão do Jacuípe' },
        { label: 'Riacho de Santana', value: 'Riacho de Santana' },
        { label: 'Ribeira do Amparo', value: 'Ribeira do Amparo' },
        { label: 'Ribeira do Pombal', value: 'Ribeira do Pombal' },
        { label: 'Ribeirão do Largo', value: 'Ribeirão do Largo' },
        { label: 'Rio de Contas', value: 'Rio de Contas' },
        { label: 'Rio do Antônio', value: 'Rio do Antônio' },
        { label: 'Rio do Pires', value: 'Rio do Pires' },
        { label: 'Rio Real', value: 'Rio Real' },
        { label: 'Rodelas', value: 'Rodelas' },
        { label: 'Ruy Barbosa', value: 'Ruy Barbosa' },
        { label: 'Salinas da Margarida', value: 'Salinas da Margarida' },
        { label: 'Salvador', value: 'Salvador' },
        { label: 'Santa Bárbara', value: 'Santa Bárbara' },
        { label: 'Santa Brígida', value: 'Santa Brígida' },
        { label: 'Santa Cruz Cabrália', value: 'Santa Cruz Cabrália' },
        { label: 'Santa Cruz da Vitória', value: 'Santa Cruz da Vitória' },
        { label: 'Santa Inês', value: 'Santa Inês' },
        { label: 'Santa Luzia', value: 'Santa Luzia' },
        { label: 'Santa Maria da Vitória', value: 'Santa Maria da Vitória' },
        { label: 'Santa Rita de Cássia', value: 'Santa Rita de Cássia' },
        { label: 'Santa Teresinha', value: 'Santa Teresinha' },
        { label: 'Santaluz', value: 'Santaluz' },
        { label: 'Santana', value: 'Santana' },
        { label: 'Santanópolis', value: 'Santanópolis' },
        { label: 'Santo Amaro', value: 'Santo Amaro' },
        { label: 'Santo Antônio de Jesus', value: 'Santo Antônio de Jesus' },
        { label: 'Santo Estêvão', value: 'Santo Estêvão' },
        { label: 'São Desidério', value: 'São Desidério' },
        { label: 'São Domingos', value: 'São Domingos' },
        { label: 'São Felipe', value: 'São Felipe' },
        { label: 'São Félix', value: 'São Félix' },
        { label: 'São Félix do Coribe', value: 'São Félix do Coribe' },
        { label: 'São Francisco do Conde', value: 'São Francisco do Conde' },
        { label: 'São Gabriel', value: 'São Gabriel' },
        { label: 'São Gonçalo dos Campos', value: 'São Gonçalo dos Campos' },
        { label: 'São José da Vitória', value: 'São José da Vitória' },
        { label: 'São José do Jacuípe', value: 'São José do Jacuípe' },
        { label: 'São Miguel das Matas', value: 'São Miguel das Matas' },
        { label: 'São Sebastião do Passé', value: 'São Sebastião do Passé' },
        { label: 'Sapeaçu', value: 'Sapeaçu' },
        { label: 'Sátiro Dias', value: 'Sátiro Dias' },
        { label: 'Saubara', value: 'Saubara' },
        { label: 'Saúde', value: 'Saúde' },
        { label: 'Seabra', value: 'Seabra' },
        { label: 'Sebastião Laranjeiras', value: 'Sebastião Laranjeiras' },
        { label: 'Senhor do Bonfim', value: 'Senhor do Bonfim' },
        { label: 'Sento Sé', value: 'Sento Sé' },
        { label: 'Serra do Ramalho', value: 'Serra do Ramalho' },
        { label: 'Serra Dourada', value: 'Serra Dourada' },
        { label: 'Serra Preta', value: 'Serra Preta' },
        { label: 'Serrinha', value: 'Serrinha' },
        { label: 'Serrolândia', value: 'Serrolândia' },
        { label: 'Simões Filho', value: 'Simões Filho' },
        { label: 'Sítio do Mato', value: 'Sítio do Mato' },
        { label: 'Sítio do Quinto', value: 'Sítio do Quinto' },
        { label: 'Sobradinho', value: 'Sobradinho' },
        { label: 'Souto Soares', value: 'Souto Soares' },
        { label: 'Tabocas do Brejo Velho', value: 'Tabocas do Brejo Velho' },
        { label: 'Tanhaçu', value: 'Tanhaçu' },
        { label: 'Tanque Novo', value: 'Tanque Novo' },
        { label: 'Tanquinho', value: 'Tanquinho' },
        { label: 'Taperoá', value: 'Taperoá' },
        { label: 'Tapiramutá', value: 'Tapiramutá' },
        { label: 'Teixeira de Freitas', value: 'Teixeira de Freitas' },
        { label: 'Teodoro Sampaio', value: 'Teodoro Sampaio' },
        { label: 'Teofilândia', value: 'Teofilândia' },
        { label: 'Teolândia', value: 'Teolândia' },
        { label: 'Terra Nova', value: 'Terra Nova' },
        { label: 'Tremedal', value: 'Tremedal' },
        { label: 'Tucano', value: 'Tucano' },
        { label: 'Uauá', value: 'Uauá' },
        { label: 'Ubaíra', value: 'Ubaíra' },
        { label: 'Ubaitaba', value: 'Ubaitaba' },
        { label: 'Ubatã', value: 'Ubatã' },
        { label: 'Uibaí', value: 'Uibaí' },
        { label: 'Umburanas', value: 'Umburanas' },
        { label: 'Una', value: 'Una' },
        { label: 'Urandi', value: 'Urandi' },
        { label: 'Uruçuca', value: 'Uruçuca' },
        { label: 'Utinga', value: 'Utinga' },
        { label: 'Valença', value: 'Valença' },
        { label: 'Valente', value: 'Valente' },
        { label: 'Várzea da Roça', value: 'Várzea da Roça' },
        { label: 'Várzea do Poço', value: 'Várzea do Poço' },
        { label: 'Várzea Nova', value: 'Várzea Nova' },
        { label: 'Varzedo', value: 'Varzedo' },
        { label: 'Vera Cruz', value: 'Vera Cruz' },
        { label: 'Vereda', value: 'Vereda' },
        { label: 'Vitória da Conquista', value: 'Vitória da Conquista' },
        { label: 'Wagner', value: 'Wagner' },
        { label: 'Wanderley', value: 'Wanderley' },
        { label: 'Wenceslau Guimarães', value: 'Wenceslau Guimarães' },
        { label: 'Xique-Xique', value: 'Xique-Xique' },
    ],
    CE: [
        { label: 'Abaiara', value: 'Abaiara' },
        { label: 'Acarape', value: 'Acarape' },
        { label: 'Acaraú', value: 'Acaraú' },
        { label: 'Acopiara', value: 'Acopiara' },
        { label: 'Aiuaba', value: 'Aiuaba' },
        { label: 'Alcântaras', value: 'Alcântaras' },
        { label: 'Altaneira', value: 'Altaneira' },
        { label: 'Alto Santo', value: 'Alto Santo' },
        { label: 'Amontada', value: 'Amontada' },
        { label: 'Antonina do Norte', value: 'Antonina do Norte' },
        { label: 'Apuiarés', value: 'Apuiarés' },
        { label: 'Aquiraz', value: 'Aquiraz' },
        { label: 'Aracati', value: 'Aracati' },
        { label: 'Aracoiaba', value: 'Aracoiaba' },
        { label: 'Ararendá', value: 'Ararendá' },
        { label: 'Araripe', value: 'Araripe' },
        { label: 'Aratuba', value: 'Aratuba' },
        { label: 'Arneiroz', value: 'Arneiroz' },
        { label: 'Assaré', value: 'Assaré' },
        { label: 'Aurora', value: 'Aurora' },
        { label: 'Baixio', value: 'Baixio' },
        { label: 'Banabuiú', value: 'Banabuiú' },
        { label: 'Barbalha', value: 'Barbalha' },
        { label: 'Barreira', value: 'Barreira' },
        { label: 'Barro', value: 'Barro' },
        { label: 'Barroquinha', value: 'Barroquinha' },
        { label: 'Baturité', value: 'Baturité' },
        { label: 'Beberibe', value: 'Beberibe' },
        { label: 'Bela Cruz', value: 'Bela Cruz' },
        { label: 'Boa Viagem', value: 'Boa Viagem' },
        { label: 'Brejo Santo', value: 'Brejo Santo' },
        { label: 'Camocim', value: 'Camocim' },
        { label: 'Campos Sales', value: 'Campos Sales' },
        { label: 'Canindé', value: 'Canindé' },
        { label: 'Capistrano', value: 'Capistrano' },
        { label: 'Caridade', value: 'Caridade' },
        { label: 'Cariré', value: 'Cariré' },
        { label: 'Caririaçu', value: 'Caririaçu' },
        { label: 'Cariús', value: 'Cariús' },
        { label: 'Carnaubal', value: 'Carnaubal' },
        { label: 'Cascavel', value: 'Cascavel' },
        { label: 'Catarina', value: 'Catarina' },
        { label: 'Catunda', value: 'Catunda' },
        { label: 'Caucaia', value: 'Caucaia' },
        { label: 'Cedro', value: 'Cedro' },
        { label: 'Chaval', value: 'Chaval' },
        { label: 'Choró', value: 'Choró' },
        { label: 'Chorozinho', value: 'Chorozinho' },
        { label: 'Coreaú', value: 'Coreaú' },
        { label: 'Crateús', value: 'Crateús' },
        { label: 'Crato', value: 'Crato' },
        { label: 'Croatá', value: 'Croatá' },
        { label: 'Cruz', value: 'Cruz' },
        { label: 'Deputado Irapuan Pinheiro', value: 'Deputado Irapuan Pinheiro' },
        { label: 'Ererê', value: 'Ererê' },
        { label: 'Eusébio', value: 'Eusébio' },
        { label: 'Farias Brito', value: 'Farias Brito' },
        { label: 'Forquilha', value: 'Forquilha' },
        { label: 'Fortaleza', value: 'Fortaleza' },
        { label: 'Fortim', value: 'Fortim' },
        { label: 'Frecheirinha', value: 'Frecheirinha' },
        { label: 'General Sampaio', value: 'General Sampaio' },
        { label: 'Graça', value: 'Graça' },
        { label: 'Granja', value: 'Granja' },
        { label: 'Granjeiro', value: 'Granjeiro' },
        { label: 'Groaíras', value: 'Groaíras' },
        { label: 'Guaiúba', value: 'Guaiúba' },
        { label: 'Guaraciaba do Norte', value: 'Guaraciaba do Norte' },
        { label: 'Guaramiranga', value: 'Guaramiranga' },
        { label: 'Hidrolândia', value: 'Hidrolândia' },
        { label: 'Horizonte', value: 'Horizonte' },
        { label: 'Ibaretama', value: 'Ibaretama' },
        { label: 'Ibiapina', value: 'Ibiapina' },
        { label: 'Ibicuitinga', value: 'Ibicuitinga' },
        { label: 'Icapuí', value: 'Icapuí' },
        { label: 'Icó', value: 'Icó' },
        { label: 'Iguatu', value: 'Iguatu' },
        { label: 'Independência', value: 'Independência' },
        { label: 'Ipaporanga', value: 'Ipaporanga' },
        { label: 'Ipaumirim', value: 'Ipaumirim' },
        { label: 'Ipu', value: 'Ipu' },
        { label: 'Ipueiras', value: 'Ipueiras' },
        { label: 'Iracema', value: 'Iracema' },
        { label: 'Irauçuba', value: 'Irauçuba' },
        { label: 'Itaiçaba', value: 'Itaiçaba' },
        { label: 'Itaitinga', value: 'Itaitinga' },
        { label: 'Itapajé', value: 'Itapajé' },
        { label: 'Itapipoca', value: 'Itapipoca' },
        { label: 'Itapiúna', value: 'Itapiúna' },
        { label: 'Itarema', value: 'Itarema' },
        { label: 'Itatira', value: 'Itatira' },
        { label: 'Jaguaretama', value: 'Jaguaretama' },
        { label: 'Jaguaribara', value: 'Jaguaribara' },
        { label: 'Jaguaribe', value: 'Jaguaribe' },
        { label: 'Jaguaruana', value: 'Jaguaruana' },
        { label: 'Jardim', value: 'Jardim' },
        { label: 'Jati', value: 'Jati' },
        { label: 'Jijoca de Jericoacoara', value: 'Jijoca de Jericoacoara' },
        { label: 'Juazeiro do Norte', value: 'Juazeiro do Norte' },
        { label: 'Jucás', value: 'Jucás' },
        { label: 'Lavras da Mangabeira', value: 'Lavras da Mangabeira' },
        { label: 'Limoeiro do Norte', value: 'Limoeiro do Norte' },
        { label: 'Madalena', value: 'Madalena' },
        { label: 'Maracanaú', value: 'Maracanaú' },
        { label: 'Maranguape', value: 'Maranguape' },
        { label: 'Marco', value: 'Marco' },
        { label: 'Martinópole', value: 'Martinópole' },
        { label: 'Massapê', value: 'Massapê' },
        { label: 'Mauriti', value: 'Mauriti' },
        { label: 'Meruoca', value: 'Meruoca' },
        { label: 'Milagres', value: 'Milagres' },
        { label: 'Milhã', value: 'Milhã' },
        { label: 'Miraíma', value: 'Miraíma' },
        { label: 'Missão Velha', value: 'Missão Velha' },
        { label: 'Mombaça', value: 'Mombaça' },
        { label: 'Monsenhor Tabosa', value: 'Monsenhor Tabosa' },
        { label: 'Morada Nova', value: 'Morada Nova' },
        { label: 'Moraújo', value: 'Moraújo' },
        { label: 'Morrinhos', value: 'Morrinhos' },
        { label: 'Mucambo', value: 'Mucambo' },
        { label: 'Mulungu', value: 'Mulungu' },
        { label: 'Nova Olinda', value: 'Nova Olinda' },
        { label: 'Nova Russas', value: 'Nova Russas' },
        { label: 'Novo Oriente', value: 'Novo Oriente' },
        { label: 'Ocara', value: 'Ocara' },
        { label: 'Orós', value: 'Orós' },
        { label: 'Pacajus', value: 'Pacajus' },
        { label: 'Pacatuba', value: 'Pacatuba' },
        { label: 'Pacoti', value: 'Pacoti' },
        { label: 'Pacujá', value: 'Pacujá' },
        { label: 'Palhano', value: 'Palhano' },
        { label: 'Palmácia', value: 'Palmácia' },
        { label: 'Paracuru', value: 'Paracuru' },
        { label: 'Paraipaba', value: 'Paraipaba' },
        { label: 'Parambu', value: 'Parambu' },
        { label: 'Paramoti', value: 'Paramoti' },
        { label: 'Pedra Branca', value: 'Pedra Branca' },
        { label: 'Penaforte', value: 'Penaforte' },
        { label: 'Pentecoste', value: 'Pentecoste' },
        { label: 'Pereiro', value: 'Pereiro' },
        { label: 'Pindoretama', value: 'Pindoretama' },
        { label: 'Piquet Carneiro', value: 'Piquet Carneiro' },
        { label: 'Pires Ferreira', value: 'Pires Ferreira' },
        { label: 'Poranga', value: 'Poranga' },
        { label: 'Porteiras', value: 'Porteiras' },
        { label: 'Potengi', value: 'Potengi' },
        { label: 'Potiretama', value: 'Potiretama' },
        { label: 'Quiterianópolis', value: 'Quiterianópolis' },
        { label: 'Quixadá', value: 'Quixadá' },
        { label: 'Quixelô', value: 'Quixelô' },
        { label: 'Quixeramobim', value: 'Quixeramobim' },
        { label: 'Quixeré', value: 'Quixeré' },
        { label: 'Redenção', value: 'Redenção' },
        { label: 'Reriutaba', value: 'Reriutaba' },
        { label: 'Russas', value: 'Russas' },
        { label: 'Saboeiro', value: 'Saboeiro' },
        { label: 'Salitre', value: 'Salitre' },
        { label: 'Santa Quitéria', value: 'Santa Quitéria' },
        { label: 'Santana do Acaraú', value: 'Santana do Acaraú' },
        { label: 'Santana do Cariri', value: 'Santana do Cariri' },
        { label: 'São Benedito', value: 'São Benedito' },
        { label: 'São Gonçalo do Amarante', value: 'São Gonçalo do Amarante' },
        { label: 'São João do Jaguaribe', value: 'São João do Jaguaribe' },
        { label: 'São Luís do Curu', value: 'São Luís do Curu' },
        { label: 'Senador Pompeu', value: 'Senador Pompeu' },
        { label: 'Senador Sá', value: 'Senador Sá' },
        { label: 'Sobral', value: 'Sobral' },
        { label: 'Solonópole', value: 'Solonópole' },
        { label: 'Tabuleiro do Norte', value: 'Tabuleiro do Norte' },
        { label: 'Tamboril', value: 'Tamboril' },
        { label: 'Tarrafas', value: 'Tarrafas' },
        { label: 'Tauá', value: 'Tauá' },
        { label: 'Tejuçuoca', value: 'Tejuçuoca' },
        { label: 'Tianguá', value: 'Tianguá' },
        { label: 'Trairi', value: 'Trairi' },
        { label: 'Tururu', value: 'Tururu' },
        { label: 'Ubajara', value: 'Ubajara' },
        { label: 'Umari', value: 'Umari' },
        { label: 'Umirim', value: 'Umirim' },
        { label: 'Uruburetama', value: 'Uruburetama' },
        { label: 'Uruoca', value: 'Uruoca' },
        { label: 'Varjota', value: 'Varjota' },
        { label: 'Várzea Alegre', value: 'Várzea Alegre' },
        { label: 'Viçosa do Ceará', value: 'Viçosa do Ceará' },
    ],
    DF: [
        { label: 'Brasília', value: 'Brasília' },
    ],
    ES: [
        { label: 'Afonso Cláudio', value: 'Afonso Cláudio' },
        { label: 'Água Doce do Norte', value: 'Água Doce do Norte' },
        { label: 'Águia Branca', value: 'Águia Branca' },
        { label: 'Alegre', value: 'Alegre' },
        { label: 'Alfredo Chaves', value: 'Alfredo Chaves' },
        { label: 'Alto Rio Novo', value: 'Alto Rio Novo' },
        { label: 'Anchieta', value: 'Anchieta' },
        { label: 'Apiacá', value: 'Apiacá' },
        { label: 'Aracruz', value: 'Aracruz' },
        { label: 'Atilio Vivacqua', value: 'Atilio Vivacqua' },
        { label: 'Baixo Guandu', value: 'Baixo Guandu' },
        { label: 'Barra de São Francisco', value: 'Barra de São Francisco' },
        { label: 'Boa Esperança', value: 'Boa Esperança' },
        { label: 'Bom Jesus do Norte', value: 'Bom Jesus do Norte' },
        { label: 'Brejetuba', value: 'Brejetuba' },
        { label: 'Cachoeiro de Itapemirim', value: 'Cachoeiro de Itapemirim' },
        { label: 'Cariacica', value: 'Cariacica' },
        { label: 'Castelo', value: 'Castelo' },
        { label: 'Colatina', value: 'Colatina' },
        { label: 'Conceição da Barra', value: 'Conceição da Barra' },
        { label: 'Conceição do Castelo', value: 'Conceição do Castelo' },
        { label: 'Divino de São Lourenço', value: 'Divino de São Lourenço' },
        { label: 'Domingos Martins', value: 'Domingos Martins' },
        { label: 'Dores do Rio Preto', value: 'Dores do Rio Preto' },
        { label: 'Ecoporanga', value: 'Ecoporanga' },
        { label: 'Fundão', value: 'Fundão' },
        { label: 'Governador Lindenberg', value: 'Governador Lindenberg' },
        { label: 'Guaçuí', value: 'Guaçuí' },
        { label: 'Guarapari', value: 'Guarapari' },
        { label: 'Ibatiba', value: 'Ibatiba' },
        { label: 'Ibiraçu', value: 'Ibiraçu' },
        { label: 'Ibitirama', value: 'Ibitirama' },
        { label: 'Iconha', value: 'Iconha' },
        { label: 'Irupi', value: 'Irupi' },
        { label: 'Itaguaçu', value: 'Itaguaçu' },
        { label: 'Itapemirim', value: 'Itapemirim' },
        { label: 'Itarana', value: 'Itarana' },
        { label: 'Iúna', value: 'Iúna' },
        { label: 'Jaguaré', value: 'Jaguaré' },
        { label: 'Jerônimo Monteiro', value: 'Jerônimo Monteiro' },
        { label: 'João Neiva', value: 'João Neiva' },
        { label: 'Laranja da Terra', value: 'Laranja da Terra' },
        { label: 'Linhares', value: 'Linhares' },
        { label: 'Mantenópolis', value: 'Mantenópolis' },
        { label: 'Marataízes', value: 'Marataízes' },
        { label: 'Marechal Floriano', value: 'Marechal Floriano' },
        { label: 'Marilândia', value: 'Marilândia' },
        { label: 'Mimoso do Sul', value: 'Mimoso do Sul' },
        { label: 'Montanha', value: 'Montanha' },
        { label: 'Mucurici', value: 'Mucurici' },
        { label: 'Muniz Freire', value: 'Muniz Freire' },
        { label: 'Muqui', value: 'Muqui' },
        { label: 'Nova Venécia', value: 'Nova Venécia' },
        { label: 'Pancas', value: 'Pancas' },
        { label: 'Pedro Canário', value: 'Pedro Canário' },
        { label: 'Pinheiros', value: 'Pinheiros' },
        { label: 'Piúma', value: 'Piúma' },
        { label: 'Ponto Belo', value: 'Ponto Belo' },
        { label: 'Presidente Kennedy', value: 'Presidente Kennedy' },
        { label: 'Rio Bananal', value: 'Rio Bananal' },
        { label: 'Rio Novo do Sul', value: 'Rio Novo do Sul' },
        { label: 'Santa Leopoldina', value: 'Santa Leopoldina' },
        { label: 'Santa Maria de Jetibá', value: 'Santa Maria de Jetibá' },
        { label: 'Santa Teresa', value: 'Santa Teresa' },
        { label: 'São Domingos do Norte', value: 'São Domingos do Norte' },
        { label: 'São Gabriel da Palha', value: 'São Gabriel da Palha' },
        { label: 'São José do Calçado', value: 'São José do Calçado' },
        { label: 'São Mateus', value: 'São Mateus' },
        { label: 'São Roque do Canaã', value: 'São Roque do Canaã' },
        { label: 'Serra', value: 'Serra' },
        { label: 'Sooretama', value: 'Sooretama' },
        { label: 'Vargem Alta', value: 'Vargem Alta' },
        { label: 'Venda Nova do Imigrante', value: 'Venda Nova do Imigrante' },
        { label: 'Viana', value: 'Viana' },
        { label: 'Vila Pavão', value: 'Vila Pavão' },
        { label: 'Vila Valério', value: 'Vila Valério' },
        { label: 'Vila Velha', value: 'Vila Velha' },
        { label: 'Vitória', value: 'Vitória' },
    ],
    GO: [
        { label: 'Abadia de Goiás', value: 'Abadia de Goiás' },
        { label: 'Abadiânia', value: 'Abadiânia' },
        { label: 'Acreúna', value: 'Acreúna' },
        { label: 'Adelândia', value: 'Adelândia' },
        { label: 'Água Fria de Goiás', value: 'Água Fria de Goiás' },
        { label: 'Água Limpa', value: 'Água Limpa' },
        { label: 'Águas Lindas de Goiás', value: 'Águas Lindas de Goiás' },
        { label: 'Alexânia', value: 'Alexânia' },
        { label: 'Aloândia', value: 'Aloândia' },
        { label: 'Alto Horizonte', value: 'Alto Horizonte' },
        { label: 'Alto Paraíso de Goiás', value: 'Alto Paraíso de Goiás' },
        { label: 'Alvorada do Norte', value: 'Alvorada do Norte' },
        { label: 'Amaralina', value: 'Amaralina' },
        { label: 'Americano do Brasil', value: 'Americano do Brasil' },
        { label: 'Amorinópolis', value: 'Amorinópolis' },
        { label: 'Anápolis', value: 'Anápolis' },
        { label: 'Anhanguera', value: 'Anhanguera' },
        { label: 'Anicuns', value: 'Anicuns' },
        { label: 'Aparecida de Goiânia', value: 'Aparecida de Goiânia' },
        { label: 'Aparecida do Rio Doce', value: 'Aparecida do Rio Doce' },
        { label: 'Aporé', value: 'Aporé' },
        { label: 'Araçu', value: 'Araçu' },
        { label: 'Aragarças', value: 'Aragarças' },
        { label: 'Aragoiânia', value: 'Aragoiânia' },
        { label: 'Araguapaz', value: 'Araguapaz' },
        { label: 'Arenópolis', value: 'Arenópolis' },
        { label: 'Aruanã', value: 'Aruanã' },
        { label: 'Aurilândia', value: 'Aurilândia' },
        { label: 'Avelinópolis', value: 'Avelinópolis' },
        { label: 'Baliza', value: 'Baliza' },
        { label: 'Barro Alto', value: 'Barro Alto' },
        { label: 'Bela Vista de Goiás', value: 'Bela Vista de Goiás' },
        { label: 'Bom Jardim de Goiás', value: 'Bom Jardim de Goiás' },
        { label: 'Bom Jesus de Goiás', value: 'Bom Jesus de Goiás' },
        { label: 'Bonfinópolis', value: 'Bonfinópolis' },
        { label: 'Bonópolis', value: 'Bonópolis' },
        { label: 'Brazabrantes', value: 'Brazabrantes' },
        { label: 'Britânia', value: 'Britânia' },
        { label: 'Buriti Alegre', value: 'Buriti Alegre' },
        { label: 'Buriti de Goiás', value: 'Buriti de Goiás' },
        { label: 'Buritinópolis', value: 'Buritinópolis' },
        { label: 'Cabeceiras', value: 'Cabeceiras' },
        { label: 'Cachoeira Alta', value: 'Cachoeira Alta' },
        { label: 'Cachoeira de Goiás', value: 'Cachoeira de Goiás' },
        { label: 'Cachoeira Dourada', value: 'Cachoeira Dourada' },
        { label: 'Caçu', value: 'Caçu' },
        { label: 'Caiapônia', value: 'Caiapônia' },
        { label: 'Caldas Novas', value: 'Caldas Novas' },
        { label: 'Caldazinha', value: 'Caldazinha' },
        { label: 'Campestre de Goiás', value: 'Campestre de Goiás' },
        { label: 'Campinaçu', value: 'Campinaçu' },
        { label: 'Campinorte', value: 'Campinorte' },
        { label: 'Campo Alegre de Goiás', value: 'Campo Alegre de Goiás' },
        { label: 'Campo Limpo de Goiás', value: 'Campo Limpo de Goiás' },
        { label: 'Campos Belos', value: 'Campos Belos' },
        { label: 'Campos Verdes', value: 'Campos Verdes' },
        { label: 'Carmo do Rio Verde', value: 'Carmo do Rio Verde' },
        { label: 'Castelândia', value: 'Castelândia' },
        { label: 'Catalão', value: 'Catalão' },
        { label: 'Caturaí', value: 'Caturaí' },
        { label: 'Cavalcante', value: 'Cavalcante' },
        { label: 'Ceres', value: 'Ceres' },
        { label: 'Cezarina', value: 'Cezarina' },
        { label: 'Chapadão do Céu', value: 'Chapadão do Céu' },
        { label: 'Cidade Ocidental', value: 'Cidade Ocidental' },
        { label: 'Cocalzinho de Goiás', value: 'Cocalzinho de Goiás' },
        { label: 'Colinas do Sul', value: 'Colinas do Sul' },
        { label: 'Córrego do Ouro', value: 'Córrego do Ouro' },
        { label: 'Corumbá de Goiás', value: 'Corumbá de Goiás' },
        { label: 'Corumbaíba', value: 'Corumbaíba' },
        { label: 'Cristalina', value: 'Cristalina' },
        { label: 'Cristianópolis', value: 'Cristianópolis' },
        { label: 'Crixás', value: 'Crixás' },
        { label: 'Cromínia', value: 'Cromínia' },
        { label: 'Cumari', value: 'Cumari' },
        { label: 'Damianópolis', value: 'Damianópolis' },
        { label: 'Damolândia', value: 'Damolândia' },
        { label: 'Davinópolis', value: 'Davinópolis' },
        { label: 'Diorama', value: 'Diorama' },
        { label: 'Divinópolis de Goiás', value: 'Divinópolis de Goiás' },
        { label: 'Doverlândia', value: 'Doverlândia' },
        { label: 'Edealina', value: 'Edealina' },
        { label: 'Edéia', value: 'Edéia' },
        { label: 'Estrela do Norte', value: 'Estrela do Norte' },
        { label: 'Faina', value: 'Faina' },
        { label: 'Fazenda Nova', value: 'Fazenda Nova' },
        { label: 'Firminópolis', value: 'Firminópolis' },
        { label: 'Flores de Goiás', value: 'Flores de Goiás' },
        { label: 'Formosa', value: 'Formosa' },
        { label: 'Formoso', value: 'Formoso' },
        { label: 'Gameleira de Goiás', value: 'Gameleira de Goiás' },
        { label: 'Goianápolis', value: 'Goianápolis' },
        { label: 'Goiandira', value: 'Goiandira' },
        { label: 'Goianésia', value: 'Goianésia' },
        { label: 'Goiânia', value: 'Goiânia' },
        { label: 'Goianira', value: 'Goianira' },
        { label: 'Goiás', value: 'Goiás' },
        { label: 'Goiatuba', value: 'Goiatuba' },
        { label: 'Gouvelândia', value: 'Gouvelândia' },
        { label: 'Guapó', value: 'Guapó' },
        { label: 'Guaraíta', value: 'Guaraíta' },
        { label: 'Guarani de Goiás', value: 'Guarani de Goiás' },
        { label: 'Guarinos', value: 'Guarinos' },
        { label: 'Heitoraí', value: 'Heitoraí' },
        { label: 'Hidrolândia', value: 'Hidrolândia' },
        { label: 'Hidrolina', value: 'Hidrolina' },
        { label: 'Iaciara', value: 'Iaciara' },
        { label: 'Inaciolândia', value: 'Inaciolândia' },
        { label: 'Indiara', value: 'Indiara' },
        { label: 'Inhumas', value: 'Inhumas' },
        { label: 'Ipameri', value: 'Ipameri' },
        { label: 'Ipiranga de Goiás', value: 'Ipiranga de Goiás' },
        { label: 'Iporá', value: 'Iporá' },
        { label: 'Israelândia', value: 'Israelândia' },
        { label: 'Itaberaí', value: 'Itaberaí' },
        { label: 'Itaguari', value: 'Itaguari' },
        { label: 'Itaguaru', value: 'Itaguaru' },
        { label: 'Itajá', value: 'Itajá' },
        { label: 'Itapaci', value: 'Itapaci' },
        { label: 'Itapirapuã', value: 'Itapirapuã' },
        { label: 'Itapuranga', value: 'Itapuranga' },
        { label: 'Itarumã', value: 'Itarumã' },
        { label: 'Itauçu', value: 'Itauçu' },
        { label: 'Itumbiara', value: 'Itumbiara' },
        { label: 'Ivolândia', value: 'Ivolândia' },
        { label: 'Jandaia', value: 'Jandaia' },
        { label: 'Jaraguá', value: 'Jaraguá' },
        { label: 'Jataí', value: 'Jataí' },
        { label: 'Jaupaci', value: 'Jaupaci' },
        { label: 'Jesúpolis', value: 'Jesúpolis' },
        { label: 'Joviânia', value: 'Joviânia' },
        { label: 'Jussara', value: 'Jussara' },
        { label: 'Lagoa Santa', value: 'Lagoa Santa' },
        { label: 'Leopoldo de Bulhões', value: 'Leopoldo de Bulhões' },
        { label: 'Luziânia', value: 'Luziânia' },
        { label: 'Mairipotaba', value: 'Mairipotaba' },
        { label: 'Mambaí', value: 'Mambaí' },
        { label: 'Mara Rosa', value: 'Mara Rosa' },
        { label: 'Marzagão', value: 'Marzagão' },
        { label: 'Matrinchã', value: 'Matrinchã' },
        { label: 'Maurilândia', value: 'Maurilândia' },
        { label: 'Mimoso de Goiás', value: 'Mimoso de Goiás' },
        { label: 'Minaçu', value: 'Minaçu' },
        { label: 'Mineiros', value: 'Mineiros' },
        { label: 'Moiporá', value: 'Moiporá' },
        { label: 'Monte Alegre de Goiás', value: 'Monte Alegre de Goiás' },
        { label: 'Montes Claros de Goiás', value: 'Montes Claros de Goiás' },
        { label: 'Montividiu', value: 'Montividiu' },
        { label: 'Montividiu do Norte', value: 'Montividiu do Norte' },
        { label: 'Morrinhos', value: 'Morrinhos' },
        { label: 'Morro Agudo de Goiás', value: 'Morro Agudo de Goiás' },
        { label: 'Mossâmedes', value: 'Mossâmedes' },
        { label: 'Mozarlândia', value: 'Mozarlândia' },
        { label: 'Mundo Novo', value: 'Mundo Novo' },
        { label: 'Mutunópolis', value: 'Mutunópolis' },
        { label: 'Nazário', value: 'Nazário' },
        { label: 'Nerópolis', value: 'Nerópolis' },
        { label: 'Niquelândia', value: 'Niquelândia' },
        { label: 'Nova América', value: 'Nova América' },
        { label: 'Nova Aurora', value: 'Nova Aurora' },
        { label: 'Nova Crixás', value: 'Nova Crixás' },
        { label: 'Nova Glória', value: 'Nova Glória' },
        { label: 'Nova Iguaçu de Goiás', value: 'Nova Iguaçu de Goiás' },
        { label: 'Nova Roma', value: 'Nova Roma' },
        { label: 'Nova Veneza', value: 'Nova Veneza' },
        { label: 'Novo Brasil', value: 'Novo Brasil' },
        { label: 'Novo Gama', value: 'Novo Gama' },
        { label: 'Novo Planalto', value: 'Novo Planalto' },
        { label: 'Orizona', value: 'Orizona' },
        { label: 'Ouro Verde de Goiás', value: 'Ouro Verde de Goiás' },
        { label: 'Ouvidor', value: 'Ouvidor' },
        { label: 'Padre Bernardo', value: 'Padre Bernardo' },
        { label: 'Palestina de Goiás', value: 'Palestina de Goiás' },
        { label: 'Palmeiras de Goiás', value: 'Palmeiras de Goiás' },
        { label: 'Palmelo', value: 'Palmelo' },
        { label: 'Palminópolis', value: 'Palminópolis' },
        { label: 'Panamá', value: 'Panamá' },
        { label: 'Paranaiguara', value: 'Paranaiguara' },
        { label: 'Paraúna', value: 'Paraúna' },
        { label: 'Perolândia', value: 'Perolândia' },
        { label: 'Petrolina de Goiás', value: 'Petrolina de Goiás' },
        { label: 'Pilar de Goiás', value: 'Pilar de Goiás' },
        { label: 'Piracanjuba', value: 'Piracanjuba' },
        { label: 'Piranhas', value: 'Piranhas' },
        { label: 'Pirenópolis', value: 'Pirenópolis' },
        { label: 'Pires do Rio', value: 'Pires do Rio' },
        { label: 'Planaltina', value: 'Planaltina' },
        { label: 'Pontalina', value: 'Pontalina' },
        { label: 'Porangatu', value: 'Porangatu' },
        { label: 'Porteirão', value: 'Porteirão' },
        { label: 'Portelândia', value: 'Portelândia' },
        { label: 'Posse', value: 'Posse' },
        { label: 'Professor Jamil', value: 'Professor Jamil' },
        { label: 'Quirinópolis', value: 'Quirinópolis' },
        { label: 'Rialma', value: 'Rialma' },
        { label: 'Rianápolis', value: 'Rianápolis' },
        { label: 'Rio Quente', value: 'Rio Quente' },
        { label: 'Rio Verde', value: 'Rio Verde' },
        { label: 'Rubiataba', value: 'Rubiataba' },
        { label: 'Sanclerlândia', value: 'Sanclerlândia' },
        { label: 'Santa Bárbara de Goiás', value: 'Santa Bárbara de Goiás' },
        { label: 'Santa Cruz de Goiás', value: 'Santa Cruz de Goiás' },
        { label: 'Santa Fé de Goiás', value: 'Santa Fé de Goiás' },
        { label: 'Santa Helena de Goiás', value: 'Santa Helena de Goiás' },
        { label: 'Santa Isabel', value: 'Santa Isabel' },
        { label: 'Santa Rita do Araguaia', value: 'Santa Rita do Araguaia' },
        { label: 'Santa Rita do Novo Destino', value: 'Santa Rita do Novo Destino' },
        { label: 'Santa Rosa de Goiás', value: 'Santa Rosa de Goiás' },
        { label: 'Santa Tereza de Goiás', value: 'Santa Tereza de Goiás' },
        { label: 'Santa Terezinha de Goiás', value: 'Santa Terezinha de Goiás' },
        { label: 'Santo Antônio da Barra', value: 'Santo Antônio da Barra' },
        { label: 'Santo Antônio de Goiás', value: 'Santo Antônio de Goiás' },
        { label: 'Santo Antônio do Descoberto', value: 'Santo Antônio do Descoberto' },
        { label: 'São Domingos', value: 'São Domingos' },
        { label: 'São Francisco de Goiás', value: 'São Francisco de Goiás' },
        "São João d'Aliança",
        { label: 'São João da Paraúna', value: 'São João da Paraúna' },
        { label: 'São Luís de Montes Belos', value: 'São Luís de Montes Belos' },
        { label: 'São Luiz do Norte', value: 'São Luiz do Norte' },
        { label: 'São Miguel do Araguaia', value: 'São Miguel do Araguaia' },
        { label: 'São Miguel do Passa Quatro', value: 'São Miguel do Passa Quatro' },
        { label: 'São Patrício', value: 'São Patrício' },
        { label: 'São Simão', value: 'São Simão' },
        { label: 'Senador Canedo', value: 'Senador Canedo' },
        { label: 'Serranópolis', value: 'Serranópolis' },
        { label: 'Silvânia', value: 'Silvânia' },
        { label: 'Simolândia', value: 'Simolândia' },
        "Sítio d'Abadia",
        { label: 'Taquaral de Goiás', value: 'Taquaral de Goiás' },
        { label: 'Teresina de Goiás', value: 'Teresina de Goiás' },
        { label: 'Terezópolis de Goiás', value: 'Terezópolis de Goiás' },
        { label: 'Três Ranchos', value: 'Três Ranchos' },
        { label: 'Trindade', value: 'Trindade' },
        { label: 'Trombas', value: 'Trombas' },
        { label: 'Turvânia', value: 'Turvânia' },
        { label: 'Turvelândia', value: 'Turvelândia' },
        { label: 'Uirapuru', value: 'Uirapuru' },
        { label: 'Uruaçu', value: 'Uruaçu' },
        { label: 'Uruana', value: 'Uruana' },
        { label: 'Urutaí', value: 'Urutaí' },
        { label: 'Valparaíso de Goiás', value: 'Valparaíso de Goiás' },
        { label: 'Varjão', value: 'Varjão' },
        { label: 'Vianópolis', value: 'Vianópolis' },
        { label: 'Vicentinópolis', value: 'Vicentinópolis' },
        { label: 'Vila Boa', value: 'Vila Boa' },
        { label: 'Vila Propício', value: 'Vila Propício' },
    ],
    MA: [
        { label: 'Açailândia', value: 'Açailândia' },
        { label: 'Afonso Cunha', value: 'Afonso Cunha' },
        { label: 'Água Doce do Maranhão', value: 'Água Doce do Maranhão' },
        { label: 'Alcântara', value: 'Alcântara' },
        { label: 'Aldeias Altas', value: 'Aldeias Altas' },
        { label: 'Altamira do Maranhão', value: 'Altamira do Maranhão' },
        { label: 'Alto Alegre do Maranhão', value: 'Alto Alegre do Maranhão' },
        { label: 'Alto Alegre do Pindaré', value: 'Alto Alegre do Pindaré' },
        { label: 'Alto Parnaíba', value: 'Alto Parnaíba' },
        { label: 'Amapá do Maranhão', value: 'Amapá do Maranhão' },
        { label: 'Amarante do Maranhão', value: 'Amarante do Maranhão' },
        { label: 'Anajatuba', value: 'Anajatuba' },
        { label: 'Anapurus', value: 'Anapurus' },
        { label: 'Apicum-Açu', value: 'Apicum-Açu' },
        { label: 'Araguanã', value: 'Araguanã' },
        { label: 'Araioses', value: 'Araioses' },
        { label: 'Arame', value: 'Arame' },
        { label: 'Arari', value: 'Arari' },
        { label: 'Axixá', value: 'Axixá' },
        { label: 'Bacabal', value: 'Bacabal' },
        { label: 'Bacabeira', value: 'Bacabeira' },
        { label: 'Bacuri', value: 'Bacuri' },
        { label: 'Bacurituba', value: 'Bacurituba' },
        { label: 'Balsas', value: 'Balsas' },
        { label: 'Barão de Grajaú', value: 'Barão de Grajaú' },
        { label: 'Barra do Corda', value: 'Barra do Corda' },
        { label: 'Barreirinhas', value: 'Barreirinhas' },
        { label: 'Bela Vista do Maranhão', value: 'Bela Vista do Maranhão' },
        { label: 'Belágua', value: 'Belágua' },
        { label: 'Benedito Leite', value: 'Benedito Leite' },
        { label: 'Bequimão', value: 'Bequimão' },
        { label: 'Bernardo do Mearim', value: 'Bernardo do Mearim' },
        { label: 'Boa Vista do Gurupi', value: 'Boa Vista do Gurupi' },
        { label: 'Bom Jardim', value: 'Bom Jardim' },
        { label: 'Bom Jesus das Selvas', value: 'Bom Jesus das Selvas' },
        { label: 'Bom Lugar', value: 'Bom Lugar' },
        { label: 'Brejo', value: 'Brejo' },
        { label: 'Brejo de Areia', value: 'Brejo de Areia' },
        { label: 'Buriti', value: 'Buriti' },
        { label: 'Buriti Bravo', value: 'Buriti Bravo' },
        { label: 'Buriticupu', value: 'Buriticupu' },
        { label: 'Buritirana', value: 'Buritirana' },
        { label: 'Cachoeira Grande', value: 'Cachoeira Grande' },
        { label: 'Cajapió', value: 'Cajapió' },
        { label: 'Cajari', value: 'Cajari' },
        { label: 'Campestre do Maranhão', value: 'Campestre do Maranhão' },
        { label: 'Cândido Mendes', value: 'Cândido Mendes' },
        { label: 'Cantanhede', value: 'Cantanhede' },
        { label: 'Capinzal do Norte', value: 'Capinzal do Norte' },
        { label: 'Carolina', value: 'Carolina' },
        { label: 'Carutapera', value: 'Carutapera' },
        { label: 'Caxias', value: 'Caxias' },
        { label: 'Cedral', value: 'Cedral' },
        { label: 'Central do Maranhão', value: 'Central do Maranhão' },
        { label: 'Centro do Guilherme', value: 'Centro do Guilherme' },
        { label: 'Centro Novo do Maranhão', value: 'Centro Novo do Maranhão' },
        { label: 'Chapadinha', value: 'Chapadinha' },
        { label: 'Cidelândia', value: 'Cidelândia' },
        { label: 'Codó', value: 'Codó' },
        { label: 'Coelho Neto', value: 'Coelho Neto' },
        { label: 'Colinas', value: 'Colinas' },
        { label: 'Conceição do Lago-Açu', value: 'Conceição do Lago-Açu' },
        { label: 'Coroatá', value: 'Coroatá' },
        { label: 'Cururupu', value: 'Cururupu' },
        { label: 'Davinópolis', value: 'Davinópolis' },
        { label: 'Dom Pedro', value: 'Dom Pedro' },
        { label: 'Duque Bacelar', value: 'Duque Bacelar' },
        { label: 'Esperantinópolis', value: 'Esperantinópolis' },
        { label: 'Estreito', value: 'Estreito' },
        { label: 'Feira Nova do Maranhão', value: 'Feira Nova do Maranhão' },
        { label: 'Fernando Falcão', value: 'Fernando Falcão' },
        { label: 'Formosa da Serra Negra', value: 'Formosa da Serra Negra' },
        { label: 'Fortaleza dos Nogueiras', value: 'Fortaleza dos Nogueiras' },
        { label: 'Fortuna', value: 'Fortuna' },
        { label: 'Godofredo Viana', value: 'Godofredo Viana' },
        { label: 'Gonçalves Dias', value: 'Gonçalves Dias' },
        { label: 'Governador Archer', value: 'Governador Archer' },
        { label: 'Governador Edison Lobão', value: 'Governador Edison Lobão' },
        { label: 'Governador Eugênio Barros', value: 'Governador Eugênio Barros' },
        { label: 'Governador Luiz Rocha', value: 'Governador Luiz Rocha' },
        { label: 'Governador Newton Bello', value: 'Governador Newton Bello' },
        { label: 'Governador Nunes Freire', value: 'Governador Nunes Freire' },
        { label: 'Graça Aranha', value: 'Graça Aranha' },
        { label: 'Grajaú', value: 'Grajaú' },
        { label: 'Guimarães', value: 'Guimarães' },
        { label: 'Humberto de Campos', value: 'Humberto de Campos' },
        { label: 'Icatu', value: 'Icatu' },
        { label: 'Igarapé do Meio', value: 'Igarapé do Meio' },
        { label: 'Igarapé Grande', value: 'Igarapé Grande' },
        { label: 'Imperatriz', value: 'Imperatriz' },
        { label: 'Itaipava do Grajaú', value: 'Itaipava do Grajaú' },
        { label: 'Itapecuru Mirim', value: 'Itapecuru Mirim' },
        { label: 'Itinga do Maranhão', value: 'Itinga do Maranhão' },
        { label: 'Jatobá', value: 'Jatobá' },
        { label: 'Jenipapo dos Vieiras', value: 'Jenipapo dos Vieiras' },
        { label: 'João Lisboa', value: 'João Lisboa' },
        { label: 'Joselândia', value: 'Joselândia' },
        { label: 'Junco do Maranhão', value: 'Junco do Maranhão' },
        { label: 'Lago da Pedra', value: 'Lago da Pedra' },
        { label: 'Lago do Junco', value: 'Lago do Junco' },
        { label: 'Lago dos Rodrigues', value: 'Lago dos Rodrigues' },
        { label: 'Lago Verde', value: 'Lago Verde' },
        { label: 'Lagoa do Mato', value: 'Lagoa do Mato' },
        { label: 'Lagoa Grande do Maranhão', value: 'Lagoa Grande do Maranhão' },
        { label: 'Lajeado Novo', value: 'Lajeado Novo' },
        { label: 'Lima Campos', value: 'Lima Campos' },
        { label: 'Loreto', value: 'Loreto' },
        { label: 'Luís Domingues', value: 'Luís Domingues' },
        { label: 'Magalhães de Almeida', value: 'Magalhães de Almeida' },
        { label: 'Maracaçumé', value: 'Maracaçumé' },
        { label: 'Marajá do Sena', value: 'Marajá do Sena' },
        { label: 'Maranhãozinho', value: 'Maranhãozinho' },
        { label: 'Mata Roma', value: 'Mata Roma' },
        { label: 'Matinha', value: 'Matinha' },
        { label: 'Matões', value: 'Matões' },
        { label: 'Matões do Norte', value: 'Matões do Norte' },
        { label: 'Milagres do Maranhão', value: 'Milagres do Maranhão' },
        { label: 'Mirador', value: 'Mirador' },
        { label: 'Miranda do Norte', value: 'Miranda do Norte' },
        { label: 'Mirinzal', value: 'Mirinzal' },
        { label: 'Monção', value: 'Monção' },
        { label: 'Montes Altos', value: 'Montes Altos' },
        { label: 'Morros', value: 'Morros' },
        { label: 'Nina Rodrigues', value: 'Nina Rodrigues' },
        { label: 'Nova Colinas', value: 'Nova Colinas' },
        { label: 'Nova Iorque', value: 'Nova Iorque' },
        { label: 'Nova Olinda do Maranhão', value: 'Nova Olinda do Maranhão' },
        "Olho d'Água das Cunhãs",
        { label: 'Olinda Nova do Maranhão', value: 'Olinda Nova do Maranhão' },
        { label: 'Paço do Lumiar', value: 'Paço do Lumiar' },
        { label: 'Palmeirândia', value: 'Palmeirândia' },
        { label: 'Paraibano', value: 'Paraibano' },
        { label: 'Parnarama', value: 'Parnarama' },
        { label: 'Passagem Franca', value: 'Passagem Franca' },
        { label: 'Pastos Bons', value: 'Pastos Bons' },
        { label: 'Paulino Neves', value: 'Paulino Neves' },
        { label: 'Paulo Ramos', value: 'Paulo Ramos' },
        { label: 'Pedreiras', value: 'Pedreiras' },
        { label: 'Pedro do Rosário', value: 'Pedro do Rosário' },
        { label: 'Penalva', value: 'Penalva' },
        { label: 'Peri Mirim', value: 'Peri Mirim' },
        { label: 'Peritoró', value: 'Peritoró' },
        { label: 'Pindaré-Mirim', value: 'Pindaré-Mirim' },
        { label: 'Pinheiro', value: 'Pinheiro' },
        { label: 'Pio XII', value: 'Pio XII' },
        { label: 'Pirapemas', value: 'Pirapemas' },
        { label: 'Poção de Pedras', value: 'Poção de Pedras' },
        { label: 'Porto Franco', value: 'Porto Franco' },
        { label: 'Porto Rico do Maranhão', value: 'Porto Rico do Maranhão' },
        { label: 'Presidente Dutra', value: 'Presidente Dutra' },
        { label: 'Presidente Juscelino', value: 'Presidente Juscelino' },
        { label: 'Presidente Médici', value: 'Presidente Médici' },
        { label: 'Presidente Sarney', value: 'Presidente Sarney' },
        { label: 'Presidente Vargas', value: 'Presidente Vargas' },
        { label: 'Primeira Cruz', value: 'Primeira Cruz' },
        { label: 'Raposa', value: 'Raposa' },
        { label: 'Riachão', value: 'Riachão' },
        { label: 'Ribamar Fiquene', value: 'Ribamar Fiquene' },
        { label: 'Rosário', value: 'Rosário' },
        { label: 'Sambaíba', value: 'Sambaíba' },
        { label: 'Santa Filomena do Maranhão', value: 'Santa Filomena do Maranhão' },
        { label: 'Santa Helena', value: 'Santa Helena' },
        { label: 'Santa Inês', value: 'Santa Inês' },
        { label: 'Santa Luzia', value: 'Santa Luzia' },
        { label: 'Santa Luzia do Paruá', value: 'Santa Luzia do Paruá' },
        { label: 'Santa Quitéria do Maranhão', value: 'Santa Quitéria do Maranhão' },
        { label: 'Santa Rita', value: 'Santa Rita' },
        { label: 'Santana do Maranhão', value: 'Santana do Maranhão' },
        { label: 'Santo Amaro do Maranhão', value: 'Santo Amaro do Maranhão' },
        { label: 'Santo Antônio dos Lopes', value: 'Santo Antônio dos Lopes' },
        { label: 'São Benedito do Rio Preto', value: 'São Benedito do Rio Preto' },
        { label: 'São Bento', value: 'São Bento' },
        { label: 'São Bernardo', value: 'São Bernardo' },
        { label: 'São Domingos do Azeitão', value: 'São Domingos do Azeitão' },
        { label: 'São Domingos do Maranhão', value: 'São Domingos do Maranhão' },
        { label: 'São Félix de Balsas', value: 'São Félix de Balsas' },
        { label: 'São Francisco do Brejão', value: 'São Francisco do Brejão' },
        { label: 'São Francisco do Maranhão', value: 'São Francisco do Maranhão' },
        { label: 'São João Batista', value: 'São João Batista' },
        { label: 'São João do Carú', value: 'São João do Carú' },
        { label: 'São João do Paraíso', value: 'São João do Paraíso' },
        { label: 'São João do Soter', value: 'São João do Soter' },
        { label: 'São João dos Patos', value: 'São João dos Patos' },
        { label: 'São José de Ribamar', value: 'São José de Ribamar' },
        { label: 'São José dos Basílios', value: 'São José dos Basílios' },
        { label: 'São Luís', value: 'São Luís' },
        { label: 'São Luís Gonzaga do Maranhão', value: 'São Luís Gonzaga do Maranhão' },
        { label: 'São Mateus do Maranhão', value: 'São Mateus do Maranhão' },
        { label: 'São Pedro da Água Branca', value: 'São Pedro da Água Branca' },
        { label: 'São Pedro dos Crentes', value: 'São Pedro dos Crentes' },
        { label: 'São Raimundo das Mangabeiras', value: 'São Raimundo das Mangabeiras' },
        { label: 'São Raimundo do Doca Bezerra', value: 'São Raimundo do Doca Bezerra' },
        { label: 'São Roberto', value: 'São Roberto' },
        { label: 'São Vicente Ferrer', value: 'São Vicente Ferrer' },
        { label: 'Satubinha', value: 'Satubinha' },
        { label: 'Senador Alexandre Costa', value: 'Senador Alexandre Costa' },
        { label: 'Senador La Rocque', value: 'Senador La Rocque' },
        { label: 'Serrano do Maranhão', value: 'Serrano do Maranhão' },
        { label: 'Sítio Novo', value: 'Sítio Novo' },
        { label: 'Sucupira do Norte', value: 'Sucupira do Norte' },
        { label: 'Sucupira do Riachão', value: 'Sucupira do Riachão' },
        { label: 'Tasso Fragoso', value: 'Tasso Fragoso' },
        { label: 'Timbiras', value: 'Timbiras' },
        { label: 'Timon', value: 'Timon' },
        { label: 'Trizidela do Vale', value: 'Trizidela do Vale' },
        { label: 'Tufilândia', value: 'Tufilândia' },
        { label: 'Tuntum', value: 'Tuntum' },
        { label: 'Turiaçu', value: 'Turiaçu' },
        { label: 'Turilândia', value: 'Turilândia' },
        { label: 'Tutóia', value: 'Tutóia' },
        { label: 'Urbano Santos', value: 'Urbano Santos' },
        { label: 'Vargem Grande', value: 'Vargem Grande' },
        { label: 'Viana', value: 'Viana' },
        { label: 'Vila Nova dos Martírios', value: 'Vila Nova dos Martírios' },
        { label: 'Vitória do Mearim', value: 'Vitória do Mearim' },
        { label: 'Vitorino Freire', value: 'Vitorino Freire' },
        { label: 'Zé Doca', value: 'Zé Doca' },
    ],
    MG: [
        { label: 'Abadia dos Dourados', value: 'Abadia dos Dourados' },
        { label: 'Abaeté', value: 'Abaeté' },
        { label: 'Abre Campo', value: 'Abre Campo' },
        { label: 'Acaiaca', value: 'Acaiaca' },
        { label: 'Açucena', value: 'Açucena' },
        { label: 'Água Boa', value: 'Água Boa' },
        { label: 'Água Comprida', value: 'Água Comprida' },
        { label: 'Aguanil', value: 'Aguanil' },
        { label: 'Águas Formosas', value: 'Águas Formosas' },
        { label: 'Águas Vermelhas', value: 'Águas Vermelhas' },
        { label: 'Aimorés', value: 'Aimorés' },
        { label: 'Aiuruoca', value: 'Aiuruoca' },
        { label: 'Alagoa', value: 'Alagoa' },
        { label: 'Albertina', value: 'Albertina' },
        { label: 'Além Paraíba', value: 'Além Paraíba' },
        { label: 'Alfenas', value: 'Alfenas' },
        { label: 'Alfredo Vasconcelos', value: 'Alfredo Vasconcelos' },
        { label: 'Almenara', value: 'Almenara' },
        { label: 'Alpercata', value: 'Alpercata' },
        { label: 'Alpinópolis', value: 'Alpinópolis' },
        { label: 'Alterosa', value: 'Alterosa' },
        { label: 'Alto Caparaó', value: 'Alto Caparaó' },
        { label: 'Alto Jequitibá', value: 'Alto Jequitibá' },
        { label: 'Alto Rio Doce', value: 'Alto Rio Doce' },
        { label: 'Alvarenga', value: 'Alvarenga' },
        { label: 'Alvinópolis', value: 'Alvinópolis' },
        { label: 'Alvorada de Minas', value: 'Alvorada de Minas' },
        { label: 'Amparo do Serra', value: 'Amparo do Serra' },
        { label: 'Andradas', value: 'Andradas' },
        { label: 'Andrelândia', value: 'Andrelândia' },
        { label: 'Angelândia', value: 'Angelândia' },
        { label: 'Antônio Carlos', value: 'Antônio Carlos' },
        { label: 'Antônio Dias', value: 'Antônio Dias' },
        { label: 'Antônio Prado de Minas', value: 'Antônio Prado de Minas' },
        { label: 'Araçaí', value: 'Araçaí' },
        { label: 'Aracitaba', value: 'Aracitaba' },
        { label: 'Araçuaí', value: 'Araçuaí' },
        { label: 'Araguari', value: 'Araguari' },
        { label: 'Arantina', value: 'Arantina' },
        { label: 'Araponga', value: 'Araponga' },
        { label: 'Araporã', value: 'Araporã' },
        { label: 'Arapuá', value: 'Arapuá' },
        { label: 'Araújos', value: 'Araújos' },
        { label: 'Araxá', value: 'Araxá' },
        { label: 'Arceburgo', value: 'Arceburgo' },
        { label: 'Arcos', value: 'Arcos' },
        { label: 'Areado', value: 'Areado' },
        { label: 'Argirita', value: 'Argirita' },
        { label: 'Aricanduva', value: 'Aricanduva' },
        { label: 'Arinos', value: 'Arinos' },
        { label: 'Astolfo Dutra', value: 'Astolfo Dutra' },
        { label: 'Ataléia', value: 'Ataléia' },
        { label: 'Augusto de Lima', value: 'Augusto de Lima' },
        { label: 'Baependi', value: 'Baependi' },
        { label: 'Baldim', value: 'Baldim' },
        { label: 'Bambuí', value: 'Bambuí' },
        { label: 'Bandeira', value: 'Bandeira' },
        { label: 'Bandeira do Sul', value: 'Bandeira do Sul' },
        { label: 'Barão de Cocais', value: 'Barão de Cocais' },
        { label: 'Barão de Monte Alto', value: 'Barão de Monte Alto' },
        { label: 'Barbacena', value: 'Barbacena' },
        { label: 'Barra Longa', value: 'Barra Longa' },
        { label: 'Barroso', value: 'Barroso' },
        { label: 'Bela Vista de Minas', value: 'Bela Vista de Minas' },
        { label: 'Belmiro Braga', value: 'Belmiro Braga' },
        { label: 'Belo Horizonte', value: 'Belo Horizonte' },
        { label: 'Belo Oriente', value: 'Belo Oriente' },
        { label: 'Belo Vale', value: 'Belo Vale' },
        { label: 'Berilo', value: 'Berilo' },
        { label: 'Berizal', value: 'Berizal' },
        { label: 'Bertópolis', value: 'Bertópolis' },
        { label: 'Betim', value: 'Betim' },
        { label: 'Bias Fortes', value: 'Bias Fortes' },
        { label: 'Bicas', value: 'Bicas' },
        { label: 'Biquinhas', value: 'Biquinhas' },
        { label: 'Boa Esperança', value: 'Boa Esperança' },
        { label: 'Bocaina de Minas', value: 'Bocaina de Minas' },
        { label: 'Bocaiúva', value: 'Bocaiúva' },
        { label: 'Bom Despacho', value: 'Bom Despacho' },
        { label: 'Bom Jardim de Minas', value: 'Bom Jardim de Minas' },
        { label: 'Bom Jesus da Penha', value: 'Bom Jesus da Penha' },
        { label: 'Bom Jesus do Amparo', value: 'Bom Jesus do Amparo' },
        { label: 'Bom Jesus do Galho', value: 'Bom Jesus do Galho' },
        { label: 'Bom Repouso', value: 'Bom Repouso' },
        { label: 'Bom Sucesso', value: 'Bom Sucesso' },
        { label: 'Bonfim', value: 'Bonfim' },
        { label: 'Bonfinópolis de Minas', value: 'Bonfinópolis de Minas' },
        { label: 'Bonito de Minas', value: 'Bonito de Minas' },
        { label: 'Borda da Mata', value: 'Borda da Mata' },
        { label: 'Botelhos', value: 'Botelhos' },
        { label: 'Botumirim', value: 'Botumirim' },
        { label: 'Brás Pires', value: 'Brás Pires' },
        { label: 'Brasilândia de Minas', value: 'Brasilândia de Minas' },
        { label: 'Brasília de Minas', value: 'Brasília de Minas' },
        { label: 'Braúnas', value: 'Braúnas' },
        { label: 'Brazópolis', value: 'Brazópolis' },
        { label: 'Brumadinho', value: 'Brumadinho' },
        { label: 'Bueno Brandão', value: 'Bueno Brandão' },
        { label: 'Buenópolis', value: 'Buenópolis' },
        { label: 'Bugre', value: 'Bugre' },
        { label: 'Buritis', value: 'Buritis' },
        { label: 'Buritizeiro', value: 'Buritizeiro' },
        { label: 'Cabeceira Grande', value: 'Cabeceira Grande' },
        { label: 'Cabo Verde', value: 'Cabo Verde' },
        { label: 'Cachoeira da Prata', value: 'Cachoeira da Prata' },
        { label: 'Cachoeira de Minas', value: 'Cachoeira de Minas' },
        { label: 'Cachoeira de Pajeú', value: 'Cachoeira de Pajeú' },
        { label: 'Cachoeira Dourada', value: 'Cachoeira Dourada' },
        { label: 'Caetanópolis', value: 'Caetanópolis' },
        { label: 'Caeté', value: 'Caeté' },
        { label: 'Caiana', value: 'Caiana' },
        { label: 'Cajuri', value: 'Cajuri' },
        { label: 'Caldas', value: 'Caldas' },
        { label: 'Camacho', value: 'Camacho' },
        { label: 'Camanducaia', value: 'Camanducaia' },
        { label: 'Cambuí', value: 'Cambuí' },
        { label: 'Cambuquira', value: 'Cambuquira' },
        { label: 'Campanário', value: 'Campanário' },
        { label: 'Campanha', value: 'Campanha' },
        { label: 'Campestre', value: 'Campestre' },
        { label: 'Campina Verde', value: 'Campina Verde' },
        { label: 'Campo Azul', value: 'Campo Azul' },
        { label: 'Campo Belo', value: 'Campo Belo' },
        { label: 'Campo do Meio', value: 'Campo do Meio' },
        { label: 'Campo Florido', value: 'Campo Florido' },
        { label: 'Campos Altos', value: 'Campos Altos' },
        { label: 'Campos Gerais', value: 'Campos Gerais' },
        { label: 'Cana Verde', value: 'Cana Verde' },
        { label: 'Canaã', value: 'Canaã' },
        { label: 'Canápolis', value: 'Canápolis' },
        { label: 'Candeias', value: 'Candeias' },
        { label: 'Cantagalo', value: 'Cantagalo' },
        { label: 'Caparaó', value: 'Caparaó' },
        { label: 'Capela Nova', value: 'Capela Nova' },
        { label: 'Capelinha', value: 'Capelinha' },
        { label: 'Capetinga', value: 'Capetinga' },
        { label: 'Capim Branco', value: 'Capim Branco' },
        { label: 'Capinópolis', value: 'Capinópolis' },
        { label: 'Capitão Andrade', value: 'Capitão Andrade' },
        { label: 'Capitão Enéas', value: 'Capitão Enéas' },
        { label: 'Capitólio', value: 'Capitólio' },
        { label: 'Caputira', value: 'Caputira' },
        { label: 'Caraí', value: 'Caraí' },
        { label: 'Caranaíba', value: 'Caranaíba' },
        { label: 'Carandaí', value: 'Carandaí' },
        { label: 'Carangola', value: 'Carangola' },
        { label: 'Caratinga', value: 'Caratinga' },
        { label: 'Carbonita', value: 'Carbonita' },
        { label: 'Careaçu', value: 'Careaçu' },
        { label: 'Carlos Chagas', value: 'Carlos Chagas' },
        { label: 'Carmésia', value: 'Carmésia' },
        { label: 'Carmo da Cachoeira', value: 'Carmo da Cachoeira' },
        { label: 'Carmo da Mata', value: 'Carmo da Mata' },
        { label: 'Carmo de Minas', value: 'Carmo de Minas' },
        { label: 'Carmo do Cajuru', value: 'Carmo do Cajuru' },
        { label: 'Carmo do Paranaíba', value: 'Carmo do Paranaíba' },
        { label: 'Carmo do Rio Claro', value: 'Carmo do Rio Claro' },
        { label: 'Carmópolis de Minas', value: 'Carmópolis de Minas' },
        { label: 'Carneirinho', value: 'Carneirinho' },
        { label: 'Carrancas', value: 'Carrancas' },
        { label: 'Carvalhópolis', value: 'Carvalhópolis' },
        { label: 'Carvalhos', value: 'Carvalhos' },
        { label: 'Casa Grande', value: 'Casa Grande' },
        { label: 'Cascalho Rico', value: 'Cascalho Rico' },
        { label: 'Cássia', value: 'Cássia' },
        { label: 'Cataguases', value: 'Cataguases' },
        { label: 'Catas Altas', value: 'Catas Altas' },
        { label: 'Catas Altas da Noruega', value: 'Catas Altas da Noruega' },
        { label: 'Catuji', value: 'Catuji' },
        { label: 'Catuti', value: 'Catuti' },
        { label: 'Caxambu', value: 'Caxambu' },
        { label: 'Cedro do Abaeté', value: 'Cedro do Abaeté' },
        { label: 'Central de Minas', value: 'Central de Minas' },
        { label: 'Centralina', value: 'Centralina' },
        { label: 'Chácara', value: 'Chácara' },
        { label: 'Chalé', value: 'Chalé' },
        { label: 'Chapada do Norte', value: 'Chapada do Norte' },
        { label: 'Chapada Gaúcha', value: 'Chapada Gaúcha' },
        { label: 'Chiador', value: 'Chiador' },
        { label: 'Cipotânea', value: 'Cipotânea' },
        { label: 'Claraval', value: 'Claraval' },
        { label: 'Claro dos Poções', value: 'Claro dos Poções' },
        { label: 'Cláudio', value: 'Cláudio' },
        { label: 'Coimbra', value: 'Coimbra' },
        { label: 'Coluna', value: 'Coluna' },
        { label: 'Comendador Gomes', value: 'Comendador Gomes' },
        { label: 'Comercinho', value: 'Comercinho' },
        { label: 'Conceição da Aparecida', value: 'Conceição da Aparecida' },
        { label: 'Conceição da Barra de Minas', value: 'Conceição da Barra de Minas' },
        { label: 'Conceição das Alagoas', value: 'Conceição das Alagoas' },
        { label: 'Conceição das Pedras', value: 'Conceição das Pedras' },
        { label: 'Conceição de Ipanema', value: 'Conceição de Ipanema' },
        { label: 'Conceição do Mato Dentro', value: 'Conceição do Mato Dentro' },
        { label: 'Conceição do Pará', value: 'Conceição do Pará' },
        { label: 'Conceição do Rio Verde', value: 'Conceição do Rio Verde' },
        { label: 'Conceição dos Ouros', value: 'Conceição dos Ouros' },
        { label: 'Cônego Marinho', value: 'Cônego Marinho' },
        { label: 'Confins', value: 'Confins' },
        { label: 'Congonhal', value: 'Congonhal' },
        { label: 'Congonhas', value: 'Congonhas' },
        { label: 'Congonhas do Norte', value: 'Congonhas do Norte' },
        { label: 'Conquista', value: 'Conquista' },
        { label: 'Conselheiro Lafaiete', value: 'Conselheiro Lafaiete' },
        { label: 'Conselheiro Pena', value: 'Conselheiro Pena' },
        { label: 'Consolação', value: 'Consolação' },
        { label: 'Contagem', value: 'Contagem' },
        { label: 'Coqueiral', value: 'Coqueiral' },
        { label: 'Coração de Jesus', value: 'Coração de Jesus' },
        { label: 'Cordisburgo', value: 'Cordisburgo' },
        { label: 'Cordislândia', value: 'Cordislândia' },
        { label: 'Corinto', value: 'Corinto' },
        { label: 'Coroaci', value: 'Coroaci' },
        { label: 'Coromandel', value: 'Coromandel' },
        { label: 'Coronel Fabriciano', value: 'Coronel Fabriciano' },
        { label: 'Coronel Murta', value: 'Coronel Murta' },
        { label: 'Coronel Pacheco', value: 'Coronel Pacheco' },
        { label: 'Coronel Xavier Chaves', value: 'Coronel Xavier Chaves' },
        { label: 'Córrego Danta', value: 'Córrego Danta' },
        { label: 'Córrego do Bom Jesus', value: 'Córrego do Bom Jesus' },
        { label: 'Córrego Fundo', value: 'Córrego Fundo' },
        { label: 'Córrego Novo', value: 'Córrego Novo' },
        { label: 'Couto de Magalhães de Minas', value: 'Couto de Magalhães de Minas' },
        { label: 'Crisólita', value: 'Crisólita' },
        { label: 'Cristais', value: 'Cristais' },
        { label: 'Cristália', value: 'Cristália' },
        { label: 'Cristiano Otoni', value: 'Cristiano Otoni' },
        { label: 'Cristina', value: 'Cristina' },
        { label: 'Crucilândia', value: 'Crucilândia' },
        { label: 'Cruzeiro da Fortaleza', value: 'Cruzeiro da Fortaleza' },
        { label: 'Cruzília', value: 'Cruzília' },
        { label: 'Cuparaque', value: 'Cuparaque' },
        { label: 'Curral de Dentro', value: 'Curral de Dentro' },
        { label: 'Curvelo', value: 'Curvelo' },
        { label: 'Datas', value: 'Datas' },
        { label: 'Delfim Moreira', value: 'Delfim Moreira' },
        { label: 'Delfinópolis', value: 'Delfinópolis' },
        { label: 'Delta', value: 'Delta' },
        { label: 'Descoberto', value: 'Descoberto' },
        { label: 'Desterro de Entre Rios', value: 'Desterro de Entre Rios' },
        { label: 'Desterro do Melo', value: 'Desterro do Melo' },
        { label: 'Diamantina', value: 'Diamantina' },
        { label: 'Diogo de Vasconcelos', value: 'Diogo de Vasconcelos' },
        { label: 'Dionísio', value: 'Dionísio' },
        { label: 'Divinésia', value: 'Divinésia' },
        { label: 'Divino', value: 'Divino' },
        { label: 'Divino das Laranjeiras', value: 'Divino das Laranjeiras' },
        { label: 'Divinolândia de Minas', value: 'Divinolândia de Minas' },
        { label: 'Divinópolis', value: 'Divinópolis' },
        { label: 'Divisa Alegre', value: 'Divisa Alegre' },
        { label: 'Divisa Nova', value: 'Divisa Nova' },
        { label: 'Divisópolis', value: 'Divisópolis' },
        { label: 'Dom Bosco', value: 'Dom Bosco' },
        { label: 'Dom Cavati', value: 'Dom Cavati' },
        { label: 'Dom Joaquim', value: 'Dom Joaquim' },
        { label: 'Dom Silvério', value: 'Dom Silvério' },
        { label: 'Dom Viçoso', value: 'Dom Viçoso' },
        { label: 'Dona Eusébia', value: 'Dona Eusébia' },
        { label: 'Dores de Campos', value: 'Dores de Campos' },
        { label: 'Dores de Guanhães', value: 'Dores de Guanhães' },
        { label: 'Dores do Indaiá', value: 'Dores do Indaiá' },
        { label: 'Dores do Turvo', value: 'Dores do Turvo' },
        { label: 'Doresópolis', value: 'Doresópolis' },
        { label: 'Douradoquara', value: 'Douradoquara' },
        { label: 'Durandé', value: 'Durandé' },
        { label: 'Elói Mendes', value: 'Elói Mendes' },
        { label: 'Engenheiro Caldas', value: 'Engenheiro Caldas' },
        { label: 'Engenheiro Navarro', value: 'Engenheiro Navarro' },
        { label: 'Entre Folhas', value: 'Entre Folhas' },
        { label: 'Entre Rios de Minas', value: 'Entre Rios de Minas' },
        { label: 'Ervália', value: 'Ervália' },
        { label: 'Esmeraldas', value: 'Esmeraldas' },
        { label: 'Espera Feliz', value: 'Espera Feliz' },
        { label: 'Espinosa', value: 'Espinosa' },
        { label: 'Espírito Santo do Dourado', value: 'Espírito Santo do Dourado' },
        { label: 'Estiva', value: 'Estiva' },
        { label: 'Estrela Dalva', value: 'Estrela Dalva' },
        { label: 'Estrela do Indaiá', value: 'Estrela do Indaiá' },
        { label: 'Estrela do Sul', value: 'Estrela do Sul' },
        { label: 'Eugenópolis', value: 'Eugenópolis' },
        { label: 'Ewbank da Câmara', value: 'Ewbank da Câmara' },
        { label: 'Extrema', value: 'Extrema' },
        { label: 'Fama', value: 'Fama' },
        { label: 'Faria Lemos', value: 'Faria Lemos' },
        { label: 'Felício dos Santos', value: 'Felício dos Santos' },
        { label: 'Felisburgo', value: 'Felisburgo' },
        { label: 'Felixlândia', value: 'Felixlândia' },
        { label: 'Fernandes Tourinho', value: 'Fernandes Tourinho' },
        { label: 'Ferros', value: 'Ferros' },
        { label: 'Fervedouro', value: 'Fervedouro' },
        { label: 'Florestal', value: 'Florestal' },
        { label: 'Formiga', value: 'Formiga' },
        { label: 'Formoso', value: 'Formoso' },
        { label: 'Fortaleza de Minas', value: 'Fortaleza de Minas' },
        { label: 'Fortuna de Minas', value: 'Fortuna de Minas' },
        { label: 'Francisco Badaró', value: 'Francisco Badaró' },
        { label: 'Francisco Dumont', value: 'Francisco Dumont' },
        { label: 'Francisco Sá', value: 'Francisco Sá' },
        { label: 'Franciscópolis', value: 'Franciscópolis' },
        { label: 'Frei Gaspar', value: 'Frei Gaspar' },
        { label: 'Frei Inocêncio', value: 'Frei Inocêncio' },
        { label: 'Frei Lagonegro', value: 'Frei Lagonegro' },
        { label: 'Fronteira', value: 'Fronteira' },
        { label: 'Fronteira dos Vales', value: 'Fronteira dos Vales' },
        { label: 'Fruta de Leite', value: 'Fruta de Leite' },
        { label: 'Frutal', value: 'Frutal' },
        { label: 'Funilândia', value: 'Funilândia' },
        { label: 'Galiléia', value: 'Galiléia' },
        { label: 'Gameleiras', value: 'Gameleiras' },
        { label: 'Glaucilândia', value: 'Glaucilândia' },
        { label: 'Goiabeira', value: 'Goiabeira' },
        { label: 'Goianá', value: 'Goianá' },
        { label: 'Gonçalves', value: 'Gonçalves' },
        { label: 'Gonzaga', value: 'Gonzaga' },
        { label: 'Gouveia', value: 'Gouveia' },
        { label: 'Governador Valadares', value: 'Governador Valadares' },
        { label: 'Grão Mogol', value: 'Grão Mogol' },
        { label: 'Grupiara', value: 'Grupiara' },
        { label: 'Guanhães', value: 'Guanhães' },
        { label: 'Guapé', value: 'Guapé' },
        { label: 'Guaraciaba', value: 'Guaraciaba' },
        { label: 'Guaraciama', value: 'Guaraciama' },
        { label: 'Guaranésia', value: 'Guaranésia' },
        { label: 'Guarani', value: 'Guarani' },
        { label: 'Guarará', value: 'Guarará' },
        { label: 'Guarda-Mor', value: 'Guarda-Mor' },
        { label: 'Guaxupé', value: 'Guaxupé' },
        { label: 'Guidoval', value: 'Guidoval' },
        { label: 'Guimarânia', value: 'Guimarânia' },
        { label: 'Guiricema', value: 'Guiricema' },
        { label: 'Gurinhatã', value: 'Gurinhatã' },
        { label: 'Heliodora', value: 'Heliodora' },
        { label: 'Iapu', value: 'Iapu' },
        { label: 'Ibertioga', value: 'Ibertioga' },
        { label: 'Ibiá', value: 'Ibiá' },
        { label: 'Ibiaí', value: 'Ibiaí' },
        { label: 'Ibiracatu', value: 'Ibiracatu' },
        { label: 'Ibiraci', value: 'Ibiraci' },
        { label: 'Ibirité', value: 'Ibirité' },
        { label: 'Ibitiúra de Minas', value: 'Ibitiúra de Minas' },
        { label: 'Ibituruna', value: 'Ibituruna' },
        { label: 'Icaraí de Minas', value: 'Icaraí de Minas' },
        { label: 'Igarapé', value: 'Igarapé' },
        { label: 'Igaratinga', value: 'Igaratinga' },
        { label: 'Iguatama', value: 'Iguatama' },
        { label: 'Ijaci', value: 'Ijaci' },
        { label: 'Ilicínea', value: 'Ilicínea' },
        { label: 'Imbé de Minas', value: 'Imbé de Minas' },
        { label: 'Inconfidentes', value: 'Inconfidentes' },
        { label: 'Indaiabira', value: 'Indaiabira' },
        { label: 'Indianópolis', value: 'Indianópolis' },
        { label: 'Ingaí', value: 'Ingaí' },
        { label: 'Inhapim', value: 'Inhapim' },
        { label: 'Inhaúma', value: 'Inhaúma' },
        { label: 'Inimutaba', value: 'Inimutaba' },
        { label: 'Ipaba', value: 'Ipaba' },
        { label: 'Ipanema', value: 'Ipanema' },
        { label: 'Ipatinga', value: 'Ipatinga' },
        { label: 'Ipiaçu', value: 'Ipiaçu' },
        { label: 'Ipuiúna', value: 'Ipuiúna' },
        { label: 'Iraí de Minas', value: 'Iraí de Minas' },
        { label: 'Itabira', value: 'Itabira' },
        { label: 'Itabirinha', value: 'Itabirinha' },
        { label: 'Itabirito', value: 'Itabirito' },
        { label: 'Itacambira', value: 'Itacambira' },
        { label: 'Itacarambi', value: 'Itacarambi' },
        { label: 'Itaguara', value: 'Itaguara' },
        { label: 'Itaipé', value: 'Itaipé' },
        { label: 'Itajubá', value: 'Itajubá' },
        { label: 'Itamarandiba', value: 'Itamarandiba' },
        { label: 'Itamarati de Minas', value: 'Itamarati de Minas' },
        { label: 'Itambacuri', value: 'Itambacuri' },
        { label: 'Itambé do Mato Dentro', value: 'Itambé do Mato Dentro' },
        { label: 'Itamogi', value: 'Itamogi' },
        { label: 'Itamonte', value: 'Itamonte' },
        { label: 'Itanhandu', value: 'Itanhandu' },
        { label: 'Itanhomi', value: 'Itanhomi' },
        { label: 'Itaobim', value: 'Itaobim' },
        { label: 'Itapagipe', value: 'Itapagipe' },
        { label: 'Itapecerica', value: 'Itapecerica' },
        { label: 'Itapeva', value: 'Itapeva' },
        { label: 'Itatiaiuçu', value: 'Itatiaiuçu' },
        { label: 'Itaú de Minas', value: 'Itaú de Minas' },
        { label: 'Itaúna', value: 'Itaúna' },
        { label: 'Itaverava', value: 'Itaverava' },
        { label: 'Itinga', value: 'Itinga' },
        { label: 'Itueta', value: 'Itueta' },
        { label: 'Ituiutaba', value: 'Ituiutaba' },
        { label: 'Itumirim', value: 'Itumirim' },
        { label: 'Iturama', value: 'Iturama' },
        { label: 'Itutinga', value: 'Itutinga' },
        { label: 'Jaboticatubas', value: 'Jaboticatubas' },
        { label: 'Jacinto', value: 'Jacinto' },
        { label: 'Jacuí', value: 'Jacuí' },
        { label: 'Jacutinga', value: 'Jacutinga' },
        { label: 'Jaguaraçu', value: 'Jaguaraçu' },
        { label: 'Jaíba', value: 'Jaíba' },
        { label: 'Jampruca', value: 'Jampruca' },
        { label: 'Janaúba', value: 'Janaúba' },
        { label: 'Januária', value: 'Januária' },
        { label: 'Japaraíba', value: 'Japaraíba' },
        { label: 'Japonvar', value: 'Japonvar' },
        { label: 'Jeceaba', value: 'Jeceaba' },
        { label: 'Jenipapo de Minas', value: 'Jenipapo de Minas' },
        { label: 'Jequeri', value: 'Jequeri' },
        { label: 'Jequitaí', value: 'Jequitaí' },
        { label: 'Jequitibá', value: 'Jequitibá' },
        { label: 'Jequitinhonha', value: 'Jequitinhonha' },
        { label: 'Jesuânia', value: 'Jesuânia' },
        { label: 'Joaíma', value: 'Joaíma' },
        { label: 'Joanésia', value: 'Joanésia' },
        { label: 'João Monlevade', value: 'João Monlevade' },
        { label: 'João Pinheiro', value: 'João Pinheiro' },
        { label: 'Joaquim Felício', value: 'Joaquim Felício' },
        { label: 'Jordânia', value: 'Jordânia' },
        { label: 'José Gonçalves de Minas', value: 'José Gonçalves de Minas' },
        { label: 'José Raydan', value: 'José Raydan' },
        { label: 'Josenópolis', value: 'Josenópolis' },
        { label: 'Juatuba', value: 'Juatuba' },
        { label: 'Juiz de Fora', value: 'Juiz de Fora' },
        { label: 'Juramento', value: 'Juramento' },
        { label: 'Juruaia', value: 'Juruaia' },
        { label: 'Juvenília', value: 'Juvenília' },
        { label: 'Ladainha', value: 'Ladainha' },
        { label: 'Lagamar', value: 'Lagamar' },
        { label: 'Lagoa da Prata', value: 'Lagoa da Prata' },
        { label: 'Lagoa dos Patos', value: 'Lagoa dos Patos' },
        { label: 'Lagoa Dourada', value: 'Lagoa Dourada' },
        { label: 'Lagoa Formosa', value: 'Lagoa Formosa' },
        { label: 'Lagoa Grande', value: 'Lagoa Grande' },
        { label: 'Lagoa Santa', value: 'Lagoa Santa' },
        { label: 'Lajinha', value: 'Lajinha' },
        { label: 'Lambari', value: 'Lambari' },
        { label: 'Lamim', value: 'Lamim' },
        { label: 'Laranjal', value: 'Laranjal' },
        { label: 'Lassance', value: 'Lassance' },
        { label: 'Lavras', value: 'Lavras' },
        { label: 'Leandro Ferreira', value: 'Leandro Ferreira' },
        { label: 'Leme do Prado', value: 'Leme do Prado' },
        { label: 'Leopoldina', value: 'Leopoldina' },
        { label: 'Liberdade', value: 'Liberdade' },
        { label: 'Lima Duarte', value: 'Lima Duarte' },
        { label: 'Limeira do Oeste', value: 'Limeira do Oeste' },
        { label: 'Lontra', value: 'Lontra' },
        { label: 'Luisburgo', value: 'Luisburgo' },
        { label: 'Luislândia', value: 'Luislândia' },
        { label: 'Luminárias', value: 'Luminárias' },
        { label: 'Luz', value: 'Luz' },
        { label: 'Machacalis', value: 'Machacalis' },
        { label: 'Machado', value: 'Machado' },
        { label: 'Madre de Deus de Minas', value: 'Madre de Deus de Minas' },
        { label: 'Malacacheta', value: 'Malacacheta' },
        { label: 'Mamonas', value: 'Mamonas' },
        { label: 'Manga', value: 'Manga' },
        { label: 'Manhuaçu', value: 'Manhuaçu' },
        { label: 'Manhumirim', value: 'Manhumirim' },
        { label: 'Mantena', value: 'Mantena' },
        { label: 'Mar de Espanha', value: 'Mar de Espanha' },
        { label: 'Maravilhas', value: 'Maravilhas' },
        { label: 'Maria da Fé', value: 'Maria da Fé' },
        { label: 'Mariana', value: 'Mariana' },
        { label: 'Marilac', value: 'Marilac' },
        { label: 'Mário Campos', value: 'Mário Campos' },
        { label: 'Maripá de Minas', value: 'Maripá de Minas' },
        { label: 'Marliéria', value: 'Marliéria' },
        { label: 'Marmelópolis', value: 'Marmelópolis' },
        { label: 'Martinho Campos', value: 'Martinho Campos' },
        { label: 'Martins Soares', value: 'Martins Soares' },
        { label: 'Mata Verde', value: 'Mata Verde' },
        { label: 'Materlândia', value: 'Materlândia' },
        { label: 'Mateus Leme', value: 'Mateus Leme' },
        { label: 'Mathias Lobato', value: 'Mathias Lobato' },
        { label: 'Matias Barbosa', value: 'Matias Barbosa' },
        { label: 'Matias Cardoso', value: 'Matias Cardoso' },
        { label: 'Matipó', value: 'Matipó' },
        { label: 'Mato Verde', value: 'Mato Verde' },
        { label: 'Matozinhos', value: 'Matozinhos' },
        { label: 'Matutina', value: 'Matutina' },
        { label: 'Medeiros', value: 'Medeiros' },
        { label: 'Medina', value: 'Medina' },
        { label: 'Mendes Pimentel', value: 'Mendes Pimentel' },
        { label: 'Mercês', value: 'Mercês' },
        { label: 'Mesquita', value: 'Mesquita' },
        { label: 'Minas Novas', value: 'Minas Novas' },
        { label: 'Minduri', value: 'Minduri' },
        { label: 'Mirabela', value: 'Mirabela' },
        { label: 'Miradouro', value: 'Miradouro' },
        { label: 'Miraí', value: 'Miraí' },
        { label: 'Miravânia', value: 'Miravânia' },
        { label: 'Moeda', value: 'Moeda' },
        { label: 'Moema', value: 'Moema' },
        { label: 'Monjolos', value: 'Monjolos' },
        { label: 'Monsenhor Paulo', value: 'Monsenhor Paulo' },
        { label: 'Montalvânia', value: 'Montalvânia' },
        { label: 'Monte Alegre de Minas', value: 'Monte Alegre de Minas' },
        { label: 'Monte Azul', value: 'Monte Azul' },
        { label: 'Monte Belo', value: 'Monte Belo' },
        { label: 'Monte Carmelo', value: 'Monte Carmelo' },
        { label: 'Monte Formoso', value: 'Monte Formoso' },
        { label: 'Monte Santo de Minas', value: 'Monte Santo de Minas' },
        { label: 'Monte Sião', value: 'Monte Sião' },
        { label: 'Montes Claros', value: 'Montes Claros' },
        { label: 'Montezuma', value: 'Montezuma' },
        { label: 'Morada Nova de Minas', value: 'Morada Nova de Minas' },
        { label: 'Morro da Garça', value: 'Morro da Garça' },
        { label: 'Morro do Pilar', value: 'Morro do Pilar' },
        { label: 'Munhoz', value: 'Munhoz' },
        { label: 'Muriaé', value: 'Muriaé' },
        { label: 'Mutum', value: 'Mutum' },
        { label: 'Muzambinho', value: 'Muzambinho' },
        { label: 'Nacip Raydan', value: 'Nacip Raydan' },
        { label: 'Nanuque', value: 'Nanuque' },
        { label: 'Naque', value: 'Naque' },
        { label: 'Natalândia', value: 'Natalândia' },
        { label: 'Natércia', value: 'Natércia' },
        { label: 'Nazareno', value: 'Nazareno' },
        { label: 'Nepomuceno', value: 'Nepomuceno' },
        { label: 'Ninheira', value: 'Ninheira' },
        { label: 'Nova Belém', value: 'Nova Belém' },
        { label: 'Nova Era', value: 'Nova Era' },
        { label: 'Nova Lima', value: 'Nova Lima' },
        { label: 'Nova Módica', value: 'Nova Módica' },
        { label: 'Nova Ponte', value: 'Nova Ponte' },
        { label: 'Nova Porteirinha', value: 'Nova Porteirinha' },
        { label: 'Nova Resende', value: 'Nova Resende' },
        { label: 'Nova Serrana', value: 'Nova Serrana' },
        { label: 'Nova União', value: 'Nova União' },
        { label: 'Novo Cruzeiro', value: 'Novo Cruzeiro' },
        { label: 'Novo Oriente de Minas', value: 'Novo Oriente de Minas' },
        { label: 'Novorizonte', value: 'Novorizonte' },
        { label: 'Olaria', value: 'Olaria' },
        "Olhos d'Água",
        { label: 'Olímpio Noronha', value: 'Olímpio Noronha' },
        { label: 'Oliveira', value: 'Oliveira' },
        { label: 'Oliveira Fortes', value: 'Oliveira Fortes' },
        { label: 'Onça de Pitangui', value: 'Onça de Pitangui' },
        { label: 'Oratórios', value: 'Oratórios' },
        { label: 'Orizânia', value: 'Orizânia' },
        { label: 'Ouro Branco', value: 'Ouro Branco' },
        { label: 'Ouro Fino', value: 'Ouro Fino' },
        { label: 'Ouro Preto', value: 'Ouro Preto' },
        { label: 'Ouro Verde de Minas', value: 'Ouro Verde de Minas' },
        { label: 'Padre Carvalho', value: 'Padre Carvalho' },
        { label: 'Padre Paraíso', value: 'Padre Paraíso' },
        { label: 'Pai Pedro', value: 'Pai Pedro' },
        { label: 'Paineiras', value: 'Paineiras' },
        { label: 'Pains', value: 'Pains' },
        { label: 'Paiva', value: 'Paiva' },
        { label: 'Palma', value: 'Palma' },
        { label: 'Palmópolis', value: 'Palmópolis' },
        { label: 'Papagaios', value: 'Papagaios' },
        { label: 'Pará de Minas', value: 'Pará de Minas' },
        { label: 'Paracatu', value: 'Paracatu' },
        { label: 'Paraguaçu', value: 'Paraguaçu' },
        { label: 'Paraisópolis', value: 'Paraisópolis' },
        { label: 'Paraopeba', value: 'Paraopeba' },
        { label: 'Passa Quatro', value: 'Passa Quatro' },
        { label: 'Passa Tempo', value: 'Passa Tempo' },
        { label: 'Passa-Vinte', value: 'Passa-Vinte' },
        { label: 'Passabém', value: 'Passabém' },
        { label: 'Passos', value: 'Passos' },
        { label: 'Patis', value: 'Patis' },
        { label: 'Patos de Minas', value: 'Patos de Minas' },
        { label: 'Patrocínio', value: 'Patrocínio' },
        { label: 'Patrocínio do Muriaé', value: 'Patrocínio do Muriaé' },
        { label: 'Paula Cândido', value: 'Paula Cândido' },
        { label: 'Paulistas', value: 'Paulistas' },
        { label: 'Pavão', value: 'Pavão' },
        { label: 'Peçanha', value: 'Peçanha' },
        { label: 'Pedra Azul', value: 'Pedra Azul' },
        { label: 'Pedra Bonita', value: 'Pedra Bonita' },
        { label: 'Pedra do Anta', value: 'Pedra do Anta' },
        { label: 'Pedra do Indaiá', value: 'Pedra do Indaiá' },
        { label: 'Pedra Dourada', value: 'Pedra Dourada' },
        { label: 'Pedralva', value: 'Pedralva' },
        { label: 'Pedras de Maria da Cruz', value: 'Pedras de Maria da Cruz' },
        { label: 'Pedrinópolis', value: 'Pedrinópolis' },
        { label: 'Pedro Leopoldo', value: 'Pedro Leopoldo' },
        { label: 'Pedro Teixeira', value: 'Pedro Teixeira' },
        { label: 'Pequeri', value: 'Pequeri' },
        { label: 'Pequi', value: 'Pequi' },
        { label: 'Perdigão', value: 'Perdigão' },
        { label: 'Perdizes', value: 'Perdizes' },
        { label: 'Perdões', value: 'Perdões' },
        { label: 'Periquito', value: 'Periquito' },
        { label: 'Pescador', value: 'Pescador' },
        { label: 'Piau', value: 'Piau' },
        { label: 'Piedade de Caratinga', value: 'Piedade de Caratinga' },
        { label: 'Piedade de Ponte Nova', value: 'Piedade de Ponte Nova' },
        { label: 'Piedade do Rio Grande', value: 'Piedade do Rio Grande' },
        { label: 'Piedade dos Gerais', value: 'Piedade dos Gerais' },
        { label: 'Pimenta', value: 'Pimenta' },
        "Pingo-d'Água",
        { label: 'Pintópolis', value: 'Pintópolis' },
        { label: 'Piracema', value: 'Piracema' },
        { label: 'Pirajuba', value: 'Pirajuba' },
        { label: 'Piranga', value: 'Piranga' },
        { label: 'Piranguçu', value: 'Piranguçu' },
        { label: 'Piranguinho', value: 'Piranguinho' },
        { label: 'Pirapetinga', value: 'Pirapetinga' },
        { label: 'Pirapora', value: 'Pirapora' },
        { label: 'Piraúba', value: 'Piraúba' },
        { label: 'Pitangui', value: 'Pitangui' },
        { label: 'Piumhi', value: 'Piumhi' },
        { label: 'Planura', value: 'Planura' },
        { label: 'Poço Fundo', value: 'Poço Fundo' },
        { label: 'Poços de Caldas', value: 'Poços de Caldas' },
        { label: 'Pocrane', value: 'Pocrane' },
        { label: 'Pompéu', value: 'Pompéu' },
        { label: 'Ponte Nova', value: 'Ponte Nova' },
        { label: 'Ponto Chique', value: 'Ponto Chique' },
        { label: 'Ponto dos Volantes', value: 'Ponto dos Volantes' },
        { label: 'Porteirinha', value: 'Porteirinha' },
        { label: 'Porto Firme', value: 'Porto Firme' },
        { label: 'Poté', value: 'Poté' },
        { label: 'Pouso Alegre', value: 'Pouso Alegre' },
        { label: 'Pouso Alto', value: 'Pouso Alto' },
        { label: 'Prados', value: 'Prados' },
        { label: 'Prata', value: 'Prata' },
        { label: 'Pratápolis', value: 'Pratápolis' },
        { label: 'Pratinha', value: 'Pratinha' },
        { label: 'Presidente Bernardes', value: 'Presidente Bernardes' },
        { label: 'Presidente Juscelino', value: 'Presidente Juscelino' },
        { label: 'Presidente Kubitschek', value: 'Presidente Kubitschek' },
        { label: 'Presidente Olegário', value: 'Presidente Olegário' },
        { label: 'Prudente de Morais', value: 'Prudente de Morais' },
        { label: 'Quartel Geral', value: 'Quartel Geral' },
        { label: 'Queluzito', value: 'Queluzito' },
        { label: 'Raposos', value: 'Raposos' },
        { label: 'Raul Soares', value: 'Raul Soares' },
        { label: 'Recreio', value: 'Recreio' },
        { label: 'Reduto', value: 'Reduto' },
        { label: 'Resende Costa', value: 'Resende Costa' },
        { label: 'Resplendor', value: 'Resplendor' },
        { label: 'Ressaquinha', value: 'Ressaquinha' },
        { label: 'Riachinho', value: 'Riachinho' },
        { label: 'Riacho dos Machados', value: 'Riacho dos Machados' },
        { label: 'Ribeirão das Neves', value: 'Ribeirão das Neves' },
        { label: 'Ribeirão Vermelho', value: 'Ribeirão Vermelho' },
        { label: 'Rio Acima', value: 'Rio Acima' },
        { label: 'Rio Casca', value: 'Rio Casca' },
        { label: 'Rio do Prado', value: 'Rio do Prado' },
        { label: 'Rio Doce', value: 'Rio Doce' },
        { label: 'Rio Espera', value: 'Rio Espera' },
        { label: 'Rio Manso', value: 'Rio Manso' },
        { label: 'Rio Novo', value: 'Rio Novo' },
        { label: 'Rio Paranaíba', value: 'Rio Paranaíba' },
        { label: 'Rio Pardo de Minas', value: 'Rio Pardo de Minas' },
        { label: 'Rio Piracicaba', value: 'Rio Piracicaba' },
        { label: 'Rio Pomba', value: 'Rio Pomba' },
        { label: 'Rio Preto', value: 'Rio Preto' },
        { label: 'Rio Vermelho', value: 'Rio Vermelho' },
        { label: 'Ritápolis', value: 'Ritápolis' },
        { label: 'Rochedo de Minas', value: 'Rochedo de Minas' },
        { label: 'Rodeiro', value: 'Rodeiro' },
        { label: 'Romaria', value: 'Romaria' },
        { label: 'Rosário da Limeira', value: 'Rosário da Limeira' },
        { label: 'Rubelita', value: 'Rubelita' },
        { label: 'Rubim', value: 'Rubim' },
        { label: 'Sabará', value: 'Sabará' },
        { label: 'Sabinópolis', value: 'Sabinópolis' },
        { label: 'Sacramento', value: 'Sacramento' },
        { label: 'Salinas', value: 'Salinas' },
        { label: 'Salto da Divisa', value: 'Salto da Divisa' },
        { label: 'Santa Bárbara', value: 'Santa Bárbara' },
        { label: 'Santa Bárbara do Leste', value: 'Santa Bárbara do Leste' },
        { label: 'Santa Bárbara do Monte Verde', value: 'Santa Bárbara do Monte Verde' },
        { label: 'Santa Bárbara do Tugúrio', value: 'Santa Bárbara do Tugúrio' },
        { label: 'Santa Cruz de Minas', value: 'Santa Cruz de Minas' },
        { label: 'Santa Cruz de Salinas', value: 'Santa Cruz de Salinas' },
        { label: 'Santa Cruz do Escalvado', value: 'Santa Cruz do Escalvado' },
        { label: 'Santa Efigênia de Minas', value: 'Santa Efigênia de Minas' },
        { label: 'Santa Fé de Minas', value: 'Santa Fé de Minas' },
        { label: 'Santa Helena de Minas', value: 'Santa Helena de Minas' },
        { label: 'Santa Juliana', value: 'Santa Juliana' },
        { label: 'Santa Luzia', value: 'Santa Luzia' },
        { label: 'Santa Margarida', value: 'Santa Margarida' },
        { label: 'Santa Maria de Itabira', value: 'Santa Maria de Itabira' },
        { label: 'Santa Maria do Salto', value: 'Santa Maria do Salto' },
        { label: 'Santa Maria do Suaçuí', value: 'Santa Maria do Suaçuí' },
        { label: 'Santa Rita de Caldas', value: 'Santa Rita de Caldas' },
        { label: 'Santa Rita de Ibitipoca', value: 'Santa Rita de Ibitipoca' },
        { label: 'Santa Rita de Jacutinga', value: 'Santa Rita de Jacutinga' },
        { label: 'Santa Rita de Minas', value: 'Santa Rita de Minas' },
        { label: 'Santa Rita do Itueto', value: 'Santa Rita do Itueto' },
        { label: 'Santa Rita do Sapucaí', value: 'Santa Rita do Sapucaí' },
        { label: 'Santa Rosa da Serra', value: 'Santa Rosa da Serra' },
        { label: 'Santa Vitória', value: 'Santa Vitória' },
        { label: 'Santana da Vargem', value: 'Santana da Vargem' },
        { label: 'Santana de Cataguases', value: 'Santana de Cataguases' },
        { label: 'Santana de Pirapama', value: 'Santana de Pirapama' },
        { label: 'Santana do Deserto', value: 'Santana do Deserto' },
        { label: 'Santana do Garambéu', value: 'Santana do Garambéu' },
        { label: 'Santana do Jacaré', value: 'Santana do Jacaré' },
        { label: 'Santana do Manhuaçu', value: 'Santana do Manhuaçu' },
        { label: 'Santana do Paraíso', value: 'Santana do Paraíso' },
        { label: 'Santana do Riacho', value: 'Santana do Riacho' },
        { label: 'Santana dos Montes', value: 'Santana dos Montes' },
        { label: 'Santo Antônio do Amparo', value: 'Santo Antônio do Amparo' },
        { label: 'Santo Antônio do Aventureiro', value: 'Santo Antônio do Aventureiro' },
        { label: 'Santo Antônio do Grama', value: 'Santo Antônio do Grama' },
        { label: 'Santo Antônio do Itambé', value: 'Santo Antônio do Itambé' },
        { label: 'Santo Antônio do Jacinto', value: 'Santo Antônio do Jacinto' },
        { label: 'Santo Antônio do Monte', value: 'Santo Antônio do Monte' },
        { label: 'Santo Antônio do Retiro', value: 'Santo Antônio do Retiro' },
        { label: 'Santo Antônio do Rio Abaixo', value: 'Santo Antônio do Rio Abaixo' },
        { label: 'Santo Hipólito', value: 'Santo Hipólito' },
        { label: 'Santos Dumont', value: 'Santos Dumont' },
        { label: 'São Bento Abade', value: 'São Bento Abade' },
        { label: 'São Brás do Suaçuí', value: 'São Brás do Suaçuí' },
        { label: 'São Domingos das Dores', value: 'São Domingos das Dores' },
        { label: 'São Domingos do Prata', value: 'São Domingos do Prata' },
        { label: 'São Félix de Minas', value: 'São Félix de Minas' },
        { label: 'São Francisco', value: 'São Francisco' },
        { label: 'São Francisco de Paula', value: 'São Francisco de Paula' },
        { label: 'São Francisco de Sales', value: 'São Francisco de Sales' },
        { label: 'São Francisco do Glória', value: 'São Francisco do Glória' },
        { label: 'São Geraldo', value: 'São Geraldo' },
        { label: 'São Geraldo da Piedade', value: 'São Geraldo da Piedade' },
        { label: 'São Geraldo do Baixio', value: 'São Geraldo do Baixio' },
        { label: 'São Gonçalo do Abaeté', value: 'São Gonçalo do Abaeté' },
        { label: 'São Gonçalo do Pará', value: 'São Gonçalo do Pará' },
        { label: 'São Gonçalo do Rio Abaixo', value: 'São Gonçalo do Rio Abaixo' },
        { label: 'São Gonçalo do Rio Preto', value: 'São Gonçalo do Rio Preto' },
        { label: 'São Gonçalo do Sapucaí', value: 'São Gonçalo do Sapucaí' },
        { label: 'São Gotardo', value: 'São Gotardo' },
        { label: 'São João Batista do Glória', value: 'São João Batista do Glória' },
        { label: 'São João da Lagoa', value: 'São João da Lagoa' },
        { label: 'São João da Mata', value: 'São João da Mata' },
        { label: 'São João da Ponte', value: 'São João da Ponte' },
        { label: 'São João das Missões', value: 'São João das Missões' },
        { label: 'São João del Rei', value: 'São João del Rei' },
        { label: 'São João do Manhuaçu', value: 'São João do Manhuaçu' },
        { label: 'São João do Manteninha', value: 'São João do Manteninha' },
        { label: 'São João do Oriente', value: 'São João do Oriente' },
        { label: 'São João do Pacuí', value: 'São João do Pacuí' },
        { label: 'São João do Paraíso', value: 'São João do Paraíso' },
        { label: 'São João Evangelista', value: 'São João Evangelista' },
        { label: 'São João Nepomuceno', value: 'São João Nepomuceno' },
        { label: 'São Joaquim de Bicas', value: 'São Joaquim de Bicas' },
        { label: 'São José da Barra', value: 'São José da Barra' },
        { label: 'São José da Lapa', value: 'São José da Lapa' },
        { label: 'São José da Safira', value: 'São José da Safira' },
        { label: 'São José da Varginha', value: 'São José da Varginha' },
        { label: 'São José do Alegre', value: 'São José do Alegre' },
        { label: 'São José do Divino', value: 'São José do Divino' },
        { label: 'São José do Goiabal', value: 'São José do Goiabal' },
        { label: 'São José do Jacuri', value: 'São José do Jacuri' },
        { label: 'São José do Mantimento', value: 'São José do Mantimento' },
        { label: 'São Lourenço', value: 'São Lourenço' },
        { label: 'São Miguel do Anta', value: 'São Miguel do Anta' },
        { label: 'São Pedro da União', value: 'São Pedro da União' },
        { label: 'São Pedro do Suaçuí', value: 'São Pedro do Suaçuí' },
        { label: 'São Pedro dos Ferros', value: 'São Pedro dos Ferros' },
        { label: 'São Romão', value: 'São Romão' },
        { label: 'São Roque de Minas', value: 'São Roque de Minas' },
        { label: 'São Sebastião da Bela Vista', value: 'São Sebastião da Bela Vista' },
        { label: 'São Sebastião da Vargem Alegre', value: 'São Sebastião da Vargem Alegre' },
        { label: 'São Sebastião do Anta', value: 'São Sebastião do Anta' },
        { label: 'São Sebastião do Maranhão', value: 'São Sebastião do Maranhão' },
        { label: 'São Sebastião do Oeste', value: 'São Sebastião do Oeste' },
        { label: 'São Sebastião do Paraíso', value: 'São Sebastião do Paraíso' },
        { label: 'São Sebastião do Rio Preto', value: 'São Sebastião do Rio Preto' },
        { label: 'São Sebastião do Rio Verde', value: 'São Sebastião do Rio Verde' },
        { label: 'São Thomé das Letras', value: 'São Thomé das Letras' },
        { label: 'São Tiago', value: 'São Tiago' },
        { label: 'São Tomás de Aquino', value: 'São Tomás de Aquino' },
        { label: 'São Vicente de Minas', value: 'São Vicente de Minas' },
        { label: 'Sapucaí-Mirim', value: 'Sapucaí-Mirim' },
        { label: 'Sardoá', value: 'Sardoá' },
        { label: 'Sarzedo', value: 'Sarzedo' },
        { label: 'Sem-Peixe', value: 'Sem-Peixe' },
        { label: 'Senador Amaral', value: 'Senador Amaral' },
        { label: 'Senador Cortes', value: 'Senador Cortes' },
        { label: 'Senador Firmino', value: 'Senador Firmino' },
        { label: 'Senador José Bento', value: 'Senador José Bento' },
        { label: 'Senador Modestino Gonçalves', value: 'Senador Modestino Gonçalves' },
        { label: 'Senhora de Oliveira', value: 'Senhora de Oliveira' },
        { label: 'Senhora do Porto', value: 'Senhora do Porto' },
        { label: 'Senhora dos Remédios', value: 'Senhora dos Remédios' },
        { label: 'Sericita', value: 'Sericita' },
        { label: 'Seritinga', value: 'Seritinga' },
        { label: 'Serra Azul de Minas', value: 'Serra Azul de Minas' },
        { label: 'Serra da Saudade', value: 'Serra da Saudade' },
        { label: 'Serra do Salitre', value: 'Serra do Salitre' },
        { label: 'Serra dos Aimorés', value: 'Serra dos Aimorés' },
        { label: 'Serrania', value: 'Serrania' },
        { label: 'Serranópolis de Minas', value: 'Serranópolis de Minas' },
        { label: 'Serranos', value: 'Serranos' },
        { label: 'Serro', value: 'Serro' },
        { label: 'Sete Lagoas', value: 'Sete Lagoas' },
        { label: 'Setubinha', value: 'Setubinha' },
        { label: 'Silveirânia', value: 'Silveirânia' },
        { label: 'Silvianópolis', value: 'Silvianópolis' },
        { label: 'Simão Pereira', value: 'Simão Pereira' },
        { label: 'Simonésia', value: 'Simonésia' },
        { label: 'Sobrália', value: 'Sobrália' },
        { label: 'Soledade de Minas', value: 'Soledade de Minas' },
        { label: 'Tabuleiro', value: 'Tabuleiro' },
        { label: 'Taiobeiras', value: 'Taiobeiras' },
        { label: 'Taparuba', value: 'Taparuba' },
        { label: 'Tapira', value: 'Tapira' },
        { label: 'Tapiraí', value: 'Tapiraí' },
        { label: 'Taquaraçu de Minas', value: 'Taquaraçu de Minas' },
        { label: 'Tarumirim', value: 'Tarumirim' },
        { label: 'Teixeiras', value: 'Teixeiras' },
        { label: 'Teófilo Otoni', value: 'Teófilo Otoni' },
        { label: 'Timóteo', value: 'Timóteo' },
        { label: 'Tiradentes', value: 'Tiradentes' },
        { label: 'Tiros', value: 'Tiros' },
        { label: 'Tocantins', value: 'Tocantins' },
        { label: 'Tocos do Moji', value: 'Tocos do Moji' },
        { label: 'Toledo', value: 'Toledo' },
        { label: 'Tombos', value: 'Tombos' },
        { label: 'Três Corações', value: 'Três Corações' },
        { label: 'Três Marias', value: 'Três Marias' },
        { label: 'Três Pontas', value: 'Três Pontas' },
        { label: 'Tumiritinga', value: 'Tumiritinga' },
        { label: 'Tupaciguara', value: 'Tupaciguara' },
        { label: 'Turmalina', value: 'Turmalina' },
        { label: 'Turvolândia', value: 'Turvolândia' },
        { label: 'Ubá', value: 'Ubá' },
        { label: 'Ubaí', value: 'Ubaí' },
        { label: 'Ubaporanga', value: 'Ubaporanga' },
        { label: 'Uberaba', value: 'Uberaba' },
        { label: 'Uberlândia', value: 'Uberlândia' },
        { label: 'Umburatiba', value: 'Umburatiba' },
        { label: 'Unaí', value: 'Unaí' },
        { label: 'União de Minas', value: 'União de Minas' },
        { label: 'Uruana de Minas', value: 'Uruana de Minas' },
        { label: 'Urucânia', value: 'Urucânia' },
        { label: 'Urucuia', value: 'Urucuia' },
        { label: 'Vargem Alegre', value: 'Vargem Alegre' },
        { label: 'Vargem Bonita', value: 'Vargem Bonita' },
        { label: 'Vargem Grande do Rio Pardo', value: 'Vargem Grande do Rio Pardo' },
        { label: 'Varginha', value: 'Varginha' },
        { label: 'Varjão de Minas', value: 'Varjão de Minas' },
        { label: 'Várzea da Palma', value: 'Várzea da Palma' },
        { label: 'Varzelândia', value: 'Varzelândia' },
        { label: 'Vazante', value: 'Vazante' },
        { label: 'Verdelândia', value: 'Verdelândia' },
        { label: 'Veredinha', value: 'Veredinha' },
        { label: 'Veríssimo', value: 'Veríssimo' },
        { label: 'Vermelho Novo', value: 'Vermelho Novo' },
        { label: 'Vespasiano', value: 'Vespasiano' },
        { label: 'Viçosa', value: 'Viçosa' },
        { label: 'Vieiras', value: 'Vieiras' },
        { label: 'Virgem da Lapa', value: 'Virgem da Lapa' },
        { label: 'Virgínia', value: 'Virgínia' },
        { label: 'Virginópolis', value: 'Virginópolis' },
        { label: 'Virgolândia', value: 'Virgolândia' },
        { label: 'Visconde do Rio Branco', value: 'Visconde do Rio Branco' },
        { label: 'Volta Grande', value: 'Volta Grande' },
        { label: 'Wenceslau Braz', value: 'Wenceslau Braz' },
    ],
    MS: [
        { label: 'Água Clara', value: 'Água Clara' },
        { label: 'Alcinópolis', value: 'Alcinópolis' },
        { label: 'Amambai', value: 'Amambai' },
        { label: 'Anastácio', value: 'Anastácio' },
        { label: 'Anaurilândia', value: 'Anaurilândia' },
        { label: 'Angélica', value: 'Angélica' },
        { label: 'Antônio João', value: 'Antônio João' },
        { label: 'Aparecida do Taboado', value: 'Aparecida do Taboado' },
        { label: 'Aquidauana', value: 'Aquidauana' },
        { label: 'Aral Moreira', value: 'Aral Moreira' },
        { label: 'Bandeirantes', value: 'Bandeirantes' },
        { label: 'Bataguassu', value: 'Bataguassu' },
        { label: 'Batayporã', value: 'Batayporã' },
        { label: 'Bela Vista', value: 'Bela Vista' },
        { label: 'Bodoquena', value: 'Bodoquena' },
        { label: 'Bonito', value: 'Bonito' },
        { label: 'Brasilândia', value: 'Brasilândia' },
        { label: 'Caarapó', value: 'Caarapó' },
        { label: 'Camapuã', value: 'Camapuã' },
        { label: 'Campo Grande', value: 'Campo Grande' },
        { label: 'Caracol', value: 'Caracol' },
        { label: 'Cassilândia', value: 'Cassilândia' },
        { label: 'Chapadão do Sul', value: 'Chapadão do Sul' },
        { label: 'Corguinho', value: 'Corguinho' },
        { label: 'Coronel Sapucaia', value: 'Coronel Sapucaia' },
        { label: 'Corumbá', value: 'Corumbá' },
        { label: 'Costa Rica', value: 'Costa Rica' },
        { label: 'Coxim', value: 'Coxim' },
        { label: 'Deodápolis', value: 'Deodápolis' },
        { label: 'Dois Irmãos do Buriti', value: 'Dois Irmãos do Buriti' },
        { label: 'Douradina', value: 'Douradina' },
        { label: 'Dourados', value: 'Dourados' },
        { label: 'Eldorado', value: 'Eldorado' },
        { label: 'Fátima do Sul', value: 'Fátima do Sul' },
        { label: 'Figueirão', value: 'Figueirão' },
        { label: 'Glória de Dourados', value: 'Glória de Dourados' },
        { label: 'Guia Lopes da Laguna', value: 'Guia Lopes da Laguna' },
        { label: 'Iguatemi', value: 'Iguatemi' },
        { label: 'Inocência', value: 'Inocência' },
        { label: 'Itaporã', value: 'Itaporã' },
        { label: 'Itaquiraí', value: 'Itaquiraí' },
        { label: 'Ivinhema', value: 'Ivinhema' },
        { label: 'Japorã', value: 'Japorã' },
        { label: 'Jaraguari', value: 'Jaraguari' },
        { label: 'Jardim', value: 'Jardim' },
        { label: 'Jateí', value: 'Jateí' },
        { label: 'Juti', value: 'Juti' },
        { label: 'Ladário', value: 'Ladário' },
        { label: 'Laguna Carapã', value: 'Laguna Carapã' },
        { label: 'Maracaju', value: 'Maracaju' },
        { label: 'Miranda', value: 'Miranda' },
        { label: 'Mundo Novo', value: 'Mundo Novo' },
        { label: 'Naviraí', value: 'Naviraí' },
        { label: 'Nioaque', value: 'Nioaque' },
        { label: 'Nova Alvorada do Sul', value: 'Nova Alvorada do Sul' },
        { label: 'Nova Andradina', value: 'Nova Andradina' },
        { label: 'Novo Horizonte do Sul', value: 'Novo Horizonte do Sul' },
        { label: 'Paraíso das Águas', value: 'Paraíso das Águas' },
        { label: 'Paranaíba', value: 'Paranaíba' },
        { label: 'Paranhos', value: 'Paranhos' },
        { label: 'Pedro Gomes', value: 'Pedro Gomes' },
        { label: 'Ponta Porã', value: 'Ponta Porã' },
        { label: 'Porto Murtinho', value: 'Porto Murtinho' },
        { label: 'Ribas do Rio Pardo', value: 'Ribas do Rio Pardo' },
        { label: 'Rio Brilhante', value: 'Rio Brilhante' },
        { label: 'Rio Negro', value: 'Rio Negro' },
        { label: 'Rio Verde de Mato Grosso', value: 'Rio Verde de Mato Grosso' },
        { label: 'Rochedo', value: 'Rochedo' },
        { label: 'Santa Rita do Pardo', value: 'Santa Rita do Pardo' },
        { label: 'São Gabriel do Oeste', value: 'São Gabriel do Oeste' },
        { label: 'Selvíria', value: 'Selvíria' },
        { label: 'Sete Quedas', value: 'Sete Quedas' },
        { label: 'Sidrolândia', value: 'Sidrolândia' },
        { label: 'Sonora', value: 'Sonora' },
        { label: 'Tacuru', value: 'Tacuru' },
        { label: 'Taquarussu', value: 'Taquarussu' },
        { label: 'Terenos', value: 'Terenos' },
        { label: 'Três Lagoas', value: 'Três Lagoas' },
        { label: 'Vicentina', value: 'Vicentina' },
    ],
    MT: [
        { label: 'Acorizal', value: 'Acorizal' },
        { label: 'Água Boa', value: 'Água Boa' },
        { label: 'Alta Floresta', value: 'Alta Floresta' },
        { label: 'Alto Araguaia', value: 'Alto Araguaia' },
        { label: 'Alto Boa Vista', value: 'Alto Boa Vista' },
        { label: 'Alto Garças', value: 'Alto Garças' },
        { label: 'Alto Paraguai', value: 'Alto Paraguai' },
        { label: 'Alto Taquari', value: 'Alto Taquari' },
        { label: 'Apiacás', value: 'Apiacás' },
        { label: 'Araguaiana', value: 'Araguaiana' },
        { label: 'Araguainha', value: 'Araguainha' },
        { label: 'Araputanga', value: 'Araputanga' },
        { label: 'Arenápolis', value: 'Arenápolis' },
        { label: 'Aripuanã', value: 'Aripuanã' },
        { label: 'Barão de Melgaço', value: 'Barão de Melgaço' },
        { label: 'Barra do Bugres', value: 'Barra do Bugres' },
        { label: 'Barra do Garças', value: 'Barra do Garças' },
        { label: 'Bom Jesus do Araguaia', value: 'Bom Jesus do Araguaia' },
        { label: 'Brasnorte', value: 'Brasnorte' },
        { label: 'Cáceres', value: 'Cáceres' },
        { label: 'Campinápolis', value: 'Campinápolis' },
        { label: 'Campo Novo do Parecis', value: 'Campo Novo do Parecis' },
        { label: 'Campo Verde', value: 'Campo Verde' },
        { label: 'Campos de Júlio', value: 'Campos de Júlio' },
        { label: 'Canabrava do Norte', value: 'Canabrava do Norte' },
        { label: 'Canarana', value: 'Canarana' },
        { label: 'Carlinda', value: 'Carlinda' },
        { label: 'Castanheira', value: 'Castanheira' },
        { label: 'Chapada dos Guimarães', value: 'Chapada dos Guimarães' },
        { label: 'Cláudia', value: 'Cláudia' },
        { label: 'Cocalinho', value: 'Cocalinho' },
        { label: 'Colíder', value: 'Colíder' },
        { label: 'Colniza', value: 'Colniza' },
        { label: 'Comodoro', value: 'Comodoro' },
        { label: 'Confresa', value: 'Confresa' },
        "Conquista D'Oeste",
        { label: 'Cotriguaçu', value: 'Cotriguaçu' },
        { label: 'Cuiabá', value: 'Cuiabá' },
        { label: 'Curvelândia', value: 'Curvelândia' },
        { label: 'Denise', value: 'Denise' },
        { label: 'Diamantino', value: 'Diamantino' },
        { label: 'Dom Aquino', value: 'Dom Aquino' },
        { label: 'Feliz Natal', value: 'Feliz Natal' },
        "Figueirópolis D'Oeste",
        { label: 'Gaúcha do Norte', value: 'Gaúcha do Norte' },
        { label: 'General Carneiro', value: 'General Carneiro' },
        "Glória D'Oeste",
        { label: 'Guarantã do Norte', value: 'Guarantã do Norte' },
        { label: 'Guiratinga', value: 'Guiratinga' },
        { label: 'Indiavaí', value: 'Indiavaí' },
        { label: 'Ipiranga do Norte', value: 'Ipiranga do Norte' },
        { label: 'Itanhangá', value: 'Itanhangá' },
        { label: 'Itaúba', value: 'Itaúba' },
        { label: 'Itiquira', value: 'Itiquira' },
        { label: 'Jaciara', value: 'Jaciara' },
        { label: 'Jangada', value: 'Jangada' },
        { label: 'Jauru', value: 'Jauru' },
        { label: 'Juara', value: 'Juara' },
        { label: 'Juína', value: 'Juína' },
        { label: 'Juruena', value: 'Juruena' },
        { label: 'Juscimeira', value: 'Juscimeira' },
        "Lambari D'Oeste",
        { label: 'Lucas do Rio Verde', value: 'Lucas do Rio Verde' },
        { label: 'Luciara', value: 'Luciara' },
        { label: 'Marcelândia', value: 'Marcelândia' },
        { label: 'Matupá', value: 'Matupá' },
        "Mirassol d'Oeste",
        { label: 'Nobres', value: 'Nobres' },
        { label: 'Nortelândia', value: 'Nortelândia' },
        { label: 'Nossa Senhora do Livramento', value: 'Nossa Senhora do Livramento' },
        { label: 'Nova Bandeirantes', value: 'Nova Bandeirantes' },
        { label: 'Nova Brasilândia', value: 'Nova Brasilândia' },
        { label: 'Nova Canaã do Norte', value: 'Nova Canaã do Norte' },
        { label: 'Nova Guarita', value: 'Nova Guarita' },
        { label: 'Nova Lacerda', value: 'Nova Lacerda' },
        { label: 'Nova Marilândia', value: 'Nova Marilândia' },
        { label: 'Nova Maringá', value: 'Nova Maringá' },
        { label: 'Nova Monte Verde', value: 'Nova Monte Verde' },
        { label: 'Nova Mutum', value: 'Nova Mutum' },
        { label: 'Nova Nazaré', value: 'Nova Nazaré' },
        { label: 'Nova Olímpia', value: 'Nova Olímpia' },
        { label: 'Nova Santa Helena', value: 'Nova Santa Helena' },
        { label: 'Nova Ubiratã', value: 'Nova Ubiratã' },
        { label: 'Nova Xavantina', value: 'Nova Xavantina' },
        { label: 'Novo Horizonte do Norte', value: 'Novo Horizonte do Norte' },
        { label: 'Novo Mundo', value: 'Novo Mundo' },
        { label: 'Novo Santo Antônio', value: 'Novo Santo Antônio' },
        { label: 'Novo São Joaquim', value: 'Novo São Joaquim' },
        { label: 'Paranaíta', value: 'Paranaíta' },
        { label: 'Paranatinga', value: 'Paranatinga' },
        { label: 'Pedra Preta', value: 'Pedra Preta' },
        { label: 'Peixoto de Azevedo', value: 'Peixoto de Azevedo' },
        { label: 'Planalto da Serra', value: 'Planalto da Serra' },
        { label: 'Poconé', value: 'Poconé' },
        { label: 'Pontal do Araguaia', value: 'Pontal do Araguaia' },
        { label: 'Ponte Branca', value: 'Ponte Branca' },
        { label: 'Pontes e Lacerda', value: 'Pontes e Lacerda' },
        { label: 'Porto Alegre do Norte', value: 'Porto Alegre do Norte' },
        { label: 'Porto dos Gaúchos', value: 'Porto dos Gaúchos' },
        { label: 'Porto Esperidião', value: 'Porto Esperidião' },
        { label: 'Porto Estrela', value: 'Porto Estrela' },
        { label: 'Poxoréu', value: 'Poxoréu' },
        { label: 'Primavera do Leste', value: 'Primavera do Leste' },
        { label: 'Querência', value: 'Querência' },
        { label: 'Reserva do Cabaçal', value: 'Reserva do Cabaçal' },
        { label: 'Ribeirão Cascalheira', value: 'Ribeirão Cascalheira' },
        { label: 'Ribeirãozinho', value: 'Ribeirãozinho' },
        { label: 'Rio Branco', value: 'Rio Branco' },
        { label: 'Rondolândia', value: 'Rondolândia' },
        { label: 'Rondonópolis', value: 'Rondonópolis' },
        { label: 'Rosário Oeste', value: 'Rosário Oeste' },
        { label: 'Salto do Céu', value: 'Salto do Céu' },
        { label: 'Santa Carmem', value: 'Santa Carmem' },
        { label: 'Santa Cruz do Xingu', value: 'Santa Cruz do Xingu' },
        { label: 'Santa Rita do Trivelato', value: 'Santa Rita do Trivelato' },
        { label: 'Santa Terezinha', value: 'Santa Terezinha' },
        { label: 'Santo Afonso', value: 'Santo Afonso' },
        { label: 'Santo Antônio do Leste', value: 'Santo Antônio do Leste' },
        { label: 'Santo Antônio do Leverger', value: 'Santo Antônio do Leverger' },
        { label: 'São Félix do Araguaia', value: 'São Félix do Araguaia' },
        { label: 'São José do Povo', value: 'São José do Povo' },
        { label: 'São José do Rio Claro', value: 'São José do Rio Claro' },
        { label: 'São José do Xingu', value: 'São José do Xingu' },
        { label: 'São José dos Quatro Marcos', value: 'São José dos Quatro Marcos' },
        { label: 'São Pedro da Cipa', value: 'São Pedro da Cipa' },
        { label: 'Sapezal', value: 'Sapezal' },
        { label: 'Serra Nova Dourada', value: 'Serra Nova Dourada' },
        { label: 'Sinop', value: 'Sinop' },
        { label: 'Sorriso', value: 'Sorriso' },
        { label: 'Tabaporã', value: 'Tabaporã' },
        { label: 'Tangará da Serra', value: 'Tangará da Serra' },
        { label: 'Tapurah', value: 'Tapurah' },
        { label: 'Terra Nova do Norte', value: 'Terra Nova do Norte' },
        { label: 'Tesouro', value: 'Tesouro' },
        { label: 'Torixoréu', value: 'Torixoréu' },
        { label: 'União do Sul', value: 'União do Sul' },
        { label: 'Vale de São Domingos', value: 'Vale de São Domingos' },
        { label: 'Várzea Grande', value: 'Várzea Grande' },
        { label: 'Vera', value: 'Vera' },
        { label: 'Vila Bela da Santíssima Trindade', value: 'Vila Bela da Santíssima Trindade' },
        { label: 'Vila Rica', value: 'Vila Rica' },
    ],
    PA: [
        { label: 'Abaetetuba', value: 'Abaetetuba' },
        { label: 'Abel Figueiredo', value: 'Abel Figueiredo' },
        { label: 'Acará', value: 'Acará' },
        { label: 'Afuá', value: 'Afuá' },
        { label: 'Água Azul do Norte', value: 'Água Azul do Norte' },
        { label: 'Alenquer', value: 'Alenquer' },
        { label: 'Almeirim', value: 'Almeirim' },
        { label: 'Altamira', value: 'Altamira' },
        { label: 'Anajás', value: 'Anajás' },
        { label: 'Ananindeua', value: 'Ananindeua' },
        { label: 'Anapu', value: 'Anapu' },
        { label: 'Augusto Corrêa', value: 'Augusto Corrêa' },
        { label: 'Aurora do Pará', value: 'Aurora do Pará' },
        { label: 'Aveiro', value: 'Aveiro' },
        { label: 'Bagre', value: 'Bagre' },
        { label: 'Baião', value: 'Baião' },
        { label: 'Bannach', value: 'Bannach' },
        { label: 'Barcarena', value: 'Barcarena' },
        { label: 'Belém', value: 'Belém' },
        { label: 'Belterra', value: 'Belterra' },
        { label: 'Benevides', value: 'Benevides' },
        { label: 'Bom Jesus do Tocantins', value: 'Bom Jesus do Tocantins' },
        { label: 'Bonito', value: 'Bonito' },
        { label: 'Bragança', value: 'Bragança' },
        { label: 'Brasil Novo', value: 'Brasil Novo' },
        { label: 'Brejo Grande do Araguaia', value: 'Brejo Grande do Araguaia' },
        { label: 'Breu Branco', value: 'Breu Branco' },
        { label: 'Breves', value: 'Breves' },
        { label: 'Bujaru', value: 'Bujaru' },
        { label: 'Cachoeira do Arari', value: 'Cachoeira do Arari' },
        { label: 'Cachoeira do Piriá', value: 'Cachoeira do Piriá' },
        { label: 'Cametá', value: 'Cametá' },
        { label: 'Canaã dos Carajás', value: 'Canaã dos Carajás' },
        { label: 'Capanema', value: 'Capanema' },
        { label: 'Capitão Poço', value: 'Capitão Poço' },
        { label: 'Castanhal', value: 'Castanhal' },
        { label: 'Chaves', value: 'Chaves' },
        { label: 'Colares', value: 'Colares' },
        { label: 'Conceição do Araguaia', value: 'Conceição do Araguaia' },
        { label: 'Concórdia do Pará', value: 'Concórdia do Pará' },
        { label: 'Cumaru do Norte', value: 'Cumaru do Norte' },
        { label: 'Curionópolis', value: 'Curionópolis' },
        { label: 'Curralinho', value: 'Curralinho' },
        { label: 'Curuá', value: 'Curuá' },
        { label: 'Curuçá', value: 'Curuçá' },
        { label: 'Dom Eliseu', value: 'Dom Eliseu' },
        { label: 'Eldorado do Carajás', value: 'Eldorado do Carajás' },
        { label: 'Faro', value: 'Faro' },
        { label: 'Floresta do Araguaia', value: 'Floresta do Araguaia' },
        { label: 'Garrafão do Norte', value: 'Garrafão do Norte' },
        { label: 'Goianésia do Pará', value: 'Goianésia do Pará' },
        { label: 'Gurupá', value: 'Gurupá' },
        { label: 'Igarapé-Açu', value: 'Igarapé-Açu' },
        { label: 'Igarapé-Miri', value: 'Igarapé-Miri' },
        { label: 'Inhangapi', value: 'Inhangapi' },
        { label: 'Ipixuna do Pará', value: 'Ipixuna do Pará' },
        { label: 'Irituia', value: 'Irituia' },
        { label: 'Itaituba', value: 'Itaituba' },
        { label: 'Itupiranga', value: 'Itupiranga' },
        { label: 'Jacareacanga', value: 'Jacareacanga' },
        { label: 'Jacundá', value: 'Jacundá' },
        { label: 'Juruti', value: 'Juruti' },
        { label: 'Limoeiro do Ajuru', value: 'Limoeiro do Ajuru' },
        { label: 'Mãe do Rio', value: 'Mãe do Rio' },
        { label: 'Magalhães Barata', value: 'Magalhães Barata' },
        { label: 'Marabá', value: 'Marabá' },
        { label: 'Maracanã', value: 'Maracanã' },
        { label: 'Marapanim', value: 'Marapanim' },
        { label: 'Marituba', value: 'Marituba' },
        { label: 'Medicilândia', value: 'Medicilândia' },
        { label: 'Melgaço', value: 'Melgaço' },
        { label: 'Mocajuba', value: 'Mocajuba' },
        { label: 'Moju', value: 'Moju' },
        { label: 'Mojuí dos Campos', value: 'Mojuí dos Campos' },
        { label: 'Monte Alegre', value: 'Monte Alegre' },
        { label: 'Muaná', value: 'Muaná' },
        { label: 'Nova Esperança do Piriá', value: 'Nova Esperança do Piriá' },
        { label: 'Nova Ipixuna', value: 'Nova Ipixuna' },
        { label: 'Nova Timboteua', value: 'Nova Timboteua' },
        { label: 'Novo Progresso', value: 'Novo Progresso' },
        { label: 'Novo Repartimento', value: 'Novo Repartimento' },
        { label: 'Óbidos', value: 'Óbidos' },
        { label: 'Oeiras do Pará', value: 'Oeiras do Pará' },
        { label: 'Oriximiná', value: 'Oriximiná' },
        { label: 'Ourém', value: 'Ourém' },
        { label: 'Ourilândia do Norte', value: 'Ourilândia do Norte' },
        { label: 'Pacajá', value: 'Pacajá' },
        { label: 'Palestina do Pará', value: 'Palestina do Pará' },
        { label: 'Paragominas', value: 'Paragominas' },
        { label: 'Parauapebas', value: 'Parauapebas' },
        "Pau d'Arco",
        { label: 'Peixe-Boi', value: 'Peixe-Boi' },
        { label: 'Piçarra', value: 'Piçarra' },
        { label: 'Placas', value: 'Placas' },
        { label: 'Ponta de Pedras', value: 'Ponta de Pedras' },
        { label: 'Portel', value: 'Portel' },
        { label: 'Porto de Moz', value: 'Porto de Moz' },
        { label: 'Prainha', value: 'Prainha' },
        { label: 'Primavera', value: 'Primavera' },
        { label: 'Quatipuru', value: 'Quatipuru' },
        { label: 'Redenção', value: 'Redenção' },
        { label: 'Rio Maria', value: 'Rio Maria' },
        { label: 'Rondon do Pará', value: 'Rondon do Pará' },
        { label: 'Rurópolis', value: 'Rurópolis' },
        { label: 'Salinópolis', value: 'Salinópolis' },
        { label: 'Salvaterra', value: 'Salvaterra' },
        { label: 'Santa Bárbara do Pará', value: 'Santa Bárbara do Pará' },
        { label: 'Santa Cruz do Arari', value: 'Santa Cruz do Arari' },
        { label: 'Santa Izabel do Pará', value: 'Santa Izabel do Pará' },
        { label: 'Santa Luzia do Pará', value: 'Santa Luzia do Pará' },
        { label: 'Santa Maria das Barreiras', value: 'Santa Maria das Barreiras' },
        { label: 'Santa Maria do Pará', value: 'Santa Maria do Pará' },
        { label: 'Santana do Araguaia', value: 'Santana do Araguaia' },
        { label: 'Santarém', value: 'Santarém' },
        { label: 'Santarém Novo', value: 'Santarém Novo' },
        { label: 'Santo Antônio do Tauá', value: 'Santo Antônio do Tauá' },
        { label: 'São Caetano de Odivelas', value: 'São Caetano de Odivelas' },
        { label: 'São Domingos do Araguaia', value: 'São Domingos do Araguaia' },
        { label: 'São Domingos do Capim', value: 'São Domingos do Capim' },
        { label: 'São Félix do Xingu', value: 'São Félix do Xingu' },
        { label: 'São Francisco do Pará', value: 'São Francisco do Pará' },
        { label: 'São Geraldo do Araguaia', value: 'São Geraldo do Araguaia' },
        { label: 'São João da Ponta', value: 'São João da Ponta' },
        { label: 'São João de Pirabas', value: 'São João de Pirabas' },
        { label: 'São João do Araguaia', value: 'São João do Araguaia' },
        { label: 'São Miguel do Guamá', value: 'São Miguel do Guamá' },
        { label: 'São Sebastião da Boa Vista', value: 'São Sebastião da Boa Vista' },
        { label: 'Sapucaia', value: 'Sapucaia' },
        { label: 'Senador José Porfírio', value: 'Senador José Porfírio' },
        { label: 'Soure', value: 'Soure' },
        { label: 'Tailândia', value: 'Tailândia' },
        { label: 'Terra Alta', value: 'Terra Alta' },
        { label: 'Terra Santa', value: 'Terra Santa' },
        { label: 'Tomé-Açu', value: 'Tomé-Açu' },
        { label: 'Tracuateua', value: 'Tracuateua' },
        { label: 'Trairão', value: 'Trairão' },
        { label: 'Tucumã', value: 'Tucumã' },
        { label: 'Tucuruí', value: 'Tucuruí' },
        { label: 'Ulianópolis', value: 'Ulianópolis' },
        { label: 'Uruará', value: 'Uruará' },
        { label: 'Vigia', value: 'Vigia' },
        { label: 'Viseu', value: 'Viseu' },
        { label: 'Vitória do Xingu', value: 'Vitória do Xingu' },
        { label: 'Xinguara', value: 'Xinguara' },
    ],
    PB: [
        { label: 'Água Branca', value: 'Água Branca' },
        { label: 'Aguiar', value: 'Aguiar' },
        { label: 'Alagoa Grande', value: 'Alagoa Grande' },
        { label: 'Alagoa Nova', value: 'Alagoa Nova' },
        { label: 'Alagoinha', value: 'Alagoinha' },
        { label: 'Alcantil', value: 'Alcantil' },
        { label: 'Algodão de Jandaíra', value: 'Algodão de Jandaíra' },
        { label: 'Alhandra', value: 'Alhandra' },
        { label: 'Amparo', value: 'Amparo' },
        { label: 'Aparecida', value: 'Aparecida' },
        { label: 'Araçagi', value: 'Araçagi' },
        { label: 'Arara', value: 'Arara' },
        { label: 'Araruna', value: 'Araruna' },
        { label: 'Areia', value: 'Areia' },
        { label: 'Areia de Baraúnas', value: 'Areia de Baraúnas' },
        { label: 'Areial', value: 'Areial' },
        { label: 'Aroeiras', value: 'Aroeiras' },
        { label: 'Assunção', value: 'Assunção' },
        { label: 'Baía da Traição', value: 'Baía da Traição' },
        { label: 'Bananeiras', value: 'Bananeiras' },
        { label: 'Baraúna', value: 'Baraúna' },
        { label: 'Barra de Santa Rosa', value: 'Barra de Santa Rosa' },
        { label: 'Barra de Santana', value: 'Barra de Santana' },
        { label: 'Barra de São Miguel', value: 'Barra de São Miguel' },
        { label: 'Bayeux', value: 'Bayeux' },
        { label: 'Belém', value: 'Belém' },
        { label: 'Belém do Brejo do Cruz', value: 'Belém do Brejo do Cruz' },
        { label: 'Bernardino Batista', value: 'Bernardino Batista' },
        { label: 'Boa Ventura', value: 'Boa Ventura' },
        { label: 'Boa Vista', value: 'Boa Vista' },
        { label: 'Bom Jesus', value: 'Bom Jesus' },
        { label: 'Bom Sucesso', value: 'Bom Sucesso' },
        { label: 'Bonito de Santa Fé', value: 'Bonito de Santa Fé' },
        { label: 'Boqueirão', value: 'Boqueirão' },
        { label: 'Borborema', value: 'Borborema' },
        { label: 'Brejo do Cruz', value: 'Brejo do Cruz' },
        { label: 'Brejo dos Santos', value: 'Brejo dos Santos' },
        { label: 'Caaporã', value: 'Caaporã' },
        { label: 'Cabaceiras', value: 'Cabaceiras' },
        { label: 'Cabedelo', value: 'Cabedelo' },
        { label: 'Cachoeira dos Índios', value: 'Cachoeira dos Índios' },
        { label: 'Cacimba de Areia', value: 'Cacimba de Areia' },
        { label: 'Cacimba de Dentro', value: 'Cacimba de Dentro' },
        { label: 'Cacimbas', value: 'Cacimbas' },
        { label: 'Caiçara', value: 'Caiçara' },
        { label: 'Cajazeiras', value: 'Cajazeiras' },
        { label: 'Cajazeirinhas', value: 'Cajazeirinhas' },
        { label: 'Caldas Brandão', value: 'Caldas Brandão' },
        { label: 'Camalaú', value: 'Camalaú' },
        { label: 'Campina Grande', value: 'Campina Grande' },
        { label: 'Capim', value: 'Capim' },
        { label: 'Caraúbas', value: 'Caraúbas' },
        { label: 'Carrapateira', value: 'Carrapateira' },
        { label: 'Casserengue', value: 'Casserengue' },
        { label: 'Catingueira', value: 'Catingueira' },
        { label: 'Catolé do Rocha', value: 'Catolé do Rocha' },
        { label: 'Caturité', value: 'Caturité' },
        { label: 'Conceição', value: 'Conceição' },
        { label: 'Condado', value: 'Condado' },
        { label: 'Conde', value: 'Conde' },
        { label: 'Congo', value: 'Congo' },
        { label: 'Coremas', value: 'Coremas' },
        { label: 'Coxixola', value: 'Coxixola' },
        { label: 'Cruz do Espírito Santo', value: 'Cruz do Espírito Santo' },
        { label: 'Cubati', value: 'Cubati' },
        { label: 'Cuité', value: 'Cuité' },
        { label: 'Cuité de Mamanguape', value: 'Cuité de Mamanguape' },
        { label: 'Cuitegi', value: 'Cuitegi' },
        { label: 'Curral de Cima', value: 'Curral de Cima' },
        { label: 'Curral Velho', value: 'Curral Velho' },
        { label: 'Damião', value: 'Damião' },
        { label: 'Desterro', value: 'Desterro' },
        { label: 'Diamante', value: 'Diamante' },
        { label: 'Dona Inês', value: 'Dona Inês' },
        { label: 'Duas Estradas', value: 'Duas Estradas' },
        { label: 'Emas', value: 'Emas' },
        { label: 'Esperança', value: 'Esperança' },
        { label: 'Fagundes', value: 'Fagundes' },
        { label: 'Frei Martinho', value: 'Frei Martinho' },
        { label: 'Gado Bravo', value: 'Gado Bravo' },
        { label: 'Guarabira', value: 'Guarabira' },
        { label: 'Gurinhém', value: 'Gurinhém' },
        { label: 'Gurjão', value: 'Gurjão' },
        { label: 'Ibiara', value: 'Ibiara' },
        { label: 'Igaracy', value: 'Igaracy' },
        { label: 'Imaculada', value: 'Imaculada' },
        { label: 'Ingá', value: 'Ingá' },
        { label: 'Itabaiana', value: 'Itabaiana' },
        { label: 'Itaporanga', value: 'Itaporanga' },
        { label: 'Itapororoca', value: 'Itapororoca' },
        { label: 'Itatuba', value: 'Itatuba' },
        { label: 'Jacaraú', value: 'Jacaraú' },
        { label: 'Jericó', value: 'Jericó' },
        { label: 'João Pessoa', value: 'João Pessoa' },
        { label: 'Joca Claudino', value: 'Joca Claudino' },
        { label: 'Juarez Távora', value: 'Juarez Távora' },
        { label: 'Juazeirinho', value: 'Juazeirinho' },
        { label: 'Junco do Seridó', value: 'Junco do Seridó' },
        { label: 'Juripiranga', value: 'Juripiranga' },
        { label: 'Juru', value: 'Juru' },
        { label: 'Lagoa', value: 'Lagoa' },
        { label: 'Lagoa de Dentro', value: 'Lagoa de Dentro' },
        { label: 'Lagoa Seca', value: 'Lagoa Seca' },
        { label: 'Lastro', value: 'Lastro' },
        { label: 'Livramento', value: 'Livramento' },
        { label: 'Logradouro', value: 'Logradouro' },
        { label: 'Lucena', value: 'Lucena' },
        "Mãe d'Água",
        { label: 'Malta', value: 'Malta' },
        { label: 'Mamanguape', value: 'Mamanguape' },
        { label: 'Manaíra', value: 'Manaíra' },
        { label: 'Marcação', value: 'Marcação' },
        { label: 'Mari', value: 'Mari' },
        { label: 'Marizópolis', value: 'Marizópolis' },
        { label: 'Massaranduba', value: 'Massaranduba' },
        { label: 'Mataraca', value: 'Mataraca' },
        { label: 'Matinhas', value: 'Matinhas' },
        { label: 'Mato Grosso', value: 'Mato Grosso' },
        { label: 'Maturéia', value: 'Maturéia' },
        { label: 'Mogeiro', value: 'Mogeiro' },
        { label: 'Montadas', value: 'Montadas' },
        { label: 'Monte Horebe', value: 'Monte Horebe' },
        { label: 'Monteiro', value: 'Monteiro' },
        { label: 'Mulungu', value: 'Mulungu' },
        { label: 'Natuba', value: 'Natuba' },
        { label: 'Nazarezinho', value: 'Nazarezinho' },
        { label: 'Nova Floresta', value: 'Nova Floresta' },
        { label: 'Nova Olinda', value: 'Nova Olinda' },
        { label: 'Nova Palmeira', value: 'Nova Palmeira' },
        "Olho d'Água",
        { label: 'Olivedos', value: 'Olivedos' },
        { label: 'Ouro Velho', value: 'Ouro Velho' },
        { label: 'Parari', value: 'Parari' },
        { label: 'Passagem', value: 'Passagem' },
        { label: 'Patos', value: 'Patos' },
        { label: 'Paulista', value: 'Paulista' },
        { label: 'Pedra Branca', value: 'Pedra Branca' },
        { label: 'Pedra Lavrada', value: 'Pedra Lavrada' },
        { label: 'Pedras de Fogo', value: 'Pedras de Fogo' },
        { label: 'Pedro Régis', value: 'Pedro Régis' },
        { label: 'Piancó', value: 'Piancó' },
        { label: 'Picuí', value: 'Picuí' },
        { label: 'Pilar', value: 'Pilar' },
        { label: 'Pilões', value: 'Pilões' },
        { label: 'Pilõezinhos', value: 'Pilõezinhos' },
        { label: 'Pirpirituba', value: 'Pirpirituba' },
        { label: 'Pitimbu', value: 'Pitimbu' },
        { label: 'Pocinhos', value: 'Pocinhos' },
        { label: 'Poço Dantas', value: 'Poço Dantas' },
        { label: 'Poço de José de Moura', value: 'Poço de José de Moura' },
        { label: 'Pombal', value: 'Pombal' },
        { label: 'Prata', value: 'Prata' },
        { label: 'Princesa Isabel', value: 'Princesa Isabel' },
        { label: 'Puxinanã', value: 'Puxinanã' },
        { label: 'Queimadas', value: 'Queimadas' },
        { label: 'Quixabá', value: 'Quixabá' },
        { label: 'Remígio', value: 'Remígio' },
        { label: 'Riachão', value: 'Riachão' },
        { label: 'Riachão do Bacamarte', value: 'Riachão do Bacamarte' },
        { label: 'Riachão do Poço', value: 'Riachão do Poço' },
        { label: 'Riacho de Santo Antônio', value: 'Riacho de Santo Antônio' },
        { label: 'Riacho dos Cavalos', value: 'Riacho dos Cavalos' },
        { label: 'Rio Tinto', value: 'Rio Tinto' },
        { label: 'Salgadinho', value: 'Salgadinho' },
        { label: 'Salgado de São Félix', value: 'Salgado de São Félix' },
        { label: 'Santa Cecília', value: 'Santa Cecília' },
        { label: 'Santa Cruz', value: 'Santa Cruz' },
        { label: 'Santa Helena', value: 'Santa Helena' },
        { label: 'Santa Inês', value: 'Santa Inês' },
        { label: 'Santa Luzia', value: 'Santa Luzia' },
        { label: 'Santa Rita', value: 'Santa Rita' },
        { label: 'Santa Teresinha', value: 'Santa Teresinha' },
        { label: 'Santana de Mangueira', value: 'Santana de Mangueira' },
        { label: 'Santana dos Garrotes', value: 'Santana dos Garrotes' },
        { label: 'Santo André', value: 'Santo André' },
        { label: 'São Bentinho', value: 'São Bentinho' },
        { label: 'São Bento', value: 'São Bento' },
        { label: 'São Domingos', value: 'São Domingos' },
        { label: 'São Domingos do Cariri', value: 'São Domingos do Cariri' },
        { label: 'São Francisco', value: 'São Francisco' },
        { label: 'São João do Cariri', value: 'São João do Cariri' },
        { label: 'São João do Rio do Peixe', value: 'São João do Rio do Peixe' },
        { label: 'São João do Tigre', value: 'São João do Tigre' },
        { label: 'São José da Lagoa Tapada', value: 'São José da Lagoa Tapada' },
        { label: 'São José de Caiana', value: 'São José de Caiana' },
        { label: 'São José de Espinharas', value: 'São José de Espinharas' },
        { label: 'São José de Piranhas', value: 'São José de Piranhas' },
        { label: 'São José de Princesa', value: 'São José de Princesa' },
        { label: 'São José do Bonfim', value: 'São José do Bonfim' },
        { label: 'São José do Brejo do Cruz', value: 'São José do Brejo do Cruz' },
        { label: 'São José do Sabugi', value: 'São José do Sabugi' },
        { label: 'São José dos Cordeiros', value: 'São José dos Cordeiros' },
        { label: 'São José dos Ramos', value: 'São José dos Ramos' },
        { label: 'São Mamede', value: 'São Mamede' },
        { label: 'São Miguel de Taipu', value: 'São Miguel de Taipu' },
        { label: 'São Sebastião de Lagoa de Roça', value: 'São Sebastião de Lagoa de Roça' },
        { label: 'São Sebastião do Umbuzeiro', value: 'São Sebastião do Umbuzeiro' },
        { label: 'São Vicente do Seridó', value: 'São Vicente do Seridó' },
        { label: 'Sapé', value: 'Sapé' },
        { label: 'Serra Branca', value: 'Serra Branca' },
        { label: 'Serra da Raiz', value: 'Serra da Raiz' },
        { label: 'Serra Grande', value: 'Serra Grande' },
        { label: 'Serra Redonda', value: 'Serra Redonda' },
        { label: 'Serraria', value: 'Serraria' },
        { label: 'Sertãozinho', value: 'Sertãozinho' },
        { label: 'Sobrado', value: 'Sobrado' },
        { label: 'Solânea', value: 'Solânea' },
        { label: 'Soledade', value: 'Soledade' },
        { label: 'Sossêgo', value: 'Sossêgo' },
        { label: 'Sousa', value: 'Sousa' },
        { label: 'Sumé', value: 'Sumé' },
        { label: 'Tacima', value: 'Tacima' },
        { label: 'Taperoá', value: 'Taperoá' },
        { label: 'Tavares', value: 'Tavares' },
        { label: 'Teixeira', value: 'Teixeira' },
        { label: 'Tenório', value: 'Tenório' },
        { label: 'Triunfo', value: 'Triunfo' },
        { label: 'Uiraúna', value: 'Uiraúna' },
        { label: 'Umbuzeiro', value: 'Umbuzeiro' },
        { label: 'Várzea', value: 'Várzea' },
        { label: 'Vieirópolis', value: 'Vieirópolis' },
        { label: 'Vista Serrana', value: 'Vista Serrana' },
        { label: 'Zabelê', value: 'Zabelê' },
    ],
    PE: [
        { label: 'Abreu e Lima', value: 'Abreu e Lima' },
        { label: 'Afogados da Ingazeira', value: 'Afogados da Ingazeira' },
        { label: 'Afrânio', value: 'Afrânio' },
        { label: 'Agrestina', value: 'Agrestina' },
        { label: 'Água Preta', value: 'Água Preta' },
        { label: 'Águas Belas', value: 'Águas Belas' },
        { label: 'Alagoinha', value: 'Alagoinha' },
        { label: 'Aliança', value: 'Aliança' },
        { label: 'Altinho', value: 'Altinho' },
        { label: 'Amaraji', value: 'Amaraji' },
        { label: 'Angelim', value: 'Angelim' },
        { label: 'Araçoiaba', value: 'Araçoiaba' },
        { label: 'Araripina', value: 'Araripina' },
        { label: 'Arcoverde', value: 'Arcoverde' },
        { label: 'Barra de Guabiraba', value: 'Barra de Guabiraba' },
        { label: 'Barreiros', value: 'Barreiros' },
        { label: 'Belém de Maria', value: 'Belém de Maria' },
        { label: 'Belém do São Francisco', value: 'Belém do São Francisco' },
        { label: 'Belo Jardim', value: 'Belo Jardim' },
        { label: 'Betânia', value: 'Betânia' },
        { label: 'Bezerros', value: 'Bezerros' },
        { label: 'Bodocó', value: 'Bodocó' },
        { label: 'Bom Conselho', value: 'Bom Conselho' },
        { label: 'Bom Jardim', value: 'Bom Jardim' },
        { label: 'Bonito', value: 'Bonito' },
        { label: 'Brejão', value: 'Brejão' },
        { label: 'Brejinho', value: 'Brejinho' },
        { label: 'Brejo da Madre de Deus', value: 'Brejo da Madre de Deus' },
        { label: 'Buenos Aires', value: 'Buenos Aires' },
        { label: 'Buíque', value: 'Buíque' },
        { label: 'Cabo de Santo Agostinho', value: 'Cabo de Santo Agostinho' },
        { label: 'Cabrobó', value: 'Cabrobó' },
        { label: 'Cachoeirinha', value: 'Cachoeirinha' },
        { label: 'Caetés', value: 'Caetés' },
        { label: 'Calçado', value: 'Calçado' },
        { label: 'Calumbi', value: 'Calumbi' },
        { label: 'Camaragibe', value: 'Camaragibe' },
        { label: 'Camocim de São Félix', value: 'Camocim de São Félix' },
        { label: 'Camutanga', value: 'Camutanga' },
        { label: 'Canhotinho', value: 'Canhotinho' },
        { label: 'Capoeiras', value: 'Capoeiras' },
        { label: 'Carnaíba', value: 'Carnaíba' },
        { label: 'Carnaubeira da Penha', value: 'Carnaubeira da Penha' },
        { label: 'Carpina', value: 'Carpina' },
        { label: 'Caruaru', value: 'Caruaru' },
        { label: 'Casinhas', value: 'Casinhas' },
        { label: 'Catende', value: 'Catende' },
        { label: 'Cedro', value: 'Cedro' },
        { label: 'Chã de Alegria', value: 'Chã de Alegria' },
        { label: 'Chã Grande', value: 'Chã Grande' },
        { label: 'Condado', value: 'Condado' },
        { label: 'Correntes', value: 'Correntes' },
        { label: 'Cortês', value: 'Cortês' },
        { label: 'Cumaru', value: 'Cumaru' },
        { label: 'Cupira', value: 'Cupira' },
        { label: 'Custódia', value: 'Custódia' },
        { label: 'Dormentes', value: 'Dormentes' },
        { label: 'Escada', value: 'Escada' },
        { label: 'Exu', value: 'Exu' },
        { label: 'Feira Nova', value: 'Feira Nova' },
        { label: 'Fernando de Noronha', value: 'Fernando de Noronha' },
        { label: 'Ferreiros', value: 'Ferreiros' },
        { label: 'Flores', value: 'Flores' },
        { label: 'Floresta', value: 'Floresta' },
        { label: 'Frei Miguelinho', value: 'Frei Miguelinho' },
        { label: 'Gameleira', value: 'Gameleira' },
        { label: 'Garanhuns', value: 'Garanhuns' },
        { label: 'Glória do Goitá', value: 'Glória do Goitá' },
        { label: 'Goiana', value: 'Goiana' },
        { label: 'Granito', value: 'Granito' },
        { label: 'Gravatá', value: 'Gravatá' },
        { label: 'Iati', value: 'Iati' },
        { label: 'Ibimirim', value: 'Ibimirim' },
        { label: 'Ibirajuba', value: 'Ibirajuba' },
        { label: 'Igarassu', value: 'Igarassu' },
        { label: 'Iguaracy', value: 'Iguaracy' },
        { label: 'Ilha de Itamaracá', value: 'Ilha de Itamaracá' },
        { label: 'Inajá', value: 'Inajá' },
        { label: 'Ingazeira', value: 'Ingazeira' },
        { label: 'Ipojuca', value: 'Ipojuca' },
        { label: 'Ipubi', value: 'Ipubi' },
        { label: 'Itacuruba', value: 'Itacuruba' },
        { label: 'Itaíba', value: 'Itaíba' },
        { label: 'Itambé', value: 'Itambé' },
        { label: 'Itapetim', value: 'Itapetim' },
        { label: 'Itapissuma', value: 'Itapissuma' },
        { label: 'Itaquitinga', value: 'Itaquitinga' },
        { label: 'Jaboatão dos Guararapes', value: 'Jaboatão dos Guararapes' },
        { label: 'Jaqueira', value: 'Jaqueira' },
        { label: 'Jataúba', value: 'Jataúba' },
        { label: 'Jatobá', value: 'Jatobá' },
        { label: 'João Alfredo', value: 'João Alfredo' },
        { label: 'Joaquim Nabuco', value: 'Joaquim Nabuco' },
        { label: 'Jucati', value: 'Jucati' },
        { label: 'Jupi', value: 'Jupi' },
        { label: 'Jurema', value: 'Jurema' },
        { label: 'Lagoa de Itaenga', value: 'Lagoa de Itaenga' },
        { label: 'Lagoa do Carro', value: 'Lagoa do Carro' },
        { label: 'Lagoa do Ouro', value: 'Lagoa do Ouro' },
        { label: 'Lagoa dos Gatos', value: 'Lagoa dos Gatos' },
        { label: 'Lagoa Grande', value: 'Lagoa Grande' },
        { label: 'Lajedo', value: 'Lajedo' },
        { label: 'Limoeiro', value: 'Limoeiro' },
        { label: 'Macaparana', value: 'Macaparana' },
        { label: 'Machados', value: 'Machados' },
        { label: 'Manari', value: 'Manari' },
        { label: 'Maraial', value: 'Maraial' },
        { label: 'Mirandiba', value: 'Mirandiba' },
        { label: 'Moreilândia', value: 'Moreilândia' },
        { label: 'Moreno', value: 'Moreno' },
        { label: 'Nazaré da Mata', value: 'Nazaré da Mata' },
        { label: 'Olinda', value: 'Olinda' },
        { label: 'Orobó', value: 'Orobó' },
        { label: 'Orocó', value: 'Orocó' },
        { label: 'Ouricuri', value: 'Ouricuri' },
        { label: 'Palmares', value: 'Palmares' },
        { label: 'Palmeirina', value: 'Palmeirina' },
        { label: 'Panelas', value: 'Panelas' },
        { label: 'Paranatama', value: 'Paranatama' },
        { label: 'Parnamirim', value: 'Parnamirim' },
        { label: 'Passira', value: 'Passira' },
        { label: 'Paudalho', value: 'Paudalho' },
        { label: 'Paulista', value: 'Paulista' },
        { label: 'Pedra', value: 'Pedra' },
        { label: 'Pesqueira', value: 'Pesqueira' },
        { label: 'Petrolândia', value: 'Petrolândia' },
        { label: 'Petrolina', value: 'Petrolina' },
        { label: 'Poção', value: 'Poção' },
        { label: 'Pombos', value: 'Pombos' },
        { label: 'Primavera', value: 'Primavera' },
        { label: 'Quipapá', value: 'Quipapá' },
        { label: 'Quixaba', value: 'Quixaba' },
        { label: 'Recife', value: 'Recife' },
        { label: 'Riacho das Almas', value: 'Riacho das Almas' },
        { label: 'Ribeirão', value: 'Ribeirão' },
        { label: 'Rio Formoso', value: 'Rio Formoso' },
        { label: 'Sairé', value: 'Sairé' },
        { label: 'Salgadinho', value: 'Salgadinho' },
        { label: 'Salgueiro', value: 'Salgueiro' },
        { label: 'Saloá', value: 'Saloá' },
        { label: 'Sanharó', value: 'Sanharó' },
        { label: 'Santa Cruz', value: 'Santa Cruz' },
        { label: 'Santa Cruz da Baixa Verde', value: 'Santa Cruz da Baixa Verde' },
        { label: 'Santa Cruz do Capibaribe', value: 'Santa Cruz do Capibaribe' },
        { label: 'Santa Filomena', value: 'Santa Filomena' },
        { label: 'Santa Maria da Boa Vista', value: 'Santa Maria da Boa Vista' },
        { label: 'Santa Maria do Cambucá', value: 'Santa Maria do Cambucá' },
        { label: 'Santa Terezinha', value: 'Santa Terezinha' },
        { label: 'São Benedito do Sul', value: 'São Benedito do Sul' },
        { label: 'São Bento do Una', value: 'São Bento do Una' },
        { label: 'São Caetano', value: 'São Caetano' },
        { label: 'São João', value: 'São João' },
        { label: 'São Joaquim do Monte', value: 'São Joaquim do Monte' },
        { label: 'São José da Coroa Grande', value: 'São José da Coroa Grande' },
        { label: 'São José do Belmonte', value: 'São José do Belmonte' },
        { label: 'São José do Egito', value: 'São José do Egito' },
        { label: 'São Lourenço da Mata', value: 'São Lourenço da Mata' },
        { label: 'São Vicente Ferrer', value: 'São Vicente Ferrer' },
        { label: 'Serra Talhada', value: 'Serra Talhada' },
        { label: 'Serrita', value: 'Serrita' },
        { label: 'Sertânia', value: 'Sertânia' },
        { label: 'Sirinhaém', value: 'Sirinhaém' },
        { label: 'Solidão', value: 'Solidão' },
        { label: 'Surubim', value: 'Surubim' },
        { label: 'Tabira', value: 'Tabira' },
        { label: 'Tacaimbó', value: 'Tacaimbó' },
        { label: 'Tacaratu', value: 'Tacaratu' },
        { label: 'Tamandaré', value: 'Tamandaré' },
        { label: 'Taquaritinga do Norte', value: 'Taquaritinga do Norte' },
        { label: 'Terezinha', value: 'Terezinha' },
        { label: 'Terra Nova', value: 'Terra Nova' },
        { label: 'Timbaúba', value: 'Timbaúba' },
        { label: 'Toritama', value: 'Toritama' },
        { label: 'Tracunhaém', value: 'Tracunhaém' },
        { label: 'Trindade', value: 'Trindade' },
        { label: 'Triunfo', value: 'Triunfo' },
        { label: 'Tupanatinga', value: 'Tupanatinga' },
        { label: 'Tuparetama', value: 'Tuparetama' },
        { label: 'Venturosa', value: 'Venturosa' },
        { label: 'Verdejante', value: 'Verdejante' },
        { label: 'Vertente do Lério', value: 'Vertente do Lério' },
        { label: 'Vertentes', value: 'Vertentes' },
        { label: 'Vicência', value: 'Vicência' },
        { label: 'Vitória de Santo Antão', value: 'Vitória de Santo Antão' },
        { label: 'Xexéu', value: 'Xexéu' },
    ],
    PI: [
        { label: 'Acauã', value: 'Acauã' },
        { label: 'Agricolândia', value: 'Agricolândia' },
        { label: 'Água Branca', value: 'Água Branca' },
        { label: 'Alagoinha do Piauí', value: 'Alagoinha do Piauí' },
        { label: 'Alegrete do Piauí', value: 'Alegrete do Piauí' },
        { label: 'Alto Longá', value: 'Alto Longá' },
        { label: 'Altos', value: 'Altos' },
        { label: 'Alvorada do Gurguéia', value: 'Alvorada do Gurguéia' },
        { label: 'Amarante', value: 'Amarante' },
        { label: 'Angical do Piauí', value: 'Angical do Piauí' },
        { label: 'Anísio de Abreu', value: 'Anísio de Abreu' },
        { label: 'Antônio Almeida', value: 'Antônio Almeida' },
        { label: 'Aroazes', value: 'Aroazes' },
        { label: 'Aroeiras do Itaim', value: 'Aroeiras do Itaim' },
        { label: 'Arraial', value: 'Arraial' },
        { label: 'Assunção do Piauí', value: 'Assunção do Piauí' },
        { label: 'Avelino Lopes', value: 'Avelino Lopes' },
        { label: 'Baixa Grande do Ribeiro', value: 'Baixa Grande do Ribeiro' },
        "Barra D'Alcântara",
        { label: 'Barras', value: 'Barras' },
        { label: 'Barreiras do Piauí', value: 'Barreiras do Piauí' },
        { label: 'Barro Duro', value: 'Barro Duro' },
        { label: 'Batalha', value: 'Batalha' },
        { label: 'Bela Vista do Piauí', value: 'Bela Vista do Piauí' },
        { label: 'Belém do Piauí', value: 'Belém do Piauí' },
        { label: 'Beneditinos', value: 'Beneditinos' },
        { label: 'Bertolínia', value: 'Bertolínia' },
        { label: 'Betânia do Piauí', value: 'Betânia do Piauí' },
        { label: 'Boa Hora', value: 'Boa Hora' },
        { label: 'Bocaina', value: 'Bocaina' },
        { label: 'Bom Jesus', value: 'Bom Jesus' },
        { label: 'Bom Princípio do Piauí', value: 'Bom Princípio do Piauí' },
        { label: 'Bonfim do Piauí', value: 'Bonfim do Piauí' },
        { label: 'Boqueirão do Piauí', value: 'Boqueirão do Piauí' },
        { label: 'Brasileira', value: 'Brasileira' },
        { label: 'Brejo do Piauí', value: 'Brejo do Piauí' },
        { label: 'Buriti dos Lopes', value: 'Buriti dos Lopes' },
        { label: 'Buriti dos Montes', value: 'Buriti dos Montes' },
        { label: 'Cabeceiras do Piauí', value: 'Cabeceiras do Piauí' },
        { label: 'Cajazeiras do Piauí', value: 'Cajazeiras do Piauí' },
        { label: 'Cajueiro da Praia', value: 'Cajueiro da Praia' },
        { label: 'Caldeirão Grande do Piauí', value: 'Caldeirão Grande do Piauí' },
        { label: 'Campinas do Piauí', value: 'Campinas do Piauí' },
        { label: 'Campo Alegre do Fidalgo', value: 'Campo Alegre do Fidalgo' },
        { label: 'Campo Grande do Piauí', value: 'Campo Grande do Piauí' },
        { label: 'Campo Largo do Piauí', value: 'Campo Largo do Piauí' },
        { label: 'Campo Maior', value: 'Campo Maior' },
        { label: 'Canavieira', value: 'Canavieira' },
        { label: 'Canto do Buriti', value: 'Canto do Buriti' },
        { label: 'Capitão de Campos', value: 'Capitão de Campos' },
        { label: 'Capitão Gervásio Oliveira', value: 'Capitão Gervásio Oliveira' },
        { label: 'Caracol', value: 'Caracol' },
        { label: 'Caraúbas do Piauí', value: 'Caraúbas do Piauí' },
        { label: 'Caridade do Piauí', value: 'Caridade do Piauí' },
        { label: 'Castelo do Piauí', value: 'Castelo do Piauí' },
        { label: 'Caxingó', value: 'Caxingó' },
        { label: 'Cocal', value: 'Cocal' },
        { label: 'Cocal de Telha', value: 'Cocal de Telha' },
        { label: 'Cocal dos Alves', value: 'Cocal dos Alves' },
        { label: 'Coivaras', value: 'Coivaras' },
        { label: 'Colônia do Gurguéia', value: 'Colônia do Gurguéia' },
        { label: 'Colônia do Piauí', value: 'Colônia do Piauí' },
        { label: 'Conceição do Canindé', value: 'Conceição do Canindé' },
        { label: 'Coronel José Dias', value: 'Coronel José Dias' },
        { label: 'Corrente', value: 'Corrente' },
        { label: 'Cristalândia do Piauí', value: 'Cristalândia do Piauí' },
        { label: 'Cristino Castro', value: 'Cristino Castro' },
        { label: 'Curimatá', value: 'Curimatá' },
        { label: 'Currais', value: 'Currais' },
        { label: 'Curral Novo do Piauí', value: 'Curral Novo do Piauí' },
        { label: 'Curralinhos', value: 'Curralinhos' },
        { label: 'Demerval Lobão', value: 'Demerval Lobão' },
        { label: 'Dirceu Arcoverde', value: 'Dirceu Arcoverde' },
        { label: 'Dom Expedito Lopes', value: 'Dom Expedito Lopes' },
        { label: 'Dom Inocêncio', value: 'Dom Inocêncio' },
        { label: 'Domingos Mourão', value: 'Domingos Mourão' },
        { label: 'Elesbão Veloso', value: 'Elesbão Veloso' },
        { label: 'Eliseu Martins', value: 'Eliseu Martins' },
        { label: 'Esperantina', value: 'Esperantina' },
        { label: 'Fartura do Piauí', value: 'Fartura do Piauí' },
        { label: 'Flores do Piauí', value: 'Flores do Piauí' },
        { label: 'Floresta do Piauí', value: 'Floresta do Piauí' },
        { label: 'Floriano', value: 'Floriano' },
        { label: 'Francinópolis', value: 'Francinópolis' },
        { label: 'Francisco Ayres', value: 'Francisco Ayres' },
        { label: 'Francisco Macedo', value: 'Francisco Macedo' },
        { label: 'Francisco Santos', value: 'Francisco Santos' },
        { label: 'Fronteiras', value: 'Fronteiras' },
        { label: 'Geminiano', value: 'Geminiano' },
        { label: 'Gilbués', value: 'Gilbués' },
        { label: 'Guadalupe', value: 'Guadalupe' },
        { label: 'Guaribas', value: 'Guaribas' },
        { label: 'Hugo Napoleão', value: 'Hugo Napoleão' },
        { label: 'Ilha Grande', value: 'Ilha Grande' },
        { label: 'Inhuma', value: 'Inhuma' },
        { label: 'Ipiranga do Piauí', value: 'Ipiranga do Piauí' },
        { label: 'Isaías Coelho', value: 'Isaías Coelho' },
        { label: 'Itainópolis', value: 'Itainópolis' },
        { label: 'Itaueira', value: 'Itaueira' },
        { label: 'Jacobina do Piauí', value: 'Jacobina do Piauí' },
        { label: 'Jaicós', value: 'Jaicós' },
        { label: 'Jardim do Mulato', value: 'Jardim do Mulato' },
        { label: 'Jatobá do Piauí', value: 'Jatobá do Piauí' },
        { label: 'Jerumenha', value: 'Jerumenha' },
        { label: 'João Costa', value: 'João Costa' },
        { label: 'Joaquim Pires', value: 'Joaquim Pires' },
        { label: 'Joca Marques', value: 'Joca Marques' },
        { label: 'José de Freitas', value: 'José de Freitas' },
        { label: 'Juazeiro do Piauí', value: 'Juazeiro do Piauí' },
        { label: 'Júlio Borges', value: 'Júlio Borges' },
        { label: 'Jurema', value: 'Jurema' },
        { label: 'Lagoa Alegre', value: 'Lagoa Alegre' },
        { label: 'Lagoa de São Francisco', value: 'Lagoa de São Francisco' },
        { label: 'Lagoa do Barro do Piauí', value: 'Lagoa do Barro do Piauí' },
        { label: 'Lagoa do Piauí', value: 'Lagoa do Piauí' },
        { label: 'Lagoa do Sítio', value: 'Lagoa do Sítio' },
        { label: 'Lagoinha do Piauí', value: 'Lagoinha do Piauí' },
        { label: 'Landri Sales', value: 'Landri Sales' },
        { label: 'Luís Correia', value: 'Luís Correia' },
        { label: 'Luzilândia', value: 'Luzilândia' },
        { label: 'Madeiro', value: 'Madeiro' },
        { label: 'Manoel Emídio', value: 'Manoel Emídio' },
        { label: 'Marcolândia', value: 'Marcolândia' },
        { label: 'Marcos Parente', value: 'Marcos Parente' },
        { label: 'Massapê do Piauí', value: 'Massapê do Piauí' },
        { label: 'Matias Olímpio', value: 'Matias Olímpio' },
        { label: 'Miguel Alves', value: 'Miguel Alves' },
        { label: 'Miguel Leão', value: 'Miguel Leão' },
        { label: 'Milton Brandão', value: 'Milton Brandão' },
        { label: 'Monsenhor Gil', value: 'Monsenhor Gil' },
        { label: 'Monsenhor Hipólito', value: 'Monsenhor Hipólito' },
        { label: 'Monte Alegre do Piauí', value: 'Monte Alegre do Piauí' },
        { label: 'Morro Cabeça no Tempo', value: 'Morro Cabeça no Tempo' },
        { label: 'Morro do Chapéu do Piauí', value: 'Morro do Chapéu do Piauí' },
        { label: 'Murici dos Portelas', value: 'Murici dos Portelas' },
        { label: 'Nazaré do Piauí', value: 'Nazaré do Piauí' },
        { label: 'Nazária', value: 'Nazária' },
        { label: 'Nossa Senhora de Nazaré', value: 'Nossa Senhora de Nazaré' },
        { label: 'Nossa Senhora dos Remédios', value: 'Nossa Senhora dos Remédios' },
        { label: 'Nova Santa Rita', value: 'Nova Santa Rita' },
        { label: 'Novo Oriente do Piauí', value: 'Novo Oriente do Piauí' },
        { label: 'Novo Santo Antônio', value: 'Novo Santo Antônio' },
        { label: 'Oeiras', value: 'Oeiras' },
        "Olho D'Água do Piauí",
        { label: 'Padre Marcos', value: 'Padre Marcos' },
        { label: 'Paes Landim', value: 'Paes Landim' },
        { label: 'Pajeú do Piauí', value: 'Pajeú do Piauí' },
        { label: 'Palmeira do Piauí', value: 'Palmeira do Piauí' },
        { label: 'Palmeirais', value: 'Palmeirais' },
        { label: 'Paquetá', value: 'Paquetá' },
        { label: 'Parnaguá', value: 'Parnaguá' },
        { label: 'Parnaíba', value: 'Parnaíba' },
        { label: 'Passagem Franca do Piauí', value: 'Passagem Franca do Piauí' },
        { label: 'Patos do Piauí', value: 'Patos do Piauí' },
        "Pau D'Arco do Piauí",
        { label: 'Paulistana', value: 'Paulistana' },
        { label: 'Pavussu', value: 'Pavussu' },
        { label: 'Pedro II', value: 'Pedro II' },
        { label: 'Pedro Laurentino', value: 'Pedro Laurentino' },
        { label: 'Picos', value: 'Picos' },
        { label: 'Pimenteiras', value: 'Pimenteiras' },
        { label: 'Pio IX', value: 'Pio IX' },
        { label: 'Piracuruca', value: 'Piracuruca' },
        { label: 'Piripiri', value: 'Piripiri' },
        { label: 'Porto', value: 'Porto' },
        { label: 'Porto Alegre do Piauí', value: 'Porto Alegre do Piauí' },
        { label: 'Prata do Piauí', value: 'Prata do Piauí' },
        { label: 'Queimada Nova', value: 'Queimada Nova' },
        { label: 'Redenção do Gurguéia', value: 'Redenção do Gurguéia' },
        { label: 'Regeneração', value: 'Regeneração' },
        { label: 'Riacho Frio', value: 'Riacho Frio' },
        { label: 'Ribeira do Piauí', value: 'Ribeira do Piauí' },
        { label: 'Ribeiro Gonçalves', value: 'Ribeiro Gonçalves' },
        { label: 'Rio Grande do Piauí', value: 'Rio Grande do Piauí' },
        { label: 'Santa Cruz do Piauí', value: 'Santa Cruz do Piauí' },
        { label: 'Santa Cruz dos Milagres', value: 'Santa Cruz dos Milagres' },
        { label: 'Santa Filomena', value: 'Santa Filomena' },
        { label: 'Santa Luz', value: 'Santa Luz' },
        { label: 'Santa Rosa do Piauí', value: 'Santa Rosa do Piauí' },
        { label: 'Santana do Piauí', value: 'Santana do Piauí' },
        { label: 'Santo Antônio de Lisboa', value: 'Santo Antônio de Lisboa' },
        { label: 'Santo Antônio dos Milagres', value: 'Santo Antônio dos Milagres' },
        { label: 'Santo Inácio do Piauí', value: 'Santo Inácio do Piauí' },
        { label: 'São Braz do Piauí', value: 'São Braz do Piauí' },
        { label: 'São Félix do Piauí', value: 'São Félix do Piauí' },
        { label: 'São Francisco de Assis do Piauí', value: 'São Francisco de Assis do Piauí' },
        { label: 'São Francisco do Piauí', value: 'São Francisco do Piauí' },
        { label: 'São Gonçalo do Gurguéia', value: 'São Gonçalo do Gurguéia' },
        { label: 'São Gonçalo do Piauí', value: 'São Gonçalo do Piauí' },
        { label: 'São João da Canabrava', value: 'São João da Canabrava' },
        { label: 'São João da Fronteira', value: 'São João da Fronteira' },
        { label: 'São João da Serra', value: 'São João da Serra' },
        { label: 'São João da Varjota', value: 'São João da Varjota' },
        { label: 'São João do Arraial', value: 'São João do Arraial' },
        { label: 'São João do Piauí', value: 'São João do Piauí' },
        { label: 'São José do Divino', value: 'São José do Divino' },
        { label: 'São José do Peixe', value: 'São José do Peixe' },
        { label: 'São José do Piauí', value: 'São José do Piauí' },
        { label: 'São Julião', value: 'São Julião' },
        { label: 'São Lourenço do Piauí', value: 'São Lourenço do Piauí' },
        { label: 'São Luis do Piauí', value: 'São Luis do Piauí' },
        { label: 'São Miguel da Baixa Grande', value: 'São Miguel da Baixa Grande' },
        { label: 'São Miguel do Fidalgo', value: 'São Miguel do Fidalgo' },
        { label: 'São Miguel do Tapuio', value: 'São Miguel do Tapuio' },
        { label: 'São Pedro do Piauí', value: 'São Pedro do Piauí' },
        { label: 'São Raimundo Nonato', value: 'São Raimundo Nonato' },
        { label: 'Sebastião Barros', value: 'Sebastião Barros' },
        { label: 'Sebastião Leal', value: 'Sebastião Leal' },
        { label: 'Sigefredo Pacheco', value: 'Sigefredo Pacheco' },
        { label: 'Simões', value: 'Simões' },
        { label: 'Simplício Mendes', value: 'Simplício Mendes' },
        { label: 'Socorro do Piauí', value: 'Socorro do Piauí' },
        { label: 'Sussuapara', value: 'Sussuapara' },
        { label: 'Tamboril do Piauí', value: 'Tamboril do Piauí' },
        { label: 'Tanque do Piauí', value: 'Tanque do Piauí' },
        { label: 'Teresina', value: 'Teresina' },
        { label: 'União', value: 'União' },
        { label: 'Uruçuí', value: 'Uruçuí' },
        { label: 'Valença do Piauí', value: 'Valença do Piauí' },
        { label: 'Várzea Branca', value: 'Várzea Branca' },
        { label: 'Várzea Grande', value: 'Várzea Grande' },
        { label: 'Vera Mendes', value: 'Vera Mendes' },
        { label: 'Vila Nova do Piauí', value: 'Vila Nova do Piauí' },
        { label: 'Wall Ferraz', value: 'Wall Ferraz' },
    ],
    PR: [
        { label: 'Abatiá', value: 'Abatiá' },
        { label: 'Adrianópolis', value: 'Adrianópolis' },
        { label: 'Agudos do Sul', value: 'Agudos do Sul' },
        { label: 'Almirante Tamandaré', value: 'Almirante Tamandaré' },
        { label: 'Altamira do Paraná', value: 'Altamira do Paraná' },
        { label: 'Alto Paraíso', value: 'Alto Paraíso' },
        { label: 'Alto Paraná', value: 'Alto Paraná' },
        { label: 'Alto Piquiri', value: 'Alto Piquiri' },
        { label: 'Altônia', value: 'Altônia' },
        { label: 'Alvorada do Sul', value: 'Alvorada do Sul' },
        { label: 'Amaporã', value: 'Amaporã' },
        { label: 'Ampére', value: 'Ampére' },
        { label: 'Anahy', value: 'Anahy' },
        { label: 'Andirá', value: 'Andirá' },
        { label: 'Ângulo', value: 'Ângulo' },
        { label: 'Antonina', value: 'Antonina' },
        { label: 'Antônio Olinto', value: 'Antônio Olinto' },
        { label: 'Apucarana', value: 'Apucarana' },
        { label: 'Arapongas', value: 'Arapongas' },
        { label: 'Arapoti', value: 'Arapoti' },
        { label: 'Arapuã', value: 'Arapuã' },
        { label: 'Araruna', value: 'Araruna' },
        { label: 'Araucária', value: 'Araucária' },
        { label: 'Ariranha do Ivaí', value: 'Ariranha do Ivaí' },
        { label: 'Assaí', value: 'Assaí' },
        { label: 'Assis Chateaubriand', value: 'Assis Chateaubriand' },
        { label: 'Astorga', value: 'Astorga' },
        { label: 'Atalaia', value: 'Atalaia' },
        { label: 'Balsa Nova', value: 'Balsa Nova' },
        { label: 'Bandeirantes', value: 'Bandeirantes' },
        { label: 'Barbosa Ferraz', value: 'Barbosa Ferraz' },
        { label: 'Barra do Jacaré', value: 'Barra do Jacaré' },
        { label: 'Barracão', value: 'Barracão' },
        { label: 'Bela Vista da Caroba', value: 'Bela Vista da Caroba' },
        { label: 'Bela Vista do Paraíso', value: 'Bela Vista do Paraíso' },
        { label: 'Bituruna', value: 'Bituruna' },
        { label: 'Boa Esperança', value: 'Boa Esperança' },
        { label: 'Boa Esperança do Iguaçu', value: 'Boa Esperança do Iguaçu' },
        { label: 'Boa Ventura de São Roque', value: 'Boa Ventura de São Roque' },
        { label: 'Boa Vista da Aparecida', value: 'Boa Vista da Aparecida' },
        { label: 'Bocaiúva do Sul', value: 'Bocaiúva do Sul' },
        { label: 'Bom Jesus do Sul', value: 'Bom Jesus do Sul' },
        { label: 'Bom Sucesso', value: 'Bom Sucesso' },
        { label: 'Bom Sucesso do Sul', value: 'Bom Sucesso do Sul' },
        { label: 'Borrazópolis', value: 'Borrazópolis' },
        { label: 'Braganey', value: 'Braganey' },
        { label: 'Brasilândia do Sul', value: 'Brasilândia do Sul' },
        { label: 'Cafeara', value: 'Cafeara' },
        { label: 'Cafelândia', value: 'Cafelândia' },
        { label: 'Cafezal do Sul', value: 'Cafezal do Sul' },
        { label: 'Califórnia', value: 'Califórnia' },
        { label: 'Cambará', value: 'Cambará' },
        { label: 'Cambé', value: 'Cambé' },
        { label: 'Cambira', value: 'Cambira' },
        { label: 'Campina da Lagoa', value: 'Campina da Lagoa' },
        { label: 'Campina do Simão', value: 'Campina do Simão' },
        { label: 'Campina Grande do Sul', value: 'Campina Grande do Sul' },
        { label: 'Campo Bonito', value: 'Campo Bonito' },
        { label: 'Campo do Tenente', value: 'Campo do Tenente' },
        { label: 'Campo Largo', value: 'Campo Largo' },
        { label: 'Campo Magro', value: 'Campo Magro' },
        { label: 'Campo Mourão', value: 'Campo Mourão' },
        { label: 'Cândido de Abreu', value: 'Cândido de Abreu' },
        { label: 'Candói', value: 'Candói' },
        { label: 'Cantagalo', value: 'Cantagalo' },
        { label: 'Capanema', value: 'Capanema' },
        { label: 'Capitão Leônidas Marques', value: 'Capitão Leônidas Marques' },
        { label: 'Carambeí', value: 'Carambeí' },
        { label: 'Carlópolis', value: 'Carlópolis' },
        { label: 'Cascavel', value: 'Cascavel' },
        { label: 'Castro', value: 'Castro' },
        { label: 'Catanduvas', value: 'Catanduvas' },
        { label: 'Centenário do Sul', value: 'Centenário do Sul' },
        { label: 'Cerro Azul', value: 'Cerro Azul' },
        { label: 'Céu Azul', value: 'Céu Azul' },
        { label: 'Chopinzinho', value: 'Chopinzinho' },
        { label: 'Cianorte', value: 'Cianorte' },
        { label: 'Cidade Gaúcha', value: 'Cidade Gaúcha' },
        { label: 'Clevelândia', value: 'Clevelândia' },
        { label: 'Colombo', value: 'Colombo' },
        { label: 'Colorado', value: 'Colorado' },
        { label: 'Congonhinhas', value: 'Congonhinhas' },
        { label: 'Conselheiro Mairinck', value: 'Conselheiro Mairinck' },
        { label: 'Contenda', value: 'Contenda' },
        { label: 'Corbélia', value: 'Corbélia' },
        { label: 'Cornélio Procópio', value: 'Cornélio Procópio' },
        { label: 'Coronel Domingos Soares', value: 'Coronel Domingos Soares' },
        { label: 'Coronel Vivida', value: 'Coronel Vivida' },
        { label: 'Corumbataí do Sul', value: 'Corumbataí do Sul' },
        { label: 'Cruz Machado', value: 'Cruz Machado' },
        { label: 'Cruzeiro do Iguaçu', value: 'Cruzeiro do Iguaçu' },
        { label: 'Cruzeiro do Oeste', value: 'Cruzeiro do Oeste' },
        { label: 'Cruzeiro do Sul', value: 'Cruzeiro do Sul' },
        { label: 'Cruzmaltina', value: 'Cruzmaltina' },
        { label: 'Curitiba', value: 'Curitiba' },
        { label: 'Curiúva', value: 'Curiúva' },
        "Diamante D'Oeste",
        { label: 'Diamante do Norte', value: 'Diamante do Norte' },
        { label: 'Diamante do Sul', value: 'Diamante do Sul' },
        { label: 'Dois Vizinhos', value: 'Dois Vizinhos' },
        { label: 'Douradina', value: 'Douradina' },
        { label: 'Doutor Camargo', value: 'Doutor Camargo' },
        { label: 'Doutor Ulysses', value: 'Doutor Ulysses' },
        { label: 'Enéas Marques', value: 'Enéas Marques' },
        { label: 'Engenheiro Beltrão', value: 'Engenheiro Beltrão' },
        { label: 'Entre Rios do Oeste', value: 'Entre Rios do Oeste' },
        { label: 'Esperança Nova', value: 'Esperança Nova' },
        { label: 'Espigão Alto do Iguaçu', value: 'Espigão Alto do Iguaçu' },
        { label: 'Farol', value: 'Farol' },
        { label: 'Faxinal', value: 'Faxinal' },
        { label: 'Fazenda Rio Grande', value: 'Fazenda Rio Grande' },
        { label: 'Fênix', value: 'Fênix' },
        { label: 'Fernandes Pinheiro', value: 'Fernandes Pinheiro' },
        { label: 'Figueira', value: 'Figueira' },
        { label: 'Flor da Serra do Sul', value: 'Flor da Serra do Sul' },
        { label: 'Floraí', value: 'Floraí' },
        { label: 'Floresta', value: 'Floresta' },
        { label: 'Florestópolis', value: 'Florestópolis' },
        { label: 'Flórida', value: 'Flórida' },
        { label: 'Formosa do Oeste', value: 'Formosa do Oeste' },
        { label: 'Foz do Iguaçu', value: 'Foz do Iguaçu' },
        { label: 'Foz do Jordão', value: 'Foz do Jordão' },
        { label: 'Francisco Alves', value: 'Francisco Alves' },
        { label: 'Francisco Beltrão', value: 'Francisco Beltrão' },
        { label: 'General Carneiro', value: 'General Carneiro' },
        { label: 'Godoy Moreira', value: 'Godoy Moreira' },
        { label: 'Goioerê', value: 'Goioerê' },
        { label: 'Goioxim', value: 'Goioxim' },
        { label: 'Grandes Rios', value: 'Grandes Rios' },
        { label: 'Guaíra', value: 'Guaíra' },
        { label: 'Guairaçá', value: 'Guairaçá' },
        { label: 'Guamiranga', value: 'Guamiranga' },
        { label: 'Guapirama', value: 'Guapirama' },
        { label: 'Guaporema', value: 'Guaporema' },
        { label: 'Guaraci', value: 'Guaraci' },
        { label: 'Guaraniaçu', value: 'Guaraniaçu' },
        { label: 'Guarapuava', value: 'Guarapuava' },
        { label: 'Guaraqueçaba', value: 'Guaraqueçaba' },
        { label: 'Guaratuba', value: 'Guaratuba' },
        { label: 'Honório Serpa', value: 'Honório Serpa' },
        { label: 'Ibaiti', value: 'Ibaiti' },
        { label: 'Ibema', value: 'Ibema' },
        { label: 'Ibiporã', value: 'Ibiporã' },
        { label: 'Icaraíma', value: 'Icaraíma' },
        { label: 'Iguaraçu', value: 'Iguaraçu' },
        { label: 'Iguatu', value: 'Iguatu' },
        { label: 'Imbaú', value: 'Imbaú' },
        { label: 'Imbituva', value: 'Imbituva' },
        { label: 'Inácio Martins', value: 'Inácio Martins' },
        { label: 'Inajá', value: 'Inajá' },
        { label: 'Indianópolis', value: 'Indianópolis' },
        { label: 'Ipiranga', value: 'Ipiranga' },
        { label: 'Iporã', value: 'Iporã' },
        { label: 'Iracema do Oeste', value: 'Iracema do Oeste' },
        { label: 'Irati', value: 'Irati' },
        { label: 'Iretama', value: 'Iretama' },
        { label: 'Itaguajé', value: 'Itaguajé' },
        { label: 'Itaipulândia', value: 'Itaipulândia' },
        { label: 'Itambaracá', value: 'Itambaracá' },
        { label: 'Itambé', value: 'Itambé' },
        "Itapejara d'Oeste",
        { label: 'Itaperuçu', value: 'Itaperuçu' },
        { label: 'Itaúna do Sul', value: 'Itaúna do Sul' },
        { label: 'Ivaí', value: 'Ivaí' },
        { label: 'Ivaiporã', value: 'Ivaiporã' },
        { label: 'Ivaté', value: 'Ivaté' },
        { label: 'Ivatuba', value: 'Ivatuba' },
        { label: 'Jaboti', value: 'Jaboti' },
        { label: 'Jacarezinho', value: 'Jacarezinho' },
        { label: 'Jaguapitã', value: 'Jaguapitã' },
        { label: 'Jaguariaíva', value: 'Jaguariaíva' },
        { label: 'Jandaia do Sul', value: 'Jandaia do Sul' },
        { label: 'Janiópolis', value: 'Janiópolis' },
        { label: 'Japira', value: 'Japira' },
        { label: 'Japurá', value: 'Japurá' },
        { label: 'Jardim Alegre', value: 'Jardim Alegre' },
        { label: 'Jardim Olinda', value: 'Jardim Olinda' },
        { label: 'Jataizinho', value: 'Jataizinho' },
        { label: 'Jesuítas', value: 'Jesuítas' },
        { label: 'Joaquim Távora', value: 'Joaquim Távora' },
        { label: 'Jundiaí do Sul', value: 'Jundiaí do Sul' },
        { label: 'Juranda', value: 'Juranda' },
        { label: 'Jussara', value: 'Jussara' },
        { label: 'Kaloré', value: 'Kaloré' },
        { label: 'Lapa', value: 'Lapa' },
        { label: 'Laranjal', value: 'Laranjal' },
        { label: 'Laranjeiras do Sul', value: 'Laranjeiras do Sul' },
        { label: 'Leópolis', value: 'Leópolis' },
        { label: 'Lidianópolis', value: 'Lidianópolis' },
        { label: 'Lindoeste', value: 'Lindoeste' },
        { label: 'Loanda', value: 'Loanda' },
        { label: 'Lobato', value: 'Lobato' },
        { label: 'Londrina', value: 'Londrina' },
        { label: 'Luiziana', value: 'Luiziana' },
        { label: 'Lunardelli', value: 'Lunardelli' },
        { label: 'Lupionópolis', value: 'Lupionópolis' },
        { label: 'Mallet', value: 'Mallet' },
        { label: 'Mamborê', value: 'Mamborê' },
        { label: 'Mandaguaçu', value: 'Mandaguaçu' },
        { label: 'Mandaguari', value: 'Mandaguari' },
        { label: 'Mandirituba', value: 'Mandirituba' },
        { label: 'Manfrinópolis', value: 'Manfrinópolis' },
        { label: 'Mangueirinha', value: 'Mangueirinha' },
        { label: 'Manoel Ribas', value: 'Manoel Ribas' },
        { label: 'Marechal Cândido Rondon', value: 'Marechal Cândido Rondon' },
        { label: 'Maria Helena', value: 'Maria Helena' },
        { label: 'Marialva', value: 'Marialva' },
        { label: 'Marilândia do Sul', value: 'Marilândia do Sul' },
        { label: 'Marilena', value: 'Marilena' },
        { label: 'Mariluz', value: 'Mariluz' },
        { label: 'Maringá', value: 'Maringá' },
        { label: 'Mariópolis', value: 'Mariópolis' },
        { label: 'Maripá', value: 'Maripá' },
        { label: 'Marmeleiro', value: 'Marmeleiro' },
        { label: 'Marquinho', value: 'Marquinho' },
        { label: 'Marumbi', value: 'Marumbi' },
        { label: 'Matelândia', value: 'Matelândia' },
        { label: 'Matinhos', value: 'Matinhos' },
        { label: 'Mato Rico', value: 'Mato Rico' },
        { label: 'Mauá da Serra', value: 'Mauá da Serra' },
        { label: 'Medianeira', value: 'Medianeira' },
        { label: 'Mercedes', value: 'Mercedes' },
        { label: 'Mirador', value: 'Mirador' },
        { label: 'Miraselva', value: 'Miraselva' },
        { label: 'Missal', value: 'Missal' },
        { label: 'Moreira Sales', value: 'Moreira Sales' },
        { label: 'Morretes', value: 'Morretes' },
        { label: 'Munhoz de Melo', value: 'Munhoz de Melo' },
        { label: 'Nossa Senhora das Graças', value: 'Nossa Senhora das Graças' },
        { label: 'Nova Aliança do Ivaí', value: 'Nova Aliança do Ivaí' },
        { label: 'Nova América da Colina', value: 'Nova América da Colina' },
        { label: 'Nova Aurora', value: 'Nova Aurora' },
        { label: 'Nova Cantu', value: 'Nova Cantu' },
        { label: 'Nova Esperança', value: 'Nova Esperança' },
        { label: 'Nova Esperança do Sudoeste', value: 'Nova Esperança do Sudoeste' },
        { label: 'Nova Fátima', value: 'Nova Fátima' },
        { label: 'Nova Laranjeiras', value: 'Nova Laranjeiras' },
        { label: 'Nova Londrina', value: 'Nova Londrina' },
        { label: 'Nova Olímpia', value: 'Nova Olímpia' },
        { label: 'Nova Prata do Iguaçu', value: 'Nova Prata do Iguaçu' },
        { label: 'Nova Santa Bárbara', value: 'Nova Santa Bárbara' },
        { label: 'Nova Santa Rosa', value: 'Nova Santa Rosa' },
        { label: 'Nova Tebas', value: 'Nova Tebas' },
        { label: 'Novo Itacolomi', value: 'Novo Itacolomi' },
        { label: 'Ortigueira', value: 'Ortigueira' },
        { label: 'Ourizona', value: 'Ourizona' },
        { label: 'Ouro Verde do Oeste', value: 'Ouro Verde do Oeste' },
        { label: 'Paiçandu', value: 'Paiçandu' },
        { label: 'Palmas', value: 'Palmas' },
        { label: 'Palmeira', value: 'Palmeira' },
        { label: 'Palmital', value: 'Palmital' },
        { label: 'Palotina', value: 'Palotina' },
        { label: 'Paraíso do Norte', value: 'Paraíso do Norte' },
        { label: 'Paranacity', value: 'Paranacity' },
        { label: 'Paranaguá', value: 'Paranaguá' },
        { label: 'Paranapoema', value: 'Paranapoema' },
        { label: 'Paranavaí', value: 'Paranavaí' },
        { label: 'Pato Bragado', value: 'Pato Bragado' },
        { label: 'Pato Branco', value: 'Pato Branco' },
        { label: 'Paula Freitas', value: 'Paula Freitas' },
        { label: 'Paulo Frontin', value: 'Paulo Frontin' },
        { label: 'Peabiru', value: 'Peabiru' },
        { label: 'Perobal', value: 'Perobal' },
        { label: 'Pérola', value: 'Pérola' },
        "Pérola d'Oeste",
        { label: 'Piên', value: 'Piên' },
        { label: 'Pinhais', value: 'Pinhais' },
        { label: 'Pinhal de São Bento', value: 'Pinhal de São Bento' },
        { label: 'Pinhalão', value: 'Pinhalão' },
        { label: 'Pinhão', value: 'Pinhão' },
        { label: 'Piraí do Sul', value: 'Piraí do Sul' },
        { label: 'Piraquara', value: 'Piraquara' },
        { label: 'Pitanga', value: 'Pitanga' },
        { label: 'Pitangueiras', value: 'Pitangueiras' },
        { label: 'Planaltina do Paraná', value: 'Planaltina do Paraná' },
        { label: 'Planalto', value: 'Planalto' },
        { label: 'Ponta Grossa', value: 'Ponta Grossa' },
        { label: 'Pontal do Paraná', value: 'Pontal do Paraná' },
        { label: 'Porecatu', value: 'Porecatu' },
        { label: 'Porto Amazonas', value: 'Porto Amazonas' },
        { label: 'Porto Barreiro', value: 'Porto Barreiro' },
        { label: 'Porto Rico', value: 'Porto Rico' },
        { label: 'Porto Vitória', value: 'Porto Vitória' },
        { label: 'Prado Ferreira', value: 'Prado Ferreira' },
        { label: 'Pranchita', value: 'Pranchita' },
        { label: 'Presidente Castelo Branco', value: 'Presidente Castelo Branco' },
        { label: 'Primeiro de Maio', value: 'Primeiro de Maio' },
        { label: 'Prudentópolis', value: 'Prudentópolis' },
        { label: 'Quarto Centenário', value: 'Quarto Centenário' },
        { label: 'Quatiguá', value: 'Quatiguá' },
        { label: 'Quatro Barras', value: 'Quatro Barras' },
        { label: 'Quatro Pontes', value: 'Quatro Pontes' },
        { label: 'Quedas do Iguaçu', value: 'Quedas do Iguaçu' },
        { label: 'Querência do Norte', value: 'Querência do Norte' },
        { label: 'Quinta do Sol', value: 'Quinta do Sol' },
        { label: 'Quitandinha', value: 'Quitandinha' },
        { label: 'Ramilândia', value: 'Ramilândia' },
        { label: 'Rancho Alegre', value: 'Rancho Alegre' },
        "Rancho Alegre D'Oeste",
        { label: 'Realeza', value: 'Realeza' },
        { label: 'Rebouças', value: 'Rebouças' },
        { label: 'Renascença', value: 'Renascença' },
        { label: 'Reserva', value: 'Reserva' },
        { label: 'Reserva do Iguaçu', value: 'Reserva do Iguaçu' },
        { label: 'Ribeirão Claro', value: 'Ribeirão Claro' },
        { label: 'Ribeirão do Pinhal', value: 'Ribeirão do Pinhal' },
        { label: 'Rio Azul', value: 'Rio Azul' },
        { label: 'Rio Bom', value: 'Rio Bom' },
        { label: 'Rio Bonito do Iguaçu', value: 'Rio Bonito do Iguaçu' },
        { label: 'Rio Branco do Ivaí', value: 'Rio Branco do Ivaí' },
        { label: 'Rio Branco do Sul', value: 'Rio Branco do Sul' },
        { label: 'Rio Negro', value: 'Rio Negro' },
        { label: 'Rolândia', value: 'Rolândia' },
        { label: 'Roncador', value: 'Roncador' },
        { label: 'Rondon', value: 'Rondon' },
        { label: 'Rosário do Ivaí', value: 'Rosário do Ivaí' },
        { label: 'Sabáudia', value: 'Sabáudia' },
        { label: 'Salgado Filho', value: 'Salgado Filho' },
        { label: 'Salto do Itararé', value: 'Salto do Itararé' },
        { label: 'Salto do Lontra', value: 'Salto do Lontra' },
        { label: 'Santa Amélia', value: 'Santa Amélia' },
        { label: 'Santa Cecília do Pavão', value: 'Santa Cecília do Pavão' },
        { label: 'Santa Cruz de Monte Castelo', value: 'Santa Cruz de Monte Castelo' },
        { label: 'Santa Fé', value: 'Santa Fé' },
        { label: 'Santa Helena', value: 'Santa Helena' },
        { label: 'Santa Inês', value: 'Santa Inês' },
        { label: 'Santa Isabel do Ivaí', value: 'Santa Isabel do Ivaí' },
        { label: 'Santa Izabel do Oeste', value: 'Santa Izabel do Oeste' },
        { label: 'Santa Lúcia', value: 'Santa Lúcia' },
        { label: 'Santa Maria do Oeste', value: 'Santa Maria do Oeste' },
        { label: 'Santa Mariana', value: 'Santa Mariana' },
        { label: 'Santa Mônica', value: 'Santa Mônica' },
        { label: 'Santa Tereza do Oeste', value: 'Santa Tereza do Oeste' },
        { label: 'Santa Terezinha de Itaipu', value: 'Santa Terezinha de Itaipu' },
        { label: 'Santana do Itararé', value: 'Santana do Itararé' },
        { label: 'Santo Antônio da Platina', value: 'Santo Antônio da Platina' },
        { label: 'Santo Antônio do Caiuá', value: 'Santo Antônio do Caiuá' },
        { label: 'Santo Antônio do Paraíso', value: 'Santo Antônio do Paraíso' },
        { label: 'Santo Antônio do Sudoeste', value: 'Santo Antônio do Sudoeste' },
        { label: 'Santo Inácio', value: 'Santo Inácio' },
        { label: 'São Carlos do Ivaí', value: 'São Carlos do Ivaí' },
        { label: 'São Jerônimo da Serra', value: 'São Jerônimo da Serra' },
        { label: 'São João', value: 'São João' },
        { label: 'São João do Caiuá', value: 'São João do Caiuá' },
        { label: 'São João do Ivaí', value: 'São João do Ivaí' },
        { label: 'São João do Triunfo', value: 'São João do Triunfo' },
        "São Jorge d'Oeste",
        { label: 'São Jorge do Ivaí', value: 'São Jorge do Ivaí' },
        { label: 'São Jorge do Patrocínio', value: 'São Jorge do Patrocínio' },
        { label: 'São José da Boa Vista', value: 'São José da Boa Vista' },
        { label: 'São José das Palmeiras', value: 'São José das Palmeiras' },
        { label: 'São José dos Pinhais', value: 'São José dos Pinhais' },
        { label: 'São Manoel do Paraná', value: 'São Manoel do Paraná' },
        { label: 'São Mateus do Sul', value: 'São Mateus do Sul' },
        { label: 'São Miguel do Iguaçu', value: 'São Miguel do Iguaçu' },
        { label: 'São Pedro do Iguaçu', value: 'São Pedro do Iguaçu' },
        { label: 'São Pedro do Ivaí', value: 'São Pedro do Ivaí' },
        { label: 'São Pedro do Paraná', value: 'São Pedro do Paraná' },
        { label: 'São Sebastião da Amoreira', value: 'São Sebastião da Amoreira' },
        { label: 'São Tomé', value: 'São Tomé' },
        { label: 'Sapopema', value: 'Sapopema' },
        { label: 'Sarandi', value: 'Sarandi' },
        { label: 'Saudade do Iguaçu', value: 'Saudade do Iguaçu' },
        { label: 'Sengés', value: 'Sengés' },
        { label: 'Serranópolis do Iguaçu', value: 'Serranópolis do Iguaçu' },
        { label: 'Sertaneja', value: 'Sertaneja' },
        { label: 'Sertanópolis', value: 'Sertanópolis' },
        { label: 'Siqueira Campos', value: 'Siqueira Campos' },
        { label: 'Sulina', value: 'Sulina' },
        { label: 'Tamarana', value: 'Tamarana' },
        { label: 'Tamboara', value: 'Tamboara' },
        { label: 'Tapejara', value: 'Tapejara' },
        { label: 'Tapira', value: 'Tapira' },
        { label: 'Teixeira Soares', value: 'Teixeira Soares' },
        { label: 'Telêmaco Borba', value: 'Telêmaco Borba' },
        { label: 'Terra Boa', value: 'Terra Boa' },
        { label: 'Terra Rica', value: 'Terra Rica' },
        { label: 'Terra Roxa', value: 'Terra Roxa' },
        { label: 'Tibagi', value: 'Tibagi' },
        { label: 'Tijucas do Sul', value: 'Tijucas do Sul' },
        { label: 'Toledo', value: 'Toledo' },
        { label: 'Tomazina', value: 'Tomazina' },
        { label: 'Três Barras do Paraná', value: 'Três Barras do Paraná' },
        { label: 'Tunas do Paraná', value: 'Tunas do Paraná' },
        { label: 'Tuneiras do Oeste', value: 'Tuneiras do Oeste' },
        { label: 'Tupãssi', value: 'Tupãssi' },
        { label: 'Turvo', value: 'Turvo' },
        { label: 'Ubiratã', value: 'Ubiratã' },
        { label: 'Umuarama', value: 'Umuarama' },
        { label: 'União da Vitória', value: 'União da Vitória' },
        { label: 'Uniflor', value: 'Uniflor' },
        { label: 'Uraí', value: 'Uraí' },
        { label: 'Ventania', value: 'Ventania' },
        { label: 'Vera Cruz do Oeste', value: 'Vera Cruz do Oeste' },
        { label: 'Verê', value: 'Verê' },
        { label: 'Virmond', value: 'Virmond' },
        { label: 'Vitorino', value: 'Vitorino' },
        { label: 'Wenceslau Braz', value: 'Wenceslau Braz' },
        { label: 'Xambrê', value: 'Xambrê' },
    ],
    RJ: [
        { label: 'Angra dos Reis', value: 'Angra dos Reis' },
        { label: 'Aperibé', value: 'Aperibé' },
        { label: 'Araruama', value: 'Araruama' },
        { label: 'Areal', value: 'Areal' },
        { label: 'Armação dos Búzios', value: 'Armação dos Búzios' },
        { label: 'Arraial do Cabo', value: 'Arraial do Cabo' },
        { label: 'Barra do Piraí', value: 'Barra do Piraí' },
        { label: 'Barra Mansa', value: 'Barra Mansa' },
        { label: 'Belford Roxo', value: 'Belford Roxo' },
        { label: 'Bom Jardim', value: 'Bom Jardim' },
        { label: 'Bom Jesus do Itabapoana', value: 'Bom Jesus do Itabapoana' },
        { label: 'Cabo Frio', value: 'Cabo Frio' },
        { label: 'Cachoeiras de Macacu', value: 'Cachoeiras de Macacu' },
        { label: 'Cambuci', value: 'Cambuci' },
        { label: 'Campos dos Goytacazes', value: 'Campos dos Goytacazes' },
        { label: 'Cantagalo', value: 'Cantagalo' },
        { label: 'Carapebus', value: 'Carapebus' },
        { label: 'Cardoso Moreira', value: 'Cardoso Moreira' },
        { label: 'Carmo', value: 'Carmo' },
        { label: 'Casimiro de Abreu', value: 'Casimiro de Abreu' },
        { label: 'Comendador Levy Gasparian', value: 'Comendador Levy Gasparian' },
        { label: 'Conceição de Macabu', value: 'Conceição de Macabu' },
        { label: 'Cordeiro', value: 'Cordeiro' },
        { label: 'Duas Barras', value: 'Duas Barras' },
        { label: 'Duque de Caxias', value: 'Duque de Caxias' },
        { label: 'Engenheiro Paulo de Frontin', value: 'Engenheiro Paulo de Frontin' },
        { label: 'Guapimirim', value: 'Guapimirim' },
        { label: 'Iguaba Grande', value: 'Iguaba Grande' },
        { label: 'Itaboraí', value: 'Itaboraí' },
        { label: 'Itaguaí', value: 'Itaguaí' },
        { label: 'Italva', value: 'Italva' },
        { label: 'Itaocara', value: 'Itaocara' },
        { label: 'Itaperuna', value: 'Itaperuna' },
        { label: 'Itatiaia', value: 'Itatiaia' },
        { label: 'Japeri', value: 'Japeri' },
        { label: 'Laje do Muriaé', value: 'Laje do Muriaé' },
        { label: 'Macaé', value: 'Macaé' },
        { label: 'Macuco', value: 'Macuco' },
        { label: 'Magé', value: 'Magé' },
        { label: 'Mangaratiba', value: 'Mangaratiba' },
        { label: 'Maricá', value: 'Maricá' },
        { label: 'Mendes', value: 'Mendes' },
        { label: 'Mesquita', value: 'Mesquita' },
        { label: 'Miguel Pereira', value: 'Miguel Pereira' },
        { label: 'Miracema', value: 'Miracema' },
        { label: 'Natividade', value: 'Natividade' },
        { label: 'Nilópolis', value: 'Nilópolis' },
        { label: 'Niterói', value: 'Niterói' },
        { label: 'Nova Friburgo', value: 'Nova Friburgo' },
        { label: 'Nova Iguaçu', value: 'Nova Iguaçu' },
        { label: 'Paracambi', value: 'Paracambi' },
        { label: 'Paraíba do Sul', value: 'Paraíba do Sul' },
        { label: 'Paraty', value: 'Paraty' },
        { label: 'Paty do Alferes', value: 'Paty do Alferes' },
        { label: 'Petrópolis', value: 'Petrópolis' },
        { label: 'Pinheiral', value: 'Pinheiral' },
        { label: 'Piraí', value: 'Piraí' },
        { label: 'Porciúncula', value: 'Porciúncula' },
        { label: 'Porto Real', value: 'Porto Real' },
        { label: 'Quatis', value: 'Quatis' },
        { label: 'Queimados', value: 'Queimados' },
        { label: 'Quissamã', value: 'Quissamã' },
        { label: 'Resende', value: 'Resende' },
        { label: 'Rio Bonito', value: 'Rio Bonito' },
        { label: 'Rio Claro', value: 'Rio Claro' },
        { label: 'Rio das Flores', value: 'Rio das Flores' },
        { label: 'Rio das Ostras', value: 'Rio das Ostras' },
        { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
        { label: 'Santa Maria Madalena', value: 'Santa Maria Madalena' },
        { label: 'Santo Antônio de Pádua', value: 'Santo Antônio de Pádua' },
        { label: 'São Fidélis', value: 'São Fidélis' },
        { label: 'São Francisco de Itabapoana', value: 'São Francisco de Itabapoana' },
        { label: 'São Gonçalo', value: 'São Gonçalo' },
        { label: 'São João da Barra', value: 'São João da Barra' },
        { label: 'São João de Meriti', value: 'São João de Meriti' },
        { label: 'São José de Ubá', value: 'São José de Ubá' },
        { label: 'São José do Vale do Rio Preto', value: 'São José do Vale do Rio Preto' },
        { label: 'São Pedro da Aldeia', value: 'São Pedro da Aldeia' },
        { label: 'São Sebastião do Alto', value: 'São Sebastião do Alto' },
        { label: 'Sapucaia', value: 'Sapucaia' },
        { label: 'Saquarema', value: 'Saquarema' },
        { label: 'Seropédica', value: 'Seropédica' },
        { label: 'Silva Jardim', value: 'Silva Jardim' },
        { label: 'Sumidouro', value: 'Sumidouro' },
        { label: 'Tanguá', value: 'Tanguá' },
        { label: 'Teresópolis', value: 'Teresópolis' },
        { label: 'Trajano de Moraes', value: 'Trajano de Moraes' },
        { label: 'Três Rios', value: 'Três Rios' },
        { label: 'Valença', value: 'Valença' },
        { label: 'Varre-Sai', value: 'Varre-Sai' },
        { label: 'Vassouras', value: 'Vassouras' },
        { label: 'Volta Redonda', value: 'Volta Redonda' },
    ],
    RN: [
        { label: 'Acari', value: 'Acari' },
        { label: 'Açu', value: 'Açu' },
        { label: 'Afonso Bezerra', value: 'Afonso Bezerra' },
        { label: 'Água Nova', value: 'Água Nova' },
        { label: 'Alexandria', value: 'Alexandria' },
        { label: 'Almino Afonso', value: 'Almino Afonso' },
        { label: 'Alto do Rodrigues', value: 'Alto do Rodrigues' },
        { label: 'Angicos', value: 'Angicos' },
        { label: 'Antônio Martins', value: 'Antônio Martins' },
        { label: 'Apodi', value: 'Apodi' },
        { label: 'Areia Branca', value: 'Areia Branca' },
        { label: 'Arês', value: 'Arês' },
        { label: 'Augusto Severo (Campo Grande)', value: 'Augusto Severo (Campo Grande)' },
        { label: 'Baía Formosa', value: 'Baía Formosa' },
        { label: 'Baraúna', value: 'Baraúna' },
        { label: 'Barcelona', value: 'Barcelona' },
        { label: 'Bento Fernandes', value: 'Bento Fernandes' },
        { label: 'Bodó', value: 'Bodó' },
        { label: 'Bom Jesus', value: 'Bom Jesus' },
        { label: 'Brejinho', value: 'Brejinho' },
        { label: 'Caiçara do Norte', value: 'Caiçara do Norte' },
        { label: 'Caiçara do Rio do Vento', value: 'Caiçara do Rio do Vento' },
        { label: 'Caicó', value: 'Caicó' },
        { label: 'Campo Redondo', value: 'Campo Redondo' },
        { label: 'Canguaretama', value: 'Canguaretama' },
        { label: 'Caraúbas', value: 'Caraúbas' },
        { label: 'Carnaúba dos Dantas', value: 'Carnaúba dos Dantas' },
        { label: 'Carnaubais', value: 'Carnaubais' },
        { label: 'Ceará-Mirim', value: 'Ceará-Mirim' },
        { label: 'Cerro Corá', value: 'Cerro Corá' },
        { label: 'Coronel Ezequiel', value: 'Coronel Ezequiel' },
        { label: 'Coronel João Pessoa', value: 'Coronel João Pessoa' },
        { label: 'Cruzeta', value: 'Cruzeta' },
        { label: 'Currais Novos', value: 'Currais Novos' },
        { label: 'Doutor Severiano', value: 'Doutor Severiano' },
        { label: 'Encanto', value: 'Encanto' },
        { label: 'Equador', value: 'Equador' },
        { label: 'Espírito Santo', value: 'Espírito Santo' },
        { label: 'Extremoz', value: 'Extremoz' },
        { label: 'Felipe Guerra', value: 'Felipe Guerra' },
        { label: 'Fernando Pedroza', value: 'Fernando Pedroza' },
        { label: 'Florânia', value: 'Florânia' },
        { label: 'Francisco Dantas', value: 'Francisco Dantas' },
        { label: 'Frutuoso Gomes', value: 'Frutuoso Gomes' },
        { label: 'Galinhos', value: 'Galinhos' },
        { label: 'Goianinha', value: 'Goianinha' },
        { label: 'Governador Dix-Sept Rosado', value: 'Governador Dix-Sept Rosado' },
        { label: 'Grossos', value: 'Grossos' },
        { label: 'Guamaré', value: 'Guamaré' },
        { label: 'Ielmo Marinho', value: 'Ielmo Marinho' },
        { label: 'Ipanguaçu', value: 'Ipanguaçu' },
        { label: 'Ipueira', value: 'Ipueira' },
        { label: 'Itajá', value: 'Itajá' },
        { label: 'Itaú', value: 'Itaú' },
        { label: 'Jaçanã', value: 'Jaçanã' },
        { label: 'Jandaíra', value: 'Jandaíra' },
        { label: 'Janduís', value: 'Janduís' },
        { label: 'Januário Cicco (Boa Saúde)', value: 'Januário Cicco (Boa Saúde)' },
        { label: 'Japi', value: 'Japi' },
        { label: 'Jardim de Angicos', value: 'Jardim de Angicos' },
        { label: 'Jardim de Piranhas', value: 'Jardim de Piranhas' },
        { label: 'Jardim do Seridó', value: 'Jardim do Seridó' },
        { label: 'João Câmara', value: 'João Câmara' },
        { label: 'João Dias', value: 'João Dias' },
        { label: 'José da Penha', value: 'José da Penha' },
        { label: 'Jucurutu', value: 'Jucurutu' },
        { label: 'Jundiá', value: 'Jundiá' },
        "Lagoa d'Anta",
        { label: 'Lagoa de Pedras', value: 'Lagoa de Pedras' },
        { label: 'Lagoa de Velhos', value: 'Lagoa de Velhos' },
        { label: 'Lagoa Nova', value: 'Lagoa Nova' },
        { label: 'Lagoa Salgada', value: 'Lagoa Salgada' },
        { label: 'Lajes', value: 'Lajes' },
        { label: 'Lajes Pintadas', value: 'Lajes Pintadas' },
        { label: 'Lucrécia', value: 'Lucrécia' },
        { label: 'Luís Gomes', value: 'Luís Gomes' },
        { label: 'Macaíba', value: 'Macaíba' },
        { label: 'Macau', value: 'Macau' },
        { label: 'Major Sales', value: 'Major Sales' },
        { label: 'Marcelino Vieira', value: 'Marcelino Vieira' },
        { label: 'Martins', value: 'Martins' },
        { label: 'Maxaranguape', value: 'Maxaranguape' },
        { label: 'Messias Targino', value: 'Messias Targino' },
        { label: 'Montanhas', value: 'Montanhas' },
        { label: 'Monte Alegre', value: 'Monte Alegre' },
        { label: 'Monte das Gameleiras', value: 'Monte das Gameleiras' },
        { label: 'Mossoró', value: 'Mossoró' },
        { label: 'Natal', value: 'Natal' },
        { label: 'Nísia Floresta', value: 'Nísia Floresta' },
        { label: 'Nova Cruz', value: 'Nova Cruz' },
        "Olho-d'Água do Borges",
        { label: 'Ouro Branco', value: 'Ouro Branco' },
        { label: 'Paraná', value: 'Paraná' },
        { label: 'Paraú', value: 'Paraú' },
        { label: 'Parazinho', value: 'Parazinho' },
        { label: 'Parelhas', value: 'Parelhas' },
        { label: 'Parnamirim', value: 'Parnamirim' },
        { label: 'Passa e Fica', value: 'Passa e Fica' },
        { label: 'Passagem', value: 'Passagem' },
        { label: 'Patu', value: 'Patu' },
        { label: 'Pau dos Ferros', value: 'Pau dos Ferros' },
        { label: 'Pedra Grande', value: 'Pedra Grande' },
        { label: 'Pedra Preta', value: 'Pedra Preta' },
        { label: 'Pedro Avelino', value: 'Pedro Avelino' },
        { label: 'Pedro Velho', value: 'Pedro Velho' },
        { label: 'Pendências', value: 'Pendências' },
        { label: 'Pilões', value: 'Pilões' },
        { label: 'Poço Branco', value: 'Poço Branco' },
        { label: 'Portalegre', value: 'Portalegre' },
        { label: 'Porto do Mangue', value: 'Porto do Mangue' },
        { label: 'Pureza', value: 'Pureza' },
        { label: 'Rafael Fernandes', value: 'Rafael Fernandes' },
        { label: 'Rafael Godeiro', value: 'Rafael Godeiro' },
        { label: 'Riacho da Cruz', value: 'Riacho da Cruz' },
        { label: 'Riacho de Santana', value: 'Riacho de Santana' },
        { label: 'Riachuelo', value: 'Riachuelo' },
        { label: 'Rio do Fogo', value: 'Rio do Fogo' },
        { label: 'Rodolfo Fernandes', value: 'Rodolfo Fernandes' },
        { label: 'Ruy Barbosa', value: 'Ruy Barbosa' },
        { label: 'Santa Cruz', value: 'Santa Cruz' },
        { label: 'Santa Maria', value: 'Santa Maria' },
        { label: 'Santana do Matos', value: 'Santana do Matos' },
        { label: 'Santana do Seridó', value: 'Santana do Seridó' },
        { label: 'Santo Antônio', value: 'Santo Antônio' },
        { label: 'São Bento do Norte', value: 'São Bento do Norte' },
        { label: 'São Bento do Trairí', value: 'São Bento do Trairí' },
        { label: 'São Fernando', value: 'São Fernando' },
        { label: 'São Francisco do Oeste', value: 'São Francisco do Oeste' },
        { label: 'São Gonçalo do Amarante', value: 'São Gonçalo do Amarante' },
        { label: 'São João do Sabugi', value: 'São João do Sabugi' },
        { label: 'São José de Mipibu', value: 'São José de Mipibu' },
        { label: 'São José do Campestre', value: 'São José do Campestre' },
        { label: 'São José do Seridó', value: 'São José do Seridó' },
        { label: 'São Miguel', value: 'São Miguel' },
        { label: 'São Miguel do Gostoso', value: 'São Miguel do Gostoso' },
        { label: 'São Paulo do Potengi', value: 'São Paulo do Potengi' },
        { label: 'São Pedro', value: 'São Pedro' },
        { label: 'São Rafael', value: 'São Rafael' },
        { label: 'São Tomé', value: 'São Tomé' },
        { label: 'São Vicente', value: 'São Vicente' },
        { label: 'Senador Elói de Souza', value: 'Senador Elói de Souza' },
        { label: 'Senador Georgino Avelino', value: 'Senador Georgino Avelino' },
        { label: 'Serra Caiada', value: 'Serra Caiada' },
        { label: 'Serra de São Bento', value: 'Serra de São Bento' },
        { label: 'Serra do Mel', value: 'Serra do Mel' },
        { label: 'Serra Negra do Norte', value: 'Serra Negra do Norte' },
        { label: 'Serrinha', value: 'Serrinha' },
        { label: 'Serrinha dos Pintos', value: 'Serrinha dos Pintos' },
        { label: 'Severiano Melo', value: 'Severiano Melo' },
        { label: 'Sítio Novo', value: 'Sítio Novo' },
        { label: 'Taboleiro Grande', value: 'Taboleiro Grande' },
        { label: 'Taipu', value: 'Taipu' },
        { label: 'Tangará', value: 'Tangará' },
        { label: 'Tenente Ananias', value: 'Tenente Ananias' },
        { label: 'Tenente Laurentino Cruz', value: 'Tenente Laurentino Cruz' },
        { label: 'Tibau', value: 'Tibau' },
        { label: 'Tibau do Sul', value: 'Tibau do Sul' },
        { label: 'Timbaúba dos Batistas', value: 'Timbaúba dos Batistas' },
        { label: 'Touros', value: 'Touros' },
        { label: 'Triunfo Potiguar', value: 'Triunfo Potiguar' },
        { label: 'Umarizal', value: 'Umarizal' },
        { label: 'Upanema', value: 'Upanema' },
        { label: 'Várzea', value: 'Várzea' },
        { label: 'Venha-Ver', value: 'Venha-Ver' },
        { label: 'Vera Cruz', value: 'Vera Cruz' },
        { label: 'Viçosa', value: 'Viçosa' },
        { label: 'Vila Flor', value: 'Vila Flor' },
    ],
    RO: [
        "Alta Floresta D'Oeste",
        { label: 'Alto Alegre dos Parecis', value: 'Alto Alegre dos Parecis' },
        { label: 'Alto Paraíso', value: 'Alto Paraíso' },
        "Alvorada D'Oeste",
        { label: 'Ariquemes', value: 'Ariquemes' },
        { label: 'Buritis', value: 'Buritis' },
        { label: 'Cabixi', value: 'Cabixi' },
        { label: 'Cacaulândia', value: 'Cacaulândia' },
        { label: 'Cacoal', value: 'Cacoal' },
        { label: 'Campo Novo de Rondônia', value: 'Campo Novo de Rondônia' },
        { label: 'Candeias do Jamari', value: 'Candeias do Jamari' },
        { label: 'Castanheiras', value: 'Castanheiras' },
        { label: 'Cerejeiras', value: 'Cerejeiras' },
        { label: 'Chupinguaia', value: 'Chupinguaia' },
        { label: 'Colorado do Oeste', value: 'Colorado do Oeste' },
        { label: 'Corumbiara', value: 'Corumbiara' },
        { label: 'Costa Marques', value: 'Costa Marques' },
        { label: 'Cujubim', value: 'Cujubim' },
        "Espigão D'Oeste",
        { label: 'Governador Jorge Teixeira', value: 'Governador Jorge Teixeira' },
        { label: 'Guajará-Mirim', value: 'Guajará-Mirim' },
        { label: 'Itapuã do Oeste', value: 'Itapuã do Oeste' },
        { label: 'Jaru', value: 'Jaru' },
        { label: 'Ji-Paraná', value: 'Ji-Paraná' },
        "Machadinho D'Oeste",
        { label: 'Ministro Andreazza', value: 'Ministro Andreazza' },
        { label: 'Mirante da Serra', value: 'Mirante da Serra' },
        { label: 'Monte Negro', value: 'Monte Negro' },
        "Nova Brasilândia D'Oeste",
        { label: 'Nova Mamoré', value: 'Nova Mamoré' },
        { label: 'Nova União', value: 'Nova União' },
        { label: 'Novo Horizonte do Oeste', value: 'Novo Horizonte do Oeste' },
        { label: 'Ouro Preto do Oeste', value: 'Ouro Preto do Oeste' },
        { label: 'Parecis', value: 'Parecis' },
        { label: 'Pimenta Bueno', value: 'Pimenta Bueno' },
        { label: 'Pimenteiras do Oeste', value: 'Pimenteiras do Oeste' },
        { label: 'Porto Velho', value: 'Porto Velho' },
        { label: 'Presidente Médici', value: 'Presidente Médici' },
        { label: 'Primavera de Rondônia', value: 'Primavera de Rondônia' },
        { label: 'Rio Crespo', value: 'Rio Crespo' },
        { label: 'Rolim de Moura', value: 'Rolim de Moura' },
        "Santa Luzia D'Oeste",
        "São Felipe D'Oeste",
        { label: 'São Francisco do Guaporé', value: 'São Francisco do Guaporé' },
        { label: 'São Miguel do Guaporé', value: 'São Miguel do Guaporé' },
        { label: 'Seringueiras', value: 'Seringueiras' },
        { label: 'Teixeirópolis', value: 'Teixeirópolis' },
        { label: 'Theobroma', value: 'Theobroma' },
        { label: 'Urupá', value: 'Urupá' },
        { label: 'Vale do Anari', value: 'Vale do Anari' },
        { label: 'Vale do Paraíso', value: 'Vale do Paraíso' },
        { label: 'Vilhena', value: 'Vilhena' },
    ],
    RR: [
        { label: 'Alto Alegre', value: 'Alto Alegre' },
        { label: 'Amajari', value: 'Amajari' },
        { label: 'Boa Vista', value: 'Boa Vista' },
        { label: 'Bonfim', value: 'Bonfim' },
        { label: 'Cantá', value: 'Cantá' },
        { label: 'Caracaraí', value: 'Caracaraí' },
        { label: 'Caroebe', value: 'Caroebe' },
        { label: 'Iracema', value: 'Iracema' },
        { label: 'Mucajaí', value: 'Mucajaí' },
        { label: 'Normandia', value: 'Normandia' },
        { label: 'Pacaraima', value: 'Pacaraima' },
        { label: 'Rorainópolis', value: 'Rorainópolis' },
        { label: 'São João da Baliza', value: 'São João da Baliza' },
        { label: 'São Luiz', value: 'São Luiz' },
        { label: 'Uiramutã', value: 'Uiramutã' },
    ],
    RS: [
        { label: 'Aceguá', value: 'Aceguá' },
        { label: 'Água Santa', value: 'Água Santa' },
        { label: 'Agudo', value: 'Agudo' },
        { label: 'Ajuricaba', value: 'Ajuricaba' },
        { label: 'Alecrim', value: 'Alecrim' },
        { label: 'Alegrete', value: 'Alegrete' },
        { label: 'Alegria', value: 'Alegria' },
        { label: 'Almirante Tamandaré do Sul', value: 'Almirante Tamandaré do Sul' },
        { label: 'Alpestre', value: 'Alpestre' },
        { label: 'Alto Alegre', value: 'Alto Alegre' },
        { label: 'Alto Feliz', value: 'Alto Feliz' },
        { label: 'Alvorada', value: 'Alvorada' },
        { label: 'Amaral Ferrador', value: 'Amaral Ferrador' },
        { label: 'Ametista do Sul', value: 'Ametista do Sul' },
        { label: 'André da Rocha', value: 'André da Rocha' },
        { label: 'Anta Gorda', value: 'Anta Gorda' },
        { label: 'Antônio Prado', value: 'Antônio Prado' },
        { label: 'Arambaré', value: 'Arambaré' },
        { label: 'Araricá', value: 'Araricá' },
        { label: 'Aratiba', value: 'Aratiba' },
        { label: 'Arroio do Meio', value: 'Arroio do Meio' },
        { label: 'Arroio do Padre', value: 'Arroio do Padre' },
        { label: 'Arroio do Sal', value: 'Arroio do Sal' },
        { label: 'Arroio do Tigre', value: 'Arroio do Tigre' },
        { label: 'Arroio dos Ratos', value: 'Arroio dos Ratos' },
        { label: 'Arroio Grande', value: 'Arroio Grande' },
        { label: 'Arvorezinha', value: 'Arvorezinha' },
        { label: 'Augusto Pestana', value: 'Augusto Pestana' },
        { label: 'Áurea', value: 'Áurea' },
        { label: 'Bagé', value: 'Bagé' },
        { label: 'Balneário Pinhal', value: 'Balneário Pinhal' },
        { label: 'Barão', value: 'Barão' },
        { label: 'Barão de Cotegipe', value: 'Barão de Cotegipe' },
        { label: 'Barão do Triunfo', value: 'Barão do Triunfo' },
        { label: 'Barra do Guarita', value: 'Barra do Guarita' },
        { label: 'Barra do Quaraí', value: 'Barra do Quaraí' },
        { label: 'Barra do Ribeiro', value: 'Barra do Ribeiro' },
        { label: 'Barra do Rio Azul', value: 'Barra do Rio Azul' },
        { label: 'Barra Funda', value: 'Barra Funda' },
        { label: 'Barracão', value: 'Barracão' },
        { label: 'Barros Cassal', value: 'Barros Cassal' },
        { label: 'Benjamin Constant do Sul', value: 'Benjamin Constant do Sul' },
        { label: 'Bento Gonçalves', value: 'Bento Gonçalves' },
        { label: 'Boa Vista das Missões', value: 'Boa Vista das Missões' },
        { label: 'Boa Vista do Buricá', value: 'Boa Vista do Buricá' },
        { label: 'Boa Vista do Cadeado', value: 'Boa Vista do Cadeado' },
        { label: 'Boa Vista do Incra', value: 'Boa Vista do Incra' },
        { label: 'Boa Vista do Sul', value: 'Boa Vista do Sul' },
        { label: 'Bom Jesus', value: 'Bom Jesus' },
        { label: 'Bom Princípio', value: 'Bom Princípio' },
        { label: 'Bom Progresso', value: 'Bom Progresso' },
        { label: 'Bom Retiro do Sul', value: 'Bom Retiro do Sul' },
        { label: 'Boqueirão do Leão', value: 'Boqueirão do Leão' },
        { label: 'Bossoroca', value: 'Bossoroca' },
        { label: 'Bozano', value: 'Bozano' },
        { label: 'Braga', value: 'Braga' },
        { label: 'Brochier', value: 'Brochier' },
        { label: 'Butiá', value: 'Butiá' },
        { label: 'Caçapava do Sul', value: 'Caçapava do Sul' },
        { label: 'Cacequi', value: 'Cacequi' },
        { label: 'Cachoeira do Sul', value: 'Cachoeira do Sul' },
        { label: 'Cachoeirinha', value: 'Cachoeirinha' },
        { label: 'Cacique Doble', value: 'Cacique Doble' },
        { label: 'Caibaté', value: 'Caibaté' },
        { label: 'Caiçara', value: 'Caiçara' },
        { label: 'Camaquã', value: 'Camaquã' },
        { label: 'Camargo', value: 'Camargo' },
        { label: 'Cambará do Sul', value: 'Cambará do Sul' },
        { label: 'Campestre da Serra', value: 'Campestre da Serra' },
        { label: 'Campina das Missões', value: 'Campina das Missões' },
        { label: 'Campinas do Sul', value: 'Campinas do Sul' },
        { label: 'Campo Bom', value: 'Campo Bom' },
        { label: 'Campo Novo', value: 'Campo Novo' },
        { label: 'Campos Borges', value: 'Campos Borges' },
        { label: 'Candelária', value: 'Candelária' },
        { label: 'Cândido Godói', value: 'Cândido Godói' },
        { label: 'Candiota', value: 'Candiota' },
        { label: 'Canela', value: 'Canela' },
        { label: 'Canguçu', value: 'Canguçu' },
        { label: 'Canoas', value: 'Canoas' },
        { label: 'Canudos do Vale', value: 'Canudos do Vale' },
        { label: 'Capão Bonito do Sul', value: 'Capão Bonito do Sul' },
        { label: 'Capão da Canoa', value: 'Capão da Canoa' },
        { label: 'Capão do Cipó', value: 'Capão do Cipó' },
        { label: 'Capão do Leão', value: 'Capão do Leão' },
        { label: 'Capela de Santana', value: 'Capela de Santana' },
        { label: 'Capitão', value: 'Capitão' },
        { label: 'Capivari do Sul', value: 'Capivari do Sul' },
        { label: 'Caraá', value: 'Caraá' },
        { label: 'Carazinho', value: 'Carazinho' },
        { label: 'Carlos Barbosa', value: 'Carlos Barbosa' },
        { label: 'Carlos Gomes', value: 'Carlos Gomes' },
        { label: 'Casca', value: 'Casca' },
        { label: 'Caseiros', value: 'Caseiros' },
        { label: 'Catuípe', value: 'Catuípe' },
        { label: 'Caxias do Sul', value: 'Caxias do Sul' },
        { label: 'Centenário', value: 'Centenário' },
        { label: 'Cerrito', value: 'Cerrito' },
        { label: 'Cerro Branco', value: 'Cerro Branco' },
        { label: 'Cerro Grande', value: 'Cerro Grande' },
        { label: 'Cerro Grande do Sul', value: 'Cerro Grande do Sul' },
        { label: 'Cerro Largo', value: 'Cerro Largo' },
        { label: 'Chapada', value: 'Chapada' },
        { label: 'Charqueadas', value: 'Charqueadas' },
        { label: 'Charrua', value: 'Charrua' },
        { label: 'Chiapetta', value: 'Chiapetta' },
        { label: 'Chuí', value: 'Chuí' },
        { label: 'Chuvisca', value: 'Chuvisca' },
        { label: 'Cidreira', value: 'Cidreira' },
        { label: 'Ciríaco', value: 'Ciríaco' },
        { label: 'Colinas', value: 'Colinas' },
        { label: 'Colorado', value: 'Colorado' },
        { label: 'Condor', value: 'Condor' },
        { label: 'Constantina', value: 'Constantina' },
        { label: 'Coqueiro Baixo', value: 'Coqueiro Baixo' },
        { label: 'Coqueiros do Sul', value: 'Coqueiros do Sul' },
        { label: 'Coronel Barros', value: 'Coronel Barros' },
        { label: 'Coronel Bicaco', value: 'Coronel Bicaco' },
        { label: 'Coronel Pilar', value: 'Coronel Pilar' },
        { label: 'Cotiporã', value: 'Cotiporã' },
        { label: 'Coxilha', value: 'Coxilha' },
        { label: 'Crissiumal', value: 'Crissiumal' },
        { label: 'Cristal', value: 'Cristal' },
        { label: 'Cristal do Sul', value: 'Cristal do Sul' },
        { label: 'Cruz Alta', value: 'Cruz Alta' },
        { label: 'Cruzaltense', value: 'Cruzaltense' },
        { label: 'Cruzeiro do Sul', value: 'Cruzeiro do Sul' },
        { label: 'David Canabarro', value: 'David Canabarro' },
        { label: 'Derrubadas', value: 'Derrubadas' },
        { label: 'Dezesseis de Novembro', value: 'Dezesseis de Novembro' },
        { label: 'Dilermando de Aguiar', value: 'Dilermando de Aguiar' },
        { label: 'Dois Irmãos', value: 'Dois Irmãos' },
        { label: 'Dois Irmãos das Missões', value: 'Dois Irmãos das Missões' },
        { label: 'Dois Lajeados', value: 'Dois Lajeados' },
        { label: 'Dom Feliciano', value: 'Dom Feliciano' },
        { label: 'Dom Pedrito', value: 'Dom Pedrito' },
        { label: 'Dom Pedro de Alcântara', value: 'Dom Pedro de Alcântara' },
        { label: 'Dona Francisca', value: 'Dona Francisca' },
        { label: 'Doutor Maurício Cardoso', value: 'Doutor Maurício Cardoso' },
        { label: 'Doutor Ricardo', value: 'Doutor Ricardo' },
        { label: 'Eldorado do Sul', value: 'Eldorado do Sul' },
        { label: 'Encantado', value: 'Encantado' },
        { label: 'Encruzilhada do Sul', value: 'Encruzilhada do Sul' },
        { label: 'Engenho Velho', value: 'Engenho Velho' },
        { label: 'Entre Rios do Sul', value: 'Entre Rios do Sul' },
        { label: 'Entre-Ijuís', value: 'Entre-Ijuís' },
        { label: 'Erebango', value: 'Erebango' },
        { label: 'Erechim', value: 'Erechim' },
        { label: 'Ernestina', value: 'Ernestina' },
        { label: 'Erval Grande', value: 'Erval Grande' },
        { label: 'Erval Seco', value: 'Erval Seco' },
        { label: 'Esmeralda', value: 'Esmeralda' },
        { label: 'Esperança do Sul', value: 'Esperança do Sul' },
        { label: 'Espumoso', value: 'Espumoso' },
        { label: 'Estação', value: 'Estação' },
        { label: 'Estância Velha', value: 'Estância Velha' },
        { label: 'Esteio', value: 'Esteio' },
        { label: 'Estrela', value: 'Estrela' },
        { label: 'Estrela Velha', value: 'Estrela Velha' },
        { label: 'Eugênio de Castro', value: 'Eugênio de Castro' },
        { label: 'Fagundes Varela', value: 'Fagundes Varela' },
        { label: 'Farroupilha', value: 'Farroupilha' },
        { label: 'Faxinal do Soturno', value: 'Faxinal do Soturno' },
        { label: 'Faxinalzinho', value: 'Faxinalzinho' },
        { label: 'Fazenda Vilanova', value: 'Fazenda Vilanova' },
        { label: 'Feliz', value: 'Feliz' },
        { label: 'Flores da Cunha', value: 'Flores da Cunha' },
        { label: 'Floriano Peixoto', value: 'Floriano Peixoto' },
        { label: 'Fontoura Xavier', value: 'Fontoura Xavier' },
        { label: 'Formigueiro', value: 'Formigueiro' },
        { label: 'Forquetinha', value: 'Forquetinha' },
        { label: 'Fortaleza dos Valos', value: 'Fortaleza dos Valos' },
        { label: 'Frederico Westphalen', value: 'Frederico Westphalen' },
        { label: 'Garibaldi', value: 'Garibaldi' },
        { label: 'Garruchos', value: 'Garruchos' },
        { label: 'Gaurama', value: 'Gaurama' },
        { label: 'General Câmara', value: 'General Câmara' },
        { label: 'Gentil', value: 'Gentil' },
        { label: 'Getúlio Vargas', value: 'Getúlio Vargas' },
        { label: 'Giruá', value: 'Giruá' },
        { label: 'Glorinha', value: 'Glorinha' },
        { label: 'Gramado', value: 'Gramado' },
        { label: 'Gramado dos Loureiros', value: 'Gramado dos Loureiros' },
        { label: 'Gramado Xavier', value: 'Gramado Xavier' },
        { label: 'Gravataí', value: 'Gravataí' },
        { label: 'Guabiju', value: 'Guabiju' },
        { label: 'Guaíba', value: 'Guaíba' },
        { label: 'Guaporé', value: 'Guaporé' },
        { label: 'Guarani das Missões', value: 'Guarani das Missões' },
        { label: 'Harmonia', value: 'Harmonia' },
        { label: 'Herval', value: 'Herval' },
        { label: 'Herveiras', value: 'Herveiras' },
        { label: 'Horizontina', value: 'Horizontina' },
        { label: 'Hulha Negra', value: 'Hulha Negra' },
        { label: 'Humaitá', value: 'Humaitá' },
        { label: 'Ibarama', value: 'Ibarama' },
        { label: 'Ibiaçá', value: 'Ibiaçá' },
        { label: 'Ibiraiaras', value: 'Ibiraiaras' },
        { label: 'Ibirapuitã', value: 'Ibirapuitã' },
        { label: 'Ibirubá', value: 'Ibirubá' },
        { label: 'Igrejinha', value: 'Igrejinha' },
        { label: 'Ijuí', value: 'Ijuí' },
        { label: 'Ilópolis', value: 'Ilópolis' },
        { label: 'Imbé', value: 'Imbé' },
        { label: 'Imigrante', value: 'Imigrante' },
        { label: 'Independência', value: 'Independência' },
        { label: 'Inhacorá', value: 'Inhacorá' },
        { label: 'Ipê', value: 'Ipê' },
        { label: 'Ipiranga do Sul', value: 'Ipiranga do Sul' },
        { label: 'Iraí', value: 'Iraí' },
        { label: 'Itaara', value: 'Itaara' },
        { label: 'Itacurubi', value: 'Itacurubi' },
        { label: 'Itapuca', value: 'Itapuca' },
        { label: 'Itaqui', value: 'Itaqui' },
        { label: 'Itati', value: 'Itati' },
        { label: 'Itatiba do Sul', value: 'Itatiba do Sul' },
        { label: 'Ivorá', value: 'Ivorá' },
        { label: 'Ivoti', value: 'Ivoti' },
        { label: 'Jaboticaba', value: 'Jaboticaba' },
        { label: 'Jacuizinho', value: 'Jacuizinho' },
        { label: 'Jacutinga', value: 'Jacutinga' },
        { label: 'Jaguarão', value: 'Jaguarão' },
        { label: 'Jaguari', value: 'Jaguari' },
        { label: 'Jaquirana', value: 'Jaquirana' },
        { label: 'Jari', value: 'Jari' },
        { label: 'Jóia', value: 'Jóia' },
        { label: 'Júlio de Castilhos', value: 'Júlio de Castilhos' },
        { label: 'Lagoa Bonita do Sul', value: 'Lagoa Bonita do Sul' },
        { label: 'Lagoa dos Três Cantos', value: 'Lagoa dos Três Cantos' },
        { label: 'Lagoa Vermelha', value: 'Lagoa Vermelha' },
        { label: 'Lagoão', value: 'Lagoão' },
        { label: 'Lajeado', value: 'Lajeado' },
        { label: 'Lajeado do Bugre', value: 'Lajeado do Bugre' },
        { label: 'Lavras do Sul', value: 'Lavras do Sul' },
        { label: 'Liberato Salzano', value: 'Liberato Salzano' },
        { label: 'Lindolfo Collor', value: 'Lindolfo Collor' },
        { label: 'Linha Nova', value: 'Linha Nova' },
        { label: 'Maçambará', value: 'Maçambará' },
        { label: 'Machadinho', value: 'Machadinho' },
        { label: 'Mampituba', value: 'Mampituba' },
        { label: 'Manoel Viana', value: 'Manoel Viana' },
        { label: 'Maquiné', value: 'Maquiné' },
        { label: 'Maratá', value: 'Maratá' },
        { label: 'Marau', value: 'Marau' },
        { label: 'Marcelino Ramos', value: 'Marcelino Ramos' },
        { label: 'Mariana Pimentel', value: 'Mariana Pimentel' },
        { label: 'Mariano Moro', value: 'Mariano Moro' },
        { label: 'Marques de Souza', value: 'Marques de Souza' },
        { label: 'Mata', value: 'Mata' },
        { label: 'Mato Castelhano', value: 'Mato Castelhano' },
        { label: 'Mato Leitão', value: 'Mato Leitão' },
        { label: 'Mato Queimado', value: 'Mato Queimado' },
        { label: 'Maximiliano de Almeida', value: 'Maximiliano de Almeida' },
        { label: 'Minas do Leão', value: 'Minas do Leão' },
        { label: 'Miraguaí', value: 'Miraguaí' },
        { label: 'Montauri', value: 'Montauri' },
        { label: 'Monte Alegre dos Campos', value: 'Monte Alegre dos Campos' },
        { label: 'Monte Belo do Sul', value: 'Monte Belo do Sul' },
        { label: 'Montenegro', value: 'Montenegro' },
        { label: 'Mormaço', value: 'Mormaço' },
        { label: 'Morrinhos do Sul', value: 'Morrinhos do Sul' },
        { label: 'Morro Redondo', value: 'Morro Redondo' },
        { label: 'Morro Reuter', value: 'Morro Reuter' },
        { label: 'Mostardas', value: 'Mostardas' },
        { label: 'Muçum', value: 'Muçum' },
        { label: 'Muitos Capões', value: 'Muitos Capões' },
        { label: 'Muliterno', value: 'Muliterno' },
        { label: 'Não-Me-Toque', value: 'Não-Me-Toque' },
        { label: 'Nicolau Vergueiro', value: 'Nicolau Vergueiro' },
        { label: 'Nonoai', value: 'Nonoai' },
        { label: 'Nova Alvorada', value: 'Nova Alvorada' },
        { label: 'Nova Araçá', value: 'Nova Araçá' },
        { label: 'Nova Bassano', value: 'Nova Bassano' },
        { label: 'Nova Boa Vista', value: 'Nova Boa Vista' },
        { label: 'Nova Bréscia', value: 'Nova Bréscia' },
        { label: 'Nova Candelária', value: 'Nova Candelária' },
        { label: 'Nova Esperança do Sul', value: 'Nova Esperança do Sul' },
        { label: 'Nova Hartz', value: 'Nova Hartz' },
        { label: 'Nova Pádua', value: 'Nova Pádua' },
        { label: 'Nova Palma', value: 'Nova Palma' },
        { label: 'Nova Petrópolis', value: 'Nova Petrópolis' },
        { label: 'Nova Prata', value: 'Nova Prata' },
        { label: 'Nova Ramada', value: 'Nova Ramada' },
        { label: 'Nova Roma do Sul', value: 'Nova Roma do Sul' },
        { label: 'Nova Santa Rita', value: 'Nova Santa Rita' },
        { label: 'Novo Barreiro', value: 'Novo Barreiro' },
        { label: 'Novo Cabrais', value: 'Novo Cabrais' },
        { label: 'Novo Hamburgo', value: 'Novo Hamburgo' },
        { label: 'Novo Machado', value: 'Novo Machado' },
        { label: 'Novo Tiradentes', value: 'Novo Tiradentes' },
        { label: 'Novo Xingu', value: 'Novo Xingu' },
        { label: 'Osório', value: 'Osório' },
        { label: 'Paim Filho', value: 'Paim Filho' },
        { label: 'Palmares do Sul', value: 'Palmares do Sul' },
        { label: 'Palmeira das Missões', value: 'Palmeira das Missões' },
        { label: 'Palmitinho', value: 'Palmitinho' },
        { label: 'Panambi', value: 'Panambi' },
        { label: 'Pantano Grande', value: 'Pantano Grande' },
        { label: 'Paraí', value: 'Paraí' },
        { label: 'Paraíso do Sul', value: 'Paraíso do Sul' },
        { label: 'Pareci Novo', value: 'Pareci Novo' },
        { label: 'Parobé', value: 'Parobé' },
        { label: 'Passa Sete', value: 'Passa Sete' },
        { label: 'Passo do Sobrado', value: 'Passo do Sobrado' },
        { label: 'Passo Fundo', value: 'Passo Fundo' },
        { label: 'Paulo Bento', value: 'Paulo Bento' },
        { label: 'Paverama', value: 'Paverama' },
        { label: 'Pedras Altas', value: 'Pedras Altas' },
        { label: 'Pedro Osório', value: 'Pedro Osório' },
        { label: 'Pejuçara', value: 'Pejuçara' },
        { label: 'Pelotas', value: 'Pelotas' },
        { label: 'Picada Café', value: 'Picada Café' },
        { label: 'Pinhal', value: 'Pinhal' },
        { label: 'Pinhal da Serra', value: 'Pinhal da Serra' },
        { label: 'Pinhal Grande', value: 'Pinhal Grande' },
        { label: 'Pinheirinho do Vale', value: 'Pinheirinho do Vale' },
        { label: 'Pinheiro Machado', value: 'Pinheiro Machado' },
        { label: 'Pinto Bandeira', value: 'Pinto Bandeira' },
        { label: 'Pirapó', value: 'Pirapó' },
        { label: 'Piratini', value: 'Piratini' },
        { label: 'Planalto', value: 'Planalto' },
        { label: 'Poço das Antas', value: 'Poço das Antas' },
        { label: 'Pontão', value: 'Pontão' },
        { label: 'Ponte Preta', value: 'Ponte Preta' },
        { label: 'Portão', value: 'Portão' },
        { label: 'Porto Alegre', value: 'Porto Alegre' },
        { label: 'Porto Lucena', value: 'Porto Lucena' },
        { label: 'Porto Mauá', value: 'Porto Mauá' },
        { label: 'Porto Vera Cruz', value: 'Porto Vera Cruz' },
        { label: 'Porto Xavier', value: 'Porto Xavier' },
        { label: 'Pouso Novo', value: 'Pouso Novo' },
        { label: 'Presidente Lucena', value: 'Presidente Lucena' },
        { label: 'Progresso', value: 'Progresso' },
        { label: 'Protásio Alves', value: 'Protásio Alves' },
        { label: 'Putinga', value: 'Putinga' },
        { label: 'Quaraí', value: 'Quaraí' },
        { label: 'Quatro Irmãos', value: 'Quatro Irmãos' },
        { label: 'Quevedos', value: 'Quevedos' },
        { label: 'Quinze de Novembro', value: 'Quinze de Novembro' },
        { label: 'Redentora', value: 'Redentora' },
        { label: 'Relvado', value: 'Relvado' },
        { label: 'Restinga Sêca', value: 'Restinga Sêca' },
        { label: 'Rio dos Índios', value: 'Rio dos Índios' },
        { label: 'Rio Grande', value: 'Rio Grande' },
        { label: 'Rio Pardo', value: 'Rio Pardo' },
        { label: 'Riozinho', value: 'Riozinho' },
        { label: 'Roca Sales', value: 'Roca Sales' },
        { label: 'Rodeio Bonito', value: 'Rodeio Bonito' },
        { label: 'Rolador', value: 'Rolador' },
        { label: 'Rolante', value: 'Rolante' },
        { label: 'Ronda Alta', value: 'Ronda Alta' },
        { label: 'Rondinha', value: 'Rondinha' },
        { label: 'Roque Gonzales', value: 'Roque Gonzales' },
        { label: 'Rosário do Sul', value: 'Rosário do Sul' },
        { label: 'Sagrada Família', value: 'Sagrada Família' },
        { label: 'Saldanha Marinho', value: 'Saldanha Marinho' },
        { label: 'Salto do Jacuí', value: 'Salto do Jacuí' },
        { label: 'Salvador das Missões', value: 'Salvador das Missões' },
        { label: 'Salvador do Sul', value: 'Salvador do Sul' },
        { label: 'Sananduva', value: 'Sananduva' },
        "Sant'Ana do Livramento",
        { label: 'Santa Bárbara do Sul', value: 'Santa Bárbara do Sul' },
        { label: 'Santa Cecília do Sul', value: 'Santa Cecília do Sul' },
        { label: 'Santa Clara do Sul', value: 'Santa Clara do Sul' },
        { label: 'Santa Cruz do Sul', value: 'Santa Cruz do Sul' },
        { label: 'Santa Margarida do Sul', value: 'Santa Margarida do Sul' },
        { label: 'Santa Maria', value: 'Santa Maria' },
        { label: 'Santa Maria do Herval', value: 'Santa Maria do Herval' },
        { label: 'Santa Rosa', value: 'Santa Rosa' },
        { label: 'Santa Tereza', value: 'Santa Tereza' },
        { label: 'Santa Vitória do Palmar', value: 'Santa Vitória do Palmar' },
        { label: 'Santana da Boa Vista', value: 'Santana da Boa Vista' },
        { label: 'Santiago', value: 'Santiago' },
        { label: 'Santo Ângelo', value: 'Santo Ângelo' },
        { label: 'Santo Antônio da Patrulha', value: 'Santo Antônio da Patrulha' },
        { label: 'Santo Antônio das Missões', value: 'Santo Antônio das Missões' },
        { label: 'Santo Antônio do Palma', value: 'Santo Antônio do Palma' },
        { label: 'Santo Antônio do Planalto', value: 'Santo Antônio do Planalto' },
        { label: 'Santo Augusto', value: 'Santo Augusto' },
        { label: 'Santo Cristo', value: 'Santo Cristo' },
        { label: 'Santo Expedito do Sul', value: 'Santo Expedito do Sul' },
        { label: 'São Borja', value: 'São Borja' },
        { label: 'São Domingos do Sul', value: 'São Domingos do Sul' },
        { label: 'São Francisco de Assis', value: 'São Francisco de Assis' },
        { label: 'São Francisco de Paula', value: 'São Francisco de Paula' },
        { label: 'São Gabriel', value: 'São Gabriel' },
        { label: 'São Jerônimo', value: 'São Jerônimo' },
        { label: 'São João da Urtiga', value: 'São João da Urtiga' },
        { label: 'São João do Polêsine', value: 'São João do Polêsine' },
        { label: 'São Jorge', value: 'São Jorge' },
        { label: 'São José das Missões', value: 'São José das Missões' },
        { label: 'São José do Herval', value: 'São José do Herval' },
        { label: 'São José do Hortêncio', value: 'São José do Hortêncio' },
        { label: 'São José do Inhacorá', value: 'São José do Inhacorá' },
        { label: 'São José do Norte', value: 'São José do Norte' },
        { label: 'São José do Ouro', value: 'São José do Ouro' },
        { label: 'São José do Sul', value: 'São José do Sul' },
        { label: 'São José dos Ausentes', value: 'São José dos Ausentes' },
        { label: 'São Leopoldo', value: 'São Leopoldo' },
        { label: 'São Lourenço do Sul', value: 'São Lourenço do Sul' },
        { label: 'São Luiz Gonzaga', value: 'São Luiz Gonzaga' },
        { label: 'São Marcos', value: 'São Marcos' },
        { label: 'São Martinho', value: 'São Martinho' },
        { label: 'São Martinho da Serra', value: 'São Martinho da Serra' },
        { label: 'São Miguel das Missões', value: 'São Miguel das Missões' },
        { label: 'São Nicolau', value: 'São Nicolau' },
        { label: 'São Paulo das Missões', value: 'São Paulo das Missões' },
        { label: 'São Pedro da Serra', value: 'São Pedro da Serra' },
        { label: 'São Pedro das Missões', value: 'São Pedro das Missões' },
        { label: 'São Pedro do Butiá', value: 'São Pedro do Butiá' },
        { label: 'São Pedro do Sul', value: 'São Pedro do Sul' },
        { label: 'São Sebastião do Caí', value: 'São Sebastião do Caí' },
        { label: 'São Sepé', value: 'São Sepé' },
        { label: 'São Valentim', value: 'São Valentim' },
        { label: 'São Valentim do Sul', value: 'São Valentim do Sul' },
        { label: 'São Valério do Sul', value: 'São Valério do Sul' },
        { label: 'São Vendelino', value: 'São Vendelino' },
        { label: 'São Vicente do Sul', value: 'São Vicente do Sul' },
        { label: 'Sapiranga', value: 'Sapiranga' },
        { label: 'Sapucaia do Sul', value: 'Sapucaia do Sul' },
        { label: 'Sarandi', value: 'Sarandi' },
        { label: 'Seberi', value: 'Seberi' },
        { label: 'Sede Nova', value: 'Sede Nova' },
        { label: 'Segredo', value: 'Segredo' },
        { label: 'Selbach', value: 'Selbach' },
        { label: 'Senador Salgado Filho', value: 'Senador Salgado Filho' },
        { label: 'Sentinela do Sul', value: 'Sentinela do Sul' },
        { label: 'Serafina Corrêa', value: 'Serafina Corrêa' },
        { label: 'Sério', value: 'Sério' },
        { label: 'Sertão', value: 'Sertão' },
        { label: 'Sertão Santana', value: 'Sertão Santana' },
        { label: 'Sete de Setembro', value: 'Sete de Setembro' },
        { label: 'Severiano de Almeida', value: 'Severiano de Almeida' },
        { label: 'Silveira Martins', value: 'Silveira Martins' },
        { label: 'Sinimbu', value: 'Sinimbu' },
        { label: 'Sobradinho', value: 'Sobradinho' },
        { label: 'Soledade', value: 'Soledade' },
        { label: 'Tabaí', value: 'Tabaí' },
        { label: 'Tapejara', value: 'Tapejara' },
        { label: 'Tapera', value: 'Tapera' },
        { label: 'Tapes', value: 'Tapes' },
        { label: 'Taquara', value: 'Taquara' },
        { label: 'Taquari', value: 'Taquari' },
        { label: 'Taquaruçu do Sul', value: 'Taquaruçu do Sul' },
        { label: 'Tavares', value: 'Tavares' },
        { label: 'Tenente Portela', value: 'Tenente Portela' },
        { label: 'Terra de Areia', value: 'Terra de Areia' },
        { label: 'Teutônia', value: 'Teutônia' },
        { label: 'Tio Hugo', value: 'Tio Hugo' },
        { label: 'Tiradentes do Sul', value: 'Tiradentes do Sul' },
        { label: 'Toropi', value: 'Toropi' },
        { label: 'Torres', value: 'Torres' },
        { label: 'Tramandaí', value: 'Tramandaí' },
        { label: 'Travesseiro', value: 'Travesseiro' },
        { label: 'Três Arroios', value: 'Três Arroios' },
        { label: 'Três Cachoeiras', value: 'Três Cachoeiras' },
        { label: 'Três Coroas', value: 'Três Coroas' },
        { label: 'Três de Maio', value: 'Três de Maio' },
        { label: 'Três Forquilhas', value: 'Três Forquilhas' },
        { label: 'Três Palmeiras', value: 'Três Palmeiras' },
        { label: 'Três Passos', value: 'Três Passos' },
        { label: 'Trindade do Sul', value: 'Trindade do Sul' },
        { label: 'Triunfo', value: 'Triunfo' },
        { label: 'Tucunduva', value: 'Tucunduva' },
        { label: 'Tunas', value: 'Tunas' },
        { label: 'Tupanci do Sul', value: 'Tupanci do Sul' },
        { label: 'Tupanciretã', value: 'Tupanciretã' },
        { label: 'Tupandi', value: 'Tupandi' },
        { label: 'Tuparendi', value: 'Tuparendi' },
        { label: 'Turuçu', value: 'Turuçu' },
        { label: 'Ubiretama', value: 'Ubiretama' },
        { label: 'União da Serra', value: 'União da Serra' },
        { label: 'Unistalda', value: 'Unistalda' },
        { label: 'Uruguaiana', value: 'Uruguaiana' },
        { label: 'Vacaria', value: 'Vacaria' },
        { label: 'Vale do Sol', value: 'Vale do Sol' },
        { label: 'Vale Real', value: 'Vale Real' },
        { label: 'Vale Verde', value: 'Vale Verde' },
        { label: 'Vanini', value: 'Vanini' },
        { label: 'Venâncio Aires', value: 'Venâncio Aires' },
        { label: 'Vera Cruz', value: 'Vera Cruz' },
        { label: 'Veranópolis', value: 'Veranópolis' },
        { label: 'Vespasiano Corrêa', value: 'Vespasiano Corrêa' },
        { label: 'Viadutos', value: 'Viadutos' },
        { label: 'Viamão', value: 'Viamão' },
        { label: 'Vicente Dutra', value: 'Vicente Dutra' },
        { label: 'Victor Graeff', value: 'Victor Graeff' },
        { label: 'Vila Flores', value: 'Vila Flores' },
        { label: 'Vila Lângaro', value: 'Vila Lângaro' },
        { label: 'Vila Maria', value: 'Vila Maria' },
        { label: 'Vila Nova do Sul', value: 'Vila Nova do Sul' },
        { label: 'Vista Alegre', value: 'Vista Alegre' },
        { label: 'Vista Alegre do Prata', value: 'Vista Alegre do Prata' },
        { label: 'Vista Gaúcha', value: 'Vista Gaúcha' },
        { label: 'Vitória das Missões', value: 'Vitória das Missões' },
        { label: 'Westfália', value: 'Westfália' },
        { label: 'Xangri-lá', value: 'Xangri-lá' },
    ],
    SC: [
        { label: 'Abdon Batista', value: 'Abdon Batista' },
        { label: 'Abelardo Luz', value: 'Abelardo Luz' },
        { label: 'Agrolândia', value: 'Agrolândia' },
        { label: 'Agronômica', value: 'Agronômica' },
        { label: 'Água Doce', value: 'Água Doce' },
        { label: 'Águas de Chapecó', value: 'Águas de Chapecó' },
        { label: 'Águas Frias', value: 'Águas Frias' },
        { label: 'Águas Mornas', value: 'Águas Mornas' },
        { label: 'Alfredo Wagner', value: 'Alfredo Wagner' },
        { label: 'Alto Bela Vista', value: 'Alto Bela Vista' },
        { label: 'Anchieta', value: 'Anchieta' },
        { label: 'Angelina', value: 'Angelina' },
        { label: 'Anita Garibaldi', value: 'Anita Garibaldi' },
        { label: 'Anitápolis', value: 'Anitápolis' },
        { label: 'Antônio Carlos', value: 'Antônio Carlos' },
        { label: 'Apiúna', value: 'Apiúna' },
        { label: 'Arabutã', value: 'Arabutã' },
        { label: 'Araquari', value: 'Araquari' },
        { label: 'Araranguá', value: 'Araranguá' },
        { label: 'Armazém', value: 'Armazém' },
        { label: 'Arroio Trinta', value: 'Arroio Trinta' },
        { label: 'Arvoredo', value: 'Arvoredo' },
        { label: 'Ascurra', value: 'Ascurra' },
        { label: 'Atalanta', value: 'Atalanta' },
        { label: 'Aurora', value: 'Aurora' },
        { label: 'Balneário Arroio do Silva', value: 'Balneário Arroio do Silva' },
        { label: 'Balneário Barra do Sul', value: 'Balneário Barra do Sul' },
        { label: 'Balneário Camboriú', value: 'Balneário Camboriú' },
        { label: 'Balneário Gaivota', value: 'Balneário Gaivota' },
        { label: 'Balneário Piçarras', value: 'Balneário Piçarras' },
        { label: 'Balneário Rincão', value: 'Balneário Rincão' },
        { label: 'Bandeirante', value: 'Bandeirante' },
        { label: 'Barra Bonita', value: 'Barra Bonita' },
        { label: 'Barra Velha', value: 'Barra Velha' },
        { label: 'Bela Vista do Toldo', value: 'Bela Vista do Toldo' },
        { label: 'Belmonte', value: 'Belmonte' },
        { label: 'Benedito Novo', value: 'Benedito Novo' },
        { label: 'Biguaçu', value: 'Biguaçu' },
        { label: 'Blumenau', value: 'Blumenau' },
        { label: 'Bocaina do Sul', value: 'Bocaina do Sul' },
        { label: 'Bom Jardim da Serra', value: 'Bom Jardim da Serra' },
        { label: 'Bom Jesus', value: 'Bom Jesus' },
        { label: 'Bom Jesus do Oeste', value: 'Bom Jesus do Oeste' },
        { label: 'Bom Retiro', value: 'Bom Retiro' },
        { label: 'Bombinhas', value: 'Bombinhas' },
        { label: 'Botuverá', value: 'Botuverá' },
        { label: 'Braço do Norte', value: 'Braço do Norte' },
        { label: 'Braço do Trombudo', value: 'Braço do Trombudo' },
        { label: 'Brunópolis', value: 'Brunópolis' },
        { label: 'Brusque', value: 'Brusque' },
        { label: 'Caçador', value: 'Caçador' },
        { label: 'Caibi', value: 'Caibi' },
        { label: 'Calmon', value: 'Calmon' },
        { label: 'Camboriú', value: 'Camboriú' },
        { label: 'Campo Alegre', value: 'Campo Alegre' },
        { label: 'Campo Belo do Sul', value: 'Campo Belo do Sul' },
        { label: 'Campo Erê', value: 'Campo Erê' },
        { label: 'Campos Novos', value: 'Campos Novos' },
        { label: 'Canelinha', value: 'Canelinha' },
        { label: 'Canoinhas', value: 'Canoinhas' },
        { label: 'Capão Alto', value: 'Capão Alto' },
        { label: 'Capinzal', value: 'Capinzal' },
        { label: 'Capivari de Baixo', value: 'Capivari de Baixo' },
        { label: 'Catanduvas', value: 'Catanduvas' },
        { label: 'Caxambu do Sul', value: 'Caxambu do Sul' },
        { label: 'Celso Ramos', value: 'Celso Ramos' },
        { label: 'Cerro Negro', value: 'Cerro Negro' },
        { label: 'Chapadão do Lageado', value: 'Chapadão do Lageado' },
        { label: 'Chapecó', value: 'Chapecó' },
        { label: 'Cocal do Sul', value: 'Cocal do Sul' },
        { label: 'Concórdia', value: 'Concórdia' },
        { label: 'Cordilheira Alta', value: 'Cordilheira Alta' },
        { label: 'Coronel Freitas', value: 'Coronel Freitas' },
        { label: 'Coronel Martins', value: 'Coronel Martins' },
        { label: 'Correia Pinto', value: 'Correia Pinto' },
        { label: 'Corupá', value: 'Corupá' },
        { label: 'Criciúma', value: 'Criciúma' },
        { label: 'Cunha Porã', value: 'Cunha Porã' },
        { label: 'Cunhataí', value: 'Cunhataí' },
        { label: 'Curitibanos', value: 'Curitibanos' },
        { label: 'Descanso', value: 'Descanso' },
        { label: 'Dionísio Cerqueira', value: 'Dionísio Cerqueira' },
        { label: 'Dona Emma', value: 'Dona Emma' },
        { label: 'Doutor Pedrinho', value: 'Doutor Pedrinho' },
        { label: 'Entre Rios', value: 'Entre Rios' },
        { label: 'Ermo', value: 'Ermo' },
        { label: 'Erval Velho', value: 'Erval Velho' },
        { label: 'Faxinal dos Guedes', value: 'Faxinal dos Guedes' },
        { label: 'Flor do Sertão', value: 'Flor do Sertão' },
        { label: 'Florianópolis', value: 'Florianópolis' },
        { label: 'Formosa do Sul', value: 'Formosa do Sul' },
        { label: 'Forquilhinha', value: 'Forquilhinha' },
        { label: 'Fraiburgo', value: 'Fraiburgo' },
        { label: 'Frei Rogério', value: 'Frei Rogério' },
        { label: 'Galvão', value: 'Galvão' },
        { label: 'Garopaba', value: 'Garopaba' },
        { label: 'Garuva', value: 'Garuva' },
        { label: 'Gaspar', value: 'Gaspar' },
        { label: 'Governador Celso Ramos', value: 'Governador Celso Ramos' },
        { label: 'Grão Pará', value: 'Grão Pará' },
        { label: 'Gravatal', value: 'Gravatal' },
        { label: 'Guabiruba', value: 'Guabiruba' },
        { label: 'Guaraciaba', value: 'Guaraciaba' },
        { label: 'Guaramirim', value: 'Guaramirim' },
        { label: 'Guarujá do Sul', value: 'Guarujá do Sul' },
        { label: 'Guatambú', value: 'Guatambú' },
        "Herval d'Oeste",
        { label: 'Ibiam', value: 'Ibiam' },
        { label: 'Ibicaré', value: 'Ibicaré' },
        { label: 'Ibirama', value: 'Ibirama' },
        { label: 'Içara', value: 'Içara' },
        { label: 'Ilhota', value: 'Ilhota' },
        { label: 'Imaruí', value: 'Imaruí' },
        { label: 'Imbituba', value: 'Imbituba' },
        { label: 'Imbuia', value: 'Imbuia' },
        { label: 'Indaial', value: 'Indaial' },
        { label: 'Iomerê', value: 'Iomerê' },
        { label: 'Ipira', value: 'Ipira' },
        { label: 'Iporã do Oeste', value: 'Iporã do Oeste' },
        { label: 'Ipuaçu', value: 'Ipuaçu' },
        { label: 'Ipumirim', value: 'Ipumirim' },
        { label: 'Iraceminha', value: 'Iraceminha' },
        { label: 'Irani', value: 'Irani' },
        { label: 'Irati', value: 'Irati' },
        { label: 'Irineópolis', value: 'Irineópolis' },
        { label: 'Itá', value: 'Itá' },
        { label: 'Itaiópolis', value: 'Itaiópolis' },
        { label: 'Itajaí', value: 'Itajaí' },
        { label: 'Itapema', value: 'Itapema' },
        { label: 'Itapiranga', value: 'Itapiranga' },
        { label: 'Itapoá', value: 'Itapoá' },
        { label: 'Ituporanga', value: 'Ituporanga' },
        { label: 'Jaborá', value: 'Jaborá' },
        { label: 'Jacinto Machado', value: 'Jacinto Machado' },
        { label: 'Jaguaruna', value: 'Jaguaruna' },
        { label: 'Jaraguá do Sul', value: 'Jaraguá do Sul' },
        { label: 'Jardinópolis', value: 'Jardinópolis' },
        { label: 'Joaçaba', value: 'Joaçaba' },
        { label: 'Joinville', value: 'Joinville' },
        { label: 'José Boiteux', value: 'José Boiteux' },
        { label: 'Jupiá', value: 'Jupiá' },
        { label: 'Lacerdópolis', value: 'Lacerdópolis' },
        { label: 'Lages', value: 'Lages' },
        { label: 'Laguna', value: 'Laguna' },
        { label: 'Lajeado Grande', value: 'Lajeado Grande' },
        { label: 'Laurentino', value: 'Laurentino' },
        { label: 'Lauro Muller', value: 'Lauro Muller' },
        { label: 'Lebon Régis', value: 'Lebon Régis' },
        { label: 'Leoberto Leal', value: 'Leoberto Leal' },
        { label: 'Lindóia do Sul', value: 'Lindóia do Sul' },
        { label: 'Lontras', value: 'Lontras' },
        { label: 'Luiz Alves', value: 'Luiz Alves' },
        { label: 'Luzerna', value: 'Luzerna' },
        { label: 'Macieira', value: 'Macieira' },
        { label: 'Mafra', value: 'Mafra' },
        { label: 'Major Gercino', value: 'Major Gercino' },
        { label: 'Major Vieira', value: 'Major Vieira' },
        { label: 'Maracajá', value: 'Maracajá' },
        { label: 'Maravilha', value: 'Maravilha' },
        { label: 'Marema', value: 'Marema' },
        { label: 'Massaranduba', value: 'Massaranduba' },
        { label: 'Matos Costa', value: 'Matos Costa' },
        { label: 'Meleiro', value: 'Meleiro' },
        { label: 'Mirim Doce', value: 'Mirim Doce' },
        { label: 'Modelo', value: 'Modelo' },
        { label: 'Mondaí', value: 'Mondaí' },
        { label: 'Monte Carlo', value: 'Monte Carlo' },
        { label: 'Monte Castelo', value: 'Monte Castelo' },
        { label: 'Morro da Fumaça', value: 'Morro da Fumaça' },
        { label: 'Morro Grande', value: 'Morro Grande' },
        { label: 'Navegantes', value: 'Navegantes' },
        { label: 'Nova Erechim', value: 'Nova Erechim' },
        { label: 'Nova Itaberaba', value: 'Nova Itaberaba' },
        { label: 'Nova Trento', value: 'Nova Trento' },
        { label: 'Nova Veneza', value: 'Nova Veneza' },
        { label: 'Novo Horizonte', value: 'Novo Horizonte' },
        { label: 'Orleans', value: 'Orleans' },
        { label: 'Otacílio Costa', value: 'Otacílio Costa' },
        { label: 'Ouro', value: 'Ouro' },
        { label: 'Ouro Verde', value: 'Ouro Verde' },
        { label: 'Paial', value: 'Paial' },
        { label: 'Painel', value: 'Painel' },
        { label: 'Palhoça', value: 'Palhoça' },
        { label: 'Palma Sola', value: 'Palma Sola' },
        { label: 'Palmeira', value: 'Palmeira' },
        { label: 'Palmitos', value: 'Palmitos' },
        { label: 'Papanduva', value: 'Papanduva' },
        { label: 'Paraíso', value: 'Paraíso' },
        { label: 'Passo de Torres', value: 'Passo de Torres' },
        { label: 'Passos Maia', value: 'Passos Maia' },
        { label: 'Paulo Lopes', value: 'Paulo Lopes' },
        { label: 'Pedras Grandes', value: 'Pedras Grandes' },
        { label: 'Penha', value: 'Penha' },
        { label: 'Peritiba', value: 'Peritiba' },
        { label: 'Pescaria Brava', value: 'Pescaria Brava' },
        { label: 'Petrolândia', value: 'Petrolândia' },
        { label: 'Pinhalzinho', value: 'Pinhalzinho' },
        { label: 'Pinheiro Preto', value: 'Pinheiro Preto' },
        { label: 'Piratuba', value: 'Piratuba' },
        { label: 'Planalto Alegre', value: 'Planalto Alegre' },
        { label: 'Pomerode', value: 'Pomerode' },
        { label: 'Ponte Alta', value: 'Ponte Alta' },
        { label: 'Ponte Alta do Norte', value: 'Ponte Alta do Norte' },
        { label: 'Ponte Serrada', value: 'Ponte Serrada' },
        { label: 'Porto Belo', value: 'Porto Belo' },
        { label: 'Porto União', value: 'Porto União' },
        { label: 'Pouso Redondo', value: 'Pouso Redondo' },
        { label: 'Praia Grande', value: 'Praia Grande' },
        { label: 'Presidente Castello Branco', value: 'Presidente Castello Branco' },
        { label: 'Presidente Getúlio', value: 'Presidente Getúlio' },
        { label: 'Presidente Nereu', value: 'Presidente Nereu' },
        { label: 'Princesa', value: 'Princesa' },
        { label: 'Quilombo', value: 'Quilombo' },
        { label: 'Rancho Queimado', value: 'Rancho Queimado' },
        { label: 'Rio das Antas', value: 'Rio das Antas' },
        { label: 'Rio do Campo', value: 'Rio do Campo' },
        { label: 'Rio do Oeste', value: 'Rio do Oeste' },
        { label: 'Rio do Sul', value: 'Rio do Sul' },
        { label: 'Rio dos Cedros', value: 'Rio dos Cedros' },
        { label: 'Rio Fortuna', value: 'Rio Fortuna' },
        { label: 'Rio Negrinho', value: 'Rio Negrinho' },
        { label: 'Rio Rufino', value: 'Rio Rufino' },
        { label: 'Riqueza', value: 'Riqueza' },
        { label: 'Rodeio', value: 'Rodeio' },
        { label: 'Romelândia', value: 'Romelândia' },
        { label: 'Salete', value: 'Salete' },
        { label: 'Saltinho', value: 'Saltinho' },
        { label: 'Salto Veloso', value: 'Salto Veloso' },
        { label: 'Sangão', value: 'Sangão' },
        { label: 'Santa Cecília', value: 'Santa Cecília' },
        { label: 'Santa Helena', value: 'Santa Helena' },
        { label: 'Santa Rosa de Lima', value: 'Santa Rosa de Lima' },
        { label: 'Santa Rosa do Sul', value: 'Santa Rosa do Sul' },
        { label: 'Santa Terezinha', value: 'Santa Terezinha' },
        { label: 'Santa Terezinha do Progresso', value: 'Santa Terezinha do Progresso' },
        { label: 'Santiago do Sul', value: 'Santiago do Sul' },
        { label: 'Santo Amaro da Imperatriz', value: 'Santo Amaro da Imperatriz' },
        { label: 'São Bento do Sul', value: 'São Bento do Sul' },
        { label: 'São Bernardino', value: 'São Bernardino' },
        { label: 'São Bonifácio', value: 'São Bonifácio' },
        { label: 'São Carlos', value: 'São Carlos' },
        { label: 'São Cristovão do Sul', value: 'São Cristovão do Sul' },
        { label: 'São Domingos', value: 'São Domingos' },
        { label: 'São Francisco do Sul', value: 'São Francisco do Sul' },
        { label: 'São João Batista', value: 'São João Batista' },
        { label: 'São João do Itaperiú', value: 'São João do Itaperiú' },
        { label: 'São João do Oeste', value: 'São João do Oeste' },
        { label: 'São João do Sul', value: 'São João do Sul' },
        { label: 'São Joaquim', value: 'São Joaquim' },
        { label: 'São José', value: 'São José' },
        { label: 'São José do Cedro', value: 'São José do Cedro' },
        { label: 'São José do Cerrito', value: 'São José do Cerrito' },
        { label: 'São Lourenço do Oeste', value: 'São Lourenço do Oeste' },
        { label: 'São Ludgero', value: 'São Ludgero' },
        { label: 'São Martinho', value: 'São Martinho' },
        { label: 'São Miguel da Boa Vista', value: 'São Miguel da Boa Vista' },
        { label: 'São Miguel do Oeste', value: 'São Miguel do Oeste' },
        { label: 'São Pedro de Alcântara', value: 'São Pedro de Alcântara' },
        { label: 'Saudades', value: 'Saudades' },
        { label: 'Schroeder', value: 'Schroeder' },
        { label: 'Seara', value: 'Seara' },
        { label: 'Serra Alta', value: 'Serra Alta' },
        { label: 'Siderópolis', value: 'Siderópolis' },
        { label: 'Sombrio', value: 'Sombrio' },
        { label: 'Sul Brasil', value: 'Sul Brasil' },
        { label: 'Taió', value: 'Taió' },
        { label: 'Tangará', value: 'Tangará' },
        { label: 'Tigrinhos', value: 'Tigrinhos' },
        { label: 'Tijucas', value: 'Tijucas' },
        { label: 'Timbé do Sul', value: 'Timbé do Sul' },
        { label: 'Timbó', value: 'Timbó' },
        { label: 'Timbó Grande', value: 'Timbó Grande' },
        { label: 'Três Barras', value: 'Três Barras' },
        { label: 'Treviso', value: 'Treviso' },
        { label: 'Treze de Maio', value: 'Treze de Maio' },
        { label: 'Treze Tílias', value: 'Treze Tílias' },
        { label: 'Trombudo Central', value: 'Trombudo Central' },
        { label: 'Tubarão', value: 'Tubarão' },
        { label: 'Tunápolis', value: 'Tunápolis' },
        { label: 'Turvo', value: 'Turvo' },
        { label: 'União do Oeste', value: 'União do Oeste' },
        { label: 'Urubici', value: 'Urubici' },
        { label: 'Urupema', value: 'Urupema' },
        { label: 'Urussanga', value: 'Urussanga' },
        { label: 'Vargeão', value: 'Vargeão' },
        { label: 'Vargem', value: 'Vargem' },
        { label: 'Vargem Bonita', value: 'Vargem Bonita' },
        { label: 'Vidal Ramos', value: 'Vidal Ramos' },
        { label: 'Videira', value: 'Videira' },
        { label: 'Vitor Meireles', value: 'Vitor Meireles' },
        { label: 'Witmarsum', value: 'Witmarsum' },
        { label: 'Xanxerê', value: 'Xanxerê' },
        { label: 'Xavantina', value: 'Xavantina' },
        { label: 'Xaxim', value: 'Xaxim' },
        { label: 'Zortéa', value: 'Zortéa' },
    ],
    SE: [
        { label: 'Amparo de São Francisco', value: 'Amparo de São Francisco' },
        { label: 'Aquidabã', value: 'Aquidabã' },
        { label: 'Aracaju', value: 'Aracaju' },
        { label: 'Arauá', value: 'Arauá' },
        { label: 'Areia Branca', value: 'Areia Branca' },
        { label: 'Barra dos Coqueiros', value: 'Barra dos Coqueiros' },
        { label: 'Boquim', value: 'Boquim' },
        { label: 'Brejo Grande', value: 'Brejo Grande' },
        { label: 'Campo do Brito', value: 'Campo do Brito' },
        { label: 'Canhoba', value: 'Canhoba' },
        { label: 'Canindé de São Francisco', value: 'Canindé de São Francisco' },
        { label: 'Capela', value: 'Capela' },
        { label: 'Carira', value: 'Carira' },
        { label: 'Carmópolis', value: 'Carmópolis' },
        { label: 'Cedro de São João', value: 'Cedro de São João' },
        { label: 'Cristinápolis', value: 'Cristinápolis' },
        { label: 'Cumbe', value: 'Cumbe' },
        { label: 'Divina Pastora', value: 'Divina Pastora' },
        { label: 'Estância', value: 'Estância' },
        { label: 'Feira Nova', value: 'Feira Nova' },
        { label: 'Frei Paulo', value: 'Frei Paulo' },
        { label: 'Gararu', value: 'Gararu' },
        { label: 'General Maynard', value: 'General Maynard' },
        { label: 'Gracho Cardoso', value: 'Gracho Cardoso' },
        { label: 'Ilha das Flores', value: 'Ilha das Flores' },
        { label: 'Indiaroba', value: 'Indiaroba' },
        { label: 'Itabaiana', value: 'Itabaiana' },
        { label: 'Itabaianinha', value: 'Itabaianinha' },
        { label: 'Itabi', value: 'Itabi' },
        "Itaporanga d'Ajuda",
        { label: 'Japaratuba', value: 'Japaratuba' },
        { label: 'Japoatã', value: 'Japoatã' },
        { label: 'Lagarto', value: 'Lagarto' },
        { label: 'Laranjeiras', value: 'Laranjeiras' },
        { label: 'Macambira', value: 'Macambira' },
        { label: 'Malhada dos Bois', value: 'Malhada dos Bois' },
        { label: 'Malhador', value: 'Malhador' },
        { label: 'Maruim', value: 'Maruim' },
        { label: 'Moita Bonita', value: 'Moita Bonita' },
        { label: 'Monte Alegre de Sergipe', value: 'Monte Alegre de Sergipe' },
        { label: 'Muribeca', value: 'Muribeca' },
        { label: 'Neópolis', value: 'Neópolis' },
        { label: 'Nossa Senhora Aparecida', value: 'Nossa Senhora Aparecida' },
        { label: 'Nossa Senhora da Glória', value: 'Nossa Senhora da Glória' },
        { label: 'Nossa Senhora das Dores', value: 'Nossa Senhora das Dores' },
        { label: 'Nossa Senhora de Lourdes', value: 'Nossa Senhora de Lourdes' },
        { label: 'Nossa Senhora do Socorro', value: 'Nossa Senhora do Socorro' },
        { label: 'Pacatuba', value: 'Pacatuba' },
        { label: 'Pedra Mole', value: 'Pedra Mole' },
        { label: 'Pedrinhas', value: 'Pedrinhas' },
        { label: 'Pinhão', value: 'Pinhão' },
        { label: 'Pirambu', value: 'Pirambu' },
        { label: 'Poço Redondo', value: 'Poço Redondo' },
        { label: 'Poço Verde', value: 'Poço Verde' },
        { label: 'Porto da Folha', value: 'Porto da Folha' },
        { label: 'Propriá', value: 'Propriá' },
        { label: 'Riachão do Dantas', value: 'Riachão do Dantas' },
        { label: 'Riachuelo', value: 'Riachuelo' },
        { label: 'Ribeirópolis', value: 'Ribeirópolis' },
        { label: 'Rosário do Catete', value: 'Rosário do Catete' },
        { label: 'Salgado', value: 'Salgado' },
        { label: 'Santa Luzia do Itanhy', value: 'Santa Luzia do Itanhy' },
        { label: 'Santa Rosa de Lima', value: 'Santa Rosa de Lima' },
        { label: 'Santana do São Francisco', value: 'Santana do São Francisco' },
        { label: 'Santo Amaro das Brotas', value: 'Santo Amaro das Brotas' },
        { label: 'São Cristóvão', value: 'São Cristóvão' },
        { label: 'São Domingos', value: 'São Domingos' },
        { label: 'São Francisco', value: 'São Francisco' },
        { label: 'São Miguel do Aleixo', value: 'São Miguel do Aleixo' },
        { label: 'Simão Dias', value: 'Simão Dias' },
        { label: 'Siriri', value: 'Siriri' },
        { label: 'Telha', value: 'Telha' },
        { label: 'Tobias Barreto', value: 'Tobias Barreto' },
        { label: 'Tomar do Geru', value: 'Tomar do Geru' },
        { label: 'Umbaúba', value: 'Umbaúba' },
    ],
    SP: [
        { label: 'Adamantina', value: 'Adamantina' },
        { label: 'Adolfo', value: 'Adolfo' },
        { label: 'Aguaí', value: 'Aguaí' },
        { label: 'Águas da Prata', value: 'Águas da Prata' },
        { label: 'Águas de Lindóia', value: 'Águas de Lindóia' },
        { label: 'Águas de Santa Bárbara', value: 'Águas de Santa Bárbara' },
        { label: 'Águas de São Pedro', value: 'Águas de São Pedro' },
        { label: 'Agudos', value: 'Agudos' },
        { label: 'Alambari', value: 'Alambari' },
        { label: 'Alfredo Marcondes', value: 'Alfredo Marcondes' },
        { label: 'Altair', value: 'Altair' },
        { label: 'Altinópolis', value: 'Altinópolis' },
        { label: 'Alto Alegre', value: 'Alto Alegre' },
        { label: 'Alumínio', value: 'Alumínio' },
        { label: 'Álvares Florence', value: 'Álvares Florence' },
        { label: 'Álvares Machado', value: 'Álvares Machado' },
        { label: 'Álvaro de Carvalho', value: 'Álvaro de Carvalho' },
        { label: 'Alvinlândia', value: 'Alvinlândia' },
        { label: 'Americana', value: 'Americana' },
        { label: 'Américo Brasiliense', value: 'Américo Brasiliense' },
        { label: 'Américo de Campos', value: 'Américo de Campos' },
        { label: 'Amparo', value: 'Amparo' },
        { label: 'Analândia', value: 'Analândia' },
        { label: 'Andradina', value: 'Andradina' },
        { label: 'Angatuba', value: 'Angatuba' },
        { label: 'Anhembi', value: 'Anhembi' },
        { label: 'Anhumas', value: 'Anhumas' },
        { label: 'Aparecida', value: 'Aparecida' },
        "Aparecida d'Oeste",
        { label: 'Apiaí', value: 'Apiaí' },
        { label: 'Araçariguama', value: 'Araçariguama' },
        { label: 'Araçatuba', value: 'Araçatuba' },
        { label: 'Araçoiaba da Serra', value: 'Araçoiaba da Serra' },
        { label: 'Aramina', value: 'Aramina' },
        { label: 'Arandu', value: 'Arandu' },
        { label: 'Arapeí', value: 'Arapeí' },
        { label: 'Araraquara', value: 'Araraquara' },
        { label: 'Araras', value: 'Araras' },
        { label: 'Arco-Íris', value: 'Arco-Íris' },
        { label: 'Arealva', value: 'Arealva' },
        { label: 'Areias', value: 'Areias' },
        { label: 'Areiópolis', value: 'Areiópolis' },
        { label: 'Ariranha', value: 'Ariranha' },
        { label: 'Artur Nogueira', value: 'Artur Nogueira' },
        { label: 'Arujá', value: 'Arujá' },
        { label: 'Aspásia', value: 'Aspásia' },
        { label: 'Assis', value: 'Assis' },
        { label: 'Atibaia', value: 'Atibaia' },
        { label: 'Auriflama', value: 'Auriflama' },
        { label: 'Avaí', value: 'Avaí' },
        { label: 'Avanhandava', value: 'Avanhandava' },
        { label: 'Avaré', value: 'Avaré' },
        { label: 'Bady Bassitt', value: 'Bady Bassitt' },
        { label: 'Balbinos', value: 'Balbinos' },
        { label: 'Bálsamo', value: 'Bálsamo' },
        { label: 'Bananal', value: 'Bananal' },
        { label: 'Barão de Antonina', value: 'Barão de Antonina' },
        { label: 'Barbosa', value: 'Barbosa' },
        { label: 'Bariri', value: 'Bariri' },
        { label: 'Barra Bonita', value: 'Barra Bonita' },
        { label: 'Barra do Chapéu', value: 'Barra do Chapéu' },
        { label: 'Barra do Turvo', value: 'Barra do Turvo' },
        { label: 'Barretos', value: 'Barretos' },
        { label: 'Barrinha', value: 'Barrinha' },
        { label: 'Barueri', value: 'Barueri' },
        { label: 'Bastos', value: 'Bastos' },
        { label: 'Batatais', value: 'Batatais' },
        { label: 'Bauru', value: 'Bauru' },
        { label: 'Bebedouro', value: 'Bebedouro' },
        { label: 'Bento de Abreu', value: 'Bento de Abreu' },
        { label: 'Bernardino de Campos', value: 'Bernardino de Campos' },
        { label: 'Bertioga', value: 'Bertioga' },
        { label: 'Bilac', value: 'Bilac' },
        { label: 'Birigui', value: 'Birigui' },
        { label: 'Biritiba-Mirim', value: 'Biritiba-Mirim' },
        { label: 'Boa Esperança do Sul', value: 'Boa Esperança do Sul' },
        { label: 'Bocaina', value: 'Bocaina' },
        { label: 'Bofete', value: 'Bofete' },
        { label: 'Boituva', value: 'Boituva' },
        { label: 'Bom Jesus dos Perdões', value: 'Bom Jesus dos Perdões' },
        { label: 'Bom Sucesso de Itararé', value: 'Bom Sucesso de Itararé' },
        { label: 'Borá', value: 'Borá' },
        { label: 'Boracéia', value: 'Boracéia' },
        { label: 'Borborema', value: 'Borborema' },
        { label: 'Borebi', value: 'Borebi' },
        { label: 'Botucatu', value: 'Botucatu' },
        { label: 'Bragança Paulista', value: 'Bragança Paulista' },
        { label: 'Braúna', value: 'Braúna' },
        { label: 'Brejo Alegre', value: 'Brejo Alegre' },
        { label: 'Brodowski', value: 'Brodowski' },
        { label: 'Brotas', value: 'Brotas' },
        { label: 'Buri', value: 'Buri' },
        { label: 'Buritama', value: 'Buritama' },
        { label: 'Buritizal', value: 'Buritizal' },
        { label: 'Cabrália Paulista', value: 'Cabrália Paulista' },
        { label: 'Cabreúva', value: 'Cabreúva' },
        { label: 'Caçapava', value: 'Caçapava' },
        { label: 'Cachoeira Paulista', value: 'Cachoeira Paulista' },
        { label: 'Caconde', value: 'Caconde' },
        { label: 'Cafelândia', value: 'Cafelândia' },
        { label: 'Caiabu', value: 'Caiabu' },
        { label: 'Caieiras', value: 'Caieiras' },
        { label: 'Caiuá', value: 'Caiuá' },
        { label: 'Cajamar', value: 'Cajamar' },
        { label: 'Cajati', value: 'Cajati' },
        { label: 'Cajobi', value: 'Cajobi' },
        { label: 'Cajuru', value: 'Cajuru' },
        { label: 'Campina do Monte Alegre', value: 'Campina do Monte Alegre' },
        { label: 'Campinas', value: 'Campinas' },
        { label: 'Campo Limpo Paulista', value: 'Campo Limpo Paulista' },
        { label: 'Campos do Jordão', value: 'Campos do Jordão' },
        { label: 'Campos Novos Paulista', value: 'Campos Novos Paulista' },
        { label: 'Cananéia', value: 'Cananéia' },
        { label: 'Canas', value: 'Canas' },
        { label: 'Cândido Mota', value: 'Cândido Mota' },
        { label: 'Cândido Rodrigues', value: 'Cândido Rodrigues' },
        { label: 'Canitar', value: 'Canitar' },
        { label: 'Capão Bonito', value: 'Capão Bonito' },
        { label: 'Capela do Alto', value: 'Capela do Alto' },
        { label: 'Capivari', value: 'Capivari' },
        { label: 'Caraguatatuba', value: 'Caraguatatuba' },
        { label: 'Carapicuíba', value: 'Carapicuíba' },
        { label: 'Cardoso', value: 'Cardoso' },
        { label: 'Casa Branca', value: 'Casa Branca' },
        { label: 'Cássia dos Coqueiros', value: 'Cássia dos Coqueiros' },
        { label: 'Castilho', value: 'Castilho' },
        { label: 'Catanduva', value: 'Catanduva' },
        { label: 'Catiguá', value: 'Catiguá' },
        { label: 'Cedral', value: 'Cedral' },
        { label: 'Cerqueira César', value: 'Cerqueira César' },
        { label: 'Cerquilho', value: 'Cerquilho' },
        { label: 'Cesário Lange', value: 'Cesário Lange' },
        { label: 'Charqueada', value: 'Charqueada' },
        { label: 'Chavantes', value: 'Chavantes' },
        { label: 'Clementina', value: 'Clementina' },
        { label: 'Colina', value: 'Colina' },
        { label: 'Colômbia', value: 'Colômbia' },
        { label: 'Conchal', value: 'Conchal' },
        { label: 'Conchas', value: 'Conchas' },
        { label: 'Cordeirópolis', value: 'Cordeirópolis' },
        { label: 'Coroados', value: 'Coroados' },
        { label: 'Coronel Macedo', value: 'Coronel Macedo' },
        { label: 'Corumbataí', value: 'Corumbataí' },
        { label: 'Cosmópolis', value: 'Cosmópolis' },
        { label: 'Cosmorama', value: 'Cosmorama' },
        { label: 'Cotia', value: 'Cotia' },
        { label: 'Cravinhos', value: 'Cravinhos' },
        { label: 'Cristais Paulista', value: 'Cristais Paulista' },
        { label: 'Cruzália', value: 'Cruzália' },
        { label: 'Cruzeiro', value: 'Cruzeiro' },
        { label: 'Cubatão', value: 'Cubatão' },
        { label: 'Cunha', value: 'Cunha' },
        { label: 'Descalvado', value: 'Descalvado' },
        { label: 'Diadema', value: 'Diadema' },
        { label: 'Dirce Reis', value: 'Dirce Reis' },
        { label: 'Divinolândia', value: 'Divinolândia' },
        { label: 'Dobrada', value: 'Dobrada' },
        { label: 'Dois Córregos', value: 'Dois Córregos' },
        { label: 'Dolcinópolis', value: 'Dolcinópolis' },
        { label: 'Dourado', value: 'Dourado' },
        { label: 'Dracena', value: 'Dracena' },
        { label: 'Duartina', value: 'Duartina' },
        { label: 'Dumont', value: 'Dumont' },
        { label: 'Echaporã', value: 'Echaporã' },
        { label: 'Eldorado', value: 'Eldorado' },
        { label: 'Elias Fausto', value: 'Elias Fausto' },
        { label: 'Elisiário', value: 'Elisiário' },
        { label: 'Embaúba', value: 'Embaúba' },
        { label: 'Embu das Artes', value: 'Embu das Artes' },
        { label: 'Embu-Guaçu', value: 'Embu-Guaçu' },
        { label: 'Emilianópolis', value: 'Emilianópolis' },
        { label: 'Engenheiro Coelho', value: 'Engenheiro Coelho' },
        { label: 'Espírito Santo do Pinhal', value: 'Espírito Santo do Pinhal' },
        { label: 'Espírito Santo do Turvo', value: 'Espírito Santo do Turvo' },
        { label: 'Estiva Gerbi', value: 'Estiva Gerbi' },
        "Estrela d'Oeste",
        { label: 'Estrela do Norte', value: 'Estrela do Norte' },
        { label: 'Euclides da Cunha Paulista', value: 'Euclides da Cunha Paulista' },
        { label: 'Fartura', value: 'Fartura' },
        { label: 'Fernando Prestes', value: 'Fernando Prestes' },
        { label: 'Fernandópolis', value: 'Fernandópolis' },
        { label: 'Fernão', value: 'Fernão' },
        { label: 'Ferraz de Vasconcelos', value: 'Ferraz de Vasconcelos' },
        { label: 'Flora Rica', value: 'Flora Rica' },
        { label: 'Floreal', value: 'Floreal' },
        { label: 'Flórida Paulista', value: 'Flórida Paulista' },
        { label: 'Florínia', value: 'Florínia' },
        { label: 'Franca', value: 'Franca' },
        { label: 'Francisco Morato', value: 'Francisco Morato' },
        { label: 'Franco da Rocha', value: 'Franco da Rocha' },
        { label: 'Gabriel Monteiro', value: 'Gabriel Monteiro' },
        { label: 'Gália', value: 'Gália' },
        { label: 'Garça', value: 'Garça' },
        { label: 'Gastão Vidigal', value: 'Gastão Vidigal' },
        { label: 'Gavião Peixoto', value: 'Gavião Peixoto' },
        { label: 'General Salgado', value: 'General Salgado' },
        { label: 'Getulina', value: 'Getulina' },
        { label: 'Glicério', value: 'Glicério' },
        { label: 'Guaiçara', value: 'Guaiçara' },
        { label: 'Guaimbê', value: 'Guaimbê' },
        { label: 'Guaíra', value: 'Guaíra' },
        { label: 'Guapiaçu', value: 'Guapiaçu' },
        { label: 'Guapiara', value: 'Guapiara' },
        { label: 'Guará', value: 'Guará' },
        { label: 'Guaraçaí', value: 'Guaraçaí' },
        { label: 'Guaraci', value: 'Guaraci' },
        "Guarani d'Oeste",
        { label: 'Guarantã', value: 'Guarantã' },
        { label: 'Guararapes', value: 'Guararapes' },
        { label: 'Guararema', value: 'Guararema' },
        { label: 'Guaratinguetá', value: 'Guaratinguetá' },
        { label: 'Guareí', value: 'Guareí' },
        { label: 'Guariba', value: 'Guariba' },
        { label: 'Guarujá', value: 'Guarujá' },
        { label: 'Guarulhos', value: 'Guarulhos' },
        { label: 'Guatapará', value: 'Guatapará' },
        { label: 'Guzolândia', value: 'Guzolândia' },
        { label: 'Herculândia', value: 'Herculândia' },
        { label: 'Holambra', value: 'Holambra' },
        { label: 'Hortolândia', value: 'Hortolândia' },
        { label: 'Iacanga', value: 'Iacanga' },
        { label: 'Iacri', value: 'Iacri' },
        { label: 'Iaras', value: 'Iaras' },
        { label: 'Ibaté', value: 'Ibaté' },
        { label: 'Ibirá', value: 'Ibirá' },
        { label: 'Ibirarema', value: 'Ibirarema' },
        { label: 'Ibitinga', value: 'Ibitinga' },
        { label: 'Ibiúna', value: 'Ibiúna' },
        { label: 'Icém', value: 'Icém' },
        { label: 'Iepê', value: 'Iepê' },
        { label: 'Igaraçu do Tietê', value: 'Igaraçu do Tietê' },
        { label: 'Igarapava', value: 'Igarapava' },
        { label: 'Igaratá', value: 'Igaratá' },
        { label: 'Iguape', value: 'Iguape' },
        { label: 'Ilha Comprida', value: 'Ilha Comprida' },
        { label: 'Ilha Solteira', value: 'Ilha Solteira' },
        { label: 'Ilhabela', value: 'Ilhabela' },
        { label: 'Indaiatuba', value: 'Indaiatuba' },
        { label: 'Indiana', value: 'Indiana' },
        { label: 'Indiaporã', value: 'Indiaporã' },
        { label: 'Inúbia Paulista', value: 'Inúbia Paulista' },
        { label: 'Ipaussu', value: 'Ipaussu' },
        { label: 'Iperó', value: 'Iperó' },
        { label: 'Ipeúna', value: 'Ipeúna' },
        { label: 'Ipiguá', value: 'Ipiguá' },
        { label: 'Iporanga', value: 'Iporanga' },
        { label: 'Ipuã', value: 'Ipuã' },
        { label: 'Iracemápolis', value: 'Iracemápolis' },
        { label: 'Irapuã', value: 'Irapuã' },
        { label: 'Irapuru', value: 'Irapuru' },
        { label: 'Itaberá', value: 'Itaberá' },
        { label: 'Itaí', value: 'Itaí' },
        { label: 'Itajobi', value: 'Itajobi' },
        { label: 'Itaju', value: 'Itaju' },
        { label: 'Itanhaém', value: 'Itanhaém' },
        { label: 'Itaóca', value: 'Itaóca' },
        { label: 'Itapecerica da Serra', value: 'Itapecerica da Serra' },
        { label: 'Itapetininga', value: 'Itapetininga' },
        { label: 'Itapeva', value: 'Itapeva' },
        { label: 'Itapevi', value: 'Itapevi' },
        { label: 'Itapira', value: 'Itapira' },
        { label: 'Itapirapuã Paulista', value: 'Itapirapuã Paulista' },
        { label: 'Itápolis', value: 'Itápolis' },
        { label: 'Itaporanga', value: 'Itaporanga' },
        { label: 'Itapuí', value: 'Itapuí' },
        { label: 'Itapura', value: 'Itapura' },
        { label: 'Itaquaquecetuba', value: 'Itaquaquecetuba' },
        { label: 'Itararé', value: 'Itararé' },
        { label: 'Itariri', value: 'Itariri' },
        { label: 'Itatiba', value: 'Itatiba' },
        { label: 'Itatinga', value: 'Itatinga' },
        { label: 'Itirapina', value: 'Itirapina' },
        { label: 'Itirapuã', value: 'Itirapuã' },
        { label: 'Itobi', value: 'Itobi' },
        { label: 'Itu', value: 'Itu' },
        { label: 'Itupeva', value: 'Itupeva' },
        { label: 'Ituverava', value: 'Ituverava' },
        { label: 'Jaborandi', value: 'Jaborandi' },
        { label: 'Jaboticabal', value: 'Jaboticabal' },
        { label: 'Jacareí', value: 'Jacareí' },
        { label: 'Jaci', value: 'Jaci' },
        { label: 'Jacupiranga', value: 'Jacupiranga' },
        { label: 'Jaguariúna', value: 'Jaguariúna' },
        { label: 'Jales', value: 'Jales' },
        { label: 'Jambeiro', value: 'Jambeiro' },
        { label: 'Jandira', value: 'Jandira' },
        { label: 'Jardinópolis', value: 'Jardinópolis' },
        { label: 'Jarinu', value: 'Jarinu' },
        { label: 'Jaú', value: 'Jaú' },
        { label: 'Jeriquara', value: 'Jeriquara' },
        { label: 'Joanópolis', value: 'Joanópolis' },
        { label: 'João Ramalho', value: 'João Ramalho' },
        { label: 'José Bonifácio', value: 'José Bonifácio' },
        { label: 'Júlio Mesquita', value: 'Júlio Mesquita' },
        { label: 'Jumirim', value: 'Jumirim' },
        { label: 'Jundiaí', value: 'Jundiaí' },
        { label: 'Junqueirópolis', value: 'Junqueirópolis' },
        { label: 'Juquiá', value: 'Juquiá' },
        { label: 'Juquitiba', value: 'Juquitiba' },
        { label: 'Lagoinha', value: 'Lagoinha' },
        { label: 'Laranjal Paulista', value: 'Laranjal Paulista' },
        { label: 'Lavínia', value: 'Lavínia' },
        { label: 'Lavrinhas', value: 'Lavrinhas' },
        { label: 'Leme', value: 'Leme' },
        { label: 'Lençóis Paulista', value: 'Lençóis Paulista' },
        { label: 'Limeira', value: 'Limeira' },
        { label: 'Lindóia', value: 'Lindóia' },
        { label: 'Lins', value: 'Lins' },
        { label: 'Lorena', value: 'Lorena' },
        { label: 'Lourdes', value: 'Lourdes' },
        { label: 'Louveira', value: 'Louveira' },
        { label: 'Lucélia', value: 'Lucélia' },
        { label: 'Lucianópolis', value: 'Lucianópolis' },
        { label: 'Luís Antônio', value: 'Luís Antônio' },
        { label: 'Luiziânia', value: 'Luiziânia' },
        { label: 'Lupércio', value: 'Lupércio' },
        { label: 'Lutécia', value: 'Lutécia' },
        { label: 'Macatuba', value: 'Macatuba' },
        { label: 'Macaubal', value: 'Macaubal' },
        { label: 'Macedônia', value: 'Macedônia' },
        { label: 'Magda', value: 'Magda' },
        { label: 'Mairinque', value: 'Mairinque' },
        { label: 'Mairiporã', value: 'Mairiporã' },
        { label: 'Manduri', value: 'Manduri' },
        { label: 'Marabá Paulista', value: 'Marabá Paulista' },
        { label: 'Maracaí', value: 'Maracaí' },
        { label: 'Marapoama', value: 'Marapoama' },
        { label: 'Mariápolis', value: 'Mariápolis' },
        { label: 'Marília', value: 'Marília' },
        { label: 'Marinópolis', value: 'Marinópolis' },
        { label: 'Martinópolis', value: 'Martinópolis' },
        { label: 'Matão', value: 'Matão' },
        { label: 'Mauá', value: 'Mauá' },
        { label: 'Mendonça', value: 'Mendonça' },
        { label: 'Meridiano', value: 'Meridiano' },
        { label: 'Mesópolis', value: 'Mesópolis' },
        { label: 'Miguelópolis', value: 'Miguelópolis' },
        { label: 'Mineiros do Tietê', value: 'Mineiros do Tietê' },
        { label: 'Mira Estrela', value: 'Mira Estrela' },
        { label: 'Miracatu', value: 'Miracatu' },
        { label: 'Mirandópolis', value: 'Mirandópolis' },
        { label: 'Mirante do Paranapanema', value: 'Mirante do Paranapanema' },
        { label: 'Mirassol', value: 'Mirassol' },
        { label: 'Mirassolândia', value: 'Mirassolândia' },
        { label: 'Mococa', value: 'Mococa' },
        { label: 'Mogi das Cruzes', value: 'Mogi das Cruzes' },
        { label: 'Mogi Guaçu', value: 'Mogi Guaçu' },
        { label: 'Mogi Mirim', value: 'Mogi Mirim' },
        { label: 'Mombuca', value: 'Mombuca' },
        { label: 'Monções', value: 'Monções' },
        { label: 'Mongaguá', value: 'Mongaguá' },
        { label: 'Monte Alegre do Sul', value: 'Monte Alegre do Sul' },
        { label: 'Monte Alto', value: 'Monte Alto' },
        { label: 'Monte Aprazível', value: 'Monte Aprazível' },
        { label: 'Monte Azul Paulista', value: 'Monte Azul Paulista' },
        { label: 'Monte Castelo', value: 'Monte Castelo' },
        { label: 'Monte Mor', value: 'Monte Mor' },
        { label: 'Monteiro Lobato', value: 'Monteiro Lobato' },
        { label: 'Morro Agudo', value: 'Morro Agudo' },
        { label: 'Morungaba', value: 'Morungaba' },
        { label: 'Motuca', value: 'Motuca' },
        { label: 'Murutinga do Sul', value: 'Murutinga do Sul' },
        { label: 'Nantes', value: 'Nantes' },
        { label: 'Narandiba', value: 'Narandiba' },
        { label: 'Natividade da Serra', value: 'Natividade da Serra' },
        { label: 'Nazaré Paulista', value: 'Nazaré Paulista' },
        { label: 'Neves Paulista', value: 'Neves Paulista' },
        { label: 'Nhandeara', value: 'Nhandeara' },
        { label: 'Nipoã', value: 'Nipoã' },
        { label: 'Nova Aliança', value: 'Nova Aliança' },
        { label: 'Nova Campina', value: 'Nova Campina' },
        { label: 'Nova Canaã Paulista', value: 'Nova Canaã Paulista' },
        { label: 'Nova Castilho', value: 'Nova Castilho' },
        { label: 'Nova Europa', value: 'Nova Europa' },
        { label: 'Nova Granada', value: 'Nova Granada' },
        { label: 'Nova Guataporanga', value: 'Nova Guataporanga' },
        { label: 'Nova Independência', value: 'Nova Independência' },
        { label: 'Nova Luzitânia', value: 'Nova Luzitânia' },
        { label: 'Nova Odessa', value: 'Nova Odessa' },
        { label: 'Novais', value: 'Novais' },
        { label: 'Novo Horizonte', value: 'Novo Horizonte' },
        { label: 'Nuporanga', value: 'Nuporanga' },
        { label: 'Ocauçu', value: 'Ocauçu' },
        { label: 'Óleo', value: 'Óleo' },
        { label: 'Olímpia', value: 'Olímpia' },
        { label: 'Onda Verde', value: 'Onda Verde' },
        { label: 'Oriente', value: 'Oriente' },
        { label: 'Orindiúva', value: 'Orindiúva' },
        { label: 'Orlândia', value: 'Orlândia' },
        { label: 'Osasco', value: 'Osasco' },
        { label: 'Oscar Bressane', value: 'Oscar Bressane' },
        { label: 'Osvaldo Cruz', value: 'Osvaldo Cruz' },
        { label: 'Ourinhos', value: 'Ourinhos' },
        { label: 'Ouro Verde', value: 'Ouro Verde' },
        { label: 'Ouroeste', value: 'Ouroeste' },
        { label: 'Pacaembu', value: 'Pacaembu' },
        { label: 'Palestina', value: 'Palestina' },
        { label: 'Palmares Paulista', value: 'Palmares Paulista' },
        "Palmeira d'Oeste",
        { label: 'Palmital', value: 'Palmital' },
        { label: 'Panorama', value: 'Panorama' },
        { label: 'Paraguaçu Paulista', value: 'Paraguaçu Paulista' },
        { label: 'Paraibuna', value: 'Paraibuna' },
        { label: 'Paraíso', value: 'Paraíso' },
        { label: 'Paranapanema', value: 'Paranapanema' },
        { label: 'Paranapuã', value: 'Paranapuã' },
        { label: 'Parapuã', value: 'Parapuã' },
        { label: 'Pardinho', value: 'Pardinho' },
        { label: 'Pariquera-Açu', value: 'Pariquera-Açu' },
        { label: 'Parisi', value: 'Parisi' },
        { label: 'Patrocínio Paulista', value: 'Patrocínio Paulista' },
        { label: 'Paulicéia', value: 'Paulicéia' },
        { label: 'Paulínia', value: 'Paulínia' },
        { label: 'Paulistânia', value: 'Paulistânia' },
        { label: 'Paulo de Faria', value: 'Paulo de Faria' },
        { label: 'Pederneiras', value: 'Pederneiras' },
        { label: 'Pedra Bela', value: 'Pedra Bela' },
        { label: 'Pedranópolis', value: 'Pedranópolis' },
        { label: 'Pedregulho', value: 'Pedregulho' },
        { label: 'Pedreira', value: 'Pedreira' },
        { label: 'Pedrinhas Paulista', value: 'Pedrinhas Paulista' },
        { label: 'Pedro de Toledo', value: 'Pedro de Toledo' },
        { label: 'Penápolis', value: 'Penápolis' },
        { label: 'Pereira Barreto', value: 'Pereira Barreto' },
        { label: 'Pereiras', value: 'Pereiras' },
        { label: 'Peruíbe', value: 'Peruíbe' },
        { label: 'Piacatu', value: 'Piacatu' },
        { label: 'Piedade', value: 'Piedade' },
        { label: 'Pilar do Sul', value: 'Pilar do Sul' },
        { label: 'Pindamonhangaba', value: 'Pindamonhangaba' },
        { label: 'Pindorama', value: 'Pindorama' },
        { label: 'Pinhalzinho', value: 'Pinhalzinho' },
        { label: 'Piquerobi', value: 'Piquerobi' },
        { label: 'Piquete', value: 'Piquete' },
        { label: 'Piracaia', value: 'Piracaia' },
        { label: 'Piracicaba', value: 'Piracicaba' },
        { label: 'Piraju', value: 'Piraju' },
        { label: 'Pirajuí', value: 'Pirajuí' },
        { label: 'Pirangi', value: 'Pirangi' },
        { label: 'Pirapora do Bom Jesus', value: 'Pirapora do Bom Jesus' },
        { label: 'Pirapozinho', value: 'Pirapozinho' },
        { label: 'Pirassununga', value: 'Pirassununga' },
        { label: 'Piratininga', value: 'Piratininga' },
        { label: 'Pitangueiras', value: 'Pitangueiras' },
        { label: 'Planalto', value: 'Planalto' },
        { label: 'Platina', value: 'Platina' },
        { label: 'Poá', value: 'Poá' },
        { label: 'Poloni', value: 'Poloni' },
        { label: 'Pompéia', value: 'Pompéia' },
        { label: 'Pongaí', value: 'Pongaí' },
        { label: 'Pontal', value: 'Pontal' },
        { label: 'Pontalinda', value: 'Pontalinda' },
        { label: 'Pontes Gestal', value: 'Pontes Gestal' },
        { label: 'Populina', value: 'Populina' },
        { label: 'Porangaba', value: 'Porangaba' },
        { label: 'Porto Feliz', value: 'Porto Feliz' },
        { label: 'Porto Ferreira', value: 'Porto Ferreira' },
        { label: 'Potim', value: 'Potim' },
        { label: 'Potirendaba', value: 'Potirendaba' },
        { label: 'Pracinha', value: 'Pracinha' },
        { label: 'Pradópolis', value: 'Pradópolis' },
        { label: 'Praia Grande', value: 'Praia Grande' },
        { label: 'Pratânia', value: 'Pratânia' },
        { label: 'Presidente Alves', value: 'Presidente Alves' },
        { label: 'Presidente Bernardes', value: 'Presidente Bernardes' },
        { label: 'Presidente Epitácio', value: 'Presidente Epitácio' },
        { label: 'Presidente Prudente', value: 'Presidente Prudente' },
        { label: 'Presidente Venceslau', value: 'Presidente Venceslau' },
        { label: 'Promissão', value: 'Promissão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Quatá', value: 'Quatá' },
        { label: 'Queiroz', value: 'Queiroz' },
        { label: 'Queluz', value: 'Queluz' },
        { label: 'Quintana', value: 'Quintana' },
        { label: 'Rafard', value: 'Rafard' },
        { label: 'Rancharia', value: 'Rancharia' },
        { label: 'Redenção da Serra', value: 'Redenção da Serra' },
        { label: 'Regente Feijó', value: 'Regente Feijó' },
        { label: 'Reginópolis', value: 'Reginópolis' },
        { label: 'Registro', value: 'Registro' },
        { label: 'Restinga', value: 'Restinga' },
        { label: 'Ribeira', value: 'Ribeira' },
        { label: 'Ribeirão Bonito', value: 'Ribeirão Bonito' },
        { label: 'Ribeirão Branco', value: 'Ribeirão Branco' },
        { label: 'Ribeirão Corrente', value: 'Ribeirão Corrente' },
        { label: 'Ribeirão do Sul', value: 'Ribeirão do Sul' },
        { label: 'Ribeirão dos Índios', value: 'Ribeirão dos Índios' },
        { label: 'Ribeirão Grande', value: 'Ribeirão Grande' },
        { label: 'Ribeirão Pires', value: 'Ribeirão Pires' },
        { label: 'Ribeirão Preto', value: 'Ribeirão Preto' },
        { label: 'Rifaina', value: 'Rifaina' },
        { label: 'Rincão', value: 'Rincão' },
        { label: 'Rinópolis', value: 'Rinópolis' },
        { label: 'Rio Claro', value: 'Rio Claro' },
        { label: 'Rio das Pedras', value: 'Rio das Pedras' },
        { label: 'Rio Grande da Serra', value: 'Rio Grande da Serra' },
        { label: 'Riolândia', value: 'Riolândia' },
        { label: 'Riversul', value: 'Riversul' },
        { label: 'Rosana', value: 'Rosana' },
        { label: 'Roseira', value: 'Roseira' },
        { label: 'Rubiácea', value: 'Rubiácea' },
        { label: 'Rubinéia', value: 'Rubinéia' },
        { label: 'Sabino', value: 'Sabino' },
        { label: 'Sagres', value: 'Sagres' },
        { label: 'Sales', value: 'Sales' },
        { label: 'Sales Oliveira', value: 'Sales Oliveira' },
        { label: 'Salesópolis', value: 'Salesópolis' },
        { label: 'Salmourão', value: 'Salmourão' },
        { label: 'Saltinho', value: 'Saltinho' },
        { label: 'Salto', value: 'Salto' },
        { label: 'Salto de Pirapora', value: 'Salto de Pirapora' },
        { label: 'Salto Grande', value: 'Salto Grande' },
        { label: 'Sandovalina', value: 'Sandovalina' },
        { label: 'Santa Adélia', value: 'Santa Adélia' },
        { label: 'Santa Albertina', value: 'Santa Albertina' },
        "Santa Bárbara d'Oeste",
        { label: 'Santa Branca', value: 'Santa Branca' },
        "Santa Clara d'Oeste",
        { label: 'Santa Cruz da Conceição', value: 'Santa Cruz da Conceição' },
        { label: 'Santa Cruz da Esperança', value: 'Santa Cruz da Esperança' },
        { label: 'Santa Cruz das Palmeiras', value: 'Santa Cruz das Palmeiras' },
        { label: 'Santa Cruz do Rio Pardo', value: 'Santa Cruz do Rio Pardo' },
        { label: 'Santa Ernestina', value: 'Santa Ernestina' },
        { label: 'Santa Fé do Sul', value: 'Santa Fé do Sul' },
        { label: 'Santa Gertrudes', value: 'Santa Gertrudes' },
        { label: 'Santa Isabel', value: 'Santa Isabel' },
        { label: 'Santa Lúcia', value: 'Santa Lúcia' },
        { label: 'Santa Maria da Serra', value: 'Santa Maria da Serra' },
        { label: 'Santa Mercedes', value: 'Santa Mercedes' },
        "Santa Rita d'Oeste",
        { label: 'Santa Rita do Passa Quatro', value: 'Santa Rita do Passa Quatro' },
        { label: 'Santa Rosa de Viterbo', value: 'Santa Rosa de Viterbo' },
        { label: 'Santa Salete', value: 'Santa Salete' },
        { label: 'Santana da Ponte Pensa', value: 'Santana da Ponte Pensa' },
        { label: 'Santana de Parnaíba', value: 'Santana de Parnaíba' },
        { label: 'Santo Anastácio', value: 'Santo Anastácio' },
        { label: 'Santo André', value: 'Santo André' },
        { label: 'Santo Antônio da Alegria', value: 'Santo Antônio da Alegria' },
        { label: 'Santo Antônio de Posse', value: 'Santo Antônio de Posse' },
        { label: 'Santo Antônio do Aracanguá', value: 'Santo Antônio do Aracanguá' },
        { label: 'Santo Antônio do Jardim', value: 'Santo Antônio do Jardim' },
        { label: 'Santo Antônio do Pinhal', value: 'Santo Antônio do Pinhal' },
        { label: 'Santo Expedito', value: 'Santo Expedito' },
        { label: 'Santópolis do Aguapeí', value: 'Santópolis do Aguapeí' },
        { label: 'Santos', value: 'Santos' },
        { label: 'São Bento do Sapucaí', value: 'São Bento do Sapucaí' },
        { label: 'São Bernardo do Campo', value: 'São Bernardo do Campo' },
        { label: 'São Caetano do Sul', value: 'São Caetano do Sul' },
        { label: 'São Carlos', value: 'São Carlos' },
        { label: 'São Francisco', value: 'São Francisco' },
        { label: 'São João da Boa Vista', value: 'São João da Boa Vista' },
        { label: 'São João das Duas Pontes', value: 'São João das Duas Pontes' },
        { label: 'São João de Iracema', value: 'São João de Iracema' },
        "São João do Pau d'Alho",
        { label: 'São Joaquim da Barra', value: 'São Joaquim da Barra' },
        { label: 'São José da Bela Vista', value: 'São José da Bela Vista' },
        { label: 'São José do Barreiro', value: 'São José do Barreiro' },
        { label: 'São José do Rio Pardo', value: 'São José do Rio Pardo' },
        { label: 'São José do Rio Preto', value: 'São José do Rio Preto' },
        { label: 'São José dos Campos', value: 'São José dos Campos' },
        { label: 'São Lourenço da Serra', value: 'São Lourenço da Serra' },
        { label: 'São Luiz do Paraitinga', value: 'São Luiz do Paraitinga' },
        { label: 'São Manuel', value: 'São Manuel' },
        { label: 'São Miguel Arcanjo', value: 'São Miguel Arcanjo' },
        { label: 'São Paulo', value: 'São Paulo' },
        { label: 'São Pedro', value: 'São Pedro' },
        { label: 'São Pedro do Turvo', value: 'São Pedro do Turvo' },
        { label: 'São Roque', value: 'São Roque' },
        { label: 'São Sebastião', value: 'São Sebastião' },
        { label: 'São Sebastião da Grama', value: 'São Sebastião da Grama' },
        { label: 'São Simão', value: 'São Simão' },
        { label: 'São Vicente', value: 'São Vicente' },
        { label: 'Sarapuí', value: 'Sarapuí' },
        { label: 'Sarutaiá', value: 'Sarutaiá' },
        { label: 'Sebastianópolis do Sul', value: 'Sebastianópolis do Sul' },
        { label: 'Serra Azul', value: 'Serra Azul' },
        { label: 'Serra Negra', value: 'Serra Negra' },
        { label: 'Serrana', value: 'Serrana' },
        { label: 'Sertãozinho', value: 'Sertãozinho' },
        { label: 'Sete Barras', value: 'Sete Barras' },
        { label: 'Severínia', value: 'Severínia' },
        { label: 'Silveiras', value: 'Silveiras' },
        { label: 'Socorro', value: 'Socorro' },
        { label: 'Sorocaba', value: 'Sorocaba' },
        { label: 'Sud Mennucci', value: 'Sud Mennucci' },
        { label: 'Sumaré', value: 'Sumaré' },
        { label: 'Suzanápolis', value: 'Suzanápolis' },
        { label: 'Suzano', value: 'Suzano' },
        { label: 'Tabapuã', value: 'Tabapuã' },
        { label: 'Tabatinga', value: 'Tabatinga' },
        { label: 'Taboão da Serra', value: 'Taboão da Serra' },
        { label: 'Taciba', value: 'Taciba' },
        { label: 'Taguaí', value: 'Taguaí' },
        { label: 'Taiaçu', value: 'Taiaçu' },
        { label: 'Taiúva', value: 'Taiúva' },
        { label: 'Tambaú', value: 'Tambaú' },
        { label: 'Tanabi', value: 'Tanabi' },
        { label: 'Tapiraí', value: 'Tapiraí' },
        { label: 'Tapiratiba', value: 'Tapiratiba' },
        { label: 'Taquaral', value: 'Taquaral' },
        { label: 'Taquaritinga', value: 'Taquaritinga' },
        { label: 'Taquarituba', value: 'Taquarituba' },
        { label: 'Taquarivaí', value: 'Taquarivaí' },
        { label: 'Tarabai', value: 'Tarabai' },
        { label: 'Tarumã', value: 'Tarumã' },
        { label: 'Tatuí', value: 'Tatuí' },
        { label: 'Taubaté', value: 'Taubaté' },
        { label: 'Tejupá', value: 'Tejupá' },
        { label: 'Teodoro Sampaio', value: 'Teodoro Sampaio' },
        { label: 'Terra Roxa', value: 'Terra Roxa' },
        { label: 'Tietê', value: 'Tietê' },
        { label: 'Timburi', value: 'Timburi' },
        { label: 'Torre de Pedra', value: 'Torre de Pedra' },
        { label: 'Torrinha', value: 'Torrinha' },
        { label: 'Trabiju', value: 'Trabiju' },
        { label: 'Tremembé', value: 'Tremembé' },
        { label: 'Três Fronteiras', value: 'Três Fronteiras' },
        { label: 'Tuiuti', value: 'Tuiuti' },
        { label: 'Tupã', value: 'Tupã' },
        { label: 'Tupi Paulista', value: 'Tupi Paulista' },
        { label: 'Turiúba', value: 'Turiúba' },
        { label: 'Turmalina', value: 'Turmalina' },
        { label: 'Ubarana', value: 'Ubarana' },
        { label: 'Ubatuba', value: 'Ubatuba' },
        { label: 'Ubirajara', value: 'Ubirajara' },
        { label: 'Uchoa', value: 'Uchoa' },
        { label: 'União Paulista', value: 'União Paulista' },
        { label: 'Urânia', value: 'Urânia' },
        { label: 'Uru', value: 'Uru' },
        { label: 'Urupês', value: 'Urupês' },
        { label: 'Valentim Gentil', value: 'Valentim Gentil' },
        { label: 'Valinhos', value: 'Valinhos' },
        { label: 'Valparaíso', value: 'Valparaíso' },
        { label: 'Vargem', value: 'Vargem' },
        { label: 'Vargem Grande do Sul', value: 'Vargem Grande do Sul' },
        { label: 'Vargem Grande Paulista', value: 'Vargem Grande Paulista' },
        { label: 'Várzea Paulista', value: 'Várzea Paulista' },
        { label: 'Vera Cruz', value: 'Vera Cruz' },
        { label: 'Vinhedo', value: 'Vinhedo' },
        { label: 'Viradouro', value: 'Viradouro' },
        { label: 'Vista Alegre do Alto', value: 'Vista Alegre do Alto' },
        { label: 'Vitória Brasil', value: 'Vitória Brasil' },
        { label: 'Votorantim', value: 'Votorantim' },
        { label: 'Votuporanga', value: 'Votuporanga' },
        { label: 'Zacarias', value: 'Zacarias' },
    ],
    TO: [
        { label: 'Abreulândia', value: 'Abreulândia' },
        { label: 'Aguiarnópolis', value: 'Aguiarnópolis' },
        { label: 'Aliança do Tocantins', value: 'Aliança do Tocantins' },
        { label: 'Almas', value: 'Almas' },
        { label: 'Alvorada', value: 'Alvorada' },
        { label: 'Ananás', value: 'Ananás' },
        { label: 'Angico', value: 'Angico' },
        { label: 'Aparecida do Rio Negro', value: 'Aparecida do Rio Negro' },
        { label: 'Aragominas', value: 'Aragominas' },
        { label: 'Araguacema', value: 'Araguacema' },
        { label: 'Araguaçu', value: 'Araguaçu' },
        { label: 'Araguaína', value: 'Araguaína' },
        { label: 'Araguanã', value: 'Araguanã' },
        { label: 'Araguatins', value: 'Araguatins' },
        { label: 'Arapoema', value: 'Arapoema' },
        { label: 'Arraias', value: 'Arraias' },
        { label: 'Augustinópolis', value: 'Augustinópolis' },
        { label: 'Aurora do Tocantins', value: 'Aurora do Tocantins' },
        { label: 'Axixá do Tocantins', value: 'Axixá do Tocantins' },
        { label: 'Babaçulândia', value: 'Babaçulândia' },
        { label: 'Bandeirantes do Tocantins', value: 'Bandeirantes do Tocantins' },
        { label: 'Barra do Ouro', value: 'Barra do Ouro' },
        { label: 'Barrolândia', value: 'Barrolândia' },
        { label: 'Bernardo Sayão', value: 'Bernardo Sayão' },
        { label: 'Bom Jesus do Tocantins', value: 'Bom Jesus do Tocantins' },
        { label: 'Brasilândia do Tocantins', value: 'Brasilândia do Tocantins' },
        { label: 'Brejinho de Nazaré', value: 'Brejinho de Nazaré' },
        { label: 'Buriti do Tocantins', value: 'Buriti do Tocantins' },
        { label: 'Cachoeirinha', value: 'Cachoeirinha' },
        { label: 'Campos Lindos', value: 'Campos Lindos' },
        { label: 'Cariri do Tocantins', value: 'Cariri do Tocantins' },
        { label: 'Carmolândia', value: 'Carmolândia' },
        { label: 'Carrasco Bonito', value: 'Carrasco Bonito' },
        { label: 'Caseara', value: 'Caseara' },
        { label: 'Centenário', value: 'Centenário' },
        { label: 'Chapada da Natividade', value: 'Chapada da Natividade' },
        { label: 'Chapada de Areia', value: 'Chapada de Areia' },
        { label: 'Colinas do Tocantins', value: 'Colinas do Tocantins' },
        { label: 'Colméia', value: 'Colméia' },
        { label: 'Combinado', value: 'Combinado' },
        { label: 'Conceição do Tocantins', value: 'Conceição do Tocantins' },
        { label: 'Couto Magalhães', value: 'Couto Magalhães' },
        { label: 'Cristalândia', value: 'Cristalândia' },
        { label: 'Crixás do Tocantins', value: 'Crixás do Tocantins' },
        { label: 'Darcinópolis', value: 'Darcinópolis' },
        { label: 'Dianópolis', value: 'Dianópolis' },
        { label: 'Divinópolis do Tocantins', value: 'Divinópolis do Tocantins' },
        { label: 'Dois Irmãos do Tocantins', value: 'Dois Irmãos do Tocantins' },
        { label: 'Dueré', value: 'Dueré' },
        { label: 'Esperantina', value: 'Esperantina' },
        { label: 'Fátima', value: 'Fátima' },
        { label: 'Figueirópolis', value: 'Figueirópolis' },
        { label: 'Filadélfia', value: 'Filadélfia' },
        { label: 'Formoso do Araguaia', value: 'Formoso do Araguaia' },
        { label: 'Fortaleza do Tabocão', value: 'Fortaleza do Tabocão' },
        { label: 'Goianorte', value: 'Goianorte' },
        { label: 'Goiatins', value: 'Goiatins' },
        { label: 'Guaraí', value: 'Guaraí' },
        { label: 'Gurupi', value: 'Gurupi' },
        { label: 'Ipueiras', value: 'Ipueiras' },
        { label: 'Itacajá', value: 'Itacajá' },
        { label: 'Itaguatins', value: 'Itaguatins' },
        { label: 'Itapiratins', value: 'Itapiratins' },
        { label: 'Itaporã do Tocantins', value: 'Itaporã do Tocantins' },
        { label: 'Jaú do Tocantins', value: 'Jaú do Tocantins' },
        { label: 'Juarina', value: 'Juarina' },
        { label: 'Lagoa da Confusão', value: 'Lagoa da Confusão' },
        { label: 'Lagoa do Tocantins', value: 'Lagoa do Tocantins' },
        { label: 'Lajeado', value: 'Lajeado' },
        { label: 'Lavandeira', value: 'Lavandeira' },
        { label: 'Lizarda', value: 'Lizarda' },
        { label: 'Luzinópolis', value: 'Luzinópolis' },
        { label: 'Marianópolis do Tocantins', value: 'Marianópolis do Tocantins' },
        { label: 'Mateiros', value: 'Mateiros' },
        { label: 'Maurilândia do Tocantins', value: 'Maurilândia do Tocantins' },
        { label: 'Miracema do Tocantins', value: 'Miracema do Tocantins' },
        { label: 'Miranorte', value: 'Miranorte' },
        { label: 'Monte do Carmo', value: 'Monte do Carmo' },
        { label: 'Monte Santo do Tocantins', value: 'Monte Santo do Tocantins' },
        { label: 'Muricilândia', value: 'Muricilândia' },
        { label: 'Natividade', value: 'Natividade' },
        { label: 'Nazaré', value: 'Nazaré' },
        { label: 'Nova Olinda', value: 'Nova Olinda' },
        { label: 'Nova Rosalândia', value: 'Nova Rosalândia' },
        { label: 'Novo Acordo', value: 'Novo Acordo' },
        { label: 'Novo Alegre', value: 'Novo Alegre' },
        { label: 'Novo Jardim', value: 'Novo Jardim' },
        { label: 'Oliveira de Fátima', value: 'Oliveira de Fátima' },
        { label: 'Palmas', value: 'Palmas' },
        { label: 'Palmeirante', value: 'Palmeirante' },
        { label: 'Palmeiras do Tocantins', value: 'Palmeiras do Tocantins' },
        { label: 'Palmeirópolis', value: 'Palmeirópolis' },
        { label: 'Paraíso do Tocantins', value: 'Paraíso do Tocantins' },
        { label: 'Paranã', value: 'Paranã' },
        "Pau D'Arco",
        { label: 'Pedro Afonso', value: 'Pedro Afonso' },
        { label: 'Peixe', value: 'Peixe' },
        { label: 'Pequizeiro', value: 'Pequizeiro' },
        { label: 'Pindorama do Tocantins', value: 'Pindorama do Tocantins' },
        { label: 'Piraquê', value: 'Piraquê' },
        { label: 'Pium', value: 'Pium' },
        { label: 'Ponte Alta do Bom Jesus', value: 'Ponte Alta do Bom Jesus' },
        { label: 'Ponte Alta do Tocantins', value: 'Ponte Alta do Tocantins' },
        { label: 'Porto Alegre do Tocantins', value: 'Porto Alegre do Tocantins' },
        { label: 'Porto Nacional', value: 'Porto Nacional' },
        { label: 'Praia Norte', value: 'Praia Norte' },
        { label: 'Presidente Kennedy', value: 'Presidente Kennedy' },
        { label: 'Pugmil', value: 'Pugmil' },
        { label: 'Recursolândia', value: 'Recursolândia' },
        { label: 'Riachinho', value: 'Riachinho' },
        { label: 'Rio da Conceição', value: 'Rio da Conceição' },
        { label: 'Rio dos Bois', value: 'Rio dos Bois' },
        { label: 'Rio Sono', value: 'Rio Sono' },
        { label: 'Sampaio', value: 'Sampaio' },
        { label: 'Sandolândia', value: 'Sandolândia' },
        { label: 'Santa Fé do Araguaia', value: 'Santa Fé do Araguaia' },
        { label: 'Santa Maria do Tocantins', value: 'Santa Maria do Tocantins' },
        { label: 'Santa Rita do Tocantins', value: 'Santa Rita do Tocantins' },
        { label: 'Santa Rosa do Tocantins', value: 'Santa Rosa do Tocantins' },
        { label: 'Santa Tereza do Tocantins', value: 'Santa Tereza do Tocantins' },
        { label: 'Santa Terezinha do Tocantins', value: 'Santa Terezinha do Tocantins' },
        { label: 'São Bento do Tocantins', value: 'São Bento do Tocantins' },
        { label: 'São Félix do Tocantins', value: 'São Félix do Tocantins' },
        { label: 'São Miguel do Tocantins', value: 'São Miguel do Tocantins' },
        { label: 'São Salvador do Tocantins', value: 'São Salvador do Tocantins' },
        { label: 'São Sebastião do Tocantins', value: 'São Sebastião do Tocantins' },
        { label: 'São Valério', value: 'São Valério' },
        { label: 'Silvanópolis', value: 'Silvanópolis' },
        { label: 'Sítio Novo do Tocantins', value: 'Sítio Novo do Tocantins' },
        { label: 'Sucupira', value: 'Sucupira' },
        { label: 'Taguatinga', value: 'Taguatinga' },
        { label: 'Taipas do Tocantins', value: 'Taipas do Tocantins' },
        { label: 'Talismã', value: 'Talismã' },
        { label: 'Tocantínia', value: 'Tocantínia' },
        { label: 'Tocantinópolis', value: 'Tocantinópolis' },
        { label: 'Tupirama', value: 'Tupirama' },
        { label: 'Tupiratins', value: 'Tupiratins' },
        { label: 'Wanderlândia', value: 'Wanderlândia' },
        { label: 'Xambioá', value: 'Xambioá' },
    ],
};

export const COUNTRIES = [
    {
        label: 'Afeganistão',
        value: 'AFG',
    },
    {
        label: 'África do Sul',
        value: 'ZAF',
    },
    {
        label: 'Albânia',
        value: 'ALB',
    },
    {
        label: 'Alemanha',
        value: 'DEU',
    },
    {
        label: 'Andorra',
        value: 'AND',
    },
    {
        label: 'Angola',
        value: 'AGO',
    },
    {
        label: 'Anguilla',
        value: 'AIA',
    },
    {
        label: 'Antártida',
        value: 'ATA',
    },
    {
        label: 'Antígua e Barbuda',
        value: 'ATG',
    },
    {
        label: 'Antilhas Holandesas',
        value: 'ANT',
    },
    {
        label: 'Arábia Saudita',
        value: 'SAU',
    },
    {
        label: 'Argélia',
        value: 'DZA',
    },
    {
        label: 'Argentina',
        value: 'ARG',
    },
    {
        label: 'Armênia',
        value: 'ARM',
    },
    {
        label: 'Aruba',
        value: 'ABW',
    },
    {
        label: 'Austrália',
        value: 'AUS',
    },
    {
        label: 'Áustria',
        value: 'AUT',
    },
    {
        label: 'Azerbaijão',
        value: 'AZE',
    },
    {
        label: 'Bahamas',
        value: 'BHS',
    },
    {
        label: 'Bahrein',
        value: 'BHR',
    },
    {
        label: 'Bangladesh',
        value: 'BGD',
    },
    {
        label: 'Barbados',
        value: 'BRB',
    },
    {
        label: 'Belarus',
        value: 'BLR',
    },
    {
        label: 'Bélgica',
        value: 'BEL',
    },
    {
        label: 'Belize',
        value: 'BLZ',
    },
    {
        label: 'Benin',
        value: 'BEN',
    },
    {
        label: 'Bermudas',
        value: 'BMU',
    },
    {
        label: 'Bolívia',
        value: 'BOL',
    },
    {
        label: 'Bósnia-Herzegóvina',
        value: 'BIH',
    },
    {
        label: 'Botsuana',
        value: 'BWA',
    },
    {
        label: 'Brasil',
        value: 'BRA',
    },
    {
        label: 'Brunei',
        value: 'BRN',
    },
    {
        label: 'Bulgária',
        value: 'BGR',
    },
    {
        label: 'Burkina Fasso',
        value: 'BFA',
    },
    {
        label: 'Burundi',
        value: 'BDI',
    },
    {
        label: 'Butão',
        value: 'BTN',
    },
    {
        label: 'Cabo Verde',
        value: 'CPV',
    },
    {
        label: 'Camarões',
        value: 'CMR',
    },
    {
        label: 'Camboja',
        value: 'KHM',
    },
    {
        label: 'Canadá',
        value: 'CAN',
    },
    {
        label: 'Cazaquistão',
        value: 'KAZ',
    },
    {
        label: 'Chade',
        value: 'TCD',
    },
    {
        label: 'Chile',
        value: 'CHL',
    },
    {
        label: 'China',
        value: 'CHN',
    },
    {
        label: 'Chipre',
        value: 'CYP',
    },
    {
        label: 'Cingapura',
        value: 'SGP',
    },
    {
        label: 'Colômbia',
        value: 'COL',
    },
    {
        label: 'Congo',
        value: 'COG',
    },
    {
        label: 'Coréia do Norte',
        value: 'PRK',
    },
    {
        label: 'Coréia do Sul',
        value: 'KOR',
    },
    {
        label: 'Costa do Marfim',
        value: 'CIV',
    },
    {
        label: 'Costa Rica',
        value: 'CRI',
    },
    {
        label: 'Croácia (Hrvatska)',
        value: 'HRV',
    },
    {
        label: 'Cuba',
        value: 'CUB',
    },
    {
        label: 'Curaçau',
        value: 'CUW',
    },
    {
        label: 'Dinamarca',
        value: 'DNK',
    },
    {
        label: 'Djibuti',
        value: 'DJI',
    },
    {
        label: 'Dominica',
        value: 'DMA',
    },
    {
        label: 'Egito',
        value: 'EGY',
    },
    {
        label: 'El Salvador',
        value: 'SLV',
    },
    {
        label: 'Emirados Árabes Unidos',
        value: 'ARE',
    },
    {
        label: 'Equador',
        value: 'ECU',
    },
    {
        label: 'Eritréia',
        value: 'ERI',
    },
    {
        label: 'Eslováquia',
        value: 'SVK',
    },
    {
        label: 'Eslovênia',
        value: 'SVN',
    },
    {
        label: 'Espanha',
        value: 'ESP',
    },
    {
        label: 'Estados Unidos',
        value: 'USA',
    },
    {
        label: 'Estônia',
        value: 'EST',
    },
    {
        label: 'Etiópia',
        value: 'ETH',
    },
    {
        label: 'Fiji',
        value: 'FJI',
    },
    {
        label: 'Filipinas',
        value: 'PHL',
    },
    {
        label: 'Finlândia',
        value: 'FIN',
    },
    {
        label: 'França',
        value: 'FRA',
    },
    {
        label: 'Gabão',
        value: 'GAB',
    },
    {
        label: 'Gâmbia',
        value: 'GMB',
    },
    {
        label: 'Gana',
        value: 'GHA',
    },
    {
        label: 'Geórgia',
        value: 'GEO',
    },
    {
        label: 'Gibraltar',
        value: 'GIB',
    },
    {
        label: 'Grã-Bretanha (Reino Unido, UK)',
        value: 'GBR',
    },
    {
        label: 'Granada',
        value: 'GRD',
    },
    {
        label: 'Grécia',
        value: 'GRC',
    },
    {
        label: 'Groelândia',
        value: 'GRL',
    },
    {
        label: 'Guadalupe',
        value: 'GLP',
    },
    {
        label: 'Guam (Território dos Estados Unidos)',
        value: 'GUM',
    },
    {
        label: 'Guatemala',
        value: 'GTM',
    },
    {
        label: 'Guernsey',
        value: 'GGY',
    },
    {
        label: 'Guiana',
        value: 'GUY',
    },
    {
        label: 'Guiana Francesa',
        value: 'GUF',
    },
    {
        label: 'Guiné',
        value: 'GIN',
    },
    {
        label: 'Guiné Equatorial',
        value: 'GNQ',
    },
    {
        label: 'Guiné-Bissau',
        value: 'GNB',
    },
    {
        label: 'Haiti',
        value: 'HTI',
    },
    {
        label: 'Holanda',
        value: 'NLD',
    },
    {
        label: 'Honduras',
        value: 'HND',
    },
    {
        label: 'Hong Kong',
        value: 'HKG',
    },
    {
        label: 'Hungria',
        value: 'HUN',
    },
    {
        label: 'Iêmen',
        value: 'YEM',
    },
    {
        label: 'Ilha Bouvet (Território da Noruega)',
        value: 'BVT',
    },
    {
        label: 'Ilha do Homem',
        value: 'IMN',
    },
    {
        label: 'Ilha Natal',
        value: 'CXR',
    },
    {
        label: 'Ilha Pitcairn',
        value: 'PCN',
    },
    {
        label: 'Ilha Reunião',
        value: 'REU',
    },
    {
        label: 'Ilhas Aland',
        value: 'ALA',
    },
    {
        label: 'Ilhas Cayman',
        value: 'CYM',
    },
    {
        label: 'Ilhas Cocos',
        value: 'CCK',
    },
    {
        label: 'Ilhas Comores',
        value: 'COM',
    },
    {
        label: 'Ilhas Cook',
        value: 'COK',
    },
    {
        label: 'Ilhas Faroes',
        value: 'FRO',
    },
    {
        label: 'Ilhas Falkland (Malvinas)',
        value: 'FLK',
    },
    {
        label: 'Ilhas Geórgia do Sul e Sandwich do Sul',
        value: 'SGS',
    },
    {
        label: 'Ilhas Heard e McDonald (Território da Austrália)',
        value: 'HMD',
    },
    {
        label: 'Ilhas Marianas do Norte',
        value: 'MNP',
    },
    {
        label: 'Ilhas Marshall',
        value: 'MHL',
    },
    {
        label: 'Ilhas Menores dos Estados Unidos',
        value: 'UMI',
    },
    {
        label: 'Ilhas Norfolk',
        value: 'NFK',
    },
    {
        label: 'Ilhas Seychelles',
        value: 'SYC',
    },
    {
        label: 'Ilhas Solomão',
        value: 'SLB',
    },
    {
        label: 'Ilhas Svalbard e Jan Mayen',
        value: 'SJM',
    },
    {
        label: 'Ilhas Tokelau',
        value: 'TKL',
    },
    {
        label: 'Ilhas Turks e Caicos',
        value: 'TCA',
    },
    {
        label: 'Ilhas Virgens (Estados Unidos)',
        value: 'VIR',
    },
    {
        label: 'Ilhas Virgens (Inglaterra)',
        value: 'VGB',
    },
    {
        label: 'Ilhas Wallis e Futuna',
        value: 'WLF',
    },
    {
        label: 'índia',
        value: 'IND',
    },
    {
        label: 'Indonésia',
        value: 'IDN',
    },
    {
        label: 'Irã',
        value: 'IRN',
    },
    {
        label: 'Iraque',
        value: 'IRQ',
    },
    {
        label: 'Irlanda',
        value: 'IRL',
    },
    {
        label: 'Islândia',
        value: 'ISL',
    },
    {
        label: 'Israel',
        value: 'ISR',
    },
    {
        label: 'Itália',
        value: 'ITA',
    },
    {
        label: 'Jamaica',
        value: 'JAM',
    },
    {
        label: 'Japão',
        value: 'JPN',
    },
    {
        label: 'Jersey',
        value: 'JEY',
    },
    {
        label: 'Jordânia',
        value: 'JOR',
    },
    {
        label: 'Kênia',
        value: 'KEN',
    },
    {
        label: 'Kiribati',
        value: 'KIR',
    },
    {
        label: 'Kuait',
        value: 'KWT',
    },
    {
        label: 'Laos',
        value: 'LAO',
    },
    {
        label: 'Látvia',
        value: 'LVA',
    },
    {
        label: 'Lesoto',
        value: 'LSO',
    },
    {
        label: 'Líbano',
        value: 'LBN',
    },
    {
        label: 'Libéria',
        value: 'LBR',
    },
    {
        label: 'Líbia',
        value: 'LBY',
    },
    {
        label: 'Liechtenstein',
        value: 'LIE',
    },
    {
        label: 'Lituânia',
        value: 'LTU',
    },
    {
        label: 'Luxemburgo',
        value: 'LUX',
    },
    {
        label: 'Macau',
        value: 'MAC',
    },
    {
        label: 'Macedônia (República Yugoslava)',
        value: 'MKD',
    },
    {
        label: 'Madagascar',
        value: 'MDG',
    },
    {
        label: 'Malásia',
        value: 'MYS',
    },
    {
        label: 'Malaui',
        value: 'MWI',
    },
    {
        label: 'Maldivas',
        value: 'MDV',
    },
    {
        label: 'Mali',
        value: 'MLI',
    },
    {
        label: 'Malta',
        value: 'MLT',
    },
    {
        label: 'Marrocos',
        value: 'MAR',
    },
    {
        label: 'Martinica',
        value: 'MTQ',
    },
    {
        label: 'Maurício',
        value: 'MUS',
    },
    {
        label: 'Mauritânia',
        value: 'MRT',
    },
    {
        label: 'Mayotte',
        value: 'MYT',
    },
    {
        label: 'México',
        value: 'MEX',
    },
    {
        label: 'Micronésia',
        value: 'FSM',
    },
    {
        label: 'Moçambique',
        value: 'MOZ',
    },
    {
        label: 'Moldova',
        value: 'MDA',
    },
    {
        label: 'Mônaco',
        value: 'MCO',
    },
    {
        label: 'Mongólia',
        value: 'MNG',
    },
    {
        label: 'Montenegro',
        value: 'MNE',
    },
    {
        label: 'Montserrat',
        value: 'MSR',
    },
    {
        label: 'Myanma',
        value: 'MMR',
    },
    {
        label: 'Namíbia',
        value: 'NAM',
    },
    {
        label: 'Nauru',
        value: 'NRU',
    },
    {
        label: 'Nepal',
        value: 'NPL',
    },
    {
        label: 'Nicarágua',
        value: 'NIC',
    },
    {
        label: 'Níger',
        value: 'NER',
    },
    {
        label: 'Nigéria',
        value: 'NGA',
    },
    {
        label: 'Niue',
        value: 'NIU',
    },
    {
        label: 'Noruega',
        value: 'NOR',
    },
    {
        label: 'Nova Caledônia',
        value: 'NCL',
    },
    {
        label: 'Nova Zelândia',
        value: 'NZL',
    },
    {
        label: 'Omã',
        value: 'OMN',
    },
    {
        label: 'Países Baixos Caribenhos',
        value: 'BES',
    },
    {
        label: 'Palau',
        value: 'PLW',
    },
    {
        label: 'Panamá',
        value: 'PAN',
    },
    {
        label: 'Papua-Nova Guiné',
        value: 'PNG',
    },
    {
        label: 'Paquistão',
        value: 'PAK',
    },
    {
        label: 'Paraguai',
        value: 'PRY',
    },
    {
        label: 'Peru',
        value: 'PER',
    },
    {
        label: 'Polinésia Francesa',
        value: 'PYF',
    },
    {
        label: 'Polônia',
        value: 'POL',
    },
    {
        label: 'Porto Rico',
        value: 'PRI',
    },
    {
        label: 'Portugal',
        value: 'PRT',
    },
    {
        label: 'Qatar',
        value: 'QAT',
    },
    {
        label: 'Quirguistão',
        value: 'KGZ',
    },
    {
        label: 'República Centro-Africana',
        value: 'CAF',
    },
    {
        label: 'República Democrática do Congo',
        value: 'COD',
    },
    {
        label: 'República Dominicana',
        value: 'DOM',
    },
    {
        label: 'República Tcheca',
        value: 'CZE',
    },
    {
        label: 'Romênia',
        value: 'ROU',
    },
    {
        label: 'Ruanda',
        value: 'RWA',
    },
    {
        label: 'Rússia (antiga URSS) - Federação Russa',
        value: 'RUS',
    },
    {
        label: 'Saara Ocidental',
        value: 'ESH',
    },
    {
        label: 'Saint Vincente e Granadinas',
        value: 'VCT',
    },
    {
        label: 'Samoa Americana',
        value: 'ASM',
    },
    {
        label: 'Samoa Ocidental',
        value: 'WSM',
    },
    {
        label: 'San Marino',
        value: 'SMR',
    },
    {
        label: 'Santa Helena',
        value: 'SHN',
    },
    {
        label: 'Santa Lúcia',
        value: 'LCA',
    },
    {
        label: 'São Bartolomeu',
        value: 'BLM',
    },
    {
        label: 'São Cristóvão e Névis',
        value: 'KNA',
    },
    {
        label: 'São Martim',
        value: 'MAF',
    },
    {
        label: 'São Martinho (Países Baixos)',
        value: 'SXM',
    },
    {
        label: 'São Tomé e Príncipe',
        value: 'STP',
    },
    {
        label: 'Senegal',
        value: 'SEN',
    },
    {
        label: 'Serra Leoa',
        value: 'SLE',
    },
    {
        label: 'Sérvia',
        value: 'SRB',
    },
    {
        label: 'Síria',
        value: 'SYR',
    },
    {
        label: 'Somália',
        value: 'SOM',
    },
    {
        label: 'Sri Lanka',
        value: 'LKA',
    },
    {
        label: 'St. Pierre and Miquelon',
        value: 'SPM',
    },
    {
        label: 'Suazilândia',
        value: 'SWZ',
    },
    {
        label: 'Sudão',
        value: 'SDN',
    },
    {
        label: 'Sudão do Sul',
        value: 'SSD',
    },
    {
        label: 'Suécia',
        value: 'SWE',
    },
    {
        label: 'Suíça',
        value: 'CHE',
    },
    {
        label: 'Suriname',
        value: 'SUR',
    },
    {
        label: 'Tadjiquistão',
        value: 'TJK',
    },
    {
        label: 'Tailândia',
        value: 'THA',
    },
    {
        label: 'Taiwan',
        value: 'TWN',
    },
    {
        label: 'Tanzânia',
        value: 'TZA',
    },
    {
        label: 'Território Britânico do Oceano índico',
        value: 'IOT',
    },
    {
        label: 'Territórios do Sul da França',
        value: 'ATF',
    },
    {
        label: 'Territórios Palestinos Ocupados',
        value: 'PSE',
    },
    {
        label: 'Timor Leste',
        value: 'TLS',
    },
    {
        label: 'Togo',
        value: 'TGO',
    },
    {
        label: 'Tonga',
        value: 'TON',
    },
    {
        label: 'Trinidad and Tobago',
        value: 'TTO',
    },
    {
        label: 'Tunísia',
        value: 'TUN',
    },
    {
        label: 'Turcomenistão',
        value: 'TKM',
    },
    {
        label: 'Turquia',
        value: 'TUR',
    },
    {
        label: 'Tuvalu',
        value: 'TUV',
    },
    {
        label: 'Ucrânia',
        value: 'UKR',
    },
    {
        label: 'Uganda',
        value: 'UGA',
    },
    {
        label: 'Uruguai',
        value: 'URY',
    },
    {
        label: 'Uzbequistão',
        value: 'UZB',
    },
    {
        label: 'Vanuatu',
        value: 'VUT',
    },
    {
        label: 'Vaticano',
        value: 'VAT',
    },
    {
        label: 'Venezuela',
        value: 'VEN',
    },
    {
        label: 'Vietnã',
        value: 'VNM',
    },
    {
        label: 'Zâmbia',
        value: 'ZMB',
    },
    {
        label: 'Zimbábue',
        value: 'ZWE',
    },
];

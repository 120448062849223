import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@components/atoms';
import { CustomContainer, Slider } from '@components/molecules';
import t from '@services/locale';

const CriticalUncertaintiesQuestion = ({
    control, formState: { errors }, idx, question,
}) => {
    const importance_idx = question.num_order - 1;
    const uncertainty_idx = question.num_order;

    return (
        <CustomContainer>
            <Text bold color="black" translate={false}>
                {idx + 1}
                .&nbsp;
                {question.statement}
            </Text>
            <Text translate={false}>{question.context}</Text>

            <Text bold>Importância</Text>
            <Slider
                control={control}
                error={errors?.answers?.importance_idx?.answer?.message}
                max={5}
                max_label={t('Alta')}
                min={1}
                min_label={t('Baixa')}
                name={`answers.${importance_idx}.answer`}
                steps={4}
                type="discrete"
            />

            <Text bold>Probabilidade de Ocorrência</Text>
            <Slider
                control={control}
                error={errors?.answers?.uncertainty_idx?.answer?.message}
                max={100}
                max_label="100%"
                min={0}
                min_label="0%"
                name={`answers.${uncertainty_idx}.answer`}
                steps={10}
                type="discrete"
            />
        </CustomContainer>
    );
};

CriticalUncertaintiesQuestion.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            answers: PropTypes.shape({
                importance_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
                uncertainty_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
            }),
        }),
    }).isRequired,
    idx: PropTypes.number.isRequired,
    question: PropTypes.shape({
        context: PropTypes.string,
        num_order: PropTypes.number,
        statement: PropTypes.string,
    }).isRequired,
};

export default CriticalUncertaintiesQuestion;

import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import { GetParsedColor } from '@styles/colors';
import { Box, Text } from '@components/atoms';

const RadioForm = (props) => {
    const {
        control,
        defaultValue,
        error,
        name,
        options,
    } = props;

    const dark_grey = GetParsedColor('grey.900');
    const light_grey = GetParsedColor('grey.500');
    const green = GetParsedColor('green');
    const blue = GetParsedColor('blue');
    const theme = createTheme({
        overrides: {
            MuiSelect: { icon: { color: light_grey } },
        },
        palette: {
            primary: { main: green },
            secondary: { main: blue },
            text: { primary: dark_grey, secondary: dark_grey },
        },
        typography: { allVariants: { color: dark_grey } },
    });

    return (
        <Box ml="1vw">
            <ThemeProvider theme={theme}>
                <Controller
                    control={control}
                    defaultValue={defaultValue || null}
                    name={name}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup onChange={(e) => onChange(e)}>
                            {options.map((v, i) => (
                                <FormControlLabel
                                    checked={v === value}
                                    control={<Radio sx={{ marginTop: -1 }} />}
                                    key={i}
                                    label={v}
                                    sx={{ alignItems: 'flex-start', marginBottom: '2vh' }}
                                    value={v}
                                />
                            ))}
                        </RadioGroup>
                    )}
                />
            </ThemeProvider>
            {error && typeof error === 'string' && (
                <Text color="red" fontSize={13} ml={2} mt={0.5}>
                    {error}
                </Text>
            )}
        </Box>
    );
};

RadioForm.defaultProps = {
    error: undefined,
};

RadioForm.propTypes = {
    error: PropTypes.string,
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default RadioForm;

import React from 'react';
import PropTypes from 'prop-types';

import { CriticalUncertaintiesQuestion } from '@components/organisms';

const CriticalUncertaintiesForm = ({ control, formState, getValues }) => {
    let fields = getValues().answers;
    fields = fields.filter((value, index) => !(index % 2));
    return (
        <>
            {fields.map((question, idx) => (
                <CriticalUncertaintiesQuestion
                    control={control}
                    formState={formState}
                    idx={idx}
                    question={question}
                />
            ))}
        </>
    );
};

CriticalUncertaintiesForm.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            answers: PropTypes.shape({
                importance_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
                uncertainty_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
            }),
        }),
    }).isRequired,
    getValues: PropTypes.func.isRequired,
};

export default CriticalUncertaintiesForm;

/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
    'Boas-vindas!': 'Boas-vindas!',
    'Esqueceu sua senha?': 'Esqueceu sua senha?',
    Senha: 'Senha',
    ENVIAR: 'ENVIAR',
    Enviar: 'Enviar',

    // Invitee Metadata
    Nome: 'Nome',
    'E-mail': 'E-mail',
    País: 'País',
    UF: 'UF',
    'Estou fora do Brasil': 'Estou fora do Brasil',
    Cidade: 'Cidade',
    'Última titulação': 'Última titulação',
    Sexo: 'Sexo',
    'Vínculo Institucional': 'Vínculo institucional',
    Doutorado: 'Doutorado',
    Mestrado: 'Mestrado',
    Graduação: 'Graduação',
    'Pós-Doutorado': 'Pós-Doutorado',
    'Pós-Graduação': 'Pós-Graduação',
    Outros: 'Outros',
    Masculino: 'Masculino',
    Feminino: 'Feminino',
    'Apagar meus dados': 'Apagar meus dados',
    'Instituição de ensino e pesquisa pública': 'Instituição de ensino e pesquisa pública',
    'Instituição de ensino e pesquisa privada': 'Instituição de ensino e pesquisa privada',
    'Administração pública': 'Administração pública',
    'Empresa privada': 'Empresa privada',
    'Empresa pública': 'Empresa pública',
    'Economia mista': 'Economia mista',
    'Terceiro Setor': 'Terceiro Setor',

    // Questions
    'Digite sua resposta': 'Digite sua resposta',
    'Digite um número inteiro': 'Digite um número inteiro',
    'Digite um número': 'Digite um número',
    'Não desejo responder': 'Não desejo responder',
    'Pergunta não obrigatória tooltext': 'Pergunta não obrigatória.  Selecione a caixa para não enviar resposta a essa questão.',
    'Mediana das respostas dos outros participantes da pesquisa': 'Mediana das respostas dos outros participantes da pesquisa',
    'Digite um número inteiro entre': 'Digite um número inteiro entre',
    'Digite um número entre': 'Digite um número entre',
    e: 'e',

    // Actions
    'Salvar rascunho': 'Salvar rascunho',
    Fechar: 'Fechar',
    Salvar: 'Salvar',
    'Após o rascunho ser salvo, você pode fechar a pesquisa e continuá-la em outro momento pelo link enviado a seu email': 'Após o rascunho ser salvo, você pode fechar a pesquisa e continuá-la em outro momento pelo link enviado a seu email',
    'Confirmar envio': 'Confirmar envio',
    'Gostaria de confirmar o envio das respostas?': 'Gostaria de confirmar o envio das respostas?',
    Ok: 'Ok',

    // Yup validations
    'Pergunta obrigatória': 'Pergunta obrigatória',
    'O valor mínimo para esta questão é': 'O valor mínimo para esta questão é',
    'O valor máximo para esta questão é': 'O valor máximo para esta questão é',
    'Email inválido': 'Email inválido',
    'A UF é obrigatória': 'A UF é obrigatória',
    'A cidade é obrigatória': 'A cidade é obrigatória',
    'A ocupação é obrigatória': 'A ocupação é obrigatória',
    'A titulação é obrigatória': 'A titulação é obrigatória',
    'Especificação da titulação é obrigatório': 'Especificação da titulação é obrigatório',
    'O nome é obrigatório': 'O nome é obrigatório',
    'O email é obrigatório': 'O email é obrigatório',
    'O sexo é obrigatório': 'O sexo é obrigatório',
    'Dia de nascimento é obrigatório': 'Dia de nascimento é obrigatório',
    'Senha é obrigatória': 'Senha é obrigatória',
    'Senha deve conter 6 caracteres': 'Senha deve conter 6 caracteres',
    'Senha deve ter no mínimo um caractere especial': 'Senha deve ter no mínimo um caractere especial',
    'Senha deve ter no mínimo uma letra maiúscula': 'Senha deve ter no mínimo uma letra maiúscula',
    'Respostas incompletas': 'Respostas incompletas',
    'O valor deve ser inteiro': 'O valor deve ser inteiro',
    'Pergunta X obrigatória': 'Pergunta %{x} obrigatória',

    // Submission made
    'Sua resposta foi registrada com sucesso': 'Sua resposta foi registrada com sucesso.',
    'Seu rascunho foi salvo e o link para você continuar a pesquisa foi mandado a seu email': 'Seu rascunho foi salvo e o link para você continuar a pesquisa foi mandado a seu email',

    // Actor motricity
    'descrição pesquisa motricidade do ator': 'Preencha as seções a seguir com valores que representam a capacidade que o ator da esquerda tem de alterar o comportamento da variável da direita, sendo que:\n0 – Não exerce influência \n1 – Exerce influência baixa \n2 – Exerce influência mediana \n3 – Exerce influência decisiva para a ocorrência ou não do evento',
    'Indique um valor para a capacidade do ator da esquerda de interferir na variável da direita': 'Indique um valor para a capacidade do ator da esquerda de interferir na variável da direita',
    'Ator seguinte': 'Ator seguinte',
    'Ator anterior': 'Ator anterior',
    'Respondendo seção': 'Respondendo seção',
    de: 'de',

    // Motricity and dependency
    'descrição pesquisa motricidade e dependência': 'Preencha as seções a seguir com valores que representam a capacidade que a variável da esquerda tem de alterar o comportamento da variável da direita, sendo que:\n0 – Não exerce influência \n1 – Exerce influência baixa \n2 – Exerce influência mediana \n3 – Exerce influência decisiva para a ocorrência ou não do evento',
    'Variável seguinte': 'Variável seguinte',
    'Variável anterior': 'Variável anterior',
    'Indique um valor para a capacidade da variável da esquerda de interferir na variável da direita': 'Indique um valor para a capacidade da variável da esquerda de interferir na variável da direita',

    // Critical Uncertainties
    Baixa: 'Baixa',
    Alta: 'Alta',
    Importância: 'Importância',
    'Probabilidade de Ocorrência': 'Probabilidade de Ocorrência',

    // Eisenhower
    Urgência: 'Urgência',
};

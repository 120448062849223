const parseData = (data) => {
    try {
        return JSON.parse(data);
    } catch (error) {
        return data;
    }
};

export const setItem = async (key, value) => {
    if (value) {
        try {
            const data = typeof value === 'object' || Array.isArray(value)
                ? JSON.stringify(value)
                : value;
            await localStorage.setItem(key, data);
        } catch (error) {
            console.warn(`[Error saving ${key}] : ${error}`);
        }
    }
};

export const getItem = async (key) => {
    if (key) {
        try {
            const result = await localStorage.getItem(key);
            if (!result || !Object.keys(result).length)
                return null;
            return parseData(result);
        } catch (error) {
            console.warn(`[Error getting ${key}] : ${error}`);
        }
    }
    return null;
};

export const mergeItem = async (key, value) => {
    if (value) {
        try {
            await localStorage.mergeItem(key, JSON.stringify(value));
            const result = await getItem(key);
            return result;
        } catch (error) {
            console.warn(`[Error updating ${key}] : ${error}`);
        }
    }
    return value;
};

export const removeItem = async (key) => {
    if (key) {
        try {
            await localStorage.removeItem(key);
        } catch (error) {
            console.warn(`[Error removing ${key}] : ${error}`);
        }
    }
    return key;
};

export const clearStorage = async () => {
    try {
        await localStorage.clear();
    } catch (error) {
        console.warn(`[Error when clear storage] : ${error}`);
    }
};

import React from 'react';
import PropTypes from 'prop-types';

import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import t from '@services/locale';

import BaseText from './styles';

const Text = ({
    align,
    bold,
    children,
    color,
    fontSize,
    ml,
    mt,
    onClick,
    style,
    translate = true,
}) => (
    <BaseText
        align={align}
        bold={bold}
        color={color || 'grey.800'}
        ml={ml}
        mt={mt}
        style={{ ...style, fontSize: fontSize || 15 }}
        translate={translate}
        onClick={onClick}
    >
        {typeof children === 'string' && translate ? t(children) : children}
    </BaseText>
);

Text.defaultProps = {
    children: undefined,
    onClick: undefined,
    translate: undefined,
};

Text.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

Text.Title = (props) => {
    const {
        align, children, color, fontSize, ml, mt, style, translate,
    } = props;
    return (
        <Text
            bold
            align={align}
            color={color || 'grey.800'}
            fontSize={fontSize || 20}
            ml={ml}
            mt={mt}
            style={style}
            translate={translate}
        >
            {children}
        </Text>
    );
};

Text.Title.defaultProps = {
    children: undefined,
    translate: undefined,
};

Text.Title.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

Text.SubTitle = (props) => {
    const {
        align, children, color, fontSize, ml, mt, style, translate,
    } = props;
    return (
        <Text
            align={align}
            color={color || 'grey.600'}
            fontSize={fontSize || 18}
            ml={ml}
            mt={mt}
            style={style}
            translate={translate}
        >
            {children}
        </Text>
    );
};

Text.SubTitle.defaultProps = {
    children: undefined,
    translate: undefined,
};

Text.SubTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

Text.Paragraph = (props) => {
    const {
        align, children, color, fontSize, ml, mt, style, translate,
    } = props;
    return (
        <Text
            align={align}
            color={color || 'grey.600'}
            fontSize={fontSize || 15}
            ml={ml}
            mt={mt}
            style={style}
            translate={translate}
        >
            {children}
        </Text>
    );
};

Text.Paragraph.defaultProps = {
    children: undefined,
    translate: undefined,
};

Text.Paragraph.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

Text.ButtonText = (props) => {
    const {
        align, bold, children, color, fontSize, ml, mt, style, translate,
    } = props;
    return (
        <Text
            align={align}
            bold={bold}
            color={color || 'grey.700'}
            fontSize={fontSize || 15}
            ml={ml}
            mt={mt}
            style={style}
            translate={translate}
        >
            {children}
        </Text>
    );
};

Text.ButtonText.defaultProps = {
    children: undefined,
    translate: undefined,
};

Text.ButtonText.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

Text.LinkBold = (props) => {
    const {
        align, bold, children, color, fontSize, ml, mt, style, translate,
    } = props;
    return (
        <Text
            underline
            align={align}
            bold={bold}
            color={color || 'grey.800'}
            fontSize={fontSize || 15}
            ml={ml}
            mt={mt}
            style={style}
            translate={translate}
        >
            {children}
        </Text>
    );
};

Text.LinkBold.defaultProps = {
    children: undefined,
    translate: undefined,
};

Text.LinkBold.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

Text.Link = (props) => {
    const {
        align, bold, children, color, fontSize, ml, mt, style, translate,
    } = props;
    return (
        <Text
            underline
            align={align}
            bold={bold}
            color={color || 'grey.600'}
            fontSize={fontSize || 17}
            ml={ml}
            mt={mt}
            style={style}
            translate={translate}
        >
            {children}
        </Text>
    );
};

Text.Link.defaultProps = {
    children: undefined,
    translate: undefined,
};

Text.Link.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
        PropTypes.func,
    ]),
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    translate: PropTypes.bool,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default Text;

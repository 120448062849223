import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// Material UI
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import { GetParsedColor } from '@styles/colors';
import { Box } from '@components/atoms';
import { capitalizeFirstLetters } from '@services/utils';

import t from '@services/locale';

const Dropdown = (props) => {
    const {
        control,
        defaultValue,
        items,
        ml,
        mt,
        name,
        onClose,
        placeholder,
        translate = true,
        valueTranslate = false,
        width,
    } = props;

    const dark_grey = GetParsedColor('grey.900');
    const light_grey = GetParsedColor('grey.500');
    const green = GetParsedColor('green');
    const blue = GetParsedColor('blue');
    const theme = createTheme({
        overrides: {
            MuiSelect: { icon: { color: light_grey } },
        },
        palette: {
            primary: { main: green },
            secondary: { main: blue },
            text: { primary: dark_grey, secondary: dark_grey },
        },
        typography: { allVariants: { color: light_grey } },
    });

    return (
        <>
            <Box ml={ml} mt={mt}>
                <ThemeProvider theme={theme}>
                    <Controller
                        control={control}
                        defaultValue={defaultValue || ''}
                        name={name}
                        render={({ field, fieldState }) => (
                            <FormControl style={{ marginBottom: '2vh', width: width || '20vw' }}>
                                <TextField
                                    select
                                    error={(!!fieldState.error)}
                                    helperText={fieldState?.error?.message}
                                    label={translate ? t(placeholder) : placeholder}
                                    name={field.name}
                                    ref={field.ref}
                                    style={{ color: dark_grey, width: '100%' }}
                                    value={field.value}
                                    onBlur={field.onBlur}
                                    onChange={(e) => {
                                        if (onClose)
                                            onClose(e.target.value);
                                        field.onChange(e);
                                    }}
                                >
                                    {items.map((item, index) => {
                                        let itemText = typeof (item) === 'object' ? item.label : item;
                                        itemText = valueTranslate ? t(itemText) : itemText;
                                        return (
                                            <MenuItem key={index} value={typeof (item) === 'object' ? item.value : item}>
                                                {capitalizeFirstLetters(itemText)}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormControl>
                        )}
                    />
                </ThemeProvider>
            </Box>
        </>
    );
};

Dropdown.propTypes = {
    placeholder: PropTypes.string.isRequired,
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default Dropdown;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from '@components/atoms';

import t from '@services/locale';

const MotricityProgress = ({ currentQuestion, totalQuestions }, ref) => (
    <Box ref={ref}>
        <Text.Title align="center">
            {t('Respondendo seção')}
            {' '}
            {currentQuestion}
            {' '}
            {t('de')}
            {' '}
            {totalQuestions}
        </Text.Title>
    </Box>
);

MotricityProgress.propTypes = {
    currentQuestion: PropTypes.number.isRequired,
    totalQuestions: PropTypes.number.isRequired,
};

export default forwardRef(MotricityProgress);

import React from 'react';
import PropTypes from 'prop-types';

import { GetParsedColor } from '@styles/colors';
import { Box, Text } from '@components/atoms';
import device from '@styles';

const SurveyHeader = ({ surveyDescription, surveyName }) => {
    const background = GetParsedColor('white');
    const blue = GetParsedColor('blue');
    const black = GetParsedColor('black');
    const description = GetParsedColor('grey.700');

    return (
        <Box
            style={{
                backgroundColor: background,
                borderRadius: '7px 7px 0px 0px',
                marginBottom: '2vh',
                paddingBottom: '5%',
                textAlign: 'center',
            }}
        >
            <Box
                style={{
                    background: `linear-gradient(to left, ${black}, ${blue})`,
                    borderRadius: 7,
                    height: 27,
                    marginBottom: '5%',
                }}
            />
            <Text.Title translate={false}>{surveyName}</Text.Title>
            <Text
                style={{
                    color: description,
                    flexWrap: 'wrap',
                    fontSize: 15,
                    margin: '3%',
                    maxWidth: device === 'web' ? '70vw' : '100vw',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    textAlign: 'justify',
                    whiteSpace: 'pre-line',
                    wordWrap: 'break-word',
                }}
                translate={false}
            >
                {surveyDescription}
            </Text>
        </Box>
    );
};

SurveyHeader.propTypes = {
    surveyDescription: PropTypes.string.isRequired,
    surveyName: PropTypes.string.isRequired,
};

export default SurveyHeader;

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

export const ResponsiveContext = createContext({});

const ResponsiveContainer = ({ children }) => {
    const isMobileDevice = useMediaQuery({ query: '(min-device-width: 480px)' });
    const isTabletDevice = useMediaQuery({ query: '(min-device-width: 768px)' });
    const isLaptop = useMediaQuery({ query: '(min-device-width: 1024px)' });
    const isDesktop = useMediaQuery({ query: '(min-device-width: 1200px)' });
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1201px )' });

    return (
        <ResponsiveContext.Provider value={{
            isBigScreen, isDesktop, isLaptop, isMobileDevice, isTabletDevice,
        }}
        >
            {children}
        </ResponsiveContext.Provider>
    );
};

ResponsiveContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ResponsiveContainer;

import React from 'react';
import { useTheme } from 'styled-components';

export const colors = {
    dark: {
        black: '#060606',
        blue: '#3962EE',
        green: '#6BBE99',
        grey: {
            100: '#0B1218',
            200: '#4D4D4D',
            300: '#666666',
            400: '#808080',
            500: '#999999',
            600: '#B3B3B3',
            700: '#CCCCCC',
            800: '#E6E6E6',
            900: '#F5F5F5',
        },
        innerBackground: '#3B3B3B',
        lightBlue: '#3962EE',
        mainBackground: '#313131',
        purple: '#3A14FE',
        red: '#db7979',
        white: '#000',
        yellow: '#E8B025',
    },
    light: {
        black: '#000',
        blue: '#3962EE',
        green: '#6BBE99',
        grey: {
            100: '#F5F5F5',
            200: '#E6E6E6',
            300: '#CCCCCC',
            400: '#B3B3B3',
            500: '#999999',
            600: '#808080',
            700: '#666666',
            800: '#4D4D4D',
            900: '#0B1218',
        },
        innerBackground: 'white',
        lightBlue: '#3962EE',
        mainBackground: '#F5F5F5',
        purple: '#3A14FE',
        red: '#D75A5A',
        white: '#fff',
        yellow: '#E8B025',
    },
};

export const getColor = (colorsObject, key) => {
    if (!key)
        return null;

    if (key.includes('.')) {
        const splited = key.split('.');
        return colorsObject[splited[0]][splited[1]] || '#fff';
    }

    return colorsObject[key] || ((key.includes('rgb') || key.includes('#')) && key);
};

export const GetParsedColor = (color) => {
    const theme = useTheme();
    const parsedFillColor = React.useMemo(
        () => getColor(theme.colors, color),
        [theme, color],
    );
    return parsedFillColor;
};

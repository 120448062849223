export const styles = {
    tableBottomRightestCell: {
        color: '#545454',
        minWidth: 43,
    },
    tableClickableCell: {
        borderBottom: '1px solid #C9C8C9',
        borderRight: '1px solid #C9C8C9',
        color: '#545454',
        cursor: 'pointer',
        minWidth: 43,
    },
    tableDiagonalCell: {
        borderBottom: '1px solid #C9C8C9',
        borderRight: '1px solid #C9C8C9',
        color: '#545454',
        minWidth: 43,
    },
    tableLastColumnCell: {
        borderBottom: '1px solid #C9C8C9',
        color: '#545454',
        cursor: 'pointer',
        minWidth: 43,
    },
    tableLastRowCell: {
        borderRight: '1px solid #C9C8C9',
        color: '#545454',
        cursor: 'pointer',
        minWidth: 43,
    },
    tableNeighborSelectedCell: {
        backgroundColor: '#BAD7CF',
        border: '1px solid #84B3A8',
        color: '#545454',
        cursor: 'pointer',
        minWidth: 43,
    },
    tableNeighborSelectedDiagonalCell: {
        backgroundColor: '#BAD7CF',
        border: '1px solid #84B3A8',
        color: '#545454',
        minWidth: 43,
    },
    tableSelectedCell: {
        backgroundColor: '#9BCABE',
        border: '1px solid #84B3A8',
        color: 'white',
        cursor: 'pointer',
        minWidth: 43,
    },
};

export default styles;

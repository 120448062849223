import React from 'react';
import PropTypes from 'prop-types';

import { useFieldArray } from 'react-hook-form';

import { GenericQuestion } from '@components/organisms';

const GenericForm = ({
    control, formState, getValues, questions, setValue, surveyType,
}) => {
    const { fields } = useFieldArray({
        control,
        name: 'answers',
    });
    let fieldIndex = 0;

    return (
        <>
            {fields.map((field, idx) => {
                const isSubheading = field.field === 'SUBHEADING';

                if (!isSubheading)
                    fieldIndex += 1;

                return (
                    <GenericQuestion
                        control={control}
                        field={field} // not in question props
                        fieldIndex={fieldIndex}
                        formState={formState}
                        getValues={getValues}
                        idx={idx}
                        question={questions[idx]}
                        setValue={setValue}
                        surveyType={surveyType}
                    />
                );
            })}
        </>
    );
};

GenericForm.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            answers: PropTypes.arrayOf(
                PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
            ),
        }),
    }).isRequired,
    getValues: PropTypes.func.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    setValue: PropTypes.func.isRequired,
    surveyType: PropTypes.string.isRequired,
};

export default GenericForm;

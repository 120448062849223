import React from 'react';
import { ThemeProvider } from 'styled-components';

import {
    Modal, ResponsiveContainer, TermsOfUse, Toast,
} from '@components';

import Routes from '@routes';
import { colors } from '@styles';

function App() {
    return (
        <ThemeProvider theme={{ colors: { ...colors['light'] } }}>
            <Toast>
                <ResponsiveContainer>
                    <Routes />
                </ResponsiveContainer>
            </Toast>
            <Modal />
            <TermsOfUse />
        </ThemeProvider>
    );
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';

// Custom
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import t from '@services/locale';
import { GetParsedColor } from '@styles/colors';
import { Box, Icon, Text } from '@components/atoms';

const Checkbox = ({
    disabled, error, label, maxWidth, ml, mt, name, onChange, sx, translate = true, value, width,
}) => {
    const dark_grey = GetParsedColor('grey.900');
    const green = GetParsedColor('green');
    const blue = GetParsedColor('blue');
    const theme = createTheme({
        palette: {
            primary: { main: green },
            secondary: { main: blue },
            text: { primary: dark_grey, secondary: dark_grey },
        },
        typography: { allVariants: { color: dark_grey } },
    });

    return (
        <>
            <Box ml={ml} mt={mt} style={maxWidth ? { width: '100%' } : undefined}>
                <ThemeProvider theme={theme}>
                    <FormControl style={{ width: width || '100%' }}>
                        <FormControlLabel
                            control={(
                                <MuiCheckbox
                                    checked={value}
                                    data-testid={name}
                                    disabled={disabled}
                                    name={label}
                                    sx={{
                                        marginTop: -1,
                                    }}
                                    onChange={(e) => onChange((e.target.checked))}
                                />
                            )}
                            label={translate ? t(label) : label}
                            sx={{ alignItems: 'flex-start', marginBottom: '2vh', ...sx }}
                        />
                    </FormControl>
                </ThemeProvider>
            </Box>
            {error && typeof error === 'string' && (
                <Text color="red" ml={2} mt={0.5}>
                    {error}
                </Text>
            )}
        </>
    );
};

Checkbox.defaultProps = {
    disabled: false,
    maxWidth: false,
};

Checkbox.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    maxWidth: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    translate: PropTypes.bool.isRequired,
    value: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export const CheckboxGroup = ({
    clearSelection, control, error, maxAnswers, name, options,
}) => (
    <Box ml="1vw">
        <Icon
            clickable
            color="grey.700"
            handleClick={clearSelection}
            name="erase"
            style={{
                position: 'absolute',
                right: '0.5rem',
                top: '0.5rem',
            }}
            tooltipText="Desfazer seleções"
        />
        <Controller
            control={control}
            defaultValue={[]}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormGroup>
                    {options.map((option) => (
                        <Checkbox
                            disabled={!value?.includes(option) && value?.length >= maxAnswers}
                            label={option}
                            value={value?.includes(option)}
                            onChange={(isChecked) => {
                                if (isChecked) {
                                    if (value?.length < maxAnswers)
                                        onChange([...value, option]);
                                } else
                                    onChange(value?.filter((v) => v !== option));
                            }}
                        />
                    ))}
                    {error && typeof error === 'string' && (
                        <Text color="red" ml={2} mt={0.5}>
                            {error}
                        </Text>
                    )}
                </FormGroup>
            )}
        />
    </Box>
);

CheckboxGroup.defaultProps = {
    error: undefined,
};

CheckboxGroup.propTypes = {
    clearSelection: PropTypes.func.isRequired,
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    error: PropTypes.string,
    maxAnswers: PropTypes.arrayOf(PropTypes.number).isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Checkbox;

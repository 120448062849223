import axios from 'axios';

import USER_DATA from '@constants/storage';
import { getItem } from '@services/database';
import { BASE_URL } from '@constants/api';

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.request.use(
    async (config) => {
        const user = await getItem(USER_DATA);
        if (user?.access_token)
            config.headers.Authorization = `Bearer ${user.access_token}`;

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

export default api;

import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

import { GetParsedColor } from '@styles/colors';
import { Box } from '@components/atoms';
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';

const Loading = (props) => {
    const {
        color, height, mt, type, width,
    } = props;
    return (
        <Box mt={mt} style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading
                color={GetParsedColor(color || 'green')}
                height={height || '4vh'}
                type={type || 'spin'}
                width={width || '4vh'}
            />
        </Box>
    );
};

Loading.defaultProps = {
    type: 'spin',
};

Loading.propTypes = {
    color: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    type: PropTypes.string,
    width: PropTypes.string.isRequired,
    ...sizePropsTypes,
    ...colorPropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default Loading;

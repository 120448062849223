import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { mergeLeft } from 'ramda';

import { Box, Icon, Text } from '@components/atoms';
import t from '@services/locale';
import {
    colorPropsTypes,
    flexPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';

import { NavbarPressable, Pressable, Touchable } from './styles';

const Button = ({
    bgColor,
    children,
    color,
    disabled,
    fontSize,
    height,
    iconLeft,
    iconMargin,
    iconRight,
    loading,
    onClick,
    style,
    tooltipText,
    translate = false,
    type,
    width,
    ...rest
}) => {
    const leftIcon = React.useMemo(() => {
        if (!iconLeft)
            return <Box />;

        if (typeof iconLeft === 'string') {
            return (
                <Icon
                    color={color || '#fff'}
                    height={30}
                    ml={iconMargin || '2vw'}
                    name={iconLeft}
                    width={25}
                />
            );
        }

        return iconLeft;
    }, [iconLeft, color, iconMargin]);

    const rightIcon = React.useMemo(() => {
        if (!iconRight)
            return <Box />;

        if (typeof iconRight === 'string') {
            return (
                <Icon
                    color={color || '#fff'}
                    height={30}
                    mr={iconMargin || '2vw'}
                    name={iconRight}
                    width={25}
                />
            );
        }

        return iconRight;
    }, [iconRight, color, iconMargin]);

    return (
        <Tooltip
            disableHoverListener={!tooltipText}
            placement="top"
            title={translate ? t(tooltipText || '') : tooltipText || ''}
        >
            <Pressable
                bgColor={bgColor || 'lightBlue'}
                data-testid={rest['data-testid']}
                disabled={disabled}
                flexDirection="row"
                height={height || '6vh'}
                loading={loading}
                // theme={theme}
                style={{
                    ...style,
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                type={type}
                width={width || '15vw'}
                onClick={onClick}
            >
                {leftIcon}
                {typeof children === 'string' ? (

                    <Text color={color || '#fff'} fontSize={fontSize || 16} translate={translate}>
                        {children}
                    </Text>
                ) : (
                    children
                )}
                {rightIcon}
            </Pressable>
        </Tooltip>
    );
};

Button.defaultProps = {
    disabled: undefined,
    iconLeft: undefined,
    iconRight: undefined,
};

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    disabled: PropTypes.bool,
    iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...flexPropsTypes,
    ...paddingPropsTypes,
    ...marginPropsTypes,
};

Button.Link = (props) => {
    const {
        bold, children, color,
        fontSize, onClick, style,
    } = props;

    const node = typeof children !== 'string'
        ? children
        : (
            <Text.Link
                bold={bold}
                color={color}
                fontSize={fontSize}
                style={{ margin: 0 }}
            >
                {children}
            </Text.Link>
        );

    return (
        <Touchable
            color={color}
            mb={0.5}
            style={mergeLeft(style, {
                backgroundColor: 'transparent',
                cursor: 'pointer',
            })}
            onClick={onClick}
        >
            {node}
        </Touchable>
    );
};

Button.Link.defaultProps = {
    onClick: undefined,
    style: {},
};

Button.Link.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onClick: PropTypes.func,
    style: PropTypes.objectOf(PropTypes.string),
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...flexPropsTypes,
    ...paddingPropsTypes,
    ...marginPropsTypes,
};

Button.BackButton = ({ props, tooltipText }) => {
    const {
        bgColor, children, color, fontSize,
    } = props;

    return (
        <Tooltip
            disableHoverListener={!tooltipText}
            placement="top"
            title={t(tooltipText || '')}
        >
            <Touchable
                alignItems="center"
                bgColor={bgColor}
                color={color}
                flexDir="row"
                style={{
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    cursor: 'pointer',
                }}
            >
                <Icon
                    color={color || 'grey.800'}
                    mr={3}
                    name="chevronLeft"
                    scale={1.2}
                />
                {typeof children === 'string' ? (
                    <Text
                        color={color}
                        fontSize={fontSize}
                    >
                        {children}

                    </Text>
                ) : (
                    children
                )}
            </Touchable>
        </Tooltip>
    );
};

Button.BackButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...flexPropsTypes,
    ...paddingPropsTypes,
    ...marginPropsTypes,
};

Button.Navbar = ({
    handleClick, icon, isExpanded, isSelected, name, ...props
}) => {
    const { color } = props;

    return (
        <Tooltip disableHoverListener={isExpanded} placement="top" title={name}>
            <NavbarPressable style={{ width: isExpanded ? '18vw' : '4vw' }}>
                <Pressable
                    style={{
                        alignItems: 'center',
                        backgroundColor: isSelected ? 'rgba(255, 255, 255, 0.3)' : 'transparent',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        height: '40px',
                        justifyContent: isExpanded ? 'flex-start' : 'center',
                        width: isExpanded ? '18vw' : '4vw',
                    }}
                    onClick={handleClick}
                >
                    <Icon
                        color={color || '#fff'}
                        name={icon}
                        scale={1.3}
                        style={{ marginLeft: isExpanded ? '1vw' : 0, marginRight: isExpanded ? '1.5vw' : 0 }}
                    />
                    {isExpanded && <Text color={color || '#fff'}>{name}</Text>}
                </Pressable>
            </NavbarPressable>
        </Tooltip>
    );
};

Button.Navbar.defaultProps = {
    disabled: undefined,
    iconLeft: undefined,
    iconRight: undefined,
    loading: undefined,
};

Button.Navbar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    disabled: PropTypes.bool,
    iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    loading: PropTypes.bool,
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...flexPropsTypes,
    ...paddingPropsTypes,
    ...marginPropsTypes,
};

Button.Step = ({
    direction, disabledColor, isDisabled, onClick, style, title,
    translate = true, variant,
}) => {
    const optionalProps = {};
    if (variant === 'outline') {
        optionalProps.bgColor = 'mainBackground';
        optionalProps.color = 'lightBlue';
    }

    if (isDisabled) {
        const propToChange = variant === 'outline' ? 'color' : 'bgColor';
        optionalProps[propToChange] = disabledColor;
        style.borderColor = disabledColor;
        style.cursor = 'not-allowed';
    }

    if (direction === 'left')
        optionalProps.iconLeft = 'chevronLeft';
    else
        optionalProps.iconRight = 'chevronRight';

    return (
        <Button
            bgColor={optionalProps.bgColor}
            color={optionalProps.color}
            fontSize={15}
            height="5vh"
            iconLeft={optionalProps.iconLeft}
            iconRight={optionalProps.iconRight}
            style={{
                ...style,
                borderWidth: '1px',
                fontWeight: 'bold',
                paddingLeft: '2rem',
                paddingRight: '2rem',
            }}
            translate={translate}
            type="button"
            onClick={isDisabled ? () => { } : onClick}
        >
            {title}
        </Button>
    );
};

Button.Step.defaultProps = {
    direction: 'left',
    disabledColor: '#999999',
    isDisabled: false,
    onClick: null,
    style: {},
    translate: true,
    variant: 'outline',
};

Button.Step.propTypes = {
    direction: PropTypes.oneOf(['left', 'right']),
    disabledColor: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.shape({
        borderColor: PropTypes.string,
        cursor: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    title: PropTypes.string.isRequired,
    translate: PropTypes.bool,
    variant: PropTypes.oneOf(['outline', 'solid']),
};

Button.CloseButton = ({
    color, onClick, style, tooltipText,
}) => (
    <Tooltip
        disableHoverListener={!tooltipText}
        placement="top"
        title={t(tooltipText || '')}
    >
        <Touchable
            alignItems="center"
            data-testid="close_button"
            flexDir="row"
            style={mergeLeft(style, {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                cursor: 'pointer',
            })}
            onClick={onClick}
        >
            <Icon
                color={color || 'grey.800'}
                name="times"
                scale={1.5}
            />
        </Touchable>
    </Tooltip>
);

Button.CloseButton.defaultProps = {
    color: undefined,
    style: {},
    tooltipText: undefined,
};

Button.CloseButton.propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.objectOf(PropTypes.string),
    tooltipText: PropTypes.string,
};

export default Button;

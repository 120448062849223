import React, {
    createRef, forwardRef, useEffect,
    useImperativeHandle, useState,
} from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Box, Icon, Text } from '@components/atoms';
import { Button, Loading } from '@components/molecules';
import { GetParsedColor } from '@styles/colors';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const INITIAL_MODAL_STATE = {
    description: null,
    isOpen: false,
    mode: null,
    submitConfig: null,
    title: null,
};

const CustomModal = (_, ref) => {
    const [state, setState] = useState(INITIAL_MODAL_STATE);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const colors = {
        error: GetParsedColor('red'),
        info: GetParsedColor('blue'),
        text: GetParsedColor('white'),
    };

    const close = () => {
        setIsSubmitting(false);
        setState(INITIAL_MODAL_STATE);
    };

    const show = (message) => {
        setState({
            description: message.description,
            isOpen: true,
            mode: message.mode,
            submitConfig: message.submitConfig,
            title: message.title,
        });
    };

    const onSubmit = () => {
        setIsSubmitting(true);
        state.submitConfig.onSubmit?.();
    };

    useImperativeHandle(ref, () => ({
        close, show,
    }));

    const isInitiated = Object.values(state).every(Boolean);

    useEffect(() => {
        let closeTimeout = null;

        if (isInitiated && state.mode === 'error')
            closeTimeout = setTimeout(close, 4000);

        return () => {
            clearInterval(closeTimeout);
        };
    }, [state]);

    if (!isInitiated)
        return null;

    return (
        <StyledDialog
            open
            aria-labelledby="customized-modal-title"
            onClose={close}
        >
            <DialogTitle
                id="customized-modal-title"
                sx={{
                    backgroundColor: colors[state.mode],
                    color: colors.text,
                    fontSize: '1.15rem',
                    fontWeight: 'bold',
                }}
            >
                <Icon
                    color="white"
                    name="exclamationCircle"
                    scale={1.2}
                    style={{
                        display: 'inline',
                    }}
                />
                <Box
                    ml={0.7}
                    mr={4}
                    style={{
                        display: 'inline',
                    }}
                >
                    {state.title}
                </Box>
                <Button.CloseButton
                    color="white"
                    style={{
                        position: 'absolute',
                        right: '1.5%',
                        top: '10%',
                    }}
                    tooltipText="Fechar"
                    onClick={close}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Text
                    fontSize="1.1rem"
                    translate={false}
                >
                    {state.description}
                </Text>
            </DialogContent>
            <DialogActions>
                <Button.Link
                    bold
                    color={colors[state.mode]}
                    style={{
                        border: `1px solid ${colors.DARK_GREY}`,
                        borderRadius: '4px',
                        padding: '0.5rem 1.5rem',
                    }}
                    onClick={onSubmit}
                >
                    {
                        isSubmitting
                            ? <Loading color={colors[state.mode]} />
                            : state.submitConfig.text
                    }
                </Button.Link>
            </DialogActions>
        </StyledDialog>
    );
};

const ref = createRef();
const ForwardedModal = forwardRef(CustomModal);
export const ModalControl = {
    close() {
        const modal = ref.current;
        modal?.close();
    },
    showError(message) {
        const modal = ref.current;
        modal?.show({
            ...message,
            mode: 'error',
            submitConfig: {
                onSubmit: modal?.close,
                text: 'Ok',
            },
        });
    },
    showInfo(message) {
        const modal = ref.current;
        modal?.show({
            ...message,
            mode: 'info',
        });
    },
};

function Modal() {
    return (
        <ForwardedModal
            ref={ref}
        />
    );
}

export default Modal;

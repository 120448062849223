import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { Controller } from 'react-hook-form';

// Material UI
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom
import { InputWrapper } from '@components/atoms';
import t from '@services/locale';
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import { GetParsedColor } from '@styles/colors';

const InputText = ({
    control,
    defaultValue = '',
    // disabled = false,
    error,
    height = 'auto',
    inputProps,
    maxChars,
    mt,
    name,
    p,
    password,
    placeholder,
    translate = true,
    type = 'text',
    value,
    width,
}) => {
    const [hidePass, setHidePass] = React.useState(password);

    const FONT_COLOR = GetParsedColor('black');
    const TIPOGRAPHY = GetParsedColor('grey.500');
    const GREEN = GetParsedColor('green');
    const BLUE = GetParsedColor('blue');
    const THEME = createTheme({
        palette: {
            primary: { main: GREEN },
            secondary: { main: BLUE },
            text: { primary: FONT_COLOR, secondary: FONT_COLOR },
        },
        typography: { allVariants: { color: TIPOGRAPHY } },
    });

    type = hidePass ? 'password' : type;

    const getIconRight = () => {
        if (password) {
            if (hidePass)
                return 'eyeHide';
            return 'eye';
        }
        return '';
    };

    if (control) {
        return (
            <InputWrapper
                error={error}
                height={height}
                iconRight={getIconRight()}
                mt={mt}
                p={p}
                onPressRight={() => password && setHidePass(!hidePass)}
            >
                <ThemeProvider theme={THEME}>
                    <Controller
                        control={control}
                        defaultValue={defaultValue || ''}
                        name={name}
                        render={({
                            field: {
                                name: fieldName, onBlur, onChange, ref, value: fieldValue,
                            }, fieldState,
                        }) => (
                            <FormControl style={{ width }}>
                                <TextField
                                    InputProps={{
                                        inputProps: {
                                            maxLength: maxChars || 10000,
                                            ...inputProps,
                                        },
                                    }}
                                    error={(!!fieldState.error)}
                                    helperText={fieldState?.error?.message}
                                    id={fieldName}
                                    label={translate ? t(placeholder) : placeholder}
                                    name={fieldName}
                                    ref={ref}
                                    style={{ height, width }}
                                    type={type}
                                    value={fieldValue || ''}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    onWheel={(event) => event.target.blur()}
                                />
                            </FormControl>
                        )}
                    />
                </ThemeProvider>
            </InputWrapper>
        );
    }
    return (
        <InputWrapper
            error={error}
            height={height}
            iconRight={getIconRight()}
            mt={mt}
            p={p}
            onPressRight={() => password && setHidePass(!hidePass)}
        >
            <ThemeProvider theme={THEME}>
                <FormControl style={{ width }}>
                    <TextField
                        id={name}
                        label={translate ? t(placeholder) : placeholder}
                        style={{ height, width }}
                        type={hidePass ? 'password' : 'text'}
                        value={value}
                    />
                </FormControl>
            </ThemeProvider>
        </InputWrapper>
    );
};

InputText.defaultProps = {
    password: undefined,
};

InputText.propTypes = {
    password: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    ...omit(['focused'], InputWrapper.propTypes),
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default InputText;

import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@components/atoms';
import device, { GetParsedColor } from '@styles';

const MainContainer = ({ children, style }) => {
    const bg_color = GetParsedColor('mainBackground');
    const containerStyle = {
        backgroundColor: bg_color,
        paddingBottom: '5vh',
        paddingLeft: device === 'web' ? '15%' : '5%',
        paddingRight: device === 'web' ? '15%' : '5%',
        paddingTop: '5vh',
    };

    return (
        <Box style={{ ...style, ...containerStyle }}>
            {children}
        </Box>
    );
};

MainContainer.defaultProps = {
    style: undefined,
};

MainContainer.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.shape({ height: PropTypes.string }),
};

export default MainContainer;

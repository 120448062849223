import React, { createContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';

import t from '@services/locale';

export const SnackbarContext = createContext({});
const AUTO_HIDE_DURATION = 10000;

const useStyles = makeStyles(() => ({
    alertTitle: {
        color: '#ffffff',
    },
}));

const Toast = ({ children }) => {
    const [snack, setSnack] = useState({
        message: '',
        open: false,
        type: 'success',
    });

    const classes = useStyles();

    const handleClose = () => setSnack({ ...snack, open: false });

    const showError = ({ message, translate = true, ...props }) => typeof message === 'string' && setSnack({
        message: translate ? t(message) : message, open: true, type: 'error', ...props,
    });

    const showSuccess = ({ message, translate = true, ...props }) => typeof message === 'string' && setSnack({
        message: translate ? t(message) : message, open: true, type: 'success', ...props,
    });

    const showWarning = ({ message, translate = true, ...props }) => typeof message === 'string' && setSnack({
        message: translate ? t(message) : message, open: true, type: 'warning', ...props,
    });

    const showInfo = ({ message, translate = true, ...props }) => typeof message === 'string' && setSnack({
        message: translate ? t(message) : message, open: true, type: 'info', ...props,
    });

    return (
        <SnackbarContext.Provider value={{
            closeSnack: handleClose, showError, showInfo, showSuccess, showWarning,
        }}
        >
            <Snackbar
                anchorOrigin={snack.anchorOrigin || { horizontal: 'center', vertical: 'top' }}
                autoHideDuration={snack.duration || AUTO_HIDE_DURATION}
                open={snack.open}
                onClose={handleClose}
            >
                <Alert
                    action={snack.action}
                    severity={snack.type}
                    variant="filled"
                    onClose={handleClose}
                >
                    {snack.title && <AlertTitle className={classes.alertTitle}>{snack.title}</AlertTitle>}
                    {snack.message}
                </Alert>
            </Snackbar>
            {children}
        </SnackbarContext.Provider>
    );
};

Toast.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Toast;

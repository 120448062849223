export const OCCUPATIONS = [
    { label: 'Instituição de ensino e pesquisa pública', value: 'PUBLIC_RESEARCH_INSTITUTION' },
    { label: 'Instituição de ensino e pesquisa privada', value: 'PRIVATE_RESEARCH_INSTITUTION' },
    { label: 'Administração pública', value: 'PUBLIC_ADMINISTRATION' },
    { label: 'Empresa privada', value: 'PRIVATE_COMPANY' },
    { label: 'Empresa pública', value: 'PUBLIC_COMPANY' },
    { label: 'Economia mista', value: 'MIXED_COMPANY' },
    { label: 'Terceiro Setor', value: 'NON_PROFIT' },
    { label: 'Outros', value: 'OTHER' },
];

export const GENDERS = [
    { label: 'Masculino', value: 'M' },
    { label: 'Feminino', value: 'F' },
    { label: 'Outro', value: 'O' },
];

export const TITLES = [
    { label: 'Pós-Doutorado', value: 'PHD' },
    { label: 'Doutorado', value: 'DOCTORATE' },
    { label: 'Mestrado', value: 'MASTER' },
    { label: 'Graduação', value: 'GRADUATE' },
    { label: 'Pós-Graduação', value: 'POSTGRADUATE' },
    { label: 'Outros', value: 'OTHER' },
];

export const NUMERIC_FIELD_TYPES = ['OPEN_FLOAT', 'OPEN_INTEGER', 'OPEN_RANGED_INTEGER', 'OPEN_RANGED_FLOAT'];
export const SLIDER_FIELD_TYPES = ['CONTINUOUS_SLIDER', 'DISCRETE_SLIDER', 'PERCENTAGE'];
export const RANGED_FIELD_TYPES = ['OPEN_RANGED_INTEGER', 'OPEN_RANGED_FLOAT', 'DISCRETE_SLIDER', 'CONTINUOUS_SLIDER'];
export const TEXT_FIELD_TYPES = ['FREE_TEXT', 'CLOSED_TEXT'];

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

import t from '@services/locale';
import * as Logos from '@assets/images';
import { Box } from '@components/atoms';
import {
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';

import Image from './styles';

const Logo = ({
    clickable, name = 'LogoDefault', style, tooltipText,
}) => (
    <Tooltip disableHoverListener={!tooltipText} placement="top" title={t(tooltipText)}>
        <Box style={{ ...style, cursor: clickable ? 'pointer' : '', justifyContent: 'center' }}>
            <Image alt="Logo" src={Logos[name]} style={{ backgroundSize: 'cover' }} />
        </Box>
    </Tooltip>
);

Logo.propTypes = {
    name: PropTypes.oneOf(Object.keys(Logos)).isRequired,
    ...sizePropsTypes,
    ...paddingPropsTypes,
    ...marginPropsTypes,
};

export default Logo;

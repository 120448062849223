import PropTypes from 'prop-types';

import { getColor } from './colors';

export const paddingProps = ({
    p, pb, ph, pl, pr, pt, pv,
}) => `
    ${p ? `padding: ${p};` : ''}
    ${ph ? `padding-horizontal: ${ph};` : ''}
    ${pv ? `padding-vertical: ${pv};` : ''}
    ${pr ? `padding-right: ${pr};` : ''}
    ${pl ? `padding-left: ${pl};` : ''}
    ${pt ? `padding-top: ${pt};` : ''}
    ${pb ? `padding-bottom: ${pb};` : ''}
  `;

export const paddingPropsTypes = {
    p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ph: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const marginProps = ({
    m, mb, mh, ml, mr, mt, mv,
}) => `
    ${m ? `margin: ${m};` : ''}
    ${mh ? `margin-horizontal: ${mh};` : ''}
    ${mv ? `margin-vertical: ${mv};` : ''}
    ${mr ? `margin-right: ${mr};` : ''}
    ${ml ? `margin-left: ${ml};` : ''}
    ${mt ? `margin-top: ${mt};` : ''}
    ${mb ? `margin-bottom: ${mb};` : ''}
  `;

export const marginPropsTypes = {
    m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const getFluid = (fluid) => {
    if (fluid) {
        if (fluid === 'vertical')
            return 'height: 100%;';
        return 'width: 100%;';
    }
    return '';
};

export const sizeProps = ({
    fluid, fontSize, height, size, width,
}) => `
  ${size ? `size: ${size};` : ''}
  ${height ? `height: ${height};` : ''}
  ${width ? `width: ${width};` : ''}
  ${fontSize ? `font-size: ${fontSize / 2};` : ''}
  ${getFluid(fluid)}
  `;

export const sizePropsTypes = {
    fluid: PropTypes.oneOfType([
        PropTypes.oneOf(['vertical', 'horizontal']),
        PropTypes.bool,
    ]),
    fontSize: PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const colorProps = ({ bgColor, color, theme }) => `
  ${color ? `color: ${getColor(theme.colors, color)};` : ''}
  ${bgColor ? `background-color: ${getColor(theme.colors, bgColor)};` : ''}
  `;

export const colorPropsTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
};

export const flexProps = ({
    alignItems,
    flex,
    flexDirection,
    flexGrow,
    flexWrap,
    justifyContent,
}) => `
  ${flex ? `flex: ${flex};` : ''}
  ${flexDirection ? `flexDirection: ${flexDirection};` : ''}
  ${flexGrow ? `flexGrow: ${flexGrow};` : ''}
  ${flexWrap ? `flexWrap: ${flexWrap};` : ''}
  ${justifyContent ? `justifyContent: ${justifyContent};` : ''}
  ${alignItems ? `alignItems: ${alignItems};` : ''}
`;

export const flexPropsTypes = {
    alignItems: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'stretch',
        'baseline',
    ]),
    display: PropTypes.string,
    flex: PropTypes.number,
    flexDirection: PropTypes.oneOf(['row', 'column', 'column-reverse', 'row-reverse']),
    flexGrow: PropTypes.number,
    flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
    justifyContent: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
    ]),
};

export const fontProps = ({
    align, bold, italic, underline,
}) => `
  ${bold ? 'font-weight: bold;' : ''}
  ${italic ? 'font-style: italic;' : ''}
  ${underline ? 'text-decoration-line: underline;' : ''}
  ${align ? `text-align: ${align};` : ''}
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { Controller } from 'react-hook-form';
import moment from 'moment';

// Date handlers
import { ptBR } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Styles
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { GetParsedColor } from '@styles/colors';

// Custom
import { Box } from '@components/atoms';
import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';

const LocalizedDatePicker = (props) => {
    const {
        control,
        defaultValue,
        name,
        placeholder,
        setValue,
    } = props;

    /* eslint-disable */
    const [dateError, setDateError] = useState(false);
    const dateProps = ['name', 'placeholder', 'control', 'error', 'defaultValue', 'watch', 'setValue'];
    const DARK_GREY = GetParsedColor('grey.900');
    const LIGHT_GREY = GetParsedColor('grey.500');
    const GREEN = GetParsedColor('green');
    const purple = GetParsedColor('purple');
    const theme = createTheme({
        overrides: {
            MuiSelect: { icon: { color: LIGHT_GREY } },
            MuiSvgIcon: { root: { color: LIGHT_GREY } },
        },
        palette: {
            primary: { main: GREEN },
            secondary: { main: purple },
            text: { primary: DARK_GREY, secondary: DARK_GREY },
        },
        typography: { allVariants: { color: LIGHT_GREY } },
    });

    // Fix placeholder with inputFormat value
    const localizedDatePickerTextField = document.getElementById('localized-date-picker');
    if (localizedDatePickerTextField)
        localizedDatePickerTextField.placeholder = 'dd/mm/aaaa';

    return (
        <>
            <Box {...omit(dateProps, props)} >
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <Controller
                            control={control}
                            defaultValue={defaultValue || null}
                            name={name}
                            render={({ field, fieldState }) => {
                                return (
                                    <DatePicker
                                        autoOk
                                        // disableToolbar: toolbar is disabled by default (renamed to showToolbar)
                                        format="dd/MM/yyyy" // format: renamed to inputFormat
                                        // inputValue and KeyboardButtonProps: KeyboardDatePicker no longer exists
                                        label={placeholder}
                                        onChange={(date) => {
                                            if (!date)
                                                setValue(name, date);
                                            else
                                                setValue(name, moment(date).format('YYYY-DD-MM'));
                                        }}
                                        slotProps={{
                                            textField: {
                                                error:(!!fieldState.error),
                                                helperText:fieldState?.error?.message
                                            }
                                        }}
                                    />
                                )
                            }}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </Box>
        </>
    );
};

LocalizedDatePicker.defaultProps = {
    error: undefined,
    onChange: undefined,
    value: undefined,
};

LocalizedDatePicker.propTypes = {
    error: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default LocalizedDatePicker;

import moment from 'moment';
import { pick } from 'ramda';

import t from '@services/locale';

export const getPropsFromPropTypes = (propTypes, props) => {
    const keys = Array.isArray(propTypes)
        ? propTypes.map((item) => Object.keys(item)).flat(1)
        : Object.keys(propTypes);

    return pick(keys, props);
};

export const dateParser = (date) => {
    if (date) {
        let new_dt = date.split('-');
        new_dt = new Date(new_dt[0], new_dt[1] - 1, new_dt[2]);
        return moment(new_dt).format('DD/MM/YYYY');
    }
    return '--';
};

export const capitalizeFirstLetters = (txt) => {
    if (!txt)
        return '';
    txt = txt.toString();
    if (txt) {
        txt = txt.toLowerCase();
        txt = txt.slice(0, 1).toUpperCase() + txt.slice(1, txt.length);
        let i = 2;
        let index = 0;
        while (txt.indexOf(' ', i) !== -1) {
            index = txt.indexOf(' ', i);
            txt = txt.slice(0, index + 1)
                + txt.slice(index + 1, index + 2).toUpperCase()
                + txt.slice(index + 2, txt.length);

            i = index + 1;
        }
        return txt;
    }
    return '';
};

export const create_range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);

export const getRangeText = (minimum, maximum) => {
    let rangeText = '';
    if (minimum !== null) {
        rangeText += `${minimum} ${t('e')}`;
        if (maximum)
            rangeText += ` ${maximum}`;
        else
            rangeText += '...';
    } else
        rangeText += '...';

    return rangeText;
};

export const getPreviewText = (question) => {
    switch (question.field) {
        case 'OPEN_FLOAT':
            return t('Digite um número');
        case 'OPEN_INTEGER':
            return t('Digite um número inteiro');
        case 'OPEN_RANGED_INTEGER':
            return `${t('Digite um número inteiro entre')} ${getRangeText(question.minimum, question.maximum)}`;
        case 'OPEN_RANGED_FLOAT':
            return `${t('Digite um número entre')} ${getRangeText(question.minimum, question.maximum)}`;
        case 'FREE_TEXT':
            return t('Digite sua resposta');
        case 'FREE_TEXT_ARRAY':
            return t('Escreva aqui');
        default:
            return '';
    }
};

export const valueLabelFormat = (value) => {
    if (Number.isInteger(value))
        return value;
    return value.toFixed(2);
};

const convertAnswer = (field, value) => {
    if (field === 'FREE_TEXT_ARRAY' || field === 'CLOSED_TEXT_ARRAY') {
        const nonEmptyValues = value.filter(Boolean);
        return nonEmptyValues.map((item, index) => {
            const isLast = index === nonEmptyValues.length - 1;

            return isLast
                ? `[${item}]`
                : `[${item}],`;
        }).join('');
    }

    if (field.includes('FLOAT'))
        return value;
    if (field !== 'UNCERTAINTY_VALUE')
        return value;
    if (field === 'GENERIC_MATRIX_SLIDER')
        return value;
    let new_value = 6 - Math.ceil(Math.abs(value - 50) / 10);
    if (new_value === 6)
        new_value = 5;
    return new_value;
};

export const convertAnswers = (answers) => answers.map((obj) => ({
    answer: convertAnswer(obj.field, obj.answer),
    num_order: obj.num_order,
}));

export const getFirstMatrixVariable = (tuple) => tuple.split(',')[0].substring(1).slice(0, -1);

export const getSecondMatrixVariable = (tuple) => tuple.split(',')[1].substring(1).slice(0, -1);

export const filterMotricityAndDependenceData = (data) => {
    const mAndDMatrix = data;
    // Number of variables is always the sqrt of the matrix length
    const numOfVaribles = Math.sqrt(mAndDMatrix.length);
    // Map one row of the matrix (already contains all variables)
    return mAndDMatrix.slice(0, numOfVaribles).map((question, index) => {
        const newQuestion = JSON.parse(JSON.stringify(question));
        // Obtain the first variable (first position)
        if (index === 0) {
            newQuestion['context'] = getFirstMatrixVariable(question['context']);
            newQuestion['statement'] = getFirstMatrixVariable(question['statement']);
            newQuestion['short_name'] = newQuestion['short_name'] ? getFirstMatrixVariable(question['short_name']) : null;
            // Obtain the remaining variables (second position)
        } else {
            newQuestion['context'] = getSecondMatrixVariable(question['context']);
            newQuestion['statement'] = getSecondMatrixVariable(question['statement']);
            newQuestion['short_name'] = newQuestion['short_name'] ? getSecondMatrixVariable(question['short_name']) : null;
        }
        return newQuestion;
    });
};

export const filterActorsMotricityData = (data) => {
    const asmMatrix = data;
    const filtereredData = [];
    for (let index = 0; index < asmMatrix.length; index += 1) {
        // Obtain first actor and first variable
        if (index === 0) {
            const firstActor = JSON.parse(JSON.stringify(asmMatrix[index]));
            const firstVariable = JSON.parse(JSON.stringify(asmMatrix[index]));
            // Obtain the first actor
            firstActor['context'] = getFirstMatrixVariable(firstActor['context']);
            firstActor['statement'] = getFirstMatrixVariable(firstActor['statement']);
            firstActor['short_name'] = firstActor['short_name'] ? getFirstMatrixVariable(firstActor['short_name']) : null;
            firstActor['num_order'] = 1;
            filtereredData.push(firstActor);
            // Obtain the first variable
            firstVariable['context'] = getSecondMatrixVariable(firstVariable['context']);
            firstVariable['statement'] = getSecondMatrixVariable(firstVariable['statement']);
            firstVariable['short_name'] = firstVariable['short_name'] ? getSecondMatrixVariable(firstVariable['short_name']) : null;
            firstVariable['field'] = 'VARIABLE_INFLUENCE_VALUE';
            firstVariable['num_order'] = 2;
            filtereredData.push(firstVariable);
        }
        const question = JSON.parse(JSON.stringify(asmMatrix[index]));
        // Check if the question is a new actor
        if (!filtereredData.find((element) => getFirstMatrixVariable(question['short_name']) === element['short_name'])) {
            question['context'] = getFirstMatrixVariable(question['context']);
            question['statement'] = getFirstMatrixVariable(question['statement']);
            question['short_name'] = question['short_name'] ? getFirstMatrixVariable(question['short_name']) : null;
            question['num_order'] = index + 2;
            filtereredData.push(question);
            // Check if the question is a new variable
        } else if (!filtereredData.find((element) => getSecondMatrixVariable(question['short_name']) === element['short_name'])) {
            question['context'] = getSecondMatrixVariable(question['context']);
            question['statement'] = getSecondMatrixVariable(question['statement']);
            question['short_name'] = question['short_name'] ? getSecondMatrixVariable(question['short_name']) : null;
            question['field'] = 'VARIABLE_INFLUENCE_VALUE';
            question['num_order'] = index + 2;
            filtereredData.push(question);
        }
    }
    return filtereredData;
};

export const splitRowValue = (value) => value.split('],[')[0].substring(1);
export const splitColumnValue = (value) => value.split('],[')[1].slice(0, -1);

export const decodeUserData = (jwtToken) => {
    if (typeof jwtToken !== 'string')
        return {};

    const encodedUserData = jwtToken.split('.')[1];
    try {
        const jsonData = JSON.parse(window.atob(encodedUserData));
        return jsonData;
    } catch (error) {
        return {};
    }
};

export const extractNextErrorMessage = (errors, questions) => {
    const { answers, invitee } = errors;

    for (const property in invitee) {
        // Since the city input is hidden at first,
        // We tell the user to choose the UF so the city input becomes visible
        if (property === 'city' && !!invitee['uf'])
            return invitee['uf'].message;

        if (invitee[property].message)
            return invitee[property].message;
    }

    for (let index = 0; index <= answers.length; index += 1) {
        const entry = answers[index];
        if (entry?.answer?.message) {
            const message = entry?.answer?.message;

            if (message !== t('Pergunta obrigatória'))
                return message;

            const matchedQuestion = questions[index];
            const qtOfHeadingBeforeQuestion = questions
                .slice(0, index)
                .filter((item) => item.field === 'SUBHEADING')
                ?.length;
            // The indexes for the subheading entries are currently being skipped when setting up Yup
            // So in order to get the true question "number", we subtract the subheadings from the num_order
            const questionNumber = matchedQuestion.num_order - qtOfHeadingBeforeQuestion;
            return t('Pergunta X obrigatória', { x: questionNumber });
        }
    }

    return t('É necessário preencher todos os campos obrigatórios antes de finalizar o envio');
};

export const extractNextErrorMessageSignup = (errors) => {
    const { invitee } = errors;

    for (const property in invitee) {
        // Since the city input is hidden at first,
        // We tell the user to choose the UF so the city input becomes visible
        if (property === 'city' && !!invitee['uf'])
            return invitee['uf'].message;

        if (invitee[property].message)
            return invitee[property].message;
    }

    return t('É necessário preencher todos os campos obrigatórios antes de finalizar o envio');
};

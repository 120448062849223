import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import { GetParsedColor } from '@styles';

import {
    Box,
    HookTableCell,
} from '@components/atoms';

import styles from './styles';

const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

    '&:nth-of-type(even)': styles.styledTableRowEven,

    '&:nth-of-type(odd)': styles.styledTableRowOdd,
}));

const Matrix = ({
    control, fields, handleCellClick, isSquareMatrix, numColumns, numRows, selectedCell,
}) => {
    const bg_color = GetParsedColor('mainBackground');

    let headerFields = fields;
    let bodyFields = fields;

    if (!isSquareMatrix) {
        // Filter actors and variables in as'm survey type
        headerFields = fields.filter((el) => el.field === 'ACTOR_INFLUENCE_VALUE');
        bodyFields = fields.filter((el) => el.field === 'VARIABLE_INFLUENCE_VALUE');
    }

    return (
        <Box
            style={{
                margin: '0vh 0vw 0vh 0vw',
                maxHeight: 580,
                padding: '0vw 0vw 0vw 0vw',
            }}
        >
            <TableContainer style={{
                maxHeight: 580,
            }}
            >
                <Table aria-label="matrix" size="medium">
                    <TableHead>
                        <StyledTableRow>
                            <TableCell style={{ backgroundColor: bg_color }} />
                            {headerFields.map((field, index) => (
                                <TableCell align="center" component="th" key={index} scope="row" style={index % 2 === 0 ? styles.tableHeadEven : styles.tableHeadOdd}>
                                    {field.short_name}
                                </TableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {bodyFields.map((field, index) => (
                            <StyledTableRow
                                key={field.short_name}
                            >
                                <TableCell align="center" component="th" scope="row" style={index % 2 === 0 ? styles.tableFirstRowEven : styles.tableFirstRowOdd}>
                                    {field.short_name}
                                </TableCell>
                                {headerFields.map((_, cellIndex) => {
                                    if (isSquareMatrix) {
                                        return (
                                            <HookTableCell
                                                cellIndex={cellIndex}
                                                cellIndexShortName={headerFields[cellIndex].short_name}
                                                control={control}
                                                handleCellClick={handleCellClick}
                                                index={index}
                                                indexShortName={bodyFields[index].short_name}
                                                isSquareMatrix={isSquareMatrix}
                                                key={cellIndex}
                                                numColumns={numColumns}
                                                selectedCell={selectedCell}
                                            />
                                        );
                                    }
                                    // No guarantee that the matrix is square in actors motricity survey type
                                    return (
                                        <HookTableCell
                                            cellIndex={cellIndex}
                                            cellIndexShortName={headerFields[cellIndex].short_name}
                                            control={control}
                                            handleCellClick={handleCellClick}
                                            index={index}
                                            indexShortName={bodyFields[index].short_name}
                                            isSquareMatrix={isSquareMatrix}
                                            key={cellIndex}
                                            numColumns={numColumns}
                                            numRows={numRows}
                                            selectedCell={selectedCell}
                                        />
                                    );
                                })}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

Matrix.defaultProps = {
    numColumns: undefined,
    numRows: undefined,
};

Matrix.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleCellClick: PropTypes.func.isRequired,
    isSquareMatrix: PropTypes.bool.isRequired,
    numColumns: PropTypes.number,
    numRows: PropTypes.number,
    selectedCell: PropTypes.shape({
        column: PropTypes.number,
        row: PropTypes.number,
        shortName: PropTypes.string,
    }).isRequired,
};

export default Matrix;

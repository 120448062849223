import React from 'react';
import * as Ramda from 'ramda';

import api from '@services/api/config';

const initialState = {
    data: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    if (action.type === 'add_data')
        return { ...state, data: action.data, loading: false };

    if (action.type === 'change_loading')
        return { ...state, loading: action.loading };

    return state;
};

const parseBody = (params, type) => {
    if (type === 'form-urlencoded') {
        const newParams = new URLSearchParams();
        Object.keys(params).forEach((key) => newParams.append(key, params[key]));
        return {
            config: {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
            params: newParams,
        };
    }

    return { config: {}, params };
};

const onFetch = async ({
    bodyType = 'json',
    dispatch,
    method,
    onCompleted,
    onError,
    params,
    url,
}) => new Promise((resolve, reject) => {
    (async () => {
        dispatch({ loading: true, type: 'change_loading' });
        const result = parseBody(params, bodyType);
        try {
            const { data } = await api[method || 'get'](
                url,
                result?.params || {},
                result?.config || {},
            );
            dispatch({ data, type: 'add_data' });
            if (onCompleted)
                onCompleted(data);
            resolve(data);
        } catch (error) {
            console.warn('[fetch error] ', error);
            dispatch({ loading: false, type: 'change_loading' });
            if (onError)
                onError(error.response?.data?.detail);
            reject(error);
        }
    })();
});

const handleFetch = (dispatch, url, props) => async (newProps = {}) => new Promise((resolve, reject) => {
    (async () => {
        try {
            const result = await onFetch({
                url,
                ...{ ...props, ...(newProps || {}) },
                dispatch,
            });
            resolve(result);
        } catch (error) {
            reject(error);
        }
    })();
});

/**
 *
 * @param url
 * @param props { method, params}
 *
 */
export const useLazyFetch = (url, props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return [
        handleFetch(dispatch, url, props),
        {
            refetch: handleFetch(dispatch, url, props),
            ...Ramda.pick(['loading', 'data'], state),
        },
    ];
};

/**
 *
 * @param url
 * @param props { method, params}
 *
 */
export const useFetch = (url, props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    React.useEffect(() => {
        if (url)
            handleFetch(dispatch, url, props)();
    }, [props?.params]);

    return {
        refetch: handleFetch(dispatch, url, props),
        ...Ramda.pick(['loading', 'data'], state),
    };
};

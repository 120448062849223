import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from '@app';

import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: 'https://791feaa6ef9b4b27b29792266e6cc41c@o876941.ingest.sentry.io/6020160',
    environment: 'homol',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
});

const path = require('path');
require('dotenv').config({
    path: path.resolve('.env'),
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, reject } from 'ramda';
import { withRouter } from 'react-router-dom';

import t from '@services/locale';
import device from '@styles';
import { useAnswerSurvey, useGetEntries, useSaveDraft } from '@services/api/hooks';
import {
    ActorsMotricityForm,
    Box,
    Button,
    CriticalUncertaintiesForm,
    EisenhowerForm,
    GenericForm,
    GenericMatrixForm,
    Icon,
    InviteeDataForm,
    Loading,
    MainContainer,
    ModalControl,
    MotricityDependenceForm,
    SnackbarContext,
    SurveyHeader,
    TermsOfUseControl,
} from '@components';

import { getItem, setItem } from '@services/database';
import {
    decodeUserData,
    extractNextErrorMessage,
    parseAnswers,
    parseInviteeData,
} from '@services/utils';

const METADATA_TOKEN = '@baseproject/token';
const INVITEE_KEYS = ['name', 'email', 'title', 'occupation', 'gender', 'city', 'uf', 'country'];

const SUBMIT_HOOK_STATE = {
    answer: {
        tos_retry: 'RETRYING_ANSWER',
        try: 'SAVING_ANSWER',
    },
    default: null,
    draft: {
        tos_retry: 'RETRYING_DRAFT',
        try: 'SAVING_DRAFT',
    },
};

const Form = (props) => {
    const {
        closeSnack, showInfo,
    } = useContext(SnackbarContext);
    const [surveyType, setSurveyType] = useState('');
    const [surveyName, setSurveyName] = useState('');
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [surveyDescription, setSurveyDescription] = useState('');
    const [answers, setAnswers] = useState();
    const [inviteeData, setInviteeData] = useState();
    const [postCompleted, setPostCompleted] = useState({
        draft: false,
        submition: false,
    });
    const [canSubmit, setCanSubmit] = useState(true);
    const [submitHookState, setSubmitHookState] = useState(SUBMIT_HOOK_STATE.default);
    const { match: { params: { token } } } = props;

    const upsertUserMetadata = (response) => {
        // store token that contains the invitee's data for use on their next survey
        if (typeof response?.details === 'string' && response.details.includes('.'))
            setItem(METADATA_TOKEN, { metadata_token: response.details });
    };

    const needsToAcceptAnyTermsOfUse = (error) => {
        const needsToAcceptTermsOfUse = error?.type === 'TOS_ERROR_UNACCEPTED'
            || error?.type === 'TOS_ERROR_UPDATE';

        return {
            isTOSUpdate: error?.type === 'TOS_ERROR_UPDATE',
            needsToAcceptTermsOfUse,
        };
    };

    const onAcceptTOS = () => {
        setSubmitHookState((currentHookState) => {
            let updatedHookState = currentHookState;

            if (currentHookState === SUBMIT_HOOK_STATE.answer.try)
                updatedHookState = SUBMIT_HOOK_STATE.answer.tos_retry;
            if (currentHookState === SUBMIT_HOOK_STATE.draft.try)
                updatedHookState = SUBMIT_HOOK_STATE.draft.tos_retry;

            return updatedHookState;
        });
    };

    const onAPIError = (error) => {
        ModalControl.close();

        const userTOSCheck = needsToAcceptAnyTermsOfUse(error);
        if (userTOSCheck.needsToAcceptTermsOfUse) {
            TermsOfUseControl.show({
                isUpdate: userTOSCheck.isTOSUpdate,
                message: error.msg,
                onAccept: onAcceptTOS,
            });
        } else {
            ModalControl.showError({
                description: error,
                title: 'Problema Encontrado',
            });
        }
    };

    const postParams = useAnswerSurvey({
        onCompleted: (response) => {
            closeSnack();
            ModalControl.close();
            setPostCompleted({
                ...postCompleted,
                submition: true,
            });
            upsertUserMetadata(response);
        },
        onError: onAPIError,
        surveyType,
        token,
    });

    const draftParams = useSaveDraft({
        onCompleted: (response) => {
            closeSnack();
            ModalControl.close();
            setPostCompleted({
                ...postCompleted,
                draft: true,
            });
            upsertUserMetadata(response);
        },
        onError: onAPIError,
        token,
    });

    const hasValidMetadata = (decodedToken) => {
        const isValid = Object
            .keys(decodedToken)
            .some((key) => INVITEE_KEYS.includes(key));

        return isValid;
    };

    const getParams = useGetEntries({
        onCompleted: async (data) => {
            const nonNullInviteeData = reject(isNil, data.invitee);
            let userData = {};
            // only gets data from localstorage if data.invitee is empty
            if (isEmpty(nonNullInviteeData)) {
                const jwtToken = await getItem(METADATA_TOKEN);
                const decodedData = decodeUserData(jwtToken?.metadata_token);
                // checking if decoded data actually has any invitee data
                if (hasValidMetadata(decodedData))
                    userData = decodedData;
            } else
                userData = data.invitee;

            setInviteeData(parseInviteeData(userData));
            setAnswers(parseAnswers(data.survey_type, data.questions));
            setSurveyQuestions(data.questions);
            setSurveyName(data.survey_name);
            setSurveyDescription(data.survey_description);
            setSurveyType(data.survey_type);
        },
        onError: onAPIError,
        surveyType,
        token,
    });

    useEffect(() => {
        if (answers && inviteeData) {
            answers?.map((v, i) => postParams.setValue(`answers.${i}`, v));
            Object.keys(inviteeData)?.map((key) => postParams.setValue(`invitee.${key}`, inviteeData[key]));
        }
    }, [answers, inviteeData]);

    const displayErrorToast = (error) => {
        ModalControl.close();

        const userTOSCheck = needsToAcceptAnyTermsOfUse(error);
        if (userTOSCheck.needsToAcceptTermsOfUse) {
            TermsOfUseControl.show({
                isUpdate: userTOSCheck.isTOSUpdate,
                message: error.msg,
                onAccept: onAcceptTOS,
            });
        } else {
            ModalControl.showError({
                description: extractNextErrorMessage(error, surveyQuestions),
                title: t('Respostas incompletas'),
            });
        }
        console.error(error);
    };

    const handleDraftSave = () => {
        const givenInvitee = postParams.getValues('invitee');
        const givenAnswers = [];
        postParams.getValues('answers').forEach((entry) => {
            if (!entry.answer && typeof entry.answer !== 'number') {
                givenAnswers.push({
                    ...entry,
                    answer: null,
                });
            } else
                givenAnswers.push(entry);
        });

        const setValueOptions = {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        };

        // The draftParams control is not being used, basically what I do is copy the values from postParams
        draftParams.setValue('invitee', givenInvitee, setValueOptions);
        draftParams.setValue('answers', givenAnswers, setValueOptions);

        ModalControl.showInfo({
            description: t('Após o rascunho ser salvo, você pode fechar a pesquisa e continuá-la em outro momento pelo link enviado a seu email'),
            submitConfig: {
                onSubmit: () => {
                    setSubmitHookState(SUBMIT_HOOK_STATE.draft.try);
                    const dispatchSubmit = draftParams.handleSubmit(draftParams.onSubmit, (error) => {
                        // Since there's no control for draftParams being used, I trigger the one from postParams
                        postParams.trigger('invitee');
                        displayErrorToast(error);
                    });
                    dispatchSubmit();
                },
                text: 'Salvar',
            },
            title: t('Salvar rascunho'),
        });
    };

    const handleSubmit = async () => {
        const { errors } = postParams.formState;
        const freeTextArrayIndex = errors.answers?.findIndex((item) => item?.answer?.type === 'length-check');

        if (typeof freeTextArrayIndex === 'number')
            postParams.clearErrors(`answers.${freeTextArrayIndex}.answer`);

        // If no errors found in hook form validation, proceed with request
        if (Object.keys(errors).length === 0) {
            ModalControl.showInfo({
                description: 'Gostaria de confirmar o envio das respostas?',
                submitConfig: {
                    onSubmit: () => {
                        setSubmitHookState(SUBMIT_HOOK_STATE.answer.try);
                        const dispatchSubmit = postParams.handleSubmit(
                            postParams.onSubmit,
                            displayErrorToast,
                        );

                        dispatchSubmit();
                    },
                    text: 'Enviar',
                },
                title: t('Confirmar envio'),
            });
        } else
            displayErrorToast(errors);
    };

    useEffect(() => {
        const setValueOptions = {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        };

        if (submitHookState === SUBMIT_HOOK_STATE.answer.tos_retry) {
            postParams.setValue('accept_tos', true, setValueOptions);
            handleSubmit();
        } else if (submitHookState === SUBMIT_HOOK_STATE.draft.tos_retry) {
            draftParams.setValue('accept_tos', true, setValueOptions);
            handleDraftSave();
        }
    }, [submitHookState]);

    if (postCompleted.submition || postCompleted.draft) {
        const farewellMessage = postCompleted.submition
            ? t('Sua resposta foi registrada com sucesso')
            : t('Seu rascunho foi salvo e o link para você continuar a pesquisa foi mandado a seu email');

        return (
            <MainContainer style={{ height: '90vh' }}>
                <Box data-testid="final-container">
                    <SurveyHeader surveyDescription={farewellMessage} surveyName={surveyName} />
                </Box>
            </MainContainer>
        );
    }

    if (getParams.loading)
        return <Loading mt="30vh" />;

    return (
        <MainContainer>
            <SurveyHeader surveyDescription={surveyDescription} surveyName={surveyName} />

            <Box style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                right: '1.5%',
                top: '1.5%',
                zIndex: 1,
            }}
            >
                <Button
                    data-testid="save-draft-btn"
                    tooltipText={t('Salvar rascunho')}
                    translate={false}
                    type="button"
                    width="6rem"
                    onClick={handleDraftSave}
                >
                    <Icon
                        color="white"
                        name="save"
                        scale={1.3}
                    />
                </Button>
            </Box>

            <InviteeDataForm
                clearErrors={postParams.clearErrors}
                control={postParams.control}
                eraseData={() => {
                    postParams.setValue('invitee.isAbroad', undefined);
                    INVITEE_KEYS.forEach((key) => {
                        postParams.setValue(`invitee.${key}`, undefined);
                        postParams.clearErrors(`invitee.${key}`);
                    });
                    setInviteeData();
                }}
                formState={postParams.formState}
                isAnsweringFromAbroad={
                    !!inviteeData?.country
                        && inviteeData?.country !== 'BRA'
                }
                setValue={postParams.setValue}
                showInfo={showInfo}
                watch={postParams.watch}
            />

            {(surveyType === 'DELPHI' || surveyType === 'PESQUISA GENERICA')
                && (
                    <GenericForm
                        control={postParams.control}
                        formState={postParams.formState}
                        getValues={postParams.getValues}
                        questions={surveyQuestions}
                        setValue={postParams.setValue}
                        surveyType={surveyType}
                    />
                )}

            {surveyType === 'RANKING DAS INCERTEZAS CRITICAS'
                && (
                    <CriticalUncertaintiesForm
                        control={postParams.control}
                        formState={postParams.formState}
                        getValues={postParams.getValues}
                    />
                )}

            {surveyType === 'EISENHOWER'
                && (
                    <EisenhowerForm
                        control={postParams.control}
                        formState={postParams.formState}
                        getValues={postParams.getValues}
                    />
                )}

            {surveyType === 'MOTRICIDADE E DEPENDENCIA'
                && (
                    <MotricityDependenceForm
                        control={postParams.control}
                        getValues={postParams.getValues}
                        onSubmitEnabled={setCanSubmit}
                    />
                )}

            {surveyType === 'MOTRICIDADE DOS ATORES'
                && (
                    <ActorsMotricityForm
                        control={postParams.control}
                        getValues={postParams.getValues}
                        onSubmitEnabled={setCanSubmit}
                    />
                )}

            {surveyType === 'MATRIZ GENERICA'
                && (
                    <GenericMatrixForm
                        control={postParams.control}
                        formState={postParams.formState}
                        getValues={postParams.getValues}
                    />
                )}

            {postParams.loading && <Loading mt="5vh" />}

            <Box style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '5vh',
                marginTop: '8vh',
            }}
            >
                <Button
                    disabled={!canSubmit}
                    fontSize={15}
                    type="button"
                    width={device === 'web' ? null : '20vw'}
                    onClick={handleSubmit}
                >
                    {t('ENVIAR')}
                </Button>
            </Box>
        </MainContainer>
    );
};

Form.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string,
        }),
    }).isRequired,
};

Form.path = ':token';
export default withRouter(Form);

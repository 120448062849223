import styled from 'styled-components';

import { getColor } from '@styles/colors';
import Box from '../Box';

const handleBorderColor = ({ error, focused, theme }) => {
    if (error)
        return getColor(theme.colors, 'red');
    if (focused)
        return getColor(theme.colors, 'grey.900');

    return getColor(theme.colors, 'grey.200');
};

export const Wrapper = styled(Box)`
  border-bottom-width: 2px;
  border-color: ${handleBorderColor};
`;

export const Pressable = styled.button`
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-width: 0;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import TableCell from '@mui/material/TableCell';

import {
    splitColumnValue,
    splitRowValue,
} from '@services/utils';

import styles from './styles';

const HookTableCell = ({
    cellIndex,
    cellIndexShortName,
    control,
    handleCellClick,
    index,
    indexShortName,
    isSquareMatrix,
    numColumns,
    numRows,
    selectedCell,
}) => {
    const selectedRowShortName = selectedCell.shortName ? splitRowValue(selectedCell.shortName) : null;
    const selectedColumnShortName = selectedCell.shortName ? splitColumnValue(selectedCell.shortName) : null;

    const matrixIndexToArrayIndex = (row, column) => {
        // flattening matrix to vector row-major fo square matrix
        if (isSquareMatrix)
            return row * numColumns + column;
        // flattening matrix to vector column-major
        return row + column * numRows;
    };

    // get cell style based on the postion in the square matrix
    const getSquareMatrixStyle = (row, column) => {
        /*
         * Selected cell styling logic
         */
        // cell is the selected cell
        if (selectedRowShortName === indexShortName && selectedColumnShortName === cellIndexShortName)
            return styles.tableSelectedCell;
        // cell is in the same row of the selected cell
        if (selectedRowShortName === indexShortName) {
            // cell column is lesser then selected column row
            if (cellIndex < selectedCell.column) {
                // cell is in the diagonal
                if (row === column)
                    return styles.tableNeighborSelectedDiagonalCell;
                return styles.tableNeighborSelectedCell;
            }
        }
        // cell is in the same column of the selected cell
        if (selectedColumnShortName === cellIndexShortName) {
            // cell row is lesser then selected cell row
            if (index < selectedCell.row) {
                // cell is in the diagonal
                if (row === column)
                    return styles.tableNeighborSelectedDiagonalCell;
                return styles.tableNeighborSelectedCell;
            }
        }

        /*
         * Cell styling logic
         */
        // cell is in the last row of the matrix
        if ((row === (numColumns - 1) && column !== (numColumns - 1)))
            return styles.tableLastRowCell;
        // cell is in the last column of the matrix
        if ((row !== (numColumns - 1) && column === (numColumns - 1)))
            return styles.tableLastColumnCell;
        // cell is in the diagonal
        if (row === column) {
            // cell is in the bottom right of the matrix
            if ((row === (numColumns - 1) && column === (numColumns - 1)))
                return styles.tableBottomRightestCell;
            return styles.tableDiagonalCell;
        }
        return styles.tableClickableCell;
    };

    // get cell style based on the postion in the matrix
    const getMatrixStyle = (row, column) => {
        /*
         * Selected cell styling logic
         */
        // cell is the selected cell
        if (selectedRowShortName === cellIndexShortName && selectedColumnShortName === indexShortName)
            return styles.tableSelectedCell;
            // cell is in the same row of the selected cell
        if (selectedRowShortName === cellIndexShortName) {
            // cell column is lesser then selected column row
            if (index < selectedCell.row)
                return styles.tableNeighborSelectedCell;
        }
        // cell is in the same column of the selected cell
        if (selectedColumnShortName === indexShortName) {
            // cell row is lesser then selected cell row
            if (cellIndex < selectedCell.column)
                return styles.tableNeighborSelectedCell;
        }

        /*
         * Cell styling logic
         */
        // cell is in the last row of the matrix
        if ((row === (numRows - 1) && column !== (numColumns - 1)))
            return styles.tableLastRowCell;
        // cell is in the last column of the matrix
        if ((row !== (numRows - 1) && column === (numColumns - 1)))
            return styles.tableLastColumnCell;
        // cell is in the bottom right of the matrix
        if ((row === (numRows - 1) && column === (numColumns - 1)))
            return styles.tableBottomRightestCell;

        return styles.tableClickableCell;
    };

    const answer = useWatch({
        control,
        // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        name: `answers.${matrixIndexToArrayIndex(index, cellIndex)}`,
    });

    // Decides if answers values are displayed on diagonal cells
    const getCellValue = () => {
        if (isSquareMatrix)
            return index === cellIndex ? null : answer.answer;
        return answer.answer;
    };

    return (
        <TableCell
            align="center"
            style={isSquareMatrix ? getSquareMatrixStyle(index, cellIndex) : getMatrixStyle(index, cellIndex)}
            onClick={() => (isSquareMatrix
                ? handleCellClick(
                    indexShortName,
                    cellIndexShortName,
                    index,
                    cellIndex,
                )
                : handleCellClick(
                    cellIndexShortName,
                    indexShortName,
                    index,
                    cellIndex,
                ))}

        >
            {getCellValue()}
        </TableCell>
    );
};

HookTableCell.defaultProps = {
    isSquareMatrix: undefined,
    numColumns: undefined,
    numRows: undefined,
};

HookTableCell.propTypes = {
    cellIndex: PropTypes.number.isRequired,
    cellIndexShortName: PropTypes.string.isRequired,
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    handleCellClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    indexShortName: PropTypes.string.isRequired,
    isSquareMatrix: PropTypes.bool,
    numColumns: PropTypes.number,
    numRows: PropTypes.number,
    selectedCell: PropTypes.shape({
        column: PropTypes.number,
        row: PropTypes.number,
        shortName: PropTypes.string,
    }).isRequired,
};

export default HookTableCell;

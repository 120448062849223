import React from 'react';
import { createBrowserHistory } from 'history';
import {
    Redirect, Route, Router, Switch,
} from 'react-router-dom';

import routes from './routes';

const history = createBrowserHistory();

if (window.location.hash.startsWith('#/'))
    history.push(window.location.hash.replace('#', ''));

const CustomRoute = () => (
    <Switch>
        {routes.map((item, index) => (
            <Route
                exact
                component={item.component} key={index}
                path={`/${item.path}`}
            />
        ))}
        <Redirect to="/" />
    </Switch>
);

const Routes = () => (
    <Router history={history}>
        <CustomRoute />
    </Router>
);

export default Routes;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Icon,
    Text,
} from '@components/atoms';
import {
    Checkbox,
    CheckboxGroup,
    CustomContainer,
    InputText,
    RadioForm,
    Slider,
    TextArea,
} from '@components/molecules';
import device, { GetParsedColor } from '@styles';

import { NUMERIC_FIELD_TYPES, SLIDER_FIELD_TYPES } from '@constants/survey';
import { getPreviewText } from '@services/utils';

const GenericQuestion = ({
    control, fieldIndex, formState: { errors },
    getValues, idx, question, setValue, surveyType,
}) => {
    // Numeric inputs macros
    let inputProps = { inputMode: 'numeric' };
    if (question.field.includes('RANGED'))
        inputProps = { ...inputProps, max: question.maximum, min: question.minimum };
    if (question.field.includes('INTEGER'))
        inputProps = { ...inputProps, pattern: '[0-9]*' };
    if (question.field.includes('FLOAT'))
        inputProps = { ...inputProps, inputMode: 'decimal' };

    const error = errors?.answers?.[idx]?.answer;

    useEffect(() => {
        if (question.array_len) {
            if (question.criteria_type === 'HAS_ANSWERED') {
                // eslint-disable-next-line default-case
                switch (question.field) {
                    case 'FREE_TEXT_ARRAY':
                        setValue(`answers.${idx}.len`, question.array_len);
                        break;
                    case 'CLOSED_TEXT_ARRAY':
                        setValue(`answers.${idx}.maxAnswers`, question.array_len);
                        break;
                }
            }

            const draftOrNextStageAnswer = question.current_answer || question.last_answer;

            if (draftOrNextStageAnswer) {
                if (question.field === 'CLOSED_TEXT_ARRAY')
                    setValue(`answers.${idx}.answer`, []);

                draftOrNextStageAnswer
                    .split('],[')
                    .forEach((listAnswer, listIndex, { length }) => {
                        const isFirst = listIndex === 0;
                        const isLast = listIndex === length - 1;

                        let formattedAnswer = listAnswer;

                        if (isFirst)
                            formattedAnswer = formattedAnswer.slice(1);

                        if (isLast)
                            formattedAnswer = formattedAnswer.slice(0, -1);

                        setValue(`answers.${idx}.answer.${listIndex}`, formattedAnswer);
                    });
            }
        }
    }, [question.array_len]);

    // Subheading header macros
    const subheading = question.field === 'SUBHEADING';
    const blue = GetParsedColor('blue');
    const black = GetParsedColor('black');

    // Non mandatory slider checkbox handling
    const [noAnswer, setNoAnswer] = useState(true);
    const handleNoAnswer = (val) => {
        setNoAnswer(val);
        if (val)
            setValue(`answers.${idx}.answer`, null);
        else
            setValue(`answers.${idx}.answer`, question.minimum);
    };
    const medianText = `Mediana: ${question.median}`;
    if (!question.enabled)
        return (<></>);

    return (
        <>
            {subheading
                && (
                    <Box
                        style={{
                            background: `linear-gradient(to left, ${black}, ${blue})`,
                            borderRadius: 7,
                            height: 15,
                            marginTop: '10vh',
                        }}
                    />
                )}

            <CustomContainer style={{ position: 'relative', textAlign: subheading ? 'center' : 'start' }}>
                <Text
                    bold
                    color="black"
                    fontSize={subheading ? 20 : 15}
                    mb={subheading ? '3%' : 0}
                    translate={false}
                >
                    {!subheading ? `${fieldIndex}. ` : null}
                    {question.statement}
                </Text>
                <Text
                    style={{
                        flexWrap: 'wrap',
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                        wordWrap: 'break-word',
                    }}
                    translate={false}
                >
                    {question.context}
                </Text>

                {NUMERIC_FIELD_TYPES.includes(question.field)
                    && (
                        <div style={{ display: 'flex', flexDirection: device === 'web' ? 'row' : 'column' }}>
                            <InputText
                                control={control}
                                inputProps={inputProps}
                                name={`answers.${idx}.answer`}
                                placeholder={getPreviewText(question)}
                                translate={false}
                                type="number"
                                width={device === 'web' ? '30vw' : '75vw'}
                            />
                            {(question.median != null && surveyType === 'DELPHI')
                                && (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: device === 'web' ? 0 : '15%' }}>
                                        <Text>
                                            {medianText}
                                        </Text>
                                        <Icon
                                            clickable={false}
                                            color="grey.700"
                                            name="faInfoCircle"
                                            scale={0.8}
                                            tooltipText="Mediana das respostas dos outros participantes da pesquisa"
                                        />
                                    </div>
                                )}
                        </div>
                    )}
                {question.field === 'FREE_TEXT'
                    && (
                        <TextArea
                            control={control}
                            error={error?.message}
                            mt="5vh"
                            name={`answers.${idx}.answer`}
                            placeholder={getPreviewText(question)}
                            translate={false}
                            width={device === 'web' ? '95%' : '75vw'}
                        />
                    )}
                {SLIDER_FIELD_TYPES.includes(question.field)
                    && (
                        <>
                            <Slider
                                control={control}
                                error={error}
                                max={question.field === 'PERCENTAGE' ? 100 : question.maximum}
                                max_label={question.field === 'PERCENTAGE' && '100%'}
                                min={question.field === 'PERCENTAGE' ? 0 : question.minimum}
                                min_label={question.field === 'PERCENTAGE' && '0%'}
                                name={`answers.${idx}.answer`}
                                sliderCallback={() => {
                                    if (question.criteria_type === 'NONE')
                                        setNoAnswer(false);
                                }}
                                steps={question.field === 'PERCENTAGE' ? 100 : question.steps}
                                type={question.field === 'CONTINUOUS_SLIDER' ? 'continuous' : 'discrete'}
                            />
                            {question.criteria_type === 'NONE'
                                && (
                                    <Box style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                    >
                                        <Checkbox
                                            label="Não desejo responder"
                                            ml="1vw"
                                            mt="2vh"
                                            name={`checkbox.${idx}.answer`}
                                            value={noAnswer}
                                            width="12vw"
                                            onChange={handleNoAnswer}
                                        />
                                        <Icon
                                            translate
                                            clickable={false}
                                            color="grey.700"
                                            ml="1vw"
                                            name="questionCircle"
                                            scale={1.2}
                                            tooltipText="Pergunta não obrigatória tooltext"
                                        />
                                    </Box>
                                )}
                            {(question.median != null && surveyType === 'DELPHI')
                                && (
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: device === 'web' ? 0 : '15%' }}>
                                        <Text>
                                            {medianText}
                                        </Text>
                                        <Icon
                                            clickable={false}
                                            color="grey.700"
                                            name="faInfoCircle"
                                            scale={0.8}
                                            tooltipText="Mediana das respostas dos outros participantes da pesquisa"
                                        />
                                    </div>
                                )}
                        </>
                    )}
                {question.field === 'CLOSED_TEXT'
                    && (
                        <RadioForm
                            control={control}
                            error={error?.message}
                            name={`answers.${idx}.answer`}
                            options={question.options}
                        />
                    )}
                {question.field === 'CLOSED_TEXT_ARRAY'
                    && (
                        <CheckboxGroup
                            clearSelection={() => {
                                setValue(`answers.${idx}.answer`, []);
                            }}
                            control={control}
                            error={error?.message}
                            maxAnswers={question.array_len}
                            name={`answers.${idx}.answer`}
                            options={question.options}
                        />
                    )}

                {question.field === 'FREE_TEXT_ARRAY'
                    && Array.from({ length: question.array_len }).map((_, listIndex) => (
                        <TextArea
                            control={control}
                            error={
                                getValues(`answers.${idx}.answer.${listIndex}`)
                                    ? undefined
                                    : error?.message
                            }
                            key={String(listIndex)}
                            mt="5vh"
                            name={`answers.${idx}.answer.${listIndex}`}
                            placeholder={getPreviewText(question)}
                            translate={false}
                            width={device === 'web' ? '95%' : '75vw'}
                        />
                    ))}
            </CustomContainer>
        </>
    );
};

GenericQuestion.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    fieldIndex: PropTypes.number.isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            answers: PropTypes.arrayOf(
                PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
            ),
        }),
    }).isRequired,
    getValues: PropTypes.func.isRequired,
    idx: PropTypes.number.isRequired,
    question: PropTypes.shape({
        array_len: PropTypes.number,
        context: PropTypes.string,
        criteria_type: PropTypes.string,
        criteria_value: PropTypes.number,
        current_answer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        enabled: PropTypes.bool,
        field: PropTypes.string,
        last_answer: PropTypes.number,
        maximum: PropTypes.number,
        median: PropTypes.number,
        minimum: PropTypes.number,
        options: PropTypes.arrayOf(PropTypes.string),
        statement: PropTypes.string,
        steps: PropTypes.number,
    }).isRequired,
    setValue: PropTypes.func.isRequired,
    surveyType: PropTypes.string.isRequired,
};

export default GenericQuestion;

import styled from 'styled-components';

import { getColor } from '@styles/colors';
import {
    colorProps,
    flexProps,
    marginProps,
    paddingProps,
    sizeProps,
} from '@styles/styleProps';

export const Pressable = styled.button`
  ${sizeProps}
  ${flexProps}
  ${colorProps}
  ${paddingProps}
  ${marginProps}
  border-radius: 50px;
  border-width: 0;

  ${({ disabled, theme }) => (disabled
        ? `opacity: 0.7; background-color: ${getColor(theme.colors, 'grey.200')};`
        : '')}
`;

export const NavbarPressable = styled.div`
  ${sizeProps}
  ${flexProps}
  ${colorProps}
  ${paddingProps}
  ${marginProps}
  border-radius: 6px;
  border-width: 0;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const Touchable = styled.button`
  ${flexProps}
  ${paddingProps}
  ${marginProps}
  ${colorProps}
`;

import React from 'react';
import PropTypes from 'prop-types';
import MuSlider from '@mui/material/Slider';
import { Controller } from 'react-hook-form';

import { ThemeProvider } from '@mui/material/styles';

import device from '@styles';

// Styles

// Utils
import { valueLabelFormat } from '@services/utils';
import { GetParsedColor } from '@styles/colors';
import { Box, Text } from '@components/atoms';

import sliderTheme from './styles';

const Slider = ({
    control,
    defaultValue,
    error,
    max,
    max_label,
    min,
    min_label,
    name,
    orientation = 'horizontal',
    steps,
    type = 'discrete',
    sliderCallback = () => { },
}) => {
    const dark_grey = GetParsedColor('grey.900');
    const blue = GetParsedColor('blue');

    min = min ? parseFloat(min, 10) : 0;
    max = max ? parseFloat(max, 10) : 100;
    steps = steps ? parseFloat(steps, 10) : 10;
    const step_size = (max - min) / steps;

    const left_label = orientation === 'horizontal' ? min_label || min : max_label || max;
    const right_label = orientation === 'horizontal' ? max_label || max : min_label || min;

    const getWidth = () => {
        if (orientation === 'horizontal') {
            if (device === 'mobile')
                return '80vw';
            return '35vw';
        }
        return '';
    };

    return (
        <>
            <Box data-testid={name} style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                <ThemeProvider theme={sliderTheme(blue)}>
                    <Box style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: orientation === 'horizontal' ? 'row' : 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                        width: getWidth(),
                    }}
                    >
                        <Text style={{ color: dark_grey, margin: '2vh' }} translate={false}>{left_label}</Text>
                        <Controller
                            as={<Slider max={2 ** 13} step={1024} valueLabelDisplay="auto" />}
                            control={control}
                            defaultValue={defaultValue ?? ''}
                            name={name}
                            render={({ field: { onChange, value } }) => (
                                <MuSlider
                                    aria-labelledby={type === 'discrete' ? 'discrete-slider' : 'input-slider'}
                                    color={value === null ? 'secondary' : 'primary'}
                                    marks={type === 'discrete'}
                                    max={max}
                                    min={min}
                                    orientation={orientation}
                                    role="presentation"
                                    step={type === 'discrete' ? step_size : 0.01}
                                    value={value}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={valueLabelFormat}
                                    onChange={(e, val) => { onChange(val); sliderCallback(name); }}
                                />
                            )}
                        />
                        <Text style={{ color: dark_grey, margin: '2vh' }} translate={false}>{right_label}</Text>
                    </Box>
                </ThemeProvider>
            </Box>
            {error && typeof error === 'string' && (
                <Text color="red" fontSize={13} ml={2} mt={0.5}>
                    {error}
                </Text>
            )}
        </>
    );
};

Slider.defaultProps = {
    control: undefined,
    defaultValue: undefined,
    error: undefined,
    max_label: undefined,
    min: undefined,
    min_label: undefined,
    name: undefined,
    orientation: undefined,
    sliderCallback: undefined,
    steps: undefined,
    type: undefined,
};

Slider.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }),
    defaultValue: PropTypes.string,
    error: PropTypes.string,
    max: PropTypes.number.isRequired,
    max_label: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    min: PropTypes.number,
    min_label: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    orientation: PropTypes.string,
    sliderCallback: PropTypes.func,
    steps: PropTypes.number,
    type: PropTypes.string,
};

export default Slider;

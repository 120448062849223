import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@components/atoms';
import { GetParsedColor } from '@styles';

const CustomContainer = ({ children, style }) => {
    const bg_color = GetParsedColor('white');
    const containerStyle = {
        backgroundColor: bg_color,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '2vh',
        padding: '5%',
        whiteSpace: 'pre-line',
    };

    return (
        <Box style={{ ...containerStyle, ...style }}>
            {children}
        </Box>
    );
};

CustomContainer.defaultProps = {
    style: undefined,
};

CustomContainer.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.shape({
        marginBottom: PropTypes.string,
    }),
};

export default CustomContainer;

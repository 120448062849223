import React from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Text,
} from '@components/atoms';
import {
    CustomContainer,
    Slider,
} from '@components/molecules';
import {
    splitColumnValue,
    splitRowValue,
} from '@services/utils';

const ActorsMotricityQuestion = ({ answers, control, selectedCell }) => {
    const rowVariable = selectedCell.shortName ? splitRowValue(selectedCell.shortName) : null;
    const columnVariable = selectedCell.shortName ? splitColumnValue(selectedCell.shortName) : null;

    const { context, statement } = selectedCell;

    const rowStatement = selectedCell.shortName ? splitRowValue(statement) : null;
    const columnStatement = selectedCell.shortName ? splitColumnValue(statement) : null;

    const rowContext = selectedCell.shortName ? splitRowValue(context) : null;
    const columnContext = selectedCell.shortName ? splitColumnValue(context) : null;

    const selectedCellIndex = selectedCell.shortName
        ? answers.findIndex((element) => element.short_name === selectedCell.shortName)
        : 0;

    return (
        <>
            <CustomContainer style={{ marginBottom: '7vh', marginTop: '2vh' }}>
                {rowVariable
                    && (
                        <Text
                            bold
                            align="center"
                            color="black"
                            fontSize={15}
                            mb="3%"
                        >
                            Indique um valor para a capacidade do ator da esquerda de interferir na variável da direita
                        </Text>
                    )}
                <Box display="flex" flexDirection="row">
                    <Box data-testid="a-side" style={{ flex: 1 }}>
                        <Text bold align="center" color="black" translate={false}>
                            {rowVariable}
                        </Text>
                        <Text align="center" translate={false}>
                            {rowStatement}
                        </Text>
                        <Text align="center" translate={false}>
                            {rowContext}
                        </Text>
                    </Box>
                    <Box data-testid="b-side" style={{ flex: 1 }}>
                        <Text bold align="center" color="black" translate={false}>
                            {columnVariable}
                        </Text>
                        <Text align="center" translate={false}>
                            {columnStatement}
                        </Text>
                        <Text align="center" translate={false}>
                            {columnContext}
                        </Text>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex', justifyContent: 'center', marginTop: '8vh',
                    }}
                >
                    <Slider
                        control={control}
                        defaultValue={0}
                        max={3}
                        max_label={3}
                        min={0}
                        min_label={0}
                        name={`answers.${selectedCellIndex}.answer`}
                        steps={3}
                        type="discrete"
                    />
                </Box>
            </CustomContainer>
        </>
    );
};

ActorsMotricityQuestion.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.shape({
        answer: PropTypes.number,
    })).isRequired,
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    selectedCell: PropTypes.shape({
        column: PropTypes.number,
        context: PropTypes.string,
        row: PropTypes.number,
        shortName: PropTypes.string,
        statement: PropTypes.string,
    }).isRequired,
};

export default ActorsMotricityQuestion;

import * as Yup from 'yup';

import moment from 'moment';

import t from '@services/locale';
import { TEXT_FIELD_TYPES } from '@constants/survey';

const SPECIAL_CHAR_REGEX = /[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]/;
const createLabeledSliderSchema = (field) => ({
    is: field,
    then: Yup.number()
        .typeError(t('Pergunta obrigatória'))
        .required(t('Pergunta obrigatória'))
        .min(1, `${t('O valor mínimo para esta questão é')} ${1}`)
        .max(5, `${t('O valor máximo para esta questão é')} ${5}`),
});

const createPercentLikeSchema = (field) => ({
    is: field,
    then: Yup.number()
        .typeError(t('Pergunta obrigatória'))
        .required(t('Pergunta obrigatória'))
        .min(0, `${t('O valor mínimo para esta questão é')} ${0}`)
        .max(100, `${t('O valor máximo para esta questão é')} ${100}`),
});

const GENERIC_SCHEMA = Yup.mixed()
    // FIELD TYPE
    .when('field', {
        is: (field) => TEXT_FIELD_TYPES.includes(field),
        otherwise: Yup.number(),
        then: Yup.string(),
    })
    .when('field', (field, schema) => {
        if (field === 'FREE_TEXT_ARRAY' || field === 'CLOSED_TEXT_ARRAY')
            return Yup.array().of(Yup.string());
        return schema;
    })
    .when('len', (len, schema) => {
        if (len) {
            return schema
                .test(
                    'length-check',
                    `É necessário responder todas as ${len} perguntas`,
                    (value) => value?.filter(Boolean)?.length === len,
                );
        }
        return schema;
    })
    // MIN MAX THRESHOLDS
    .when('minimum', (minimum, schema) => (minimum
        ? schema.min(minimum, `${t('O valor mínimo para esta questão é')} ${minimum}`)
        : schema))
    .when('maximum', (maximum, schema) => (maximum
        ? schema.max(maximum, `${t('O valor máximo para esta questão é')} ${maximum}`)
        : schema))
    // INTEGER OR FLOAT TYPE
    .when('field', (field, schema) => (field.includes('INTEGER')
        ? schema.integer(t('O valor deve ser inteiro'))
        : schema))
    // REQUIRED
    .when('criteria_type', (criteria_type, schema) => (criteria_type === 'HAS_ANSWERED'
        ? schema
            .typeError(t('Pergunta obrigatória'))
            .required(t('Pergunta obrigatória'))
        : schema
            .nullable(true)
            .transform((v) => {
                const isValidNumber = typeof v === 'number' && !Number.isNaN(v);
                if (isValidNumber)
                    // allow number 0
                    return v ?? null;

                return v || null;
            })))
    .when(['criteria_type', 'maxAnswers'], {
        is: (criteria_type, maxAnswers) => criteria_type === 'HAS_ANSWERED' && typeof maxAnswers === 'number',
        then: (schema) => schema
            .min(1, 'Pelo menos uma opção deve ser selecionada')
            .max(Yup.ref('maxAnswers'), ({ max }) => `Deve escolher no máximo ${max} opções`),
    });

const CRITICAL_UNCERTAINTIES_SCHEMA = Yup.number()
    // CRITICAL UNCERTAINTIES - IMPORTANCE
    .when('field', createLabeledSliderSchema('IMPORTANCE_VALUE'))
    // CRITICAL UNCERTAINTIES - UNCERTAINTY
    .when('field', createPercentLikeSchema('UNCERTAINTY_VALUE'))
    .when('field', createPercentLikeSchema('PERCENTAGE'));

const EISENHOWER_SCHEMA = Yup.number()
    // EISENHOWER - IMPORTANCE
    .when('field', createLabeledSliderSchema('IMPORTANCE_VALUE'))
    // EISENHOWER - URGENCY
    .when('field', createLabeledSliderSchema('URGENCY_VALUE'));

const MOTRICITY_DEPENDENCE_SCHEMA = Yup.number()
    // MORTICITY DEPENDENCE - INTERFERENCE
    .when('field', {
        is: 'INTERFERENCE_VALUE',
        then: Yup.number()
            .typeError(t('Pergunta obrigatória'))
            .required(t('Pergunta obrigatória'))
            .min(0, `${t('O valor mínimo para esta questão é')} ${0}`)
            .max(3, `${t('O valor máximo para esta questão é')} ${3}`),
    });

const ACTORS_MOTRICITY_SCHEMA = Yup.number()
    // ACTORS' MOTRICITY - ACTOR
    .when('field', {
        is: 'ACTOR_INFLUENCE_VALUE',
        then: Yup.number()
            .typeError(t('Pergunta obrigatória'))
            .required(t('Pergunta obrigatória'))
            .min(0, `${t('O valor mínimo para esta questão é')} ${0}`)
            .max(3, `${t('O valor máximo para esta questão é')} ${3}`),
    })
    // ACTORS' MOTRICITY - VARIABLE
    .when('field', {
        is: 'VARIABLE_INFLUENCE_VALUE',
        then: Yup.number()
            .typeError(t('Pergunta obrigatória'))
            .required(t('Pergunta obrigatória'))
            .min(0, `${t('O valor mínimo para esta questão é')} ${0}`)
            .max(3, `${t('O valor máximo para esta questão é')} ${3}`),
    });

const ANSWERS_SCHEMA = (surveyType) => {
    if (surveyType === 'RANKING DAS INCERTEZAS CRITICAS')
        return CRITICAL_UNCERTAINTIES_SCHEMA;
    if (surveyType === 'EISENHOWER')
        return EISENHOWER_SCHEMA;
    if (surveyType === 'MOTRICIDADE E DEPENDENCIA')
        return MOTRICITY_DEPENDENCE_SCHEMA;
    if (surveyType === 'MOTRICIDADE DOS ATORES')
        return ACTORS_MOTRICITY_SCHEMA;
    return GENERIC_SCHEMA;
};

const INVITEE_SCHEMA = Yup.object().shape({
    city: Yup.string()
        .when('isAbroad', {
            is: (value) => !value,
            otherwise: (schema) => schema,
            then: (schema) => schema.required(t('A cidade é obrigatória')).typeError(t('A cidade é obrigatória')),
        }),
    country: Yup.string()
        .when('isAbroad', {
            is: (value) => !!value,
            otherwise: (schema) => schema,
            then: (schema) => schema.required(t('O país é obrigatória')).typeError(t('O país é obrigatória')),
        }),
    email: Yup.string()
        .email(t('Email inválido'))
        .required(t('O email é obrigatório'))
        .typeError(t('O email é obrigatório'))
        .trim()
        .lowercase(),
    gender: Yup.string()
        .required(t('O sexo é obrigatório'))
        .typeError(t('O sexo é obrigatório')),
    isAbroad: Yup.boolean(),
    name: Yup.string()
        .required(t('O nome é obrigatório'))
        .typeError(t('O nome é obrigatório')),
    occupation: Yup.string()
        .required(t('A ocupação é obrigatória'))
        .typeError(t('A ocupação é obrigatória')),
    title: Yup.string()
        .required(t('A titulação é obrigatória'))
        .typeError(t('A titulação é obrigatória')),
    uf: Yup.string()
        .when('isAbroad', {
            is: (value) => !value,
            otherwise: (schema) => schema,
            then: (schema) => schema.required(t('A UF é obrigatória')).typeError(t('A UF é obrigatória')),
        }),
});

export const EXPERT_SCHEMA = Yup.object().shape({
    accept_tos: Yup.boolean().test(
        'is-true',
        'É necessário aceitar os termos para criação da conta',
        (value) => value === true,
    ),
    birth: Yup.string()
        .required(t('Dia de nascimento é obrigatório'))
        .test('Data de Aniversário', 'Precisa ter mais de 18 anos', (value) => moment().diff(moment(value, 'YYYY-DD-MM'), 'years') >= 18)
        .typeError(t('Dia de nascimento é obrigatório')),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Senhas não coincidem'),
    email: Yup.string()
        .email(t('Email inválido'))
        .required(t('O email é obrigatório'))
        .typeError(t('O email é obrigatório'))
        .trim(),
    gender: Yup.string()
        .required(t('O sexo é obrigatório'))
        .typeError(t('O sexo é obrigatório')),
    name: Yup.string()
        .required(t('O nome é obrigatório'))
        .typeError(t('O nome é obrigatório')),
    occupation: Yup.string()
        .required(t('A ocupação é obrigatória'))
        .typeError(t('A ocupação é obrigatória')),
    password: Yup.string()
        .min(6, t('Senha deve conter 6 caracteres'))
        .required(t('Senha é obrigatória'))
        .matches(SPECIAL_CHAR_REGEX, t('Senha deve ter no mínimo um caractere especial'))
        .matches(/[A-Z]+/, t('Senha deve ter no mínimo uma letra maiúscula')),
    title: Yup.string()
        .required(t('A titulação é obrigatória'))
        .typeError(t('A titulação é obrigatória')),
    title_extension: Yup.string()
        .required(t('Especificação da titulação é obrigatório'))
        .typeError(t('Especificação da titulação é obrigatório')),
});

export const FORM_SUBMIT_SCHEMA = (surveyType) => Yup.object().shape({
    answers: Yup.array().of(
        Yup.object().shape({
            answer: ANSWERS_SCHEMA(surveyType),
            criteria_type: Yup.string().required(),
            field: Yup.string().required(),
            maximum: Yup.number().nullable(),
            minimum: Yup.number().nullable(),
            num_order: Yup.number(),
        }),
    ),
    invitee: INVITEE_SCHEMA,
});

export const FORM_DRAFT_SCHEMA = Yup.object().shape({
    answers: Yup.array().of(
        Yup.object().shape({
            num_order: Yup.number(),
        }).nullable(),
    ),
    invitee: INVITEE_SCHEMA,
});

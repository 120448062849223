import React, {
    createRef, forwardRef,
    useImperativeHandle, useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { Box, Text } from '@components/atoms';
import { Button, Checkbox, Logo } from '@components/molecules';
import { GetParsedColor } from '@styles/colors';
import { BASE_URL } from '@constants/api';

const INITIAL_MODAL_STATE = {
    isOpen: false,
    isUpdate: false,
    message: null,
    onAccept: null,
    onDecline: null,
};

const Modal = (_, ref) => {
    const [config, setConfig] = useState(INITIAL_MODAL_STATE);
    const [hasAcceptedTheTermsOfUse, setHasAcceptedTheTermsOfUse] = useState(false);
    const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const GREY_BORDER = GetParsedColor('grey.800');
    const linkToTermsOfUse = `${BASE_URL}/v1/auth/tos`;

    const close = () => {
        setConfig(INITIAL_MODAL_STATE);
        setHasAcceptedTheTermsOfUse(false);
        setShowDeclineConfirmation(false);
    };

    const show = (openedConfig) => {
        setConfig({
            isOpen: true,
            isUpdate: openedConfig.isUpdate,
            message: openedConfig.message,
            onAccept: openedConfig.onAccept,
            onDecline: openedConfig.onDecline,
        });
    };

    useImperativeHandle(ref, () => ({
        close, show,
    }));

    const handleConfirmDecline = () => {
        setShowDeclineConfirmation(true);
    };

    const handleDismissDecline = () => {
        setShowDeclineConfirmation(false);
    };

    let title = 'Termos de Uso do SocialPort Form';
    const description = {
        anchor: ' Link',
        paragraph: `${config.message}.`,
    };
    let confirmationText = 'Eu li e aceito os Termos de Uso.';

    if (config.isUpdate) {
        title = `Atualização dos ${title}`;
        confirmationText = 'Eu reli e aceito os novos Termos de Uso.';
    }

    return (
        <>
            <Dialog
                open={!showPDF && config.isOpen}
                onClose={(event, reason) => {
                    if (reason === 'escapeKeyDown' || reason === 'backdropClick')
                        return;
                    close(event);
                }}
            >
                <DialogTitle>
                    <Logo
                        name="PurpleLogoCollapsed"
                        style={{
                            height: '2rem',
                        }}
                        tooltipText="SocialPort Form"
                    />
                </DialogTitle>
                <DialogContent
                    dividers
                    data-testid="accept_terms_modal"
                >
                    {!showDeclineConfirmation && (
                        <Box>
                            <Text.Title
                                style={{ marginBottom: '1.2rem' }}
                            >
                                {title}
                            </Text.Title>
                            <Text>
                                {description.paragraph}
                                <Text
                                    color="blue"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setShowPDF(true);
                                    }}
                                >
                                    {description.anchor}
                                </Text>
                            </Text>
                            <Checkbox
                                label={confirmationText}
                                mb="1rem"
                                mt="2rem"
                                name="accept_terms_checkbox"
                                value={hasAcceptedTheTermsOfUse}
                                width="auto"
                                onChange={setHasAcceptedTheTermsOfUse}
                            />
                        </Box>
                    )}
                    {showDeclineConfirmation && (
                        <Box>
                            <Text.Title
                                style={{ margin: 0 }}
                            >
                                Confirme a sua intenção
                            </Text.Title>
                            <Text
                                style={{ marginBottom: 0 }}
                            >
                                O aceite dos termos de uso é necessário para uso da plataforma SocialPort Form.
                                Em caso de rejeição, você ficará impossibilitado de responder o formulário.
                            </Text>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    {!showDeclineConfirmation && (
                        <>
                            <Button
                                bgColor="white"
                                color="grey.800"
                                height="2.5rem"
                                style={{
                                    border: `1px solid ${GREY_BORDER}`,
                                }}
                                width="12rem"
                                onClick={handleConfirmDecline}
                            >
                                Cancelar
                            </Button>
                            <Button
                                data-testid="accept_terms_button"
                                disabled={!hasAcceptedTheTermsOfUse}
                                height="2.5rem"
                                width="12rem"
                                onClick={() => {
                                    config.onAccept();
                                    close();
                                }}
                            >
                                Ok
                            </Button>
                        </>
                    )}
                    {showDeclineConfirmation && (
                        <>
                            <Button
                                bgColor="red"
                                height="2.5rem"
                                width="12rem"
                                onClick={close}
                            >
                                Cancelar
                            </Button>
                            <Button
                                height="2.5rem"
                                width="12rem"
                                onClick={handleDismissDecline}
                            >
                                Voltar
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                open={showPDF}
                sx={{
                    '& .MuiDialog-paper': {
                        maxHeight: 'unset',
                        maxWidth: 'unset',
                    },
                }}
                onClose={() => setShowPDF(false)}
            >
                <Box
                    height="90vh"
                    width="90vw"
                >
                    <Box
                        style={{
                            display: 'flex',
                            height: '100%',
                            overflow: 'hidden',
                            padding: 0,
                            width: '100%',
                        }}
                    >
                        <iframe
                            src={`${linkToTermsOfUse}?t=${Date.now()}`}
                            style={{
                                border: 0,
                                flexGrow: 1,
                                margin: 0,
                                padding: 0,
                            }}
                            title="renderer"
                        />
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

const ref = createRef();
const ForwardedModal = forwardRef(Modal);
export const TermsOfUseControl = {
    close() {
        const modal = ref.current;
        modal?.close();
    },
    show(config) {
        const modal = ref.current;
        modal?.show(config);
    },
};

const TermsOfUse = () => (
    <ForwardedModal
        ref={ref}
    />
);

export default TermsOfUse;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import device from '@styles';
import { CITIES, COUNTRIES, UFS } from '@constants/locations';
import { GENDERS, OCCUPATIONS, TITLES } from '@constants/survey';
import { Box, Icon } from '@components/atoms';
import {
    Checkbox,
    CustomContainer,
    Dropdown,
    InputText,
} from '@components/molecules';

const InviteeDataForm = ({
    clearErrors, control, eraseData,
    formState: { errors }, isAnsweringFromAbroad: isAbroadFromCache,
    setValue, showInfo, watch,
}) => {
    const styles = {
        inviteeFieldContainer: {
            display: 'flex',
            flexDirection: device === 'mobile' ? 'column' : 'row',
            justifyContent: 'space-between',
        },
    };
    const width = device === 'web' ? '30vw' : '75vw';
    const uf = watch('invitee.uf') || undefined;
    const country = watch('invitee.country') || undefined;

    const [isAnsweringFromAbroad, setIsAnsweringFromAbroad] = useState(false);
    const isFirstRenderRef = useRef(true);

    const clearLocationErrors = () => {
        clearErrors('invitee.city');
        clearErrors('invitee.uf');
        clearErrors('invitee.country');
    };

    const onCheckboxChange = (isAbroad) => {
        setIsAnsweringFromAbroad(isAbroad);
        setValue('invitee.isAbroad', isAbroad);
    };

    useEffect(() => {
        if (isAbroadFromCache)
            setValue('invitee.isAbroad', true);

        setIsAnsweringFromAbroad(isAbroadFromCache);
    }, [isAbroadFromCache]);

    useEffect(() => {
        if (country === 'BRA' && isAnsweringFromAbroad) {
            onCheckboxChange(false);
            showInfo({
                message: 'Escolha também a UF e depois a cidade em que está',
                title: 'Você está em território nacional',
            });
        }
    }, [country]);

    useEffect(() => {
        if (isAnsweringFromAbroad) {
            setValue('invitee.city', '');
            setValue('invitee.uf', '');
            setValue('invitee.country', '');

            clearLocationErrors();
        }

        if (!isAnsweringFromAbroad && !isFirstRenderRef.current)
            clearLocationErrors();

        isFirstRenderRef.current = false;
    }, [isAnsweringFromAbroad]);

    return (
        <CustomContainer
            style={{
                marginBottom: '7vh',
                position: 'relative',
            }}
        >
            <Icon
                clickable
                color="grey.700"
                handleClick={() => {
                    eraseData();
                }}
                name="erase"
                style={{
                    position: 'absolute',
                    right: '0.5rem',
                    top: '0.5rem',
                }}
                tooltipText="Apagar meus dados"
            />
            <Box mb="2vh" style={styles.inviteeFieldContainer}>
                <InputText
                    translate
                    control={control}
                    error={errors?.invitee?.name?.message}
                    name="invitee.name"
                    p="0vw"
                    placeholder="Nome"
                    width={width}
                />
                <InputText
                    control={control}
                    error={errors?.invitee?.email?.message}
                    mt={device === 'mobile' ? '2vh' : '0'}
                    name="invitee.email"
                    p="0vw"
                    placeholder="E-mail"
                    width={width}
                />
            </Box>
            <Box style={styles.inviteeFieldContainer}>
                {!isAnsweringFromAbroad && (
                    <Dropdown
                        translate
                        control={control}
                        // error={errors?.invitee?.uf?.message}
                        items={UFS}
                        name="invitee.uf" // not working (?)
                        placeholder="UF"
                        width={width}
                        onClose={() => setValue('invitee.city', undefined)}
                    />
                )}
                {isAnsweringFromAbroad && (
                    <Dropdown
                        translate
                        control={control}
                        items={COUNTRIES}
                        name="invitee.country"
                        placeholder="País"
                        width={width}
                        onClose={() => setValue('invitee.country', undefined)}
                    />
                )}
                {!uf && (
                    <Checkbox
                        maxWidth
                        translate
                        label="Estou fora do Brasil"
                        ml="3rem"
                        name="isAbroad"
                        value={isAnsweringFromAbroad}
                        onChange={onCheckboxChange}
                    />
                )}

                {!!uf
                && (
                    <Dropdown
                        translate
                        control={control}
                        // error={errors?.invitee?.city?.message}
                        items={CITIES[uf]}
                        name="invitee.city"
                        placeholder="Cidade"
                        width={width}
                    />
                )}
            </Box>
            <Box style={styles.inviteeFieldContainer}>
                <Dropdown
                    translate
                    valueTranslate
                    control={control}
                    // error={errors?.invitee?.title?.message}
                    items={TITLES}
                    name="invitee.title"
                    placeholder="Última titulação"
                    width={width}
                />
                <Dropdown
                    translate
                    valueTranslate
                    control={control}
                    // error={errors?.invitee?.occupation?.message}
                    items={OCCUPATIONS}
                    name="invitee.occupation"
                    placeholder="Vínculo Institucional"
                    width={width}
                />
            </Box>
            <Box style={styles.inviteeFieldContainer}>
                <Dropdown
                    control={control}
                    // error={errors?.invitee?.gender?.message}
                    items={GENDERS}
                    name="invitee.gender"
                    placeholder="Sexo"
                    width={width}
                />
            </Box>
        </CustomContainer>
    );
};

InviteeDataForm.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    eraseData: PropTypes.func.isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            invitee: PropTypes.shape({
                city: PropTypes.shape({
                    message: PropTypes.string,
                }),
                email: PropTypes.shape({
                    message: PropTypes.string,
                }),
                gender: PropTypes.shape({
                    message: PropTypes.string,
                }),
                name: PropTypes.shape({
                    message: PropTypes.string,
                }),
                occupation: PropTypes.shape({
                    message: PropTypes.string,
                }),
                title: PropTypes.shape({
                    message: PropTypes.string,
                }),
                uf: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }),
        }),
    }).isRequired,
    isAnsweringFromAbroad: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
    showInfo: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
};

export default InviteeDataForm;

const getPreviousValue = (obj) => {
    if (obj.current_answer || typeof obj.current_answer === 'number')
        return obj.current_answer;

    if (obj.last_answer)
        return obj.last_answer;

    return undefined;
};

export const genericParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    return initializedField;
};

export const percentageParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    if (initializedField)
        return initializedField;
    if (obj.criteria_type === 'NONE')
        return null;
    return 0;
};

export const sliderParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    if (initializedField)
        return initializedField;
    if (obj.criteria_type === 'NONE')
        return null;
    return obj.minimum;
};

export const labeledSliderParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    return initializedField || 1;
};

export const uncertaintySliderParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    return initializedField || 0;
};

export const integerParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    return !initializedField
        ? undefined
        : parseInt(initializedField, 10).toString();
};

export const interferenceSliderParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    return initializedField || 0;
};

export const influenceSliderParser = (obj) => {
    const initializedField = getPreviousValue(obj);
    return initializedField || 0;
};

export const inviteeParser = (value) => {
    if (value)
        return value;
    return '';
};

export const parseAnswers = (surveyType, questions) => {
    const parsers = {
        ACTOR_INFLUENCE_VALUE: influenceSliderParser,
        CLOSED_TEXT: genericParser,
        CLOSED_TEXT_ARRAY: genericParser,
        CONTINUOUS_SLIDER: sliderParser,
        DISCRETE_SLIDER: sliderParser,
        FREE_TEXT: genericParser,
        FREE_TEXT_ARRAY: genericParser,
        IMPORTANCE_VALUE: labeledSliderParser,
        INTERFERENCE_VALUE: interferenceSliderParser,
        OPEN_FLOAT: genericParser,
        OPEN_INTEGER: integerParser,
        OPEN_RANGED_FLOAT: genericParser,
        OPEN_RANGED_INTEGER: integerParser,
        PERCENTAGE: uncertaintySliderParser,
        SUBHEADING: genericParser,
        UNCERTAINTY_VALUE: uncertaintySliderParser,
        URGENCY_VALUE: labeledSliderParser,
        VARIABLE_INFLUENCE_VALUE: influenceSliderParser,
    };

    if (surveyType === 'RANKING DAS INCERTEZAS CRITICAS' || surveyType === 'EISENHOWER') {
        return questions.map((obj) => ({
            answer: parsers[obj.field](obj),
            context: obj.context,
            criteria_type: obj.criteria_type,
            field: obj.field,
            num_order: obj.num_order,
            statement: obj.statement,
        }));
    }

    if (surveyType === 'MATRIZ GENERICA') {
        const axes = {
            max: questions[0].maximum,
            min: questions[0].minimum,
            x: questions[1].axis,
            y: questions[0].axis,
        };

        return questions.map((obj) => ({
            answer: parsers[obj.field](obj),
            axes,
            context: obj.context,
            criteria_type: obj.criteria_type,
            field: 'GENERIC_MATRIX_SLIDER',
            num_order: obj.num_order,
            statement: obj.statement,
        }));
    }

    // Add short_name for m&d survey type
    if (surveyType === 'MOTRICIDADE E DEPENDENCIA') {
        return questions.map((obj) => ({
            answer: parsers[obj.field](obj),
            context: obj.context,
            criteria_type: obj.criteria_type,
            field: obj.field,
            num_order: obj.num_order,
            short_name: obj.short_name,
            statement: obj.statement,
        }));
    }

    // Add short_name for as'm survey type
    if (surveyType === 'MOTRICIDADE DOS ATORES') {
        return questions.map((obj) => ({
            answer: parsers[obj.field](obj),
            context: obj.context,
            criteria_type: obj.criteria_type,
            field: obj.field,
            num_order: obj.num_order,
            short_name: obj.short_name,
            statement: obj.statement,
        }));
    }

    // PESQUISA GENERICA, DELPHI and remaining survey types
    return questions.map((obj) => ({
        answer: parsers[obj.field](obj),
        context: obj.context,
        criteria_type: obj.criteria_type,
        field: obj.field,
        maximum: obj.maximum,
        minimum: obj.minimum,
        num_order: obj.num_order,
        options: obj.options,
        statement: obj.statement,
        steps: obj.steps,
    }));
};

export const parseInviteeData = (data) => {
    const parsed_data = {};
    Object.entries(data).forEach(([key, value]) => {
        parsed_data[key] = inviteeParser(value);
    });
    return parsed_data;
};

import React, {
    useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

import device from '@styles';
import t from '@services/locale';
import { GENDERS, OCCUPATIONS, TITLES } from '@constants/survey';
import {
    CustomContainer,
    Dropdown,
    InputText,
    LocalizedDatePicker,
    TextArea,
} from '@components/molecules';
import {
    Box,
    Button,
    Checkbox,
    Icon,
    MainContainer,
    ModalControl,
    SnackbarContext,
    SurveyHeader,
    Text,
} from '@components';

import {
    decodeUserData,
    extractNextErrorMessageSignup,
} from '@services/utils';

import { useSignupExpert } from '@services/api/hooks';

const INVITEE_KEYS = ['name', 'email', 'title', 'occupation', 'gender', 'city', 'uf', 'country'];

const ExpertDataForm = () => {
    const styles = {
        inviteeFieldContainer: {
            display: 'flex',
            flexDirection: device === 'mobile' ? 'column' : 'row',
            justifyContent: 'space-between',
        },
    };
    const { closeSnack } = useContext(SnackbarContext);
    const width = device === 'web' ? '30vw' : '75vw';
    const [specTitle, setSpecTitle] = useState(false);
    const [postCompleted, setPostCompleted] = useState({
        submition: false,
    });

    const token = new URLSearchParams(window.location.search).get('token');
    const tokenData = decodeUserData(token);
    const { invited_email, server: endpoint } = tokenData;
    const [acceptTOS, setAcceptTOS] = useState(false);
    const [showPDF, setShowPDF] = useState(false);

    const onAPIError = (error) => {
        ModalControl.close();
        ModalControl.showError({
            description: error,
            title: 'Problema Encontrado',
        });
    };
    const postParams = useSignupExpert({
        endpoint,
        onCompleted: () => {
            closeSnack();
            ModalControl.close();
            setPostCompleted({
                ...postCompleted,
                submition: true,
            });
        },
        onError: onAPIError,
        token,
    });

    const handleAcceptTOS = (val) => {
        setAcceptTOS(val);
        postParams.setValue('accept_tos', val);
    };

    const eraseData = () => {
        INVITEE_KEYS.forEach((key) => {
            postParams.setValue(`${key}`, undefined);
            postParams.clearErrors(`${key}`);
        });
    };

    const displayErrorToast = (error) => {
        ModalControl.close();
        ModalControl.showError({
            description: extractNextErrorMessageSignup(error),
            title: 'Informações Incompletas',
        });
        console.error(error);
    };

    const handleSubmit = async () => {
        const { errors } = postParams.formState;
        const freeTextArrayIndex = errors.answers?.findIndex((item) => item?.answer?.type === 'length-check');

        if (typeof freeTextArrayIndex === 'number')
            postParams.clearErrors(`answers.${freeTextArrayIndex}.answer`);

        // If no errors found in hook form validation, proceed with request
        if (Object.keys(errors).length === 0) {
            ModalControl.showInfo({
                description: 'Gostaria de confirmar o envio das informações?',
                submitConfig: {
                    onSubmit: () => {
                        const dispatchSubmit = postParams.handleSubmit(
                            postParams.onSubmit,
                            displayErrorToast,
                        );

                        dispatchSubmit();
                    },
                    text: t('Enviar'),
                },
                title: t('Confirmar envio'),
            });
        } else
            displayErrorToast(errors);
    };

    if (postCompleted.submition) {
        const farewellMessage = 'Agora você pode acessar a Rede SocialPort pelo aplicativo no seu celular. Baixe agora na app Store ou Google Play!';
        return (
            <MainContainer style={{ height: '90vh' }}>
                <Box data-testid="final-container">
                    <SurveyHeader surveyDescription={farewellMessage} surveyName="Cadastro realizado com sucesso!" />
                </Box>
            </MainContainer>
        );
    }

    const {
        control, formState, setValue, trigger, watch,
    } = postParams;
    const signup_text = 'Cadastro na Rede SocialPort';
    const linkToTermsOfUse = `https://${endpoint}/auth/tos`;

    return (
        <MainContainer>
            <SurveyHeader surveyName={signup_text} />

            <CustomContainer
                style={{
                    marginBottom: '7vh',
                    position: 'relative',
                }}
            >
                <Icon
                    clickable
                    color="grey.700"
                    handleClick={() => {
                        eraseData();
                        setSpecTitle(false);
                    }}
                    name="erase"
                    style={{
                        position: 'absolute',
                        right: '0.5rem',
                        top: '0.5rem',
                    }}
                    tooltipText="Apagar meus dados"
                />
                <Box mb="2vh" style={styles.inviteeFieldContainer}>
                    <InputText
                        control={control}
                        error={formState?.errors?.name?.message}
                        name="name"
                        p="0vw"
                        placeholder="Nome"
                        width={width}
                    />
                    <InputText
                        control={control}
                        defaultValue={invited_email}
                        error={formState?.errors?.name?.message}
                        mt={device === 'mobile' ? '2vh' : '0'}
                        name="email"
                        p="0vw"
                        placeholder="E-mail"
                        width={width}
                    />
                </Box>
                <Box style={styles.inviteeFieldContainer}>
                    <Dropdown
                        control={control}
                        error={formState?.errors?.gender?.message}
                        items={GENDERS}
                        name="gender"
                        placeholder="Sexo"
                        width={width}
                    />
                    <Dropdown
                        control={control}
                        error={formState?.errors?.occupation?.message}
                        items={OCCUPATIONS}
                        name="occupation"
                        placeholder="Vínculo Institucional"
                        width={width}
                    />
                </Box>
                <Box style={styles.inviteeFieldContainer}>
                    <Dropdown
                        control={control}
                        error={formState?.errors?.title?.message}
                        items={TITLES}
                        name="title"
                        placeholder="Última titulação"
                        width={width}
                        onClose={() => setSpecTitle(true)}
                    />
                    {specTitle && (
                        <InputText
                            control={control}
                            error={formState?.errors?.name?.message}
                            mt={device === 'mobile' ? '2vh' : '0'}
                            name="title_extension"
                            p="0vw"
                            placeholder="Especificação Titularidade"
                            width={width}
                        />
                    )}

                </Box>
                <Box mb="2vh" style={styles.inviteeFieldContainer}>
                    <LocalizedDatePicker
                        control={control}
                        error={formState?.errors?.birth?.message}
                        name="birth"
                        placeholder="Data de nascimento"
                        setValue={(name, value) => { setValue(name, value); trigger(); }}
                        watch={watch}
                        width="auto"
                    />
                    <Box
                        position="relative"
                        width="30vw"
                    >
                        <Box
                            style={{
                                alignItems: 'baseline',
                                display: 'flex',
                            }}
                        >
                            <Checkbox
                                label="Eu li e aceito os "
                                mt="2vh"
                                name="accept_tos"
                                sx={{ marginRight: '0.5px' }}
                                value={acceptTOS}
                                width="auto"
                                onChange={handleAcceptTOS}
                            />
                            <Text
                                color="blue"
                                fontSize="1rem"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setShowPDF(true);
                                }}
                            >
                                Termos de Uso
                            </Text>
                        </Box>
                        {typeof formState?.errors?.accept_tos?.message === 'string' && (
                            <Text
                                color="red"
                                fontSize={13}
                                style={{ bottom: '-0.5rem', position: 'absolute' }}
                            >
                                {formState?.errors?.accept_tos?.message}
                            </Text>
                        )}
                    </Box>
                </Box>
            </CustomContainer>
            <CustomContainer
                style={{
                    marginBottom: '7vh',
                    position: 'relative',
                }}
            >
                <Box mb="2vh" style={styles.inviteeFieldContainer}>
                    <TextArea
                        control={control}
                        name="bio"
                        placeholder="Bio"
                        width="60vw"
                    />
                </Box>
            </CustomContainer>
            <CustomContainer
                style={{
                    marginBottom: '7vh',
                    position: 'relative',
                }}
            >
                <Box mb="2vh" style={styles.inviteeFieldContainer}>
                    <InputText
                        password
                        control={control}
                        error={formState?.errors?.name?.message}
                        mt={device === 'mobile' ? '2vh' : '0'}
                        name="password"
                        p="0vw"
                        placeholder="Senha"
                        width={width}
                    />
                </Box>
                <Box mb="2vh" style={styles.inviteeFieldContainer}>
                    <InputText
                        password
                        control={control}
                        error={formState?.errors?.name?.message}
                        mt={device === 'mobile' ? '2vh' : '0'}
                        name="confirm_password"
                        p="0vw"
                        placeholder="Confirmar senha"
                        width={width}
                    />
                </Box>
            </CustomContainer>
            <Box style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '5vh',
                marginTop: '8vh',
            }}
            >
                <Button
                    fontSize={15}
                    mt="5vh"
                    width={device === 'web' ? null : '20vw'}
                    onClick={handleSubmit}
                >
                    ENVIAR
                </Button>
            </Box>
            <Dialog
                open={showPDF}
                sx={{
                    '& .MuiDialog-paper': {
                        maxHeight: 'unset',
                        maxWidth: 'unset',
                    },
                }}
                onClose={() => setShowPDF(false)}
            >
                <Box
                    height="90vh"
                    width="90vw"
                >
                    <Box
                        style={{
                            display: 'flex',
                            height: '100%',
                            overflow: 'hidden',
                            padding: 0,
                            width: '100%',
                        }}
                    >
                        <iframe
                            src={`${linkToTermsOfUse}?t=${Date.now()}`}
                            style={{
                                border: 0,
                                flexGrow: 1,
                                margin: 0,
                                padding: 0,
                            }}
                            title="renderer"
                        />
                    </Box>
                </Box>
            </Dialog>
        </MainContainer>
    );
};

ExpertDataForm.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            invitee: PropTypes.shape({
                city: PropTypes.shape({
                    message: PropTypes.string,
                }),
                email: PropTypes.shape({
                    message: PropTypes.string,
                }),
                gender: PropTypes.shape({
                    message: PropTypes.string,
                }),
                name: PropTypes.shape({
                    message: PropTypes.string,
                }),
                occupation: PropTypes.shape({
                    message: PropTypes.string,
                }),
                title: PropTypes.shape({
                    message: PropTypes.string,
                }),
                uf: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }),
        }),
    }).isRequired,
};

ExpertDataForm.path = 'socialport/signup';
export default ExpertDataForm;

import React from 'react';
import PropTypes from 'prop-types';

import { GenericMatrixQuestion } from '@components/organisms';

const GenericMatrixForm = ({ control, getValues }) => {
    let fields = getValues().answers;
    fields = fields.filter((value, index) => !(index % 2));

    return (
        <>
            {fields.map((question, idx) => (
                <GenericMatrixQuestion
                    control={control}
                    idx={idx}
                    key={String(question.num_order)}
                    question={question}
                />
            ))}
        </>
    );
};

GenericMatrixForm.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    getValues: PropTypes.func.isRequired,
};

export default GenericMatrixForm;

import { createTheme } from '@mui/material/styles';

const sliderTheme = (root_color) => createTheme({
    overrides: {
        MuiSlider: {
            active: {},
            colorPrimary: {
                color: root_color,
                height: 8,
                width: '22vw',
            },
            colorSecondary: {
                color: 'gray',
                height: 8,
                width: '22vw',
            },
            mark: {
                backgroundColor: '#bfbfbf',
                height: 8,
                width: 1,
            },
            rail: {
                borderRadius: 4,
                height: 8,
            },
            root: {
                color: root_color,
                height: 8,
                width: '22vw',
            },
            thumb: {
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                height: 24,
                marginLeft: -12,
                marginTop: -8,
                width: 24,
            },
            track: {
                borderRadius: 4,
                height: 8,
            },
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            vertical: {
                '& $mark': {
                    height: 1,
                    width: 8,
                },
                '& $rail': {
                    width: '8px !important',
                },
                '& $track': {
                    width: '8px !important',
                },
            },
        },
    },
});

export default sliderTheme;

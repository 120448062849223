import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import axios from 'axios';

import { GET_ENTRIES, POST_ANSWERS, SAVE_DRAFT } from '@constants/api';
import { convertAnswers } from '@services/utils';

import { EXPERT_SCHEMA, FORM_DRAFT_SCHEMA, FORM_SUBMIT_SCHEMA } from '@services/api/schemas';

import { useFetch, useLazyFetch } from './base';

export const useGetEntries = ({ onCompleted, onError, token }) => {
    const { data, loading, refetch } = useFetch(GET_ENTRIES(token), { onCompleted, onError });
    return { data, loading, refetch };
};

export const useSignupExpert = ({
    endpoint, onCompleted, onError, token,
}) => {
    const hookFormProps = useForm({
        defaultValues: {
            accept_tos: false,
            bio: '',
            birth: '',
            confirm_password: '',
            gender: '',
            name: '',
            occupation: '',
            password: '',
            title: '',
            title_extension: '',
        },
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(EXPERT_SCHEMA),
    });

    const onSubmit = async (values) => {
        try {
            const result = await axios.post(
                `https://${endpoint}/users/?invite_token=${token}`,
                values,
            );
            if (onCompleted)
                onCompleted(result);
        } catch (error) {
            console.error(error);
            if (error.response?.data?.detail)
                onError(error.response?.data?.detail);
            else
                onError('Erro inesperado');
        }
    };

    return {
        ...hookFormProps, onSubmit,
    };
};

export const useAnswerSurvey = ({
    onCompleted, onError, surveyType, token,
}) => {
    const [onPost, { data, loading }] = useLazyFetch(POST_ANSWERS(token), {
        bodyType: 'json',
        method: 'post',
        onError,
    });

    const hookFormProps = useForm({
        defaultValues: {
            accept_tos: null,
            answers: [
                {
                    answer: '',
                    context: '',
                    criteria_type: '',
                    field: '',
                    maximum: null,
                    minimum: null,
                    num_order: 0,
                    options: '',
                    short_name: '',
                    statement: '',
                    steps: '',
                },
            ],
            invitee: {
                city: '',
                email: '',
                gender: '',
                name: '',
                occupation: '',
                title: '',
                uf: '',
            },
        },
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(FORM_SUBMIT_SCHEMA(surveyType)),
    });
    const onSubmit = async (values) => {
        values['answers'] = convertAnswers(values['answers']);
        if (values['invitee']?.uf && values['invitee']?.city)
            values['invitee'].country = 'BRA';
        else {
            delete values['invitee']?.uf;
            delete values['invitee']?.city;
        }

        const result = await onPost({ params: values });
        if (onCompleted)
            onCompleted(result);
    };

    return {
        ...hookFormProps, data, loading, onSubmit,
    };
};

export const useSaveDraft = ({
    onCompleted, onError, token,
}) => {
    const [onPost, { data, loading }] = useLazyFetch(SAVE_DRAFT(token), {
        bodyType: 'json',
        method: 'post',
        onError,
    });

    const hookFormProps = useForm({
        defaultValues: {
            accept_tos: null,
            answers: [
                {
                    answer: '',
                    context: '',
                    criteria_type: '',
                    field: '',
                    maximum: null,
                    minimum: null,
                    num_order: 0,
                    options: '',
                    short_name: '',
                    statement: '',
                    steps: '',
                },
            ],
            invitee: {
                city: '',
                email: '',
                gender: '',
                name: '',
                occupation: '',
                title: '',
                uf: '',
            },
        },
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(FORM_DRAFT_SCHEMA),
    });
    const onSubmit = async (values) => {
        values['answers'] = convertAnswers(values['answers']);
        if (values['invitee']?.uf && values['invitee']?.city)
            values['invitee'].country = 'BRA';
        else {
            delete values['invitee']?.uf;
            delete values['invitee']?.city;
        }

        const result = await onPost({ params: values });
        if (onCompleted)
            onCompleted(result);
    };

    return {
        ...hookFormProps, data, loading, onSubmit,
    };
};

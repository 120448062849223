import React from 'react';
import PropTypes from 'prop-types';

import {
    colorPropsTypes,
    marginPropsTypes,
    paddingPropsTypes,
    sizePropsTypes,
} from '@styles/styleProps';
import Box from '../Box';
import Icon from '../Icon';

import { Pressable, Wrapper } from './styles';

const InputWrapper = ({
    children,
    error,
    height,
    iconLeft,
    iconRight,
    mt,
    onPressRight,
    p,
}) => (
    <>
        <Wrapper
            error={error}
            // focused={focused}
            height={height || 6}
            p={p || '1vw'}
            style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'row',
            }}
            // theme={theme}
        >
            {!!iconLeft && (
                <Icon fillColor="grey.800" name={iconLeft} />
            )}
            <Box ml={iconLeft ? '1vw' : 0} mr={iconRight ? '1vw' : 0} mt={mt}>
                {children}
            </Box>
            {!!iconRight && (
                <Pressable onClick={onPressRight}>
                    <Icon fillColor="grey.800" name={iconRight} scale={1.2} />
                </Pressable>
            )}
        </Wrapper>
    </>
);

InputWrapper.defaultProps = {
    children: undefined,
    iconLeft: undefined,
    iconRight: undefined,
    onPressRight: undefined,
};

InputWrapper.propTypes = {
    children: PropTypes.node,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    onPressRight: PropTypes.func,
    ...colorPropsTypes,
    ...sizePropsTypes,
    ...marginPropsTypes,
    ...paddingPropsTypes,
};

export default InputWrapper;

import * as Sentry from '@sentry/react';
import I18n from 'i18n-js';

import en from './en-US';
import pt from './pt-BR';

I18n.translations = {
    'en-US': en,
    en_US: en,
    pt,
    'pt-BR': pt,
    pt_BR: pt,
};

const setLanguageToI18n = () => {
    const userLang = navigator.language || navigator.userLanguage;
    const language = userLang;
    const iHaveThisLanguage = I18n.translations.hasOwnProperty(language);
    if (iHaveThisLanguage)
        I18n.locale = language;
    else
        (I18n.defaultLocale = 'pt_BR');

    if (!iHaveThisLanguage)
        I18n.locale = 'pt_BR';
};

setLanguageToI18n();

const translate = (key, variable = null) => {
    if (key && I18n.t(key, variable).includes('missing')) {
        console.warn(`missing translation of this key -> ${key}`);
        Sentry.captureException(new Error(`missing translation of this key -> ${key}`));
    }
    return (I18n.t(key, variable).includes('missing') ? key : I18n.t(key, variable));
};

export default translate;

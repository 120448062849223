import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@components/atoms';
import { CustomContainer, Slider } from '@components/molecules';
import t from '@services/locale';

const EisenhowerQuestion = ({
    control, formState: { errors }, idx, question,
}) => {
    const importance_idx = question.num_order - 1;
    const urgency_idx = question.num_order;

    return (
        <CustomContainer>
            <Text bold color="black" translate={false}>
                {idx + 1}
                .&nbsp;
                {question.statement}
            </Text>
            <Text translate={false}>{question.context}</Text>

            <Text bold>Importância</Text>
            <Slider
                control={control}
                error={errors?.answers?.importance_idx?.answer?.message}
                max={5}
                max_label={t('Alta')}
                min={1}
                min_label={t('Baixa')}
                name={`answers.${importance_idx}.answer`}
                steps={4}
                type="discrete"
            />

            <Text bold>Urgência</Text>
            <Slider
                control={control}
                error={errors?.answers?.urgency_idx?.answer?.message}
                max={5}
                max_label={t('Alta')}
                min={1}
                min_label={t('Baixa')}
                name={`answers.${urgency_idx}.answer`}
                steps={4}
                type="discrete"
            />
        </CustomContainer>
    );
};

EisenhowerQuestion.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func,
    }).isRequired,
    formState: PropTypes.shape({
        errors: PropTypes.shape({
            answers: PropTypes.shape({
                importance_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
                urgency_idx: PropTypes.shape({
                    answer: PropTypes.shape({
                        message: PropTypes.string,
                    }),
                }),
            }),
        }),
    }).isRequired,
    idx: PropTypes.number.isRequired,
    question: PropTypes.shape({
        context: PropTypes.string,
        num_order: PropTypes.number,
        statement: PropTypes.string,
    }).isRequired,
};

export default EisenhowerQuestion;
